import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    matchingCardData: []
}

export const matchingCardSlice = createSlice({
    name: 'matchingCardSlice',
    initialState,
    reducers: {
        setMatchingCard: (state, action) => {
            state.matchingCardData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setMatchingCard
} = matchingCardSlice.actions
export default matchingCardSlice.reducer