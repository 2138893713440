import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function SideBar() {
  const value: any = localStorage.getItem("user");
  const filterData: any = localStorage.getItem("filterData");
  var getadmin: any = JSON.parse(value)?.role;
  var type: any = JSON.parse(value)?.type;
  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">
      <aside
        className="fixed inset-y-0 block w-full p-0 my-4  antialiased overflow-y-auto transition-transform duration-200 -translate-x-full bg-themeColor border-0 shadow-xl max-w-25 ease-nav-brand xl:ml-5 rounded-2xl xl:left-0 xl:translate-x-0 overflow-x-hidden"
        aria-expanded="false"
      >
        <div className="sticky top-0 bg-themeColor z-10">
          <i
            className="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden"
            sidenav-close="true"
          ></i>
          <NavLink
            to="/"
            className="block p-3 m-auto text-sm whitespace-nowrap text-black"
          >
            <img
              src="../../../assets/images/logo.png"
              className="inline border-4 border-themeBgColor rounded-full transition-all duration-200 ease-nav-brand h-20 w-20"
              alt="SATV"
            />
            {/* <img src="./assets/img/logo-ct.png"
                            className="hidden h-full max-w-full transition-all duration-200 dark:inline ease-nav-brand max-h-10"
                            alt="main_logo" /> */}
            {/* <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand"></span> */}
          </NavLink>
        </div>

        <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />

        <div className="flex flex-col items-center ">
          <div className="rounded-md">
            <ul className="flex flex-col pl-0 mb-0">
              <li className="w-full transition-all ease-in-out py-1">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                      : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent text-white hover:text-black btn btn-1"
                  }
                  onClick={() => localStorage.removeItem("filterData")}
                >
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center p-4">
                    <img
                      src="../../../assets/images/Home.svg"
                      className="inline transition-all duration-200 h-10 w-10"
                      alt="Home"
                    />
                  </div>
                </NavLink>
                <div className="duration-300 leading-normal pt-1 px-4 text-center text-sm text-white pointer-events-none ease">
                  Dashboard
                </div>
              </li>

              {getadmin === "superadmin" ||
              getadmin === "admin" ||
              getadmin === "designer" ||
              getadmin === "programmer" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/samplecarddashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent text-white hover:text-black btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <img
                          src="../../../assets/images/MatchingCard.svg"
                          className="inline transition-all duration-200 h-10 w-10"
                          alt="Sample Cards"
                        />
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Sample Card
                    </div>
                  </li>
                </>
              ) : null}
              {getadmin === "superadmin" ||
              getadmin === "admin" ||
              getadmin === "designer" ||
              getadmin === "programmer" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/matchingcarddashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent text-white hover:text-black btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <img
                          src="../../../assets/images/MatchingCard.svg"
                          className="inline transition-all duration-200 h-10 w-10"
                          alt="Matching Cards"
                        />
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Matching Card
                    </div>
                  </li>
                </>
              ) : null}
              {getadmin === "superadmin" ||
              getadmin === "admin" ||
              getadmin === "programmer" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/jobworkdashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <img
                          src="../../../assets/images/MatchingCard.svg"
                          className="inline transition-all duration-200 h-10 w-10"
                          alt="Job work"
                        />
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Job Work
                    </div>
                  </li>
                </>
              ) : null}
              {getadmin === "superadmin" ||
              getadmin === "admin" ||
              getadmin === "programmer" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/employeereportdashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent text-white hover:text-black btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <NavLink
                          to="/employeereportdashboard"
                          className={({ isActive }) =>
                            isActive
                              ? "relative top-0 text-3xl text-black far fa-file-alt"
                              : "relative top-0 text-3xl text-black fas fa-file-alt"
                          }
                        ></NavLink>
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Employee Report
                    </div>
                  </li>
                </>
              ) : null}
              {getadmin === "works" ||
              type === "all" ||
              getadmin === "superadmin" ||
              getadmin === "admin" ||
              getadmin === "programmer" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/matchingcarddashboardstock"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent text-white hover:text-black btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <img
                          src="../../../assets/images/MatchingCard.svg"
                          className="inline transition-all duration-200 h-10 w-10"
                          alt="Matching Cards Stock"
                        />
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Stock
                    </div>
                  </li>
                </>
              ) : null}
              {getadmin === "superadmin" || getadmin === "admin" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/dressratecarddashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <img
                          src="../../../assets/images/MatchingCard.svg"
                          className="inline transition-all duration-200 h-10 w-10"
                          alt="Rate Card"
                        />
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Rate Card
                    </div>
                  </li>
                </>
              ) : null}
              {getadmin === "superadmin" ||
              getadmin === "admin" ||
              getadmin === "designer" ||
              getadmin === "programmer" ? (
                <>
                  <li className="mt-0.5 w-full transition-all ease-in-out py-2">
                    <NavLink
                      to="/partylist"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1 "
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <NavLink
                          to="/partylist"
                          className={({ isActive }) =>
                            isActive
                              ? "relative top-0 text-3xl text-black fas fa-images"
                              : "relative top-0 text-3xl text-black fas fa-image"
                          }
                        ></NavLink>
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Party Images
                    </div>
                  </li>
                </>
              ) : null}

              {getadmin === "admin" || getadmin === "superadmin" ? (
                <>
                  <li className="w-full transition-all ease-in-out py-1">
                    <NavLink
                      to="/user"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                          : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent btn btn-1"
                      }
                      onClick={() => localStorage.removeItem("filterData")}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <NavLink
                          to="/user"
                          className={({ isActive }) =>
                            isActive
                              ? "relative top-0 text-3xl text-black fas fa-users"
                              : "relative top-0 text-3xl text-black fas fa-user"
                          }
                          onClick={() => localStorage.removeItem("filterData")}
                        ></NavLink>
                      </div>
                    </NavLink>
                    <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                      Users
                    </div>
                  </li>
                </>
              ) : null}

              {getadmin !== "works" && (
                <li className="w-full transition-all ease-in-out py-1">
                  <NavLink
                    to="/setting"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                        : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent text-white hover:text-black btn btn-1"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <NavLink
                        to="/setting"
                        className={({ isActive }) =>
                          isActive
                            ? "relative top-0 text-3xl text-black fas fa-cogs"
                            : "relative top-0 text-3xl text-black fas fa-cog"
                        }
                        onClick={() => localStorage.removeItem("filterData")}
                      ></NavLink>
                    </div>
                  </NavLink>
                  <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                    Data Setting
                  </div>
                </li>
              )}

              <li className="w-full transition-all ease-in-out py-1">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive
                      ? "py-2 cursor-pointer bg-white hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                      : "py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 transition-colors flex-col bg-transparent btn btn-1"
                  }
                >
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <i className="relative top-0 text-3xl text-black fas fa-users"></i>
                  </div>
                </NavLink>
                <div className="duration-300 pt-1 px-4 leading-4 text-center text-sm text-white pointer-events-none ease">
                  Profile
                </div>
              </li>
              <li className="w-full transition-all ease-in-out py-1">
                <a
                  className="py-2 cursor-pointer hover:bg-white/70 text-sm ease-nav-brand my-0 mx-3 flex items-center whitespace-nowrap rounded-xl px-4 text-black transition-colors flex-col btn btn-1"
                  href="#"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <i className="relative top-0 text-3xl fas fa-user-lock"></i>
                  </div>
                </a>
                <div className="duration-300 pt-1 px-4 text-center text-sm text-white pointer-events-none ease">
                  Sign out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
}
