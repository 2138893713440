import { useNavigate } from "react-router-dom";
import { useLazyAllRateCardQuery, useLazyRateCardPartyQuery } from "../../servicesRtkQuery/publicApi";
import { useEffect, useState } from "react";
import CountUp from 'react-countup';

export default function PartyList() {
    const navigate = useNavigate()
    const [rateCardPartyTrigger, rateCardPartyResult] = useLazyRateCardPartyQuery()
    const { isSuccess: isSuccessRateCardParty, isFetching: isFetchingRateCardParty } = rateCardPartyResult

    const [allTrigger, allResult] = useLazyAllRateCardQuery()
    const { isSuccess: isAllSuccess, isFetching: isAllFetching } = allResult

    const [allSatvCount, setAllSatvCount]: any = useState(0);
    const [partyNames, setPartyNames]: any = useState([])

    useEffect(() => {
        rateCardPartyTrigger()
        allTrigger()
    }, [])

    useEffect(() => {
        if (isSuccessRateCardParty && !isFetchingRateCardParty) {
            setPartyNames(rateCardPartyResult?.data?.data)
        }
    }, [isSuccessRateCardParty, isFetchingRateCardParty, rateCardPartyResult?.data?.data])

    useEffect(() => {
        if (isAllSuccess && !isAllFetching) {
            setAllSatvCount(allResult?.data?.data?.dress + allResult?.data?.data?.saree + allResult?.data?.data?.lump)
        }
    }, [isAllSuccess, isAllFetching])

    return (
        <div className="m-0 mt-20 lg:mt-0 xl:mt-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl flex bg-themeBgColor items-center p-0 bg-cover">
            <div className="w-full mx-6 overflow-hidden my-5">
                <div>
                    <nav className="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-xl bg-gray-50" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <label className="inline-flex items-center text-sm font-medium text-gray-700">
                                    <i className="fas fa-images mr-2"></i>
                                    Party Images
                                </label>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="w-full bg-white rounded-xl">
                    <div className="py-2 break-words border-0 border-transparent border-solid bg-clip-border grid grid-cols-2 justify-center items-center gap-2 bg-themeBgColor">
                        <div className="flex justify-center border bg-white rounded-xl">
                            <button className="w-50 max-50 px-3" onClick={() => navigate(`/satvratecardimage`)}>
                                <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs focus:bg-gray-100 focus:shadow-xs">
                                    <div className="flex-auto p-4">
                                        <div className="relative flex flex-row justify-center -mx-3">
                                            <i className="fa fa-folder text-9xl text-orange-500"></i>
                                            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-orange-500 border-2 border-white rounded-full top-4 right-7"><CountUp end={allSatvCount} duration={0.5} /></div>
                                        </div>
                                        <div className="text-center break-words overflow-clip">
                                            Satv Images
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="flex justify-center border bg-white rounded-xl">
                            <button className="w-50 max-50 px-3" onClick={() => navigate(`/partyratecardimage`)}>
                                <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs focus:bg-gray-100 focus:shadow-xs">
                                    <div className="flex-auto p-4">
                                        <div className="relative flex flex-row justify-center -mx-3">
                                            <i className="fa fa-folder text-9xl text-yellow-500"></i>
                                            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-yellow-500 border-2 border-white rounded-full top-4 right-7"><CountUp end={allSatvCount} duration={0.5} /></div>
                                        </div>
                                        <div className="text-center break-words overflow-clip">
                                            All Party Images
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" rounded-xl bg-white">
                    <div className="mx-2">
                        <div className="mb-6 py-4 break-words border-0 border-transparent border-solid bg-clip-border grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-center md:justify-start items-center">
                            {partyNames?.map((party: any, i: any) => {
                                return (
                                    <button key={i} className="w-40 max-40" onClick={() => navigate(`/ratecardimage/${party?._id}?party=${party?.name}&saree=${party?.sareeTotal}&dress=${party?.dressTotal}&lump=${party?.lumpTotal}`)}>
                                        <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs">
                                            <div className="flex-auto py-4">
                                                <div className="relative flex flex-row justify-center -mx-3">
                                                    <i className="fa fa-folder text-9xl text-themeColor/70"></i>
                                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-themeColor border-2 border-white rounded-full top-4 right-8"><CountUp end={party?.dressTotal + party?.sareeTotal + party?.lumpTotal} duration={0.5} /></div>
                                                </div>
                                                <div className="text-center break-words overflow-clip">
                                                    {party?.name}
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}