/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useUpdateEmployeeMutation, useAddEmployeeMutation, useLazyAllEmployeeQuery, useLazyGetEmployeeQuery } from '../../servicesRtkQuery/publicApi'
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Swal from 'sweetalert2';
import { employeeValidation } from '../../utils/validation';
import TextBox from '../../components/form/TextBox';
import customStyles, { capitalize } from '../../utils/globalFunctions';
import { ERROR_MESSAGE_TIMEOUT } from '../../utils/constants';
import moment from 'moment';
import { SettingTableAction } from '../../components/modal/SettingTableAction';

const Employee = () => {
    const [employeeTrigger, employeeResult] = useLazyAllEmployeeQuery()
    const { isSuccess, isFetching, isError, error } = employeeResult
    const [employeeData, setEmployeeData]: any = useState<any>([])

    const [newEmployeeData, setNewEmployeeData]: any = useState({
        name: "",
        type: []
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState("");

    const [totalRows, setTotalRows]: any = useState(0);
    const [perPage, setPerPage]: any = useState(25);
    const [page, setPage]: any = useState(1);

    const Close = <i className='fas fa-times bg-black text-white rounded-full px-2 py-1.5'></i>
    useEffect(() => {
        if (!filterText) {
            employeeTrigger({ page: page, limit: perPage });
        }
    }, [page, perPage]);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setEmployeeData(employeeResult?.data?.data?.employee)
            setTotalRows(employeeResult?.data?.data?.count);
        }
    }, [isSuccess, isFetching])

    const columns: any = [
        {
            name: "name",
            selector: (row: { [x: string]: any; }) => row?.name,
            sortable: true
        },
        {
            name: "Created At",
            selector: (row: { [x: string]: any; }) => moment(row?.createAt).format("DD-MM-YYYY"),
            sortable: true
        },
        {
            name: "type",
            selector: (row: { [x: string]: any; }) => row?.type?.join?.(", "),
            sortable: true
        }
    ];
    const [addSetting, addResult] = useAddEmployeeMutation()
    const [updateUser, updateResult] = useUpdateEmployeeMutation()
    const [updateRow, setUpdateRows] = useState<any>();

    const handleUpdate = () => {
        let typeArray: any = []
        if (skechersCheck) {
            typeArray.push("skecher");
        }
        if (designCheck) {
            typeArray.push("designer");
        }
        if (imageCheck) {
            typeArray.push("image");
        }
        let updatedData = {
            name: capitalize(newEmployeeData.name.trim()),
            type: typeArray
        }
        const result: any = employeeValidation(updatedData)
        if (result === true) {
            let id = updateRow._id
            updateUser({
                payload: updatedData,
                query: {
                    eId: id
                }
            }).then((data: any) => {
                if (data.error) {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: data.error.data.message,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: ERROR_MESSAGE_TIMEOUT,
                        timerProgressBar: true
                    })
                }
                else {
                    setIsModalOpen(false)
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Employee has been Successfully Updated!",
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    })
                    if (!filterText) {
                        employeeTrigger({ page: page, limit: perPage });
                    }
                }
            })

        }
        else {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.errorMessage,
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }

    }

    const handleDelete = (row: any) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let id = row._id
                let updatedData = {
                    name: row.name,
                    type: row.type,
                    status: false
                }
                updateUser({
                    payload: updatedData,
                    query: {
                        eId: id
                    }
                }).then((data: any) => {
                    if (data.error) {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: data.error.data.message,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: ERROR_MESSAGE_TIMEOUT,
                            timerProgressBar: true
                        })
                    }
                    else {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: "Employee has been deleted!",
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        })
                        if (!filterText) {
                            employeeTrigger({ page: page, limit: perPage });
                        }
                    }
                })
            }
        });

    }

    const onHandleChange = (e: any) => {
        setNewEmployeeData({
            ...newEmployeeData,
            [e.target.name]: e.target.value,
        });

    };

    const handleSubmit = (e: any) => {
        let typeArray: any = []
        if (skechersCheck) {
            typeArray.push("skecher");
        }
        if (designCheck) {
            typeArray.push("designer");
        }
        if (imageCheck) {
            typeArray.push("image");
        }
        let updatedData = {
            name: capitalize(newEmployeeData.name.trim()),
            type: typeArray,
        }
        const result: any = employeeValidation(updatedData)
        if (result === true) {
            addSetting(updatedData).then((data: any) => {
                if (data.error) {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: data.error.data.message,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: ERROR_MESSAGE_TIMEOUT,
                        timerProgressBar: true
                    })
                }
                else {
                    setIsModalOpen(false)
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Employee has been Successfully Added!",
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    })
                    if (!filterText) {
                        employeeTrigger({ page: page, limit: perPage });
                    }
                }
            })
        }
        else {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.errorMessage,
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }

    const modalClose = () => {
        setIsModalOpen(false)
    }

    function editFunction(row: any) {
        setIsModalOpen(true);
        setUpdateRows(row);
        setIsAdd(false);
        let updatedData = {
            name: row.name,
        };
        row?.type?.includes("skecher") ? setSkechersCheck(true) : setSkechersCheck(false);
        row?.type?.includes("designer") ? setDesignCheck(true) : setDesignCheck(false);
        row?.type?.includes("image") ? setImageCheck(true) : setImageCheck(false);
        setNewEmployeeData(updatedData);
    }
    const [skechersCheck, setSkechersCheck] = useState(false);
    const [imageCheck, setImageCheck] = useState(false);
    const [designCheck, setDesignCheck] = useState(false);

    const [isAdd, setIsAdd] = useState<any>(false)

    // filter search
    const [employee, result] = useLazyGetEmployeeQuery()
    const { isSuccess: isESucces, isFetching: isEFetching, isError: isEErorr, error: Eerror } = result
    const [filterText, setFilterText] = useState('');
    useEffect(() => {
        if (filterText) {
            let payload = {
                name: filterText,
                page: page,
                limit: perPage
            }
            employee(payload)
        }
        if (!filterText) {
            employeeTrigger({ page: page, limit: perPage })
        }
    }, [filterText, page, perPage])
    useEffect(() => {
        if (isESucces && !isEFetching) {
            setEmployeeData(result?.data?.data?.employee)
            setTotalRows(result?.data?.data?.count)
        }
    }, [isESucces, isEFetching])
    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            employeeTrigger({ page: page, limit: perPage })
        }
    };

    // pagination 
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const handlePerRowsChange = async (newPerPage: any) => {
        setPerPage(newPerPage);
    }
    return (
        <div className="mb-6 break-words border-0 border-transparent border-solid bg-clip-border text-black">
            <div className="px-3 py-4 flex justify-between items-center">
                <button className="px-4 py-2  text-xs md:text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                    onClick={() => {
                        setIsModalOpen(true);
                        setIsAdd(true);
                        let updatedData = {
                            metaKey: "",
                            metaValue: [],
                        }
                        setNewEmployeeData(updatedData)
                        setDesignCheck(false)
                        setSkechersCheck(false)
                        setImageCheck(false)
                    }}>
                    <i className='fas fa-user-plus'></i><span className='ml-2'>Add Employee</span>
                </button>
                <div className='relative w-[90px] md:w-max'>
                    <TextBox
                        id="search"
                        type="text"
                        placeholder=" "
                        label="Search"
                        aria-label="Search Input"
                        value={filterText}
                        onChange={(e: any) => {
                            setFilterText(e?.target?.value)
                        }}
                    />
                    {filterText ?
                        <i className='fas fa-times-circle text-gray-600 cursor-pointer right-3 top-3 absolute' onClick={handleClear}></i>
                        : null
                    }
                </div>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
                <div className="px-5 overflow-x-auto">
                    <DataTable
                        title="Employees"
                        columns={columns}
                        data={employeeData}
                        fixedHeader={true}
                        defaultSortFieldId="id"
                        defaultSortAsc={false}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                        paginationDefaultPage={page}
                        paginationPerPage={perPage}
                        striped={true}
                        responsive={true}
                        highlightOnHover
                        persistTableHead
                        customStyles={customStyles}
                        onRowClicked={(e: any) => {
                            setSelectedRow(e);
                            setIsActionModalOpen(true)
                        }}
                    />
                </div>
            </div>

            <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto rounded">
                    {isModalOpen ?
                        <Modal
                            open={isModalOpen}
                            closeIcon={Close}
                            onClose={modalClose}
                            center
                            onEscKeyDown={() => setIsModalOpen(false)}
                            onOverlayClick={() => setIsModalOpen(false)}
                            classNames={{
                                modal: "rounded-xl"
                            }}
                            styles={{
                                overlay: {
                                    height: "auto",
                                },
                            }}
                        >
                            <form className="w-100 p-2">
                                <div className="flex flex-wrap mb-6">
                                    <div className="w-full md:w-4/5 px-3">
                                        <TextBox name="name" htmlFor="name" id="name" type="text" placeholder=" " className="text-lg" label="Name" value={newEmployeeData?.name} onChange={(e: any) => onHandleChange(e)} />
                                    </div>
                                </div>

                                <div className="flex flex-wrap mb-6">
                                    <div className="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                                        <input
                                            id="skechers"
                                            name='skechers'
                                            type="checkbox"
                                            checked={skechersCheck}
                                            onChange={(e) => {
                                                setSkechersCheck(!skechersCheck)
                                            }}
                                            className="mx-2"
                                        />
                                        <label htmlFor="skechers">
                                            Skecher
                                        </label>
                                    </div>
                                    <div className="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                                        <input
                                            id="image"
                                            name='image'
                                            type="checkbox"
                                            checked={imageCheck}
                                            onChange={(e) => {
                                                setImageCheck(!imageCheck)
                                            }}
                                            className="mx-2"
                                        />
                                        <label htmlFor="image">
                                            Image
                                        </label>
                                    </div>
                                    <div className="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                                        <input
                                            id="designer"
                                            name='designer'
                                            type="checkbox"
                                            checked={designCheck}
                                            onChange={(e) => {
                                                setDesignCheck(!designCheck)
                                            }}
                                            className="mx-2"
                                        />
                                        <label htmlFor="designer">
                                            Designer
                                        </label>
                                    </div>
                                </div>
                                {
                                    isAdd ?
                                        <button className="ml-4 px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                                            onClick={handleSubmit}>
                                            Add
                                        </button>
                                        :
                                        <button className="ml-4 px-3 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                                            onClick={handleUpdate}>
                                            Update
                                        </button>
                                }
                            </form>

                        </Modal>
                        : null}
                </div>
            </div>
            {isActionModalOpen ?
                <SettingTableAction
                    header={selectedRow?.name}
                    isActionModalOpen={isActionModalOpen}
                    setIsActionModalOpen={setIsActionModalOpen}
                    editFunction={editFunction}
                    selectedRow={selectedRow}
                    handleDelete={handleDelete}
                />
                : null}


        </div>

    )
}

export default Employee


