import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sareeChallanData: []
}

export const sareeChallanSlice = createSlice({
    name: 'sareeChallanSlice',
    initialState,
    reducers: {
        setSareeChallan: (state, action) => {
            state.sareeChallanData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSareeChallan
} = sareeChallanSlice.actions
export default sareeChallanSlice.reducer