import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useAddMaterialMutation } from "../../servicesRtkQuery/publicApi";
import TextBox from "../form/TextBox";
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../redux/globalSlice';
import Error from "../error";
import Swal from "sweetalert2";
import { capitalize } from "../../utils/globalFunctions";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export const MaterialAdd = (props: any) => {
    const dispatch = useDispatch()
    const [materialName, setMaterialName]: any = useState()
    useEffect(() => {
        if (props.materialAdd) {
            setMaterialName(props.materialAdd)
        }
    }, [props.materialAdd])

    const handleChange = (e: any) => {
        setMaterialName(e.target.value)
    }

    const [addMaterial, { isSuccess, isError, isLoading, error, data }] = useAddMaterialMutation();
    const createMaterial = () => {
        let setError: any = '';
        dispatch(setErrorMessage(setError))
        if (materialName.trim() === "") {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Please Enter Valid Input !",
                position: 'top-end',
                showConfirmButton: false,
                timer : ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
        else {
            addMaterial({ name: capitalize(materialName.trim()) })
        }
    }

    useEffect(() => {
        if (isSuccess && !isLoading) {
            let newMaterial: any = {
                label: capitalize(materialName.trim()),
                value: data.data.insertedIds[0],
            }
            props.getMaterialData(newMaterial)
        }
        if (isError && !isLoading) {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Material Already Exists !",
                position: 'top-end',
                showConfirmButton: false,
                timer : ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }, [isSuccess, isError, isLoading])


    return (
        <div>
            <Modal open={props.showAddMaterial} showCloseIcon={false}
                center
                closeOnEsc={false}
                closeOnOverlayClick={false}
                            classNames={{
                                modal: "rounded-xl"
                            }}
                styles={{
                    overlay: {
                        height: "auto",
                    },
                }}
                onClose={() => props.setShowAddMaterial(false)}>
                <div className="inset-0 overflow-auto">
                    <div className="flex items-end sm:items-center justify-center text-center sm:p-0">
                        <div className="relative rounded-lg overflow-hidden text-left shadow-xl">
                            <div className="w-full">
                                <div className="text-lg text-left">Add New Material</div>
                                <div className="p-6">
                                    <TextBox
                                        id="materialName" name="materialName" htmlFor="materialName"
                                        label="Material Name" placeholder=" " onChange={(e: any) => handleChange(e)} value={materialName}
                                    />
                                    <div className="m-3 flex justify-between">
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => props.setShowAddMaterial(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => createMaterial()}>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >
        </div >
    )
}
