import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from 'react-to-print';
import { convertImageToBase64, getFormattedNumber } from "../../../utils/globalFunctions";
import moment from "moment";
import { useLazyGetChallanQuery } from "../../../servicesRtkQuery/publicApi";

export const SareeChallanPreview = (props: any) => {
    const exportRef: any = useRef(null);
    const [image, setImage]: any = useState();
    const [logo, setLogo]: any = useState();

    let total: any = 0
    let totalPcs: any = 0
    const [challan, setChallan]: any = useState()

    const id = props.id
    const [trigger, result] = useLazyGetChallanQuery()
    const { isSuccess, isFetching, isError, error } = result

    useEffect(() => {        
        trigger({
            cId: id,
            type: "saree"
        })
    }, [])

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setChallan(result?.data?.data?.challan?.[0])
        }
    }, [isSuccess, isFetching])

    useEffect(() => {
        convertImageToBase64("https://cnt-satv.s3.ap-south-1.amazonaws.com/logo.png", setLogo)
    }, [challan])

    challan?.items?.forEach((card: any) => {
        total = total + card?.TR
    });

    challan?.fabricColors?.forEach((card: any) => {
        totalPcs = totalPcs + card?.PM
    });
    return (
        <div>
            <Modal open={props.showPreview} showCloseIcon={false}
                closeOnEsc={false}
                onEscKeyDown={() => props.setShowPreview(false)}
                onOverlayClick={() => props.setShowPreview(false)}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl"
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto"
                    }
                }}
                aria-labelledby="dressrate-card-pdf"
                aria-describedby="dressrate-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setShowPreview(false)}>
                <div className="overflow-auto p-2">
                    <div ref={exportRef} className={`overflow-hidden a4-portrait`}>
                        <div className="border border-gray-600">
                            <div className="grid grid-cols-6 font-bold items-center p-2 bg-gray-100">
                                <div className="flex justify-center capitalize">
                                    <img src={logo} alt="सत्व" />
                                </div>
                                <div className="col-span-2 text-center">
                                    <div className="text-4xl my-1">
                                        Satv
                                    </div>
                                    <div>
                                        Mo. 9724783030
                                    </div>
                                </div>
                                <div className="col-span-3 font-semibold text-lg">
                                    <div className="my-2 capitalize">
                                        <span className="">Name : </span>{challan?.name}
                                    </div>
                                    <div>
                                        <span className="capitalize">Address : </span>{challan?.address}
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 border-y border-black">
                                <div className={`text-sm grid grid-cols-6 gap-2`}>
                                    <div className="col-span-6">
                                        <table className="w-full border border-collapse bg-white text-left">
                                            <thead className="bg-themeColor/40 divide-black border-t border-x border-black text-4">
                                                <tr>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center w-1/6">Party Name</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center w-1/6">P Ch. No.</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center w-1/6">Design No</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center w-1/6">Card No.</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center w-1/6">Date</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center w-1/6">Job Ch No</th>

                                                </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-black border border-black text-4">
                                                <tr>
                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">{challan?.party?.shortName}</td>
                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{challan?.PCNo}</td>
                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{challan?.designNo}</td>
                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{challan?.cardNo}</td>
                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{moment(challan?.date).format("DD/MM/YYYY")}</td>
                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{challan?.challanNo}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-span-6 mt-2">
                                        <table className="w-full border border-collapse bg-white text-left capitalize">
                                            <thead className="bg-gray-100 divide-black border border-black text-4">
                                                <tr className="border border-black text-sm">
                                                    <td className="text-center w-20 border-r-2 border-t-2 border-l-2 border-black">Color</td>
                                                    {
                                                        challan?.fabricColors?.map((item: any, index: any) => {
                                                            return (
                                                                <td className="text-center w-20 border-r border-black">{item?.fabricColor?.colorName}</td>
                                                            )
                                                        })
                                                    }
                                                    <td className="text-center w-20 border-r-2 border-t-2 border-l-2 border-black">Total Pcs</td>
                                                    <td className="text-center w-20 border-r-2 border-t-2 border-l-2 border-black">Fabric</td>
                                                </tr>
                                                <tr className="border border-black text-sm">
                                                    <td className="text-center border-r-2 border-l-2 border-b-2 w-20 border-black">Pcs</td>
                                                    {
                                                        challan?.fabricColors?.map((item: any, index: any) => {
                                                            return (
                                                                <td className="text-center w-20 border-r border-black">{item?.PM}</td>
                                                            )
                                                        })
                                                    }
                                                    <td className="text-center w-20 border-r-2 border-t border-l-2 border-b-2 border-black">{totalPcs}</td>
                                                    <td className="text-center w-20 border-r-2 border-t border-l-2 border-b-2 border-black">{challan?.clothe?.name}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div className="col-span-6 mt-2">
                                        <table className="w-full border border-collapse bg-white text-left">
                                            <thead className="bg-themeColor/40 divide-black border-t border-x border-black text-4">
                                                <tr>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Item</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Stitch</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Area</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Code</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Rate</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Pcs/Mtr</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">Total Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-black border border-black text-4">
                                                {
                                                    challan?.items?.map((item: any) => {
                                                        return (
                                                            <tr>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">{item?.item?.name}</td>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.stitch}</td>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.area}</td>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.code}</td>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.rate}</td>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.PM}</td>
                                                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.TR}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={6} className="px-3 gap-3 border-r border-black font-semibold text-gray-900 capitalize text-left">&nbsp;</td>
                                                    <td className="gap-3 border-2 border-black font-semibold text-gray-900 capitalize">{total}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="w-full border border-collapse bg-white text-left mt-2">
                                            <thead className="bg-themeColor/40 divide-black border border-black text-4">
                                                <tr>
                                                    <th scope="col" className="py-1.5 border-r border-b  border-black capitalize text-center">Total Pcs/Mtr</th>
                                                    <th scope="col" className="py-1.5 border-r border-b  border-black capitalize text-center">Rate</th>
                                                    <th scope="col" className="py-1.5 border-r border-b  border-black capitalize text-center">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-black border border-black text-4">
                                                <tr>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">{totalPcs}</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">{total}</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-center">{totalPcs * total}</th>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="w-full border border-collapse bg-white text-left mt-2">
                                            <thead className="divide-black border border-black text-4">
                                                <tr className="bg-themeColor/40">
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-left pl-3">{challan?.name}</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-left pl-3">Satv Job Recieved</th>
                                                    <th scope="col" className="py-1.5 border-r border-black capitalize text-left pl-3">Recieved Pcs</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col" className="py-1.5 px-3 border-r border-t border-black capitalize text-left">Sign :</th>
                                                    <th scope="col" className="py-1.5 px-3 border-r border-t border-black capitalize text-left">Date :</th>
                                                    <th scope="col" className="py-1.5 px-3 border-r border-t border-black capitalize text-left">Sign :</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-3 flex flex-col lg:flex-row gap-2">
                        <div>
                            <ReactToPrint
                                trigger={() =>
                                    <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2'>
                                        Saree Challan Print <i className='fas fa-file-download'></i>
                                    </button>
                                }
                                content={() => exportRef.current} copyStyles={true} documentTitle={props.pageName}
                            />
                        </div>
                        <div>
                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => props.setShowPreview(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
