import { useState } from "react";
import Cloth from "./cloth";
import ClothParty from "./clothparty";
import DesignCode from "./designcode";
import Employee from "./employee";
import FabricColors from "./fabriccolors";
import Item from "./item";
import Party from "./party";
import RateSetting from "./ratesetting";
import JobWorkName from "./jobworkname";
import Description from "./Description";
import Material from "./material";
import Diamond from "./diamond";
import Remarks from "./remarks";
import ChallanRemarks from "./challanRemarks";
import Title from "./title";

const Settings = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isNavSubMenuOpen, setIsNavSubMenuOpen] = useState(false);

    const [toggleState, setToggleState] = useState(1);
    const [toggleSubMenuState, setToggleSubMenuState] = useState(1);

    const toggleTab = (index: any) => {
        setToggleState(index);
    };

    const toggleSubMenuTab = (index: any) => {
        setToggleSubMenuState(index);
    };

    const SubMenus = () => {
        return (
            <>
                {toggleSubMenuState == 1 ?
                    <li className={`${toggleState === 1 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(1)}>employee</button>
                    </li>
                    : null}
                <li className={`${toggleState === 2 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                    <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(2)}>Party</button>
                </li>
                <li className={`${toggleState === 3 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                    <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(3)}>Fabric</button>
                </li>
                {toggleSubMenuState == 1 || toggleSubMenuState == 2 || toggleSubMenuState == 3 ?
                    <li className={`${toggleState === 4 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(4)}>Fabric Party</button>
                    </li>
                    : null}
                <li className={`${toggleState === 5 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                    <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(5)}>Item</button>
                </li>
                {toggleSubMenuState == 1 || toggleSubMenuState == 2 ?
                    <li className={`${toggleState === 6 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(6)}>thread color</button>
                    </li>
                    : null}
                <li className={`${toggleState === 7 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                    <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(7)}>Fabric Colors</button>
                </li>
                {toggleSubMenuState == 4 ?
                    <li className={`${toggleState === 8 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(8)}>Job Work Name</button>
                    </li>
                    : null}
                {toggleSubMenuState == 2 ?
                    <li className={`${toggleState === 9 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(9)}>global Settings</button>
                    </li>
                    : null}
                {toggleSubMenuState == 4 ?
                    <li className={`${toggleState === 10 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(10)}>Description</button>
                    </li>
                    : null}
                {toggleSubMenuState == 3 ?
                    <li className={`${toggleState === 11 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(11)}>Remarks</button>
                    </li>
                    : null}
                {toggleSubMenuState == 4 ?
                    <li className={`${toggleState === 12 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(12)}>Challan Remarks</button>
                    </li>
                    : null}
                {toggleSubMenuState == 3 ?
                    <li className={`${toggleState === 13 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(13)}>Diamonds</button>
                    </li>
                    : null}
                {toggleSubMenuState == 3 ?
                    <li className={`${toggleState === 14 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(14)}>Materials</button>
                    </li>
                    : null}
                {toggleSubMenuState == 3 ?
                    <>
                        <li className={`${toggleState === 15 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                            <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(15)}>Main Title (Lump)</button>
                        </li>
                        <li className={`${toggleState === 16 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                            <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(16)}>Job Title (Lump)</button>
                        </li>
                    </>
                    : null}
            </>
        )
    }

    return (
        <div className="m-0 mt-16 lg:mt-0 xl:mt-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl  bg-themeBgColor p-0 px-6 bg-cover">
            <div className="w-full mt-5 rounded-xl overflow-hidden">
                <div className="border rounded-xl md:rounded-xl p-1.5 bg-gray-50 shadow-sm hidden lg:block">
                    <div className="border-b border-gray-400 pb-1">
                        <ul className="list-none grid grid-cols-4 border-b-0 pl-0 gap-3" role="tablist">
                            <li className={`${toggleSubMenuState === 1 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                                <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => {
                                    toggleSubMenuTab(1)
                                    setToggleState(1)
                                }}>Sample card</button>
                            </li>
                            <li className={`${toggleSubMenuState === 2 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                                <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => {
                                    toggleSubMenuTab(2)
                                    setToggleState(2)
                                }}>matching card</button>
                            </li>
                            <li className={`${toggleSubMenuState === 3 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                                <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => {
                                    toggleSubMenuTab(3)
                                    setToggleState(2)
                                }}>rate card</button>
                            </li>
                            <li className={`${toggleSubMenuState === 4 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl`}>
                                <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => {
                                    toggleSubMenuTab(4)
                                    setToggleState(2)
                                }}>job work</button>
                            </li>
                        </ul>
                    </div>
                    <ul className="list-none flex flex-wrap border-b-0 pl-0 gap-3 mt-2" role="tablist">
                        <SubMenus />
                    </ul>
                </div>

                <div className="block lg:hidden">
                    <nav className={`absolute ml-0 sm:ml-0 md:ml-0 lg:ml-0 sm:top-0 md:top-0 font-[Poppins] lg:top-0 left-6 right-0 z-80 flex flex-wrap items-center px-4 py-2 m-6 mb-0 shadow-sm rounded-t-xl ${isNavOpen ? "" : "rounded-b-xl"} bg-white lg:flex-nowrap lg:justify-start`}>
                        <div className="flex justify-between items-center w-full">
                            <span className="text-2xl font-[Poppins]">
                                Data Settings
                            </span>
                            <span className="text-3xl cursor-pointer mx-2 lg:hidden block">
                                <button onClick={() => setIsNavOpen(!isNavOpen)}>
                                    <i className={`fas ${isNavOpen ? "fa-angle-up" : "fa-angle-down"} text-black`}></i>
                                </button>
                            </span>
                        </div>

                        <ul className={`${isNavOpen ? 'nav-links show-nav' : 'nav-links hidden'} bg-white rounded-b-xl shadow-lg`}>
                            <li className={`${toggleSubMenuState === 1 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-2`}>
                                <button className={`w-full border-x-0 border-t-0 border-transparent px-7 py-2 text-xs text-left uppercase leading-tight text-black hover:isolate hover:border-transparent focus:isolate focus:border-transparent flex justify-between items-center`} onClick={() => {
                                    toggleSubMenuTab(1)
                                    setIsNavSubMenuOpen(!isNavSubMenuOpen)
                                }}><div>sample card</div><i className={`text-base fas ${isNavSubMenuOpen && toggleSubMenuState === 1 ? "fa-angle-up" : "fa-angle-down"} text-black`}></i></button>
                                {toggleSubMenuState === 1 && isNavSubMenuOpen ?
                                    <ul className={`rounded-xl bg-gray-100 py-2 absolute top-[60px] left-2 right-2 px-2`} onClick={() => setIsNavOpen(!isNavOpen)}>
                                        <SubMenus />
                                    </ul>
                                    : null}
                            </li>
                            <li className={`${toggleSubMenuState === 2 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-2`}>
                                <button className={`w-full border-x-0 border-t-0 border-transparent px-7 py-2 text-xs text-left uppercase leading-tight text-black hover:isolate hover:border-transparent focus:isolate focus:border-transparent flex justify-between items-center`} onClick={() => {
                                    toggleSubMenuTab(2)
                                    setIsNavSubMenuOpen(!isNavSubMenuOpen)
                                }}><div>matching card</div><i className={`text-base fas ${isNavSubMenuOpen && toggleSubMenuState === 2 ? "fa-angle-up" : "fa-angle-down"} text-black`}></i></button>
                                {toggleSubMenuState === 2 && isNavSubMenuOpen ?
                                    <ul className={`rounded-xl bg-gray-100 py-2 absolute top-[108px] left-2 right-2 px-2`} onClick={() => setIsNavOpen(!isNavOpen)}>
                                        <SubMenus />
                                    </ul>
                                    : null}
                            </li>
                            <li className={`${toggleSubMenuState === 3 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-2`}>
                                <button className={`w-full border-x-0 border-t-0 border-transparent px-7 py-2 text-xs text-left uppercase leading-tight text-black hover:isolate hover:border-transparent focus:isolate focus:border-transparent flex justify-between items-center`} onClick={() => {
                                    toggleSubMenuTab(3)
                                    setIsNavSubMenuOpen(!isNavSubMenuOpen)
                                }}><div>rate card</div><i className={`text-base fas ${isNavSubMenuOpen && toggleSubMenuState === 3 ? "fa-angle-up" : "fa-angle-down"} text-black`}></i></button>
                                {toggleSubMenuState === 3 && isNavSubMenuOpen ?
                                    <ul className={`rounded-xl bg-gray-100 py-2 absolute top-[156px] left-2 right-2 px-2`} onClick={() => setIsNavOpen(!isNavOpen)}>
                                        <SubMenus />
                                    </ul>
                                    : null}
                            </li>
                            <li className={`${toggleSubMenuState === 4 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "btn setting-btn-1 hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-2`}>
                                <button className={`w-full border-x-0 border-t-0 border-transparent px-7 py-2 text-xs text-left uppercase leading-tight text-black hover:isolate hover:border-transparent focus:isolate focus:border-transparent flex justify-between items-center`} onClick={() => {
                                    toggleSubMenuTab(4)
                                    setIsNavSubMenuOpen(!isNavSubMenuOpen)
                                }}><div>job work</div><i className={`text-base fas ${isNavSubMenuOpen && toggleSubMenuState === 4 ? "fa-angle-up" : "fa-angle-down"} text-black`}></i></button>
                                {toggleSubMenuState === 4 && isNavSubMenuOpen ?
                                    <ul className={`rounded-xl bg-gray-100 py-2 absolute top-[204px] left-2 right-2 px-2`} onClick={() => setIsNavOpen(!isNavOpen)}>
                                        <SubMenus />
                                    </ul>
                                    : null}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="w-full mt-20 lg:mt-2">
                <div className="w-full h-full rounded-xl overflow-hidden bg-white p-4">
                    <div className="">
                        <div
                            className={`transition-opacity duration-150 ease-linear ${toggleState === 1 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Employee />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 2 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Party />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 3 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Cloth />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 4 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <ClothParty />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 5 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Item />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 6 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <DesignCode />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 7 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <FabricColors />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 8 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <JobWorkName />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 9 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <RateSetting />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 10 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Description />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 11 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Remarks />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 12 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <ChallanRemarks />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 13 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Diamond />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 14 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Material />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 15 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Title type="main" />
                        </div>
                        <div className={`transition-opacity duration-150 ease-linear ${toggleState === 16 ? "opacity-100" : "opacity-0 hidden"}`}>
                            <Title type="job" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings
