import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    jobWorkData: []
}

export const jobWorkSlice = createSlice({
    name: 'jobWorkSlice',
    initialState,
    reducers: {
        setJobWork: (state, action) => {
            state.jobWorkData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setJobWork
} = jobWorkSlice.actions
export default jobWorkSlice.reducer