export const setUserTokenInLocalSorage = (data:any) => {
    localStorage.setItem('_token', data.access_token)
    localStorage.setItem('_rtoken', data.refresh_token)
}

export const setUserDetailInLocalSorage = (data:any) => {
    localStorage.setItem('_token', data?.access_token)
    localStorage.setItem('_rtoken', data?.refresh_token)
    localStorage.setItem('user', JSON.stringify(data));
}
export const getUserDetailFromLocalSorage = () => {
    let getData:any = localStorage.getItem('user')
    const localUserDetail = JSON.parse(getData)
    return localUserDetail
}

export const getUserTokenInLocalSorage = () => {
    let getData:any = localStorage.getItem('_token')
    return getData
}

export const getUserRefreshTokenInLocalSorage = () => {
    let getData:any = localStorage.getItem('_rtoken')
    return getData
}