import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import DataTableLoader from "../../page/DataTableLoader";
import Modal from "react-responsive-modal";
import {
  useLazyGetMatchingCardByReportQuery,
  useLazyMatchingCardQuery,
} from "../../../servicesRtkQuery/publicApi";

const SareeMatchingCardPdf = (props: any) => {
  var { allCount, search, type, isSearching, matchingCard } = props;
  const exportRef: any = useRef(null);
  const [cardData, setCardData]: any = useState();
  const [isCount, setCount]: any = useState();

  const [cardTrigger, cardResult] = useLazyGetMatchingCardByReportQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;
  const [trigger, result] = useLazyMatchingCardQuery();
  const { isSuccess, isFetching, isError, error } = result;
  // useEffect(() => {
  //   if (!isSearching) {
  //     trigger({
  //       limit: allCount,
  //     });
  //   } else {
  //     setCardData(matchingCard);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isSuccess && !isFetching) {
  //     setCardData(result?.data?.data?.matchingCard);
  //     console.log(result?.data?.data?.matchingCard);
  //   }
  // }, [isSuccess, isFetching]);
  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCount(cardResult?.data?.data?.TTPPM)
      setCardData(cardResult?.data?.data?.matchingCard);
    }
  }, [isCardSuccess, isCardFetching]);

  useEffect(() => {
    let searchData: any = {
      limit: allCount,
    };
    Object.keys(search).map((key: any) => {
      if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
    });
    if (type !== "all") {
      searchData = { ...searchData, type: type };
    }
    if (Object.keys(searchData).length !== 0) {
      cardTrigger(searchData);
    }
  }, [search]);
  return (
    <div>
      <Modal
        open={props.isExportSareeData}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        onEscKeyDown={() => props?.setIsExportSareeData(false)}
        onOverlayClick={() => props?.setIsExportSareeData(false)}
        aria-labelledby="sample-card-pdf"
        aria-describedby="sample-card-pdf-description"
        animationDuration={400}
        onClose={() => props?.setIsExportSareeData(false)}
      >
        <div className="overflow-auto">
          <div className="text-center">
            <div className="bg-white border rounded-lg text-left shadow-xl">
              <div ref={exportRef} className={`a5-portrait overflow-auto`}>
                <div className="">
                  {/* <div className="font-bold items-center p-2 bg-gray-100 text-[18px]">
                    <div className="text-themeColor capitalize text-center">
                      Saree Matching Card Data
                    </div>
                  </div> */}
                  {!isCardFetching ? (
                    <div className="">
                      <div className={`text-sm`}>
                        <table className="w-full border-collapse bg-white text-left">
                          <tbody className="text-center text-4">
                            <tr>
                              <td>
                                {cardData?.map((item: any, i: any) => {
                                  const isFirstIndex = i === 0;
                                  return (
                                    <div key={i}>
                                      <div className="text-left mt-3 bg-themeColor flex justify-between items-center">
                                        <h3 className="text-white font-medium p-2">
                                          Party:{item?.party?.name}
                                        </h3>
                                        {isFirstIndex && (
                                          <h4 className="text-white px-5 py-1 font-medium  mr-5">
                                            Total Saree = {isCount}
                                          </h4>
                                        )}
                                      </div>
                                      <div className="flex border-2 border-black">
                                        <div className="design-no border-r-2 border-black">
                                          <div className="h-[35px] flex items-center justify-center text-[18px]">
                                            डी.नंबर
                                          </div>
                                          <div className="border-t-2 border-t-black text-[18px] border-b-2 border-b-black h-[35px] flex items-center justify-center">
                                            {item?.designNo}
                                          </div>
                                          <div className="party-image w-[299px] p-2">
                                            <img
                                              src={item?.image?.URL}
                                              alt=""
                                              className="w-full "
                                              style={{ height: "400px" }}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-full">
                                          <div className="flex justify-around text-center border-b-2 border-black">
                                            <span className="w-4/12 border-r-2 border-black h-[35px] flex items-center justify-center text-[18px]">
                                              चलन नंबर
                                            </span>
                                            <span className="w-4/12 border-r-2 border-black h-[35px] flex items-center justify-center text-[18px]">
                                              पार्टी डी.नंबर
                                            </span>
                                            <span className="w-4/12 border-r-2 h-[35px] flex items-center justify-center text-[18px]">
                                              कार्ड नंबर
                                            </span>
                                          </div>
                                          <div className="flex justify-around text-center border-b-2 border-black">
                                            <span className="w-4/12 border-r-2 border-black h-[32px] flex items-center justify-center text-[18px]">
                                              {item?.challanNo}
                                            </span>
                                            <span className="w-4/12 border-r-2 border-black h-[32px] flex items-center justify-center text-[18px]">
                                              {item?.partyDesignNo}
                                            </span>
                                            <span className="w-4/12 border-r-2 h-[32px] flex items-center justify-center text-[18px]">
                                              {item?.invoiceNo}
                                            </span>
                                          </div>
                                          <div className="h-[350px]">
                                            <table className="w-full border-collapse bg-white mt-4 border-2 border-l-0 border-r-0  border-black ">
                                              <tr className="border-b-2 border-black">
                                                <th className="w-4/12 border-r-2 border-black h-[40px] text-[18px]">
                                                  Cloth
                                                </th>
                                                <th className="w-4/12 border-r-2 border-black h-[40px] text-[18px]">
                                                  Color
                                                </th>
                                                <th className="w-4/12 h-[40px] text-[18px]">
                                                  Pcs
                                                </th>
                                              </tr>
                                              {item?.fabricColors?.map(
                                                (j: any) => {
                                                  return (
                                                    <tr>
                                                      <td className="w-4/12 border-r-2 border-b border-black h-[38px] text-[18px]">
                                                        {item?.clothe?.name}
                                                      </td>
                                                      <td className="w-4/12 border-r-2 border-b border-black text-[18px]">
                                                        {
                                                          j?.fabricColorId
                                                            ?.colorName
                                                        }
                                                      </td>
                                                      <td className="w-4/12  border-b border-black text-[18px]">
                                                        {j?.PM}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </table>
                                          </div>
                                          <div className="flex justify-around mt-[5px]">
                                            <div className="totalPcs border-2 border-black px-7 py-1 text-[18px]">
                                              Rate = {item?.jobRate}
                                            </div>
                                            <div className="totalPcs border-2 border-black px-5 py-1 text-[18px]">
                                              Total Pcs = {item?.TPPM}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <DataTableLoader />
                  )}
                </div>
              </div>
              <div className="m-3 flex flex-col lg:flex-row gap-2">
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                        Print <i className="fas fa-file-download"></i>
                      </button>
                    )}
                    content={() => exportRef.current}
                    documentTitle={props.pageName}
                  />
                </div>
                <div>
                  <button
                    className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                    onClick={() => props?.setIsExportSareeData(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SareeMatchingCardPdf;
