import { useNavigate, useParams } from "react-router-dom";
import TextBox from "../../components/form/TextBox";
import Select from "react-select";
import {
  useLazyMatchingCardStockQuery,
  useUpdateStockMutation,
} from "../../servicesRtkQuery/publicApi";
import { useEffect, useState } from "react";
import { getUserDetailFromLocalSorage } from "../../utils/localStorage";
import moment from "moment";
import Swal from "sweetalert2";

export default function UpdateStocks() {
  const navigate = useNavigate();
  const user: any = localStorage.getItem("user");
  const userDetails = JSON.parse(user);
  const type = [
    { label: "Hand", value: "hand" },
    { label: "Delivery", value: "delivery" },
  ];
  const [selectedType, setSelectedType]: any = useState({
    label: "Hand",
    value: "hand",
  });
  const [ErrorMessage, setErrorMessage]: any = useState({
    normal: {
      short: "",
      plan: "",
      work: "",
    },
    production: {
      short: "",
      plan: "",
      work: "",
    },
    checking: {
      short: "",
      plan: "",
      work: "",
    },
    stitching: {
      short: "",
      plan: "",
      work: "",
    },
    receiveDate: "",
  });
  // get matching-card
  const [trigger, result] = useLazyMatchingCardStockQuery();
  const { isSuccess, isFetching } = result;
  // update matching-card
  const [updateTrigger, updateResult] = useUpdateStockMutation();
  const { isSuccess: isUpdate, isFetching: isNotUpdate } = updateResult;
  const [matchingCardStock, setMatchingCardStock]: any = useState({});
  const [data, setData]: any = useState({});
  const [statusData, setStatusData]: any = useState({
    production: {
      plan: 0,
      short: 0,
      work: 0,
      updateAt: "",
      userId: "",
    },
    checking: {
      plan: 0,
      short: 0,
      work: 0,
      updateAt: "",
      userId: "",
    },
    stitching: {
      plan: 0,
      short: 0,
      work: 0,
      updateAt: "",
      userId: "",
    },
    normal: {
      plan: 0,
      short: 0,
      work: 0,
    },
  });
  const [dates, setDates] = useState({
    date: "",
    receiveDate: "",
  });
  let { id } = useParams();
  useEffect(() => {
    trigger({ msId: id });
  }, [id, trigger]);
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const dates = result?.data?.data?.matchingCardStock
        ?.matchingCardStock?.[0]?.date
        ? moment(
            result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]?.date
          ).format("YYYY-MM-DD")
        : "";
      const receiveDate = result?.data?.data?.matchingCardStock
        ?.matchingCardStock?.[0]?.receiveDate
        ? moment(
            result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.receiveDate
          ).format("YYYY-MM-DD")
        : "";
      setDates({
        date: dates,
        receiveDate: receiveDate,
      });
      setMatchingCardStock(result?.data?.data?.matchingCardStock);
      if (
        result?.data?.data?.matchingCardStock?.matchingCardStock?.length > 0
      ) {
        setStatusData({
          production: {
            plan: result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.production?.plan,
            short:
              result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
                ?.production?.short,
            work: result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.production?.work,
            updateAt: "",
            userId: result?.data?.data?.matchingCardStock
              ?.matchingCardStock?.[0]?.production?.userId
              ? result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
                  ?.production?.userId
              : null,
          },
          checking: {
            plan: result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.checking?.plan,
            short:
              result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
                ?.checking?.short,
            work: result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.checking?.work,
            updateAt: "",
            userId: result?.data?.data?.matchingCardStock
              ?.matchingCardStock?.[0]?.checking?.userId
              ? result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
                  ?.checking?.userId
              : null,
          },
          stitching: {
            plan: result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.stitching?.plan,
            short:
              result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
                ?.stitching?.short,
            work: result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
              ?.stitching?.work,
            updateAt: "",
            userId: result?.data?.data?.matchingCardStock
              ?.matchingCardStock?.[0]?.stitching?.userId
              ? result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
                  ?.stitching?.userId
              : null,
          },
        });
      }
      if (
        result?.data?.data?.matchingCardStock?.matchingCardStock?.[0]
          ?.stockStatus === "hand"
      ) {
        setSelectedType({ label: "Delivery", value: "delivery" });
      }
    }
  }, [isSuccess, isFetching]);
  const handleChange = (e: any) => {
    setData({
      ...data,
      normal: {
        ...data?.normal,
        [e.target.name]: e.target?.value,
      },
    });
    setErrorMessage({
      ...ErrorMessage,
      normal: {
        ...ErrorMessage?.normal,
        [e.target.name]: "",
      },
    });
  };
  const handleSubmit = () => {
    const user = getUserDetailFromLocalSorage();
    const currentDate = new Date();
    const plan = Number(data?.normal?.plan) || 0;
    const work = Number(data?.normal?.work) || 0;
    const short = Number(data?.normal?.short) || 0;
    const total = plan + work + short;
    let finalData = {};
    // Clear previous error messages
    const stockStatus = matchingCardStock?.matchingCardStock[0]?.stockStatus;
    if (stockStatus === "plan") {
      finalData = {
        query: { msId: id },
        payload: {
          stockStatus: "production",
          receiveDate: moment(currentDate.toString()).format("YYYY-MM-DD"),
          date: "",
          production: {
            work,
            short,
            plan,
            userId: user?._id,
            updateAt: currentDate?.toString(),
          },
        },
      };
    } else if (stockStatus === "production") {
      // Similar logic for other statuses
      finalData = {
        query: { msId: id },
        payload: {
          stockStatus: "checking",
          receiveDate: moment(currentDate.toString()).format("YYYY-MM-DD"),
          date: "",
          checking: {
            work,
            short,
            plan,
            userId: user?._id,
            updateAt: currentDate?.toString(),
          },
        },
      };
    }
    // Additional conditions
    if (stockStatus === "checking") {
      // Similar logic for other statuses
      finalData = {
        query: { msId: id },
        payload: {
          stockStatus: selectedType.value,
          receiveDate: moment(currentDate.toString()).format("YYYY-MM-DD"),
          date: "",
          [selectedType.value]: {
            userId: user?._id,
            updateAt: currentDate?.toString(),
          },
        },
      };
      Swal.fire({
        title: "Are you sure?",
        // text: `You won't be able to ${stockStatus === "plan" ? "production" : stockStatus === "production" ? "checking" : stockStatus === "checking" ? "hand" : stockStatus === "hand" ? "delivery" :"plan"} page!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result?.isConfirmed) {
          updateTrigger(finalData);
        }
      });
    } else if (stockStatus === "hand") {
      // Similar logic for other statuses
      finalData = {
        query: { msId: id },
        payload: {
          stockStatus: selectedType.value,
          receiveDate: moment(currentDate.toString()).format("YYYY-MM-DD"),
          date: "",
          delivery: { userId: user?._id, updateAt: currentDate?.toString() },
        },
      };
      Swal.fire({
        title: "Are you sure?",
        // text: `You won't be able to ${stockStatus === "plan" ? "production" : stockStatus === "production" ? "checking" : stockStatus === "checking" ? "hand" : stockStatus === "hand" ? "delivery" :"plan"} page!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result?.isConfirmed) {
          updateTrigger(finalData);
        }
      });
    }

    const pPlan = Number(statusData?.production?.plan) || 0;
    const pWork = Number(statusData?.production?.work) || 0;
    const pShort = Number(statusData?.production?.short) || 0;
    const totalProduction = Number(pPlan) + Number(pWork) + Number(pShort);
    const cPlan = Number(statusData?.checking?.plan) || 0;
    const cWork = Number(statusData?.checking?.work) || 0;
    const cShort = Number(statusData?.checking?.short) || 0;
    const totalChecking = cPlan + cWork + cShort;
    const sPlan = Number(statusData?.stitching?.plan) || 0;
    const sWork = Number(statusData?.stitching?.work) || 0;
    const sShort = Number(statusData?.stitching?.short) || 0;
    const totalSti = sPlan + sWork + sShort;
    if (total > matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM) {
      setErrorMessage({
        ...ErrorMessage,
        normal: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (!plan && !work && !short) {
      setErrorMessage({
        ...ErrorMessage,
        normal: {
          short: "At least one field must be filled",
          work: "At least one field must be filled",
          plan: "At least one field must be filled",
        },
      });
    } else if (
      Number(totalSti) >
      matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM
    ) {
      setErrorMessage({
        ...ErrorMessage,
        stitching: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (
      Number(totalChecking) >
      matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM
    ) {
      setErrorMessage({
        ...ErrorMessage,
        checking: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (
      Number(totalProduction) >
      matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM
    ) {
      setErrorMessage({
        ...ErrorMessage,
        production: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (!dates?.receiveDate || dates?.receiveDate === "") {
      setErrorMessage({
        ...ErrorMessage,
        receiveDate: "Please Enter Received Date",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        // text: `You won't be able to ${stockStatus === "plan" ? "production" : stockStatus === "production" ? "checking" : stockStatus === "checking" ? "hand" : stockStatus === "hand" ? "delivery" :"plan"} page!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result?.isConfirmed) {
          updateTrigger(finalData);
          setData({
            normal: {
              plan: "",
              short: "",
              work: "",
            },
          });
          setErrorMessage({
            ...ErrorMessage,
            checking: {
              short: "",
              plan: "",
              work: "",
            },
            production: {
              short: "",
              plan: "",
              work: "",
            },
            stitching: {
              short: "",
              plan: "",
              work: "",
            },
            normal: {
              short: "",
              plan: "",
              work: "",
            },
            receiveDate: "",
          });
        }
      });
      // setData({
      //   normal: {
      //     plan: "",
      //     short: "",
      //     work: "",
      //   },
      // });
      // setErrorMessage({
      //   ...ErrorMessage,
      //   checking: {
      //     short: "",
      //     plan: "",
      //     work: "",
      //   },
      //   production: {
      //     short: "",
      //     plan: "",
      //     work: "",
      //   },
      //   stitching: {
      //     short: "",
      //     plan: "",
      //     work: "",
      //   },
      //   normal: {
      //     short: "",
      //     plan: "",
      //     work: "",
      //   },
      //   receiveDate: "",
      // });
    }
  };
  const goToNext = () => {
    const user = getUserDetailFromLocalSorage();
    const plan = Number(statusData?.production?.plan) || 0;
    const work = Number(statusData?.production?.work) || 0;
    const short = Number(statusData?.production?.short) || 0;
    const totalProduction = plan + work + short;
    const cPlan = Number(statusData?.checking?.plan) || 0;
    const cWork = Number(statusData?.checking?.work) || 0;
    const cShort = Number(statusData?.checking?.short) || 0;
    const totalChecking = cPlan + cWork + cShort;
    const sPlan = Number(statusData?.stitching?.plan) || 0;
    const sWork = Number(statusData?.stitching?.work) || 0;
    const sShort = Number(statusData?.stitching?.short) || 0;
    const totalSti = sPlan + sWork + sShort;
    var currentDate = new Date();
    if (
      totalProduction >
      matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM
    ) {
      setErrorMessage({
        ...ErrorMessage,
        production: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (
      totalChecking >
      matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM
    ) {
      setErrorMessage({
        ...ErrorMessage,
        checking: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (
      totalSti > matchingCardStock?.matchingCardStock?.[0]?.matchingCard?.TPPM
    ) {
      setErrorMessage({
        ...ErrorMessage,
        stitching: {
          short: "More than Total Pcs",
          work: "More than Total Pcs",
          plan: "More than Total Pcs",
        },
      });
    } else if (!dates?.receiveDate || dates?.receiveDate === "") {
      setErrorMessage({
        ...ErrorMessage,
        receiveDate: "Please Enter Receive Date",
      });
    } else {
      let payload;
      if (matchingCardStock?.matchingCardStock[0]?.stockStatus === "plan") {
        payload = {
          date: "",
          receiveDate: dates?.receiveDate,
        };
      } else if (
        matchingCardStock?.matchingCardStock[0]?.stockStatus === "production"
      ) {
        payload = {
          date: "",
          receiveDate: dates?.receiveDate,
          production: {
            ...statusData?.production,
            userId: user?._id,
            updateAt: currentDate.toString(),
          },
        };
      } else if (
        matchingCardStock?.matchingCardStock[0]?.stockStatus === "checking"
      ) {
        payload = {
          date: "",
          receiveDate: dates?.receiveDate,
          production: {
            ...statusData?.production,
            updateAt: currentDate.toString(),
            userId: user?._id,
          },
          checking: {
            ...statusData?.checking,
            updateAt: currentDate.toString(),
            userId: user?._id,
          },
        };
      } else {
        payload = {
          date: "",
          receiveDate: dates?.receiveDate,
          production: {
            ...statusData?.production,
            updateAt: currentDate.toString(),
            userId: user?._id,
          },
          checking: {
            ...statusData?.checking,
            updateAt: currentDate.toString(),
            userId: user?._id,
          },
          stitching: {
            ...statusData?.stitching,
            updateAt: currentDate.toString(),
            userId: user?._id,
          },
        };
      }
      const finalData = {
        query: {
          msId: id,
        },
        payload: payload,
      };
      updateTrigger(finalData);
    }
  };
  useEffect(() => {
    if (isUpdate && !isNotUpdate) {
      trigger({ msId: id });

      Swal.fire({
        title: "Great",
        text: "Stock Successfully Updated !!",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isUpdate, isNotUpdate]);
  return (
    <>
      <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
        <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
          <div className="invisible lg:visible xl:visible sticky top-5 z-90">
            <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
              <div className="flex gap-5 w-full  items-center">
                <div className="px-4 py-4  text-white rounded-l-xl bg-themeColor w-[40%]">
                  <span>Matching Card Stock </span>
                </div>
                <div className="px-2 py-2 text-white md:w-auto rounded-xl bg-themeColor capitalize">
                  <span>
                    {matchingCardStock?.matchingCardStock?.[0]?.stockStatus}
                  </span>
                </div>
                {/* <div> */}
                <TextBox
                  tabIndex={-1}
                  id="receiveDate"
                  type="date"
                  name="receiveDate"
                  htmlFor="receiveDate"
                  label="Received Date"
                  placeholder=" "
                  minValue={""}
                  value={dates?.receiveDate}
                  hasError={ErrorMessage?.receiveDate}
                  onChange={(e: any) => {
                    setDates({
                      ...dates,
                      receiveDate: e.target.value,
                    });
                    setErrorMessage({
                      ...ErrorMessage,
                      receiveDate: "",
                    });
                  }}
                />
                <span className="text-xxs text-red-500 text-left">
                  {ErrorMessage?.receiveDate}
                </span>
                {/* </div> */}
                <TextBox
                  tabIndex={-1}
                  id="cardNo"
                  readOnly={true}
                  type="text"
                  name="cardNo"
                  htmlFor="cardNo"
                  label="Card No"
                  placeholder=" "
                  minValue={""}
                  value={
                    matchingCardStock?.matchingCardStock?.[0]?.matchingCard
                      ?.invoiceNo
                  }
                />

                {/* <TextBox
                  tabIndex={-1}
                  id="date"
                  type="date"
                  name="date"
                  htmlFor="date"
                  label="Date"
                  placeholder=" "
                  value={dates?.date}
                  disabled
                  onChange={(e: any) => {
                    setDates({
                      ...dates,
                      date: e.target.value ? e.target.value : "",
                    });
                  }}
                /> */}

                {matchingCardStock?.matchingCardStock?.[0]?.stockStatus ===
                "checking" ? (
                  // ||matchingCardStock?.matchingCardStock?.[0]?.stockStatus ==="hand"
                  <div className="w-44">
                    <>
                      <Select
                        name="partyName"
                        classNamePrefix="customSelect"
                        className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                        onChange={(e: any) => {
                          if (e) {
                            setSelectedType(e);
                          }
                        }}
                        options={type}
                        value={selectedType}
                      />
                    </>
                  </div>
                ) : null}
                <div className="px-3 w-full flex justify-end gap-2">
                  {matchingCardStock?.matchingCardStock?.[0]?.stockStatus !==
                    "delivery" && (
                    <button
                      onClick={handleSubmit}
                      className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor hover:shadow-xs hover:-translate-y-px"
                    >
                      <i className="fa fa-save"></i>
                      <span className="ml-2">
                        Go To{" "}
                        {matchingCardStock?.matchingCardStock?.[0]
                          ?.stockStatus === "plan"
                          ? "Production"
                          : matchingCardStock?.matchingCardStock?.[0]
                              ?.stockStatus === "production"
                          ? "Checking"
                          : matchingCardStock?.matchingCardStock?.[0]
                              ?.stockStatus === "checking"
                          ? selectedType?.label
                          : "Delivery"}
                      </span>
                    </button>
                  )}
                  <button
                    onClick={goToNext}
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  >
                    <i className="fa fa-save"></i>
                    <span className="ml-2">Update</span>
                  </button>

                  <button
                    className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/matchingcarddashboardstock");
                    }}
                  >
                    <i className="fa fa-close"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </nav>
          </div>
          <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0  fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
            <div className="w-full rounded-2xl mb-5 bg-white overflow-auto">
              <div className="block lg:hidden xl:hidden md:block">
                <div className="grid grid-cols-1 md:grid-cols-4 pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                  <div className="px-2 mb-2 w-full py-2 text-center text-white md:w-auto rounded-xl bg-themeColor capitalize">
                    <span>
                      {matchingCardStock?.matchingCardStock?.[0]?.stockStatus}
                    </span>
                  </div>
                  <TextBox
                    tabIndex={-1}
                    id="date"
                    type="date"
                    name="date"
                    className="mb-2"
                    htmlFor="date"
                    label="Date"
                    placeholder=" "
                    disabled
                    value={dates?.date}
                    onChange={(e: any) => {
                      setDates({
                        ...dates,
                        date: e.target.value,
                      });
                    }}
                  />

                  <div>
                    <TextBox
                      tabIndex={-1}
                      id="receiveDate"
                      type="date"
                      name="receiveDate"
                      htmlFor="receiveDate"
                      label="Received Date"
                      placeholder=" "
                      hasError={ErrorMessage?.date}
                      value={dates?.receiveDate}
                      onChange={(e: any) => {
                        setDates({
                          ...dates,
                          receiveDate: e.target.value,
                        });
                        setErrorMessage({
                          ...ErrorMessage,
                          receiveDate: "",
                        });
                      }}
                    />
                    <span className="text-xs text-red-500 text-left">
                      {ErrorMessage?.receiveDate}
                    </span>
                  </div>
                  <TextBox
                    tabIndex={-1}
                    id="cardNo"
                    readOnly={true}
                    type="text"
                    name="cardNo"
                    htmlFor="cardNo"
                    label="Card No"
                    placeholder=" "
                    minValue={""}
                    value={
                      matchingCardStock?.matchingCardStock?.[0]?.matchingCard
                        ?.invoiceNo
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full rounded-2xl mb-5 bg-white overflow-auto">
              {(matchingCardStock?.matchingCardStock?.[0]?.stockStatus ===
                "plan" ||
                matchingCardStock?.matchingCardStock?.[0]?.stockStatus ===
                  "production") && (
                <div className="w-full h-full rounded-xl bg-white overflow-auto">
                  <div className="grid grid-cols-1 md:grid-cols-4 pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                    <>
                      <div className="mb-2">
                        <TextBox
                          htmlFor="TPPM"
                          id="TPPM"
                          disabled={true}
                          name="TPPM"
                          label="TPPM"
                          placeholder=" "
                          value={
                            matchingCardStock?.matchingCardStock?.[0]
                              ?.matchingCard?.TPPM
                          }
                          type="number"
                        />
                      </div>
                      <div className="mb-2">
                        <TextBox
                          htmlFor="work"
                          id="work"
                          name="work"
                          label="Work"
                          placeholder=" "
                          hasError={ErrorMessage?.normal?.work}
                          value={data?.normal?.work}
                          type="number"
                          onChange={(e: any) => handleChange(e)}
                        />
                        <span className="text-xs text-red-500 text-left">
                          {ErrorMessage?.normal?.work}
                        </span>
                      </div>
                      <div className="mb-2">
                        <TextBox
                          htmlFor="short"
                          id="short"
                          name="short"
                          label="short"
                          hasError={ErrorMessage?.normal?.short}
                          placeholder=" "
                          value={data?.normal?.short}
                          type="number"
                          onChange={(e: any) => handleChange(e)}
                        />
                        <span className="text-xs text-red-500 text-left">
                          {ErrorMessage?.normal?.short}
                        </span>
                      </div>
                      <div className="mb-2">
                        <TextBox
                          htmlFor="plan"
                          id="plan"
                          name="plan"
                          label="plan"
                          hasError={ErrorMessage?.normal?.plan}
                          placeholder=" "
                          value={data?.normal?.plan}
                          type="number"
                          onChange={(e: any) => handleChange(e)}
                        />
                        <span className="text-xs text-red-500 text-left">
                          {ErrorMessage?.normal?.plan}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
            {matchingCardStock?.matchingCardStock?.[0] && (
              <>
                {Object.keys(
                  matchingCardStock?.matchingCardStock?.[0]?.delivery
                ).length > 0 && (
                  <div className="w-full bg-white mb-5 rounded-2xl overflow-auto">
                    <div className="py-4 px-5">
                      <fieldset
                        className={`${
                          matchingCardStock?.matchingCardStock?.[0]
                            ?.stockStatus === "delivery"
                            ? "border-green-400"
                            : "border-gray-400"
                        } border-2 rounded-2  p-2 lg:col-span-4`}
                      >
                        <legend className="capitalize px-2">Delivery</legend>
                        <div className="flex flex-wrap pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                          <div className="flex flex-col">
                            <div>
                              Updated At :{" "}
                              {moment(
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.delivery?.createAt
                              ).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              Updated By :{" "}
                              {
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.delivery?.updatedBy?.name
                              }
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
                {Object.keys(matchingCardStock?.matchingCardStock?.[0]?.hand)
                  .length > 0 && (
                  <div className="w-full bg-white mb-5 rounded-2xl overflow-auto">
                    <div className="py-4 px-5">
                      <fieldset
                        className={`${
                          matchingCardStock?.matchingCardStock?.[0]
                            ?.stockStatus === "hand"
                            ? "border-green-400"
                            : "border-gray-400"
                        } border-2 rounded-2  p-2 lg:col-span-4`}
                      >
                        <legend className="capitalize px-2">Hand</legend>
                        <div className="flex flex-wrap pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                          <div className="flex flex-col">
                            <div>
                              Updated At :{" "}
                              {moment(
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.production?.createAt
                              ).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              Updated By :{" "}
                              {
                                matchingCardStock?.matchingCardStock?.[0]?.hand
                                  ?.updatedBy?.name
                              }
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
                {/* {Object.keys(
                  matchingCardStock?.matchingCardStock?.[0]?.stitching
                ).length > 0 && (
                  <div className="w-full bg-white mb-5 rounded-2xl overflow-auto">
                    <div className="py-4 px-5">
                      <fieldset
                        className={`${
                          matchingCardStock?.matchingCardStock?.[0]
                            ?.stockStatus === "stitching"
                            ? "border-green-400"
                            : "border-gray-400"
                        } border-2 rounded-2  p-2 lg:col-span-4`}
                      >
                        <legend className="capitalize px-2">Checking</legend>
                        <div className="flex flex-wrap pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                          <div className="flex flex-col">
                            <div className="grid grid-cols-1 md:grid-cols-4 px-2 lg:p-0 xl:p-0 gap-2 mb-2">
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="TPPM"
                                  id="TPPM"
                                  name="TPPM"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "stitching"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  label="TPPM"
                                  disabled={true}
                                  placeholder=" "
                                  value={
                                    matchingCardStock?.matchingCardStock?.[0]
                                      ?.matchingCard?.TPPM
                                  }
                                  type="number"
                                />
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="plan"
                                  id="plan"
                                  name="plan"
                                  label="plan"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "stitching"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  placeholder=" "
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "stitching"
                                      ? true
                                      : false
                                  }
                                  hasError={ErrorMessage?.stitching?.plan}
                                  value={statusData?.stitching?.plan}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      stitching: {
                                        ...statusData?.stitching,
                                        plan: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      stitching: {
                                        ...ErrorMessage?.stitching,
                                        plan: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.stitching?.plan}
                                </span>
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="short"
                                  id="short"
                                  name="short"
                                  label="short"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "stitching"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  placeholder=" "
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "stitching"
                                      ? true
                                      : false
                                  }
                                  hasError={ErrorMessage?.stitching?.short}
                                  value={statusData?.stitching?.short}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      stitching: {
                                        ...statusData?.stitching,
                                        short: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      stitching: {
                                        ...ErrorMessage?.stitching,
                                        short: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.stitching?.short}
                                </span>
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="work"
                                  id="work"
                                  name="work"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "stitching"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "stitching"
                                      ? true
                                      : false
                                  }
                                  placeholder=" "
                                  hasError={ErrorMessage?.stitching?.work}
                                  value={statusData?.stitching?.work}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      stitching: {
                                        ...statusData?.stitching,
                                        work: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      stitching: {
                                        ...ErrorMessage?.stitching,
                                        work: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.stitching?.work}
                                </span>
                              </div>
                            </div>
                            <div>
                              Updated At :{" "}
                              {moment(
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.production?.createAt
                              ).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              Updated By :{" "}
                              {
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.stitching?.updatedBy?.name
                              }
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )} */}
                {Object.keys(
                  matchingCardStock?.matchingCardStock?.[0]?.checking
                ).length > 0 && (
                  <div className="w-full bg-white mb-5 rounded-2xl overflow-auto">
                    <div className="py-4 px-5">
                      <fieldset
                        className={`${
                          matchingCardStock?.matchingCardStock?.[0]
                            ?.stockStatus === "checking"
                            ? "border-green-400"
                            : "border-gray-400"
                        } border-2 rounded-2  p-2 lg:col-span-4`}
                        style={{
                          cursor: `${
                            userDetails?.type === "all"
                              ? ""
                              : userDetails?.type !== "checking"
                              ? "not-allowed"
                              : ""
                          }`,
                        }}
                      >
                        <legend className="capitalize px-2">Checking</legend>
                        <div className="flex flex-wrap pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                          <div className="flex flex-col">
                            <div className="grid grid-cols-1 md:grid-cols-4 px-2 lg:p-0 xl:p-0 gap-2 mb-2">
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="TPPM"
                                  id="TPPM"
                                  name="TPPM"
                                  label="TPPM"
                                  disabled={true}
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "checking"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  placeholder=" "
                                  value={
                                    matchingCardStock?.matchingCardStock?.[0]
                                      ?.matchingCard?.TPPM
                                  }
                                  type="number"
                                />
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="plan"
                                  id="plan"
                                  name="plan"
                                  label="plan"
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "checking"
                                      ? true
                                      : false
                                  }
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "checking"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  placeholder=" "
                                  hasError={ErrorMessage?.checking?.plan}
                                  value={statusData?.checking?.plan}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      checking: {
                                        ...statusData?.checking,
                                        plan: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      checking: {
                                        ...ErrorMessage?.checking,
                                        plan: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.checking?.plan}
                                </span>
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="short"
                                  id="short"
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "checking"
                                      ? true
                                      : false
                                  }
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "checking"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  name="short"
                                  label="short"
                                  placeholder=" "
                                  hasError={ErrorMessage?.checking?.short}
                                  value={statusData?.checking?.short}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      checking: {
                                        ...statusData?.checking,
                                        short: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      checking: {
                                        ...ErrorMessage?.checking,
                                        short: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.checking?.short}
                                </span>
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="work"
                                  id="work"
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "checking"
                                      ? true
                                      : false
                                  }
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "checking"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  label="work"
                                  hasError={ErrorMessage?.checking?.work}
                                  placeholder=" "
                                  value={statusData?.checking?.work}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      checking: {
                                        ...statusData?.checking,
                                        work: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      checking: {
                                        ...ErrorMessage?.checking,
                                        work: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.checking?.work}
                                </span>
                              </div>
                            </div>
                            <div>
                              Updated At :{" "}
                              {moment(
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.production?.createAt
                              ).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              Updated By :{" "}
                              {
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.checking?.updatedBy?.name
                              }
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
                {Object.keys(
                  matchingCardStock?.matchingCardStock?.[0]?.production
                ).length > 0 && (
                  <div className="w-full bg-white mb-5 rounded-2xl overflow-auto">
                    <div className="py-4 px-5">
                      <fieldset
                        className={`${
                          matchingCardStock?.matchingCardStock?.[0]
                            ?.stockStatus === "production"
                            ? "border-green-400"
                            : "border-gray-400"
                        }  border-2 rounded-2  p-2 lg:col-span-4`}
                      >
                        <legend className="capitalize px-2">Production</legend>
                        <div
                          className="flex flex-wrap pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5"
                          style={{
                            cursor: `${
                              userDetails?.type === "all"
                                ? ""
                                : userDetails?.type !== "production"
                                ? "not-allowed"
                                : ""
                            }`,
                          }}
                        >
                          <div className="flex flex-col">
                            <div className="grid grid-cols-1 md:grid-cols-4 px-2 lg:p-0 xl:p-0 gap-2 mb-2">
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="TPPM"
                                  id="TPPM"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "production"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  name="TPPM"
                                  label="TPPM"
                                  disabled={true}
                                  placeholder=" "
                                  value={
                                    matchingCardStock?.matchingCardStock?.[0]
                                      ?.matchingCard?.TPPM
                                  }
                                  type="number"
                                />
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="plan"
                                  id="plan"
                                  name="plan"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "production"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "production"
                                      ? true
                                      : false
                                  }
                                  label="plan"
                                  placeholder=""
                                  hasError={ErrorMessage?.production?.plan}
                                  value={statusData?.production?.plan}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      production: {
                                        ...statusData?.production,
                                        plan: Number(e.target.value),
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      production: {
                                        ...ErrorMessage?.production,
                                        plan: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.production?.plan}
                                </span>
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="short"
                                  id="short"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "production"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "production"
                                      ? true
                                      : false
                                  }
                                  name="short"
                                  hasError={ErrorMessage?.production?.short}
                                  label="short"
                                  placeholder=" "
                                  value={statusData?.production?.short}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      production: {
                                        ...statusData?.production,
                                        short: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      production: {
                                        ...ErrorMessage?.production,
                                        short: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.production?.short}
                                </span>
                              </div>
                              <div className="mt-2">
                                <TextBox
                                  htmlFor="work"
                                  id="work"
                                  name="work"
                                  label="work"
                                  style={{
                                    cursor: `${
                                      userDetails?.type === "all"
                                        ? ""
                                        : userDetails?.type !== "production"
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                  disabled={
                                    userDetails?.type === "all"
                                      ? false
                                      : userDetails?.type !== "production"
                                      ? true
                                      : false
                                  }
                                  hasError={ErrorMessage?.production?.work}
                                  placeholder=" "
                                  value={statusData?.production?.work}
                                  type="number"
                                  onChange={(e: any) => {
                                    setStatusData({
                                      ...statusData,
                                      production: {
                                        ...statusData?.production,
                                        work: e.target.value,
                                      },
                                    });
                                    setErrorMessage({
                                      ...ErrorMessage,
                                      production: {
                                        ...ErrorMessage?.production,
                                        work: "",
                                      },
                                    });
                                  }}
                                />
                                <span className="text-xs text-red-500 text-left">
                                  {ErrorMessage?.production?.work}
                                </span>
                              </div>
                            </div>
                            <div>
                              Updated At :{" "}
                              {moment(
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.production?.createAt
                              ).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              Updated By :{" "}
                              {
                                matchingCardStock?.matchingCardStock?.[0]
                                  ?.production?.updatedBy?.name
                              }
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
                <div className="w-full bg-white mb-5 rounded-2xl overflow-auto">
                  {matchingCardStock?.matchingCardStock?.[0] && (
                    <div className="py-4 px-5">
                      <fieldset className="border-2 rounded-2 border-gray-400 p-2 lg:col-span-4">
                        <legend className="capitalize px-2">Plan</legend>
                        <div className="flex flex-wrap pt-4 px-2 lg:p-0 xl:p-0 gap-2 m-5">
                          {Object.keys(
                            matchingCardStock?.matchingCardStock?.[0]?.plan
                          ).length > 0 && (
                            <div className="flex flex-col">
                              <div>
                                Created At :{" "}
                                {moment(
                                  matchingCardStock?.matchingCardStock?.[0]
                                    ?.plan?.createAt
                                ).format("YYYY-MM-DD")}
                              </div>
                              <div>Created By : System</div>
                            </div>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  )}
                </div>
                <div className="block lg:hidden xl:hidden md:block">
                  <div className="flex  gap-5 w-full justify-center items-center bg-white mb-5 p-2 rounded-2xl">
                    {matchingCardStock?.matchingCardStock?.[0]?.stockStatus ===
                      "stitching" ||
                    matchingCardStock?.matchingCardStock?.[0]?.stockStatus ===
                      "hand" ? (
                      <div className="w-44">
                        <>
                          <Select
                            name="partyName"
                            classNamePrefix="customSelect"
                            className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                setSelectedType(e);
                              }
                            }}
                            options={type}
                            value={selectedType}
                          />
                        </>
                      </div>
                    ) : null}
                    <div className="grid grid-cols-1 md:grid-cols-3 p-3 lg:p-0 xl:p-0 gap-2">
                      {matchingCardStock?.matchingCardStock?.[0]
                        ?.stockStatus !== "delivery" && (
                        <button
                          onClick={handleSubmit}
                          className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor hover:shadow-xs hover:-translate-y-px"
                        >
                          <i className="fa fa-save"></i>
                          <span className="ml-2">
                            Go To{" "}
                            {matchingCardStock?.matchingCardStock?.[0]
                              ?.stockStatus === "plan"
                              ? "Production"
                              : matchingCardStock?.matchingCardStock?.[0]
                                  ?.stockStatus === "production"
                              ? "Checking"
                              : matchingCardStock?.matchingCardStock?.[0]
                                  ?.stockStatus === "checking"
                              ? "Stitching"
                              : matchingCardStock?.matchingCardStock?.[0]
                                  ?.stockStatus === "stitching"
                              ? selectedType?.label
                              : "Delivery"}
                          </span>
                        </button>
                      )}
                      <button
                        onClick={goToNext}
                        className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      >
                        <i className="fa fa-save"></i>
                        <span className="ml-2">Update</span>
                      </button>

                      <button
                        className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        onClick={() => {
                          navigate("/matchingcarddashboardstock");
                        }}
                      >
                        <i className="fa fa-close"></i>
                        <span className="ml-2">Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
