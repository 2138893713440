import { useState, useEffect, useRef } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../components/form/Select";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ClothPartyAdd } from "../../components/modal/ClothPartyAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import { DiamondAdd } from "../../components/modal/DiamondAdd";
import { FoilColorAdd } from "../../components/modal/FoilColorAdd";
import { MaterialAdd } from "../../components/modal/MaterialAdd";
import {
  useLazyPartyQuery,
  useLazyNewSareeRateInvoiceNumberQuery,
  useLazyClothQuery,
  useLazyItemQuery,
  useUpdateSareeRateCardMutation,
  useLazyGetSareeRateCardQuery,
  useLazyClothPartyQuery,
  useLazyMaterialQuery,
  useLazyDiamondsQuery,
  useLazyFoilColorQuery,
  useLazyRemarksQuery,
  useAddSareeRateCardMutation,
  useLazyGetClothQuery,
  useLazyGetClothPartyQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyGetDiamondQuery,
  useLazyGetFoilColorQuery,
  useLazyGetMaterialQuery,
  useLazyGetRemarkQuery,
  useLazyAllSareeRateInvoiceNumberQuery,
} from "../../servicesRtkQuery/publicApi";
import { useSelector } from "react-redux";
import ProgressiveImage from "react-progressive-graceful-image";
import uuid from "react-uuid";
import S3 from "aws-sdk/clients/s3";
import ObjectID from "bson-objectid";
import Swal from "sweetalert2";
import { RemarksAdd } from "../../components/modal/RemarksAdd";
import {
  sareeJobWorkValidation,
  sareeFoilValidation,
  sareeHandValidation,
  sareeStitchValidation,
  sareeFabricValidation,
} from "../../utils/validation";
import Loader from "../../components/page/loader";
import { ERROR_MESSAGE_TIMEOUT, FILE_UPLOAD_SIZE } from "../../utils/constants";
import { SareeRateCardPreview } from "../../components/pdf/ratecard/sareeRateCardPreview";
import { useLazySareeRateCardQuery } from "../../servicesRtkQuery/publicApi";
import {
  selectFilterConfig,
  setDefaultDate,
} from "../../utils/globalFunctions";
import { createFilter } from "react-select";

export default function SareeRateCard() {
  let pointTotal = 0;
  const bucketName: any = process.env.REACT_APP_AWS_BUCKET_NAME;
  const region: any = process.env.REACT_APP_AWS_BUCKET_REGION;
  const accessKeyId: any = process.env.REACT_APP_AWS_ACCESS_KEY;
  const secretAccessKey: any = process.env.REACT_APP_AWS_SECRET_KEY;

  const s3 = new S3({
    region: region,
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  });
  const [isCardUpdate, setIsCardUpdate] = useState(false);
  const [isCardCopy, setIsCardCopy] = useState(false);
  let url = window.location.pathname;
  let { id } = useParams();
  useEffect(() => {
    if (url == "/ratecard/saree/copy") {
      setIsCardCopy(true);
    } else if (url == "/ratecard/saree/add") {
      clearData();
      setIsCardUpdate(false);
      setIsCardCopy(false);
    } else {
      if (id) {
        setIsCardUpdate(true);
        setIsCardCopy(false);
      } else {
        setIsCardUpdate(false);
        setIsCardCopy(false);
      }
    }
  }, [id]);
  // Redux Data State
  const sareeCardData = useSelector(
    (state: any) => state.rateCardSlice.rateCardData
  );

  // Global Sample Card Data State
  const [cardData, setCardData]: any = useState();
  const [jobWorkData, setJobWorkData]: any = useState({});
  const [foilData, setFoilData]: any = useState({});
  const [stitchData, setStitchData]: any = useState({});
  const [handData, setHandData]: any = useState({});
  const [fabricData, setFabricData]: any = useState({});

  const [jobWorkTableData, setJobWorkTableData] = useState<any>([]);
  const [foilTableData, setFoilTableData] = useState<any>([]);
  const [stitchTableData, setStitchTableData] = useState<any>([]);
  const [handTableData, setHandTableData] = useState<any>([]);
  const [fabricTableData, setFabricTableData] = useState<any>([]);

  const [selectedJobRow, setSelectedJobRow]: any = useState("");
  const [selectedFoilRow, setSelectedFoilRow]: any = useState("");
  const [selectedStitchRow, setSelectedStitchRow]: any = useState("");
  const [selectedHandRow, setSelectedHandRow]: any = useState("");
  const [selectedFabricRow, setSelectedFabricRow]: any = useState("");

  const [trigger, result] = useLazyPartyQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [invoicetrigger, invoiceResult] =
    useLazyNewSareeRateInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceerror,
  } = invoiceResult;

  const [allInvoicetrigger, allInvoiceResult] =
    useLazyAllSareeRateInvoiceNumberQuery();
  const {
    isSuccess: isAllInvoiceSuccess,
    isFetching: isAllInvoiceFetching,
    isError: isAllInvoiceError,
    error: allInvoiceerror,
  } = allInvoiceResult;

  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [clothPartyTrigger, clothPartyResult] = useLazyClothPartyQuery();
  const {
    isSuccess: isClothPartySuccess,
    isFetching: isClothPartyFetching,
    isError: isClothPartyError,
    error: clothPartyError,
  } = clothPartyResult;

  const [itemTrigger, itemResult] = useLazyItemQuery();
  const {
    isSuccess: isItemSuccess,
    isFetching: isItemFetching,
    isError: isItemError,
    error: itemError,
  } = itemResult;

  const [materialTrigger, materialResult] = useLazyMaterialQuery();
  const {
    isSuccess: isMaterialSuccess,
    isFetching: isMaterialFetching,
    isError: isMaterialError,
    error: materialError,
  } = materialResult;

  const [foilTrigger, foilResult] = useLazyFoilColorQuery();
  const {
    isSuccess: isFoilSuccess,
    isFetching: isFoilFetching,
    isError: isFoilError,
    error: FoilError,
  } = foilResult;

  const [remarksTrigger, remarksResult] = useLazyRemarksQuery();
  const {
    isSuccess: isRemarksSuccess,
    isFetching: isRemarksFetching,
    isError: isRemarksError,
    error: RemarksError,
  } = remarksResult;

  const [diamondTrigger, diamondResult] = useLazyDiamondsQuery();
  const {
    isSuccess: isDiamondSuccess,
    isFetching: isDiamondFetching,
    isError: isDiamondError,
    error: diamondError,
  } = diamondResult;

  const [sareeTrigger, sareeResult] = useLazyGetSareeRateCardQuery();
  const {
    isSuccess: isSareeSuccess,
    isFetching: isSareeFetching,
    isError: isSareeError,
    error: sareeError,
  } = sareeResult;

  const [pdfTrigger, pdfResult] = useLazyGetSareeRateCardQuery();
  const {
    isSuccess: isPdfSuccess,
    isFetching: isPdfFetching,
    isError: isPdfError,
    error: pdfError,
  } = pdfResult;

  const [allTrigger, allResult] = useLazySareeRateCardQuery();
  const {
    isSuccess: isAllSuccess,
    isFetching: isAllFetching,
    isError: isAllError,
    error: allError,
  } = allResult;
  const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
  const {
    isSuccess: isgetClothSuccess,
    isFetching: isgetClothFetching,
    isError: isgetClothError,
    error: getClothError,
  } = getClothResult;
  const [getClothPartyTrigger, getClothPartyResult] =
    useLazyGetClothPartyQuery();
  const {
    isSuccess: isgetClothPartySuccess,
    isFetching: isgetClothPartyFetching,
    isError: isgetClothPartyError,
    error: getClothPartyError,
  } = getClothPartyResult;
  const [getItemTrigger, getItemResult] = useLazyGetItemQuery();
  const {
    isSuccess: isgetItemSuccess,
    isFetching: isgetItemFetching,
    isError: isgetItemError,
    error: getItemError,
  } = getItemResult;
  const [getPartyNameTrigger, getPartyNameResult] = useLazyGetPartyNameQuery();
  const {
    isSuccess: isgetPartyNameSuccess,
    isFetching: isgetPartyNameFetching,
    isError: isgetPartyNameError,
    error: getPartyNameError,
  } = getPartyNameResult;
  const [getDiamondTrigger, getDiamondResult] = useLazyGetDiamondQuery();
  const {
    isSuccess: isGetDiamondSuccess,
    isFetching: isGetDiamondFetching,
    isError: isGetDiamondError,
    error: getdiamondError,
  } = getDiamondResult;
  const [getRemarkTrigger, getRemarkResult] = useLazyGetRemarkQuery();
  const {
    isSuccess: isGetRemarksSuccess,
    isFetching: isGetRemarksFetching,
    isError: isGetRemarksError,
    error: getRemarksError,
  } = remarksResult;
  const [getMaterialTrigger, getMaterialResult] = useLazyGetMaterialQuery();
  const {
    isSuccess: isgetMaterialSuccess,
    isFetching: isgetMaterialFetching,
    isError: isgetMaterialError,
    error: getMaterialError,
  } = getMaterialResult;
  const [getFoilColorTrigger, getFoilColorResult] = useLazyGetFoilColorQuery();
  const {
    isSuccess: isFoilColorSuccess,
    isFetching: isFoilColorFetching,
    isError: isFoilColorError,
    error: FoilColorError,
  } = getFoilColorResult;

  const [showSareeRatePreview, setShowSareeRatePreview] = useState(false);

  useEffect(() => {
    trigger("");
    invoicetrigger();
    clothTrigger("");
    clothPartyTrigger("");
    itemTrigger("");
    materialTrigger("");
    diamondTrigger("");
    foilTrigger("");
    remarksTrigger("");
  }, []);

  const navigate = useNavigate();
  // error message state
  const [errorMessage, setErrorMessage]: any = useState({});

  // Party Creatable State
  const [showAddParty, setShowAddParty] = useState(false);
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [Party, setParty]: any = useState();
  const [selectedParty, setSelectedParty]: any = useState({});

  // Cloth Creatable State
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [clotheState, setClotheState]: any = useState({});

  // Item Creatable State
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemAdd, setItemAdd] = useState("");
  const [Item, setItem]: any = useState<any>([]);
  const [selectedItem, setSelectedItem]: any = useState({});
  const [itemState, setItemState]: any = useState({});
  const [selectedFoilItem, setSelectedFoilItem]: any = useState({});
  const [selectedStitchItem, setSelectedStitchItem]: any = useState({});
  const [selectedHandItem, setSelectedHandItem]: any = useState({});
  const [selectedFabricItem, setSelectedFabricItem]: any = useState({});

  // Cloth Party Creatable State
  const [showAddClothParty, setShowAddClothParty] = useState(false);
  const [clothPartyAdd, setClothPartyAdd] = useState("");
  const [clothParty, setClothParty]: any = useState<any>([]);
  const [selectedClothParty, setSelectedClothParty]: any = useState({});
  const [clothePartyState, setClothePartyState]: any = useState({});

  // Material Creatable State
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [materialAdd, setMaterialAdd] = useState("");
  const [Material, setMaterial]: any = useState([]);
  const [selectedMaterial, setSelectedMaterial]: any = useState({});
  const [materialState, setMaterialState]: any = useState({});

  // Reamrks Creatable State
  const [showAddRemarks, setShowAddRemarks] = useState(false);
  const [remarksAdd, setRemarksAdd] = useState("");
  const [Remarks, setRemarks]: any = useState([]);
  const [selectedRemarks, setSelectedRemarks]: any = useState({});
  const [selectedHandRemarks, setSelectedHandRemarks]: any = useState({});
  const [remarksState, setRemarksState]: any = useState({});

  // Diamond Creatable State
  const [showAddDiamond, setShowAddDiamond] = useState(false);
  const [diamondAdd, setDiamondAdd] = useState("");
  const [Diamond, setDiamond]: any = useState([]);
  const [selectedDiamond, setSelectedDiamond]: any = useState({});
  const [diamondState, setDiamondState]: any = useState({});

  // Foil Color Creatable State
  const [showAddFoil, setShowAddFoil] = useState(false);
  const [foilAdd, setFoilAdd] = useState("");
  const [Foil, setFoil]: any = useState([]);
  const [selectedFoil, setSelectedFoil]: any = useState({});
  const [foilState, setFoilState]: any = useState({});

  const [invoiceNo, setInvoiceNo]: any = useState<any>([]);
  const [invoice, setInvoice]: any = useState<any>([]);
  const [newRateCard, setNewRateCard]: any = useState<any>([]);

  const [images, setImages]: any = useState();
  const [selectedInvoice, setSelectedInvoice]: any = useState("");
  const [selectedInvoiceCard, setSelectedInvoiceCard]: any = useState("");
  const [tdays, setTdays] = useState(0);

  // Is Update State
  const [isJobUpdate, setIsJobUpdate] = useState(false);
  const [isFoilUpdate, setIsFoilUpdate] = useState(false);
  const [isStitchUpdate, setIsStitchUpdate] = useState(false);
  const [isHandUpdate, setIsHandUpdate] = useState(false);
  const [isFabricUpdate, setIsFabricUpdate] = useState(false);

  //Total States
  const [handTotal, setHandTotal]: any = useState("");
  const [stitchTotal, setStitchTotal]: any = useState("");
  const [foilTotal, setFoilTotal]: any = useState("");
  const [fabricTotal, setFabricTotal]: any = useState("");
  const [jobTotal, setJobTotal]: any = useState("");

  const [handPar, setHandPar]: any = useState();
  const [stitchPar, setStitchPar]: any = useState();
  const [foilPar, setFoilPar]: any = useState();
  const [fabricPar, setFabricPar]: any = useState();
  const [jobPar, setJobPar]: any = useState();

  const [jobNotes, setJobNotes]: any = useState("");
  const [foilNotes, setFoilNotes]: any = useState("");
  const [dyeNotes, setDyeNotes]: any = useState("");
  const [stitchNotes, setStitchNotes]: any = useState("");
  const [handNotes, setHandNotes]: any = useState("");
  const [fabricNotes, setFabricNotes]: any = useState("");
  const [finalNotes, setFinalNotes]: any = useState("");

  const [isFoilSelect, setIsFoilSelect] = useState(false);
  const [isStitchSelect, setIsStitchSelect] = useState(false);
  const [isHandSelect, setIsHandSelect] = useState(false);
  const [isFabricSelect, setIsFabricSelect] = useState(false);
  const [isJobSelect, setIsJobSelect] = useState(false);

  const [updateCard] = useUpdateSareeRateCardMutation();
  const [addCard] = useAddSareeRateCardMutation();
  const [savedId, setSavedId]: any = useState("");

  // Dye Section States
  const [dyeData, setDyeData]: any = useState({});
  const [dyeTableData, setDyeTableData] = useState<any>([]);
  const [selectedDyeRow, setSelectedDyeRow]: any = useState("");
  const [selectedDyeItem, setSelectedDyeItem]: any = useState({});
  const [selectedDye, setSelectedDye]: any = useState({});
  const [isDyeUpdate, setIsDyeUpdate] = useState(false);
  const [dyeTotal, setDyeTotal]: any = useState(0);
  const [selectedDyeRemarks, setSelectedDyeRemarks]: any = useState({});
  const [dyePar, setDyePar]: any = useState();
  const [isDyeSelect, setIsDyeSelect] = useState(false);
  const [isValue, setIsValue]: any = useState([]);
  // reference props
  const jobWorkRef: any = useRef(null);
  const foilRef: any = useRef(null);
  const stitchingRef: any = useRef(null);
  const handWorkRef: any = useRef(null);
  const fabricsRef: any = useRef(null);
  const [isOldDiamond, setIsOldDiamond]: any = useState([]);
  const handleRemoveOption = (removedOption: any) => {
    const updatedOptions: any = isOldDiamond.filter(
      (option: any) => option.value !== removedOption.value
    );
    setIsOldDiamond(updatedOptions);
  };
  const creatableOptions = Diamond.filter(
    (option: any) => !isOldDiamond.includes(option?.label)
  );
  const [amountTotal, setAmountTotal]: any = useState(0);
  useEffect(() => {
    let totalAmount = 0;
    handTableData?.forEach((item: any) => {
      if (item?.amount !== "") {
        totalAmount = totalAmount + Number(item?.amount);
      }
    });
    setAmountTotal(Math.floor(totalAmount));
  }, [handTableData]);

  const [diamondTotal, setDimondTotal]: any = useState({});
  useEffect(() => {
    let totalDiamond: any = {};
    handTableData?.forEach((item: any) => {
      item?.diamond?.map((i: any, index: any) => {
        totalDiamond[i?.titleId] = {
          totalQty:
            (totalDiamond[i?.titleId]?.totalQty
              ? totalDiamond[i?.titleId]?.totalQty
              : 0) + Math.floor(Number(item?.head) * Number(i?.qty)),
          total: (totalDiamond[i?.titleId]?.total || 0) + Number(i?.qty),
        };
      });
      setDimondTotal(totalDiamond);
    });
  }, [handTableData]);

  useEffect(() => {
    if (isCardCopy) {
      let copyId = localStorage.getItem("copy");

      if (sareeCardData?.sareeCard) {
        let apiData = sareeCardData?.sareeCard?.filter((item: any) => {
          return item._id === copyId;
        })[0];

        const date: any = setDefaultDate();
        setCardData({
          ...apiData,
          date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
          image: "",
        });
        if (apiData?.value && Array.isArray(apiData?.value)) {
          setIsValue(apiData.value);
        }
        let jobData: any = [];
        apiData?.job?.forEach((item: any) => {
          let object = {
            _id: item?._id,
            area: item?.area,
            itemId: item?.item?._id,
            stitch: item?.stitch,
            code: item?.code,
            rate: item?.rate,
            meter: item?.meter,
          };
          jobData.push(object);
        });
        setJobWorkTableData(jobData);

        let foilData: any = [];
        if (Object.keys(apiData?.foil[0]).length > 0) {
          apiData?.foil?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              colorId: item?.color?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            foilData.push(object);
          });
        }
        setFoilTableData(foilData);

        let dyeData: any = [];
        if (apiData?.daying?.[0]) {
          if (Object.keys(apiData?.daying?.[0]).length > 0) {
            apiData?.daying?.forEach((item: any) => {
              let object = {
                _id: item?._id,
                colorId: item?.color?._id,
                itemId: item?.item?._id,
                remarkId: item?.remark?._id,
                qty: item?.qty,
                rate: item?.rate,
                amount: item?.amount,
              };
              dyeData.push(object);
            });
          }
        }

        setDyeTableData(dyeData);

        let stitchData: any = [];
        if (Object.keys(apiData?.stitching[0]).length > 0) {
          apiData?.stitching?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              materialId: item?.materials?._id,
              itemId: item?.item?._id,
              meter: item?.meter,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            stitchData.push(object);
          });
        }

        setStitchTableData(stitchData);

        let handData: any = [];
        if (Object.keys(apiData?.hand[0]).length > 0) {
          apiData?.hand?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              head: Number(item?.head),
              diamond: item?.diamond,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            handData.push(object);
          });
        }
        setHandTableData(handData);

        let fabricData: any = [];
        if (Object.keys(apiData?.fabrics[0]).length > 0) {
          apiData?.fabrics?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              clotheId: item?.clothe?._id,
              itemId: item?.item?._id,
              clothePartyId: item?.clotheParty?._id,
              meter: item?.meter,
              rate: item?.rate,
              amount: item?.amount,
            };
            fabricData.push(object);
          });
        }
        setFabricTableData(fabricData);
      } else {
        sareeTrigger(copyId);
      }
    } else if (isCardUpdate) {
      if (sareeCardData?.sareeCard) {
        let apiData = sareeCardData?.sareeCard?.filter((item: any) => {
          return item._id === id;
        })[0];
        setCardData(apiData);
        let jobData: any = [];
        apiData?.job?.forEach((item: any) => {
          let object = {
            _id: item?._id,
            area: item?.area,
            itemId: item?.item?._id,
            stitch: item?.stitch,
            code: item?.code,
            rate: item?.rate,
            meter: item?.meter,
          };
          jobData.push(object);
        });
        setJobWorkTableData(jobData);
        if (apiData?.value && Array.isArray(apiData?.value)) {
          setIsValue(apiData.value);
        }
        let foilData: any = [];
        if (Object.keys(apiData?.foil[0]).length > 0) {
          apiData?.foil?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              colorId: item?.color?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            foilData.push(object);
          });
        }
        setFoilTableData(foilData);

        let dyeData: any = [];
        if (apiData?.daying?.[0]) {
          if (Object.keys(apiData?.daying?.[0]).length > 0) {
            apiData?.daying?.forEach((item: any) => {
              let object = {
                _id: item?._id,
                colorId: item?.color?._id,
                itemId: item?.item?._id,
                remarkId: item?.remark?._id,
                qty: item?.qty,
                rate: item?.rate,
                amount: item?.amount,
              };
              dyeData.push(object);
            });
          }
        }

        setDyeTableData(dyeData);

        let stitchData: any = [];
        if (Object.keys(apiData?.stitching[0]).length > 0) {
          apiData?.stitching?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              materialId: item?.materials?._id,
              itemId: item?.item?._id,
              meter: item?.meter,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            stitchData.push(object);
          });
        }
        setStitchTableData(stitchData);

        let handData: any = [];
        if (Object.keys(apiData?.hand[0]).length > 0) {
          apiData?.hand?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              head: Number(item?.head),
              diamond: item?.diamond,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            handData.push(object);
          });
        }
        setHandTableData(handData);

        let fabricData: any = [];
        if (Object.keys(apiData?.fabrics[0]).length > 0) {
          apiData?.fabrics?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              clotheId: item?.clothe?._id,
              itemId: item?.item?._id,
              clothePartyId: item?.clotheParty?._id,
              meter: item?.meter,
              rate: item?.rate,
              amount: item?.amount,
            };
            fabricData.push(object);
          });
        }
        setFabricTableData(fabricData);
      } else {
        sareeTrigger(id);
      }
    } else {
      setCardData({});
      const date: any = setDefaultDate();
      setCardData({
        ...cardData,
        date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
      });
    }
  }, [isCardCopy, isCardUpdate]);

  const clearData = () => {
    setCardData({
      designNo: "0-0",
      date: "",
      image: "",
    });
    setJobWorkTableData([]);
    setJobWorkData({
      srNo: "",
      area: "",
      itemId: "",
      reamrksId: "",
      Pcs: "",
      code: "",
      stitch: "",
      totalQty: "",
      valueAdd: "",
      totalAmt: "",
      rate: "",
    });
    setSelectedRemarks({});
    setIsJobUpdate(false);

    setSelectedCloth({});
    setFoilTableData([]);
    setFoilData({
      qty: "",
      rate: "",
      amount: "",
    });
    setSelectedFoil({});
    setSelectedFoilItem({});
    setIsFoilUpdate(false);
    setDyeTableData([]);
    setDyeData({
      qty: "",
      rate: "",
      amount: "",
    });
    setSelectedDye({});
    setSelectedDyeItem({});
    setSelectedDyeRemarks({});
    setIsDyeUpdate(false);
    setHandTableData([]);
    setHandData({
      qty: "",
      rate: "",
      amount: "",
      head: "",
      diamond: [],
    });
    setSelectedHandItem({});
    setSelectedHandRemarks({});

    setIsHandUpdate(false);
    setStitchTableData([]);

    setStitchData({
      qty: "",
      rate: "",
      amount: "",
      meter: "",
    });
    setSelectedMaterial({});
    setSelectedStitchItem({});

    setIsStitchUpdate(false);
    setFabricTableData([]);

    setFabricData({
      meter: "",
      rate: "",
      amount: "",
    });
    setSelectedCloth({});
    setSelectedFabricItem({});
    setSelectedClothParty({});

    setIsFabricUpdate(false);
    setSelectedParty({});
    setImages(null);
    setIsJobSelect(false);
    setIsStitchSelect(false);
    setIsFoilSelect(false);
    setIsHandSelect(false);
    setIsFabricSelect(false);
    setJobPar("");
    setStitchPar("");
    setFoilPar("");
    setHandPar("");
    setStitchPar("");
    setFabricPar("");
  };

  useEffect(() => {
    clearData();
  }, [isCardUpdate]);

  useEffect(() => {
    if (isgetClothPartySuccess && !isgetClothPartyFetching) {
      let clothParty = getClothPartyResult.data?.data?.clotheParty.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (clothParty) {
        setClothParty(clothParty);
      }
    }
  }, [isgetClothPartySuccess, isgetClothPartyFetching]);

  useEffect(() => {
    if (isFoilColorSuccess && !isFoilColorFetching) {
      let getAllFoil = getFoilColorResult.data?.data?.foilColor?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setFoil(getAllFoil ? getAllFoil : []);
      let foils: any = {};
      getAllFoil?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        foils[id] = label;
      });
      setFoilState(foils);
    }
  }, [isFoilColorSuccess, isFoilColorFetching]);

  useEffect(() => {
    if (isGetRemarksSuccess && !isGetRemarksFetching) {
      let getAllRemarks = getRemarkResult.data?.data?.remark?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setRemarks(getAllRemarks ? getAllRemarks : []);
      let remarks: any = {};
      getAllRemarks?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        remarks[id] = label;
      });
      setRemarksState(remarks);
    }
  }, [isGetRemarksSuccess, isGetRemarksFetching]);

  useEffect(() => {
    if (isgetMaterialSuccess && !isgetMaterialFetching) {
      let getAllMaterial = getMaterialResult.data?.data?.material?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setMaterial(getAllMaterial ? getAllMaterial : []);

      let materials: any = {};

      getAllMaterial?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        materials[id] = label;
      });

      setMaterialState(materials);
    }
  }, [isgetMaterialSuccess, isgetMaterialFetching]);

  useEffect(() => {
    if (isgetItemSuccess && !isgetItemFetching) {
      let item = getItemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      if (item) {
        setItem(item);
      }
    }
  }, [isgetItemSuccess, isgetItemFetching]);

  useEffect(() => {
    if (isgetPartyNameSuccess && !isgetPartyNameFetching) {
      let party = getPartyNameResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (party) {
        setParty(party);
      }
    }
  }, [isgetPartyNameSuccess, isgetPartyNameFetching]);

  useEffect(() => {
    if (isGetDiamondSuccess && !isGetDiamondFetching) {
      let getAllDiamond = getDiamondResult.data?.data?.diamond?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setDiamond(getAllDiamond ? getAllDiamond : []);
      let diamonds: any = {};
      getAllDiamond?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        diamonds[id] = label;
      });
      setDiamondState(diamonds);
    }
  }, [isGetDiamondSuccess, isGetDiamondFetching]);

  useEffect(() => {
    if (isCardUpdate || isCardCopy) {
      if (isSareeSuccess && !isSareeFetching) {
        let apiData = sareeResult?.data?.data?.sareeCard?.[0];
        if (isCardUpdate) {
          setCardData(apiData);
        } else {
          const date: any = setDefaultDate();
          setCardData({
            ...apiData,
            date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
            image: "",
          });
        }
        if (apiData?.value && Array.isArray(apiData?.value)) {
          setIsValue(apiData.value);
        }
        let jobData: any = [];
        apiData?.job?.forEach((item: any) => {
          let object = {
            _id: item?._id,
            area: item?.area,
            itemId: item?.item?._id,
            stitch: item?.stitch,
            code: item?.code,
            rate: item?.rate,
            meter: item?.meter,
          };
          jobData.push(object);
        });
        setJobWorkTableData(jobData);

        let foilData: any = [];
        if (Object.keys(apiData?.foil[0]).length > 0) {
          apiData?.foil?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              colorId: item?.color?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            foilData.push(object);
          });
        }
        setFoilTableData(foilData);

        let dyeData: any = [];
        if (apiData?.daying?.[0]) {
          if (Object.keys(apiData?.daying?.[0]).length > 0) {
            apiData?.daying?.forEach((item: any) => {
              let object = {
                _id: item?._id,
                colorId: item?.color?._id,
                itemId: item?.item?._id,
                remarkId: item?.remark?._id,
                qty: item?.qty,
                rate: item?.rate,
                amount: item?.amount,
              };
              dyeData.push(object);
            });
          }
        }

        setDyeTableData(dyeData);

        let stitchData: any = [];
        if (Object.keys(apiData?.stitching[0]).length > 0) {
          apiData?.stitching?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              materialId: item?.materials?._id,
              itemId: item?.item?._id,
              meter: item?.meter,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            stitchData.push(object);
          });
        }
        setStitchTableData(stitchData);

        let handData: any = [];
        if (Object.keys(apiData?.hand[0]).length > 0) {
          apiData?.hand?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              head: Number(item?.head),
              diamond: item?.diamond,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            handData.push(object);
          });
        }
        setHandTableData(handData);

        let fabricData: any = [];
        if (Object.keys(apiData?.fabrics[0]).length > 0) {
          apiData?.fabrics?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              clotheId: item?.clothe?._id,
              itemId: item?.item?._id,
              clothePartyId: item?.clotheParty?._id,
              meter: item?.meter,
              rate: item?.rate,
              amount: item?.amount,
            };
            fabricData.push(object);
          });
        }
        setFabricTableData(fabricData);
      }
    } else {
      setCardData({});
      const date: any = setDefaultDate();
      setCardData({
        ...cardData,
        date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
      });
    }
  }, [isSareeSuccess, isSareeFetching, materialState]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      let getAllParty = result.data?.data?.party.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setParty(getAllParty ? getAllParty : []);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setCloth(getAllCloth ? getAllCloth : []);
      let cloths: any = {};
      getAllCloth?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        cloths[id] = label;
      });
      setClotheState(cloths);
    }
  }, [isClothSuccess, isClothFetching]);

  useEffect(() => {
    if (isClothPartySuccess && !isClothPartyFetching) {
      let getAllClothParty = clothPartyResult.data?.data?.clotheParty.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setClothParty(getAllClothParty ? getAllClothParty : []);
      let parties: any = {};
      getAllClothParty?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        parties[id] = label;
      });
      setClothePartyState(parties);
    }
  }, [isClothPartySuccess, isClothPartyFetching]);

  useEffect(() => {
    if (isMaterialSuccess && !isMaterialFetching) {
      let getAllMaterial = materialResult.data?.data?.material?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setMaterial(getAllMaterial ? getAllMaterial : []);
      let materials: any = {};
      getAllMaterial?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        materials[id] = label;
      });
      setMaterialState(materials);
    }
  }, [isMaterialSuccess, isMaterialFetching]);

  useEffect(() => {
    if (isRemarksSuccess && !isRemarksFetching) {
      let getAllRemarks = remarksResult.data?.data?.remark?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setRemarks(getAllRemarks ? getAllRemarks : []);
      let remarks: any = {};
      getAllRemarks?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        remarks[id] = label;
      });
      setRemarksState(remarks);
    }
  }, [isRemarksSuccess, isRemarksFetching]);

  useEffect(() => {
    if (isDiamondSuccess && !isDiamondFetching) {
      let getAllDiamond = diamondResult.data?.data?.diamond?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setDiamond(getAllDiamond ? getAllDiamond : []);
      let diamonds: any = {};
      getAllDiamond?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        diamonds[id] = label;
      });
      setDiamondState(diamonds);
    }
  }, [isDiamondSuccess, isDiamondFetching]);

  useEffect(() => {
    if (isFoilSuccess && !isFoilFetching) {
      let getAllFoil = foilResult.data?.data?.foilColor?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setFoil(getAllFoil ? getAllFoil : []);
      let foils: any = {};
      getAllFoil?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        foils[id] = label;
      });
      setFoilState(foils);
    }
  }, [isFoilSuccess, isFoilFetching]);

  useEffect(() => {
    if (isItemSuccess && !isItemFetching) {
      let getAllItem = itemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setItem(getAllItem ? getAllItem : []);
      let items: any = {};
      getAllItem?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        items[id] = label;
      });
      setItemState(items);
    }
  }, [isItemSuccess, isItemFetching]);

  useEffect(() => {
    if (isgetClothSuccess && !isgetClothFetching) {
      let cloth = getClothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (cloth) {
        setCloth(cloth);
      }
    }
  }, [isgetClothSuccess, isgetClothFetching]);

  const getData = (data: any) => {
    setSelectedParty(data);
    setParty([...Party, data]);
    setShowAddParty(false);
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const getClothPartyData = (data: any) => {
    setSelectedClothParty(data);
    setClothParty([...clothParty, data]);
    setShowAddClothParty(false);
  };

  const [itemType, setItemType] = useState("");
  const getItemData = (data: any, type: any) => {
    if (type === "") {
      setSelectedItem(data);
    } else if (type === "foil") {
      setSelectedFoilItem(data);
    } else if (type === "stitch") {
      setSelectedStitchItem(data);
    } else if (type === "hand") {
      setSelectedHandItem(data);
    } else if (type === "fabric") {
      setSelectedFabricItem(data);
    } else if (type === "dye") {
      setSelectedDyeItem(data);
    }
    setItem([...Item, data]);
    setShowAddItem(false);
  };

  const getMaterialData = (data: any) => {
    setSelectedMaterial(data);
    setMaterial([...Material, data]);
    setShowAddMaterial(false);
  };

  const getRemarksData = (data: any, type: any) => {
    if (type === "") {
      setSelectedRemarks(data);
    } else if (type === "hand") {
      setSelectedHandRemarks(data);
    } else if (type === "dye") {
      setSelectedDyeRemarks(data);
    }
    setRemarks([...Remarks, data]);
    setShowAddRemarks(false);
  };

  const getDiamondData = (data: any) => {
    setSelectedDiamond(data);
    setDiamond([...Diamond, data]);
    setShowAddDiamond(false);
  };

  const getFoilData = (data: any) => {
    setSelectedFoil(data);
    setFoil([...Foil, data]);
    setShowAddFoil(false);
  };

  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "party":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "clothParty":
        let selectedClothParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedClothParty(selectedClothParty);
        break;
      case "item":
        let selectedItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedItem(selectedItem);
        break;
      case "foilitem":
        let selectedFoilItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedFoilItem(selectedFoilItem);
        break;
      case "dyeitem":
        let selectedDyeItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedDyeItem(selectedDyeItem);
        break;
      case "stitchitem":
        let selectedStitchItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedStitchItem(selectedStitchItem);
        break;
      case "handitem":
        let handitem = {
          label: e.label,
          value: e.value,
        };
        setSelectedHandItem(handitem);
        break;
      case "fabricitem":
        let fabricitem = {
          label: e.label,
          value: e.value,
        };
        setSelectedFabricItem(fabricitem);
        break;
      case "material":
        let selecetdMaterial = {
          label: e.label,
          value: e.value,
        };
        setSelectedMaterial(selecetdMaterial);
        break;
      case "handremarks":
        let handremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedHandRemarks(handremarks);
        break;
      case "dyeremarks":
        let dyeremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedDyeRemarks(dyeremarks);
        break;
      case "remarks":
        let remarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedRemarks(remarks);
        break;
      case "diamond":
        let additional = {
          label: e[e.length - 1].label,
          value: e[e.length - 1].value,
          qty: "",
        };
        setIsOldDiamond((prevValue: any) => [...prevValue, additional]);
        break;
      case "foilColor":
        let foilColor = {
          label: e.label,
          value: e.value,
        };
        setSelectedFoil(foilColor);
        break;
      case "dyeColor":
        let dyeColor = {
          label: e.label,
          value: e.value,
        };
        setSelectedDye(dyeColor);
        break;
      default:
    }
  };
  useEffect(() => {
    var set: any = [];
    const allDiamonds: any = [];
    handTableData &&
      handTableData?.map((item: any) => {
        return item?.diamond?.map((textbox: any, index: any) => {
          if (!allDiamonds.includes(textbox?.title?._id)) {
            allDiamonds.push(textbox?.title?._id);
            set.push({
              value: textbox?.title?._id,
              label: textbox?.title?.name,
              qty: "",
            });
          }
        });
      });
    setIsOldDiamond(set);
    if (handTableData?.length <= 0) {
      setIsValue([]);
    }
  }, [handTableData]);
  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      var invoices: any = [];
      invoiceResult &&
        invoiceResult.data?.data?.map((invoice: any) => {
          if (invoices.indexOf(invoice?.invoiceNo) === -1) {
            invoices.push(invoice?.invoiceNo);
            if (id) {
              // To select the invoice number when editing
              if (id === invoice?._id) {
                setSelectedInvoice(invoice?.invoiceNo);
                allInvoicetrigger({ invoiceNo: Number(invoice?.invoiceNo) });
              }
            } else {
              setSelectedInvoice(invoices[0]);
              allInvoicetrigger({ invoiceNo: Number(invoices[0]) });
            }
          }
        });
      setInvoice(invoices);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);

  useEffect(() => {
    if (isAllInvoiceSuccess && !isAllInvoiceFetching) {
      var data: any = [];
      setNewRateCard(allInvoiceResult.data.data);
      let status = false;
      // allInvoiceResult && allInvoiceResult.data?.data?.map((invoice: any) => {
      //     if (id) {
      //         if (id == invoice?._id) {
      //             data.push(invoice?.invoiceNo + "-" + invoice?.cardNo)
      //             setSelectedInvoiceCard(invoice?.invoiceNo + "-" + invoice?.cardNo)
      //             setSelectedInvoice(invoice?.invoiceNo)
      //             console.log("all")
      //             status = true;
      //         }
      //     }

      // })
      allInvoiceResult &&
        allInvoiceResult.data?.data?.map((invoice: any) => {
          data.push(invoice?.cardNo);
          if (id && isCardUpdate) {
            // If Updating SampleCard
            if (id === invoice?._id) {
              // To select the correct invoice-card number when editing
              setSelectedInvoiceCard(invoice?.cardNo);
              setSelectedInvoice(invoice?.invoiceNo);
              status = true;
            }
          } else {
            // If Adding SampleCard
            setSelectedInvoiceCard(data[0]);
            // setSelectedInvoice(data[0])
            status = true;
          }
        });
      if (!status) {
        navigate(`/ratecard/saree`);
      }
      setInvoiceNo(data);
    }
  }, [isAllInvoiceSuccess, isAllInvoiceFetching]);

  const handleInvoices = (e: any) => {
    setSelectedInvoice(e.target.value);
    // setIsSaved(false)
    setIsCardUpdate(false);
    allInvoicetrigger({ invoiceNo: Number(e.target.value) });
  };

  const handleInvoiceChange = (e: any) => {
    // setIsSaved(false)
    newRateCard?.map((item: any) => {
      if (
        item.invoiceNo + "-" + item.cardNo ===
        selectedInvoice + "-" + e.target.value
      ) {
        setSelectedInvoiceCard(e.target.value);
        if (item._id) {
          navigate(`/ratecard/saree/${item?._id}`);
          sareeTrigger(item._id);
        } else {
          navigate(`/ratecard/saree/add`);
        }
      }
    });
  };

  const handleJobWorkChanges = (e: any) => {
    setJobWorkData({
      ...jobWorkData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleFoilChanges = (e: any) => {
    setFoilData({
      ...foilData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDyeChanges = (e: any) => {
    setDyeData({
      ...dyeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleStitchChanges = (e: any) => {
    setStitchData({
      ...stitchData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleHandChanges = (e: any) => {
    setHandData({
      ...handData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleFabricChanges = (e: any) => {
    setFabricData({
      ...fabricData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleChanges = (e: any) => {
    if (e.target.name == "totalDays") {
      setTdays(e.target.value);
      setCardData({
        ...cardData,
        toalDays: tdays,
      });
    } else {
      setCardData({
        ...cardData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    let total = 0;
    jobWorkTableData?.forEach((item: any) => {
      total = total + Number(item?.rate);
    });

    setJobTotal(Math.floor(total));
  }, [jobWorkTableData]);

  useEffect(() => {
    let total = 0;
    foilTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setFoilTotal(Math.floor(total));
  }, [foilTableData]);

  useEffect(() => {
    setDyeData({
      ...dyeData,
      amount: Math.floor(Number(dyeData?.qty) * Number(dyeData?.rate)),
    });
  }, [dyeData?.qty, dyeData?.rate]);

  useEffect(() => {
    let total = 0;
    dyeTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setDyeTotal(Math.floor(total));
  }, [dyeTableData]);

  useEffect(() => {
    let total = 0;
    stitchTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setStitchTotal(Math.floor(total));
  }, [stitchTableData]);

  useEffect(() => {
    let total = 0;
    fabricTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setFabricTotal(Math.floor(total));
  }, [fabricTableData]);

  useEffect(() => {
    if (isCardUpdate || isCardCopy) {
      invoicetrigger("");
      setImages(cardData?.image);
      setSelectedParty({
        label: cardData?.party?.name,
        value: cardData?.party?._id,
      });
      setJobNotes(cardData?.jobNotes);
      setFoilNotes(cardData?.foilNotes);
      setDyeNotes(cardData?.dayingNotes);
      setHandNotes(cardData?.handNotes);
      setStitchNotes(cardData?.stitchingNotes);
      setFabricNotes(cardData?.fabricsNotes);
      setFinalNotes(cardData?.finalNotes);
      setIsFabricSelect(cardData?.finalCosting?.fabrics?.isSelected);
      setIsFoilSelect(cardData?.finalCosting?.foil?.isSelected);
      setIsHandSelect(cardData?.finalCosting?.hand?.isSelected);
      setIsStitchSelect(cardData?.finalCosting?.stitching?.isSelected);
      setIsDyeSelect(cardData?.finalCosting?.daying?.isSelected);
      setIsJobSelect(cardData?.finalCosting?.work?.isSelected);
      cardData?.finalCosting?.work?.ParPct
        ? setJobPar(cardData?.finalCosting?.work?.ParPct)
        : setJobPar();
      cardData?.finalCosting?.stitching?.ParPct
        ? setStitchPar(cardData?.finalCosting?.stitching?.ParPct)
        : setStitchPar();
      cardData?.finalCosting?.foil?.ParPct
        ? setFoilPar(cardData?.finalCosting?.foil?.ParPct)
        : setFoilPar();
      cardData?.finalCosting?.fabrics?.ParPct
        ? setFabricPar(cardData?.finalCosting?.fabrics?.ParPct)
        : setFabricPar();
      cardData?.finalCosting?.hand?.ParPct
        ? setHandPar(cardData?.finalCosting?.hand?.ParPct)
        : setHandPar();
      cardData?.finalCosting?.daying?.ParPct
        ? setDyePar(cardData?.finalCosting?.daying?.ParPct)
        : setDyePar();
    } else {
      // To set Today's Date as Default when adding Rate Card
      let date = document?.getElementById("date") as HTMLInputElement | any;
      let today = new Date() as any;
      let month = today?.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day =
        today?.getDate() < 10 ? "0" + today?.getDate() : today?.getDate();

      date.value = today?.getFullYear() + "-" + month + "-" + day;
    }
  }, [cardData]);

  useEffect(() => {
    setJobWorkData({
      ...jobWorkData,
      rate: Math.floor(
        (Number(jobWorkData?.stitch) / Number(jobWorkData?.area)) *
          Number(jobWorkData?.meter) *
          Number(jobWorkData?.code)
      ),
    });
    setErrorMessage({
      ...errorMessage,
      rate: "",
    });
  }, [
    jobWorkData?.stitch,
    jobWorkData?.area,
    jobWorkData?.meter,
    jobWorkData?.code,
  ]);

  useEffect(() => {
    setFoilData({
      ...foilData,
      amount: Math.floor(Number(foilData?.qty) * Number(foilData?.rate)),
    });
    setErrorMessage({
      ...errorMessage,
      amount: "",
    });
  }, [foilData?.qty, foilData?.rate]);

  useEffect(() => {
    let amount: any;
    if (stitchData?.meter) {
      amount = Math.floor(
        (Number(stitchData?.qty) / Number(stitchData?.meter)) *
          Number(stitchData?.rate)
      );
    } else {
      amount = Math.floor(Number(stitchData?.qty) * Number(stitchData?.rate));
    }
    setStitchData({
      ...stitchData,
      amount: amount,
    });
    setErrorMessage({
      ...errorMessage,
      amount1: "",
    });
  }, [stitchData?.qty, stitchData?.rate, stitchData?.meter]);

  useEffect(() => {
    setHandData({
      ...handData,
      amount: Math.floor(
        (Number(handData?.qty) * Number(handData?.rate)) / 100
      ),
    });
    setErrorMessage({
      ...errorMessage,
      amount2: "",
    });
  }, [handData?.qty, handData?.rate]);

  useEffect(() => {
    setFabricData({
      ...fabricData,
      amount: Math.floor(Number(fabricData?.meter) * Number(fabricData?.rate)),
    });
    setErrorMessage({
      ...errorMessage,
      amount3: "",
    });
  }, [fabricData?.meter, fabricData?.rate]);

  const handlepostImage = (image: any) => {
    imageUpload(image[0], (url: any) => {
      setImages(url);
    });
  };

  useEffect(() => {
    if (isAllSuccess && !isAllFetching) {
      if (!isCardUpdate) {
        navigate(`/ratecard/saree/${savedId}`);
      }
    }
  }, [isAllSuccess, isAllFetching]);

  const [imageCopy, setImageCopy]: any = useState([]);
  const imageDelete = (imageFile: any) => {
    setImageCopy([...imageCopy, imageFile]);
    setImages("");
    // s3.deleteObject({
    //     Bucket: bucketName,
    //     Key: imageFile.replace("https://cnt-satv.s3.ap-south-1.amazonaws.com/", "")
    // }, function (err: any, data: any) {
    //     if (data)
    //         setImages("")
    //     return data
    // })
  };

  const deleteImageOnSaveUpdate = () => {
    if (imageCopy?.length > 0) {
      imageCopy?.map((image: any) => {
        s3.deleteObject({
          Bucket: bucketName,
          Key: image?.replace(
            "https://cnt-satv.s3.ap-south-1.amazonaws.com/",
            ""
          ),
        });
      });
      setImageCopy([]);
    }
  };

  const [singleCard, setSingleCard] = useState();

  const openInvoicePreview = () => {
    if (id || savedId) {
      pdfTrigger(isCardUpdate ? id : savedId);
    }
  };

  useEffect(() => {
    if (isPdfSuccess && !isPdfFetching) {
      setSingleCard(pdfResult?.data?.data?.sareeCard?.[0]);
      setShowSareeRatePreview(true);
    }
  }, [isPdfSuccess, isPdfFetching]);

  const imageUpload = async (imageFile: any, callback: any) => {
    const rawBytes = uuid();
    const imageName =
      rawBytes.toString() + "." + imageFile?.file?.name?.split(".")[1];

    const uploadURL = await s3.getSignedUrlPromise("putObject", {
      Bucket: bucketName,
      Key: "Rate-cards/" + imageName,
      Expires: 60,
    });

    await fetch(uploadURL, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: imageFile.file,
    });

    const imageUrl = uploadURL.split("?")[0];
    callback(imageUrl);
  };

  const [isUpdating, setIsUpdating]: any = useState(false);

  const updateData = () => {
    let status = false;
    if (Object.keys(selectedParty)?.length === 0) {
      errorData["party"] = "Party is Required";
      status = true;
    } else errorData["party"] = "";
    if (jobWorkTableData?.length === 0) {
      errorData["jobwork"] = "Job Work is Required";
      status = true;
    } else {
      errorData["jobwork"] = "";
    }
    if (!images) {
      errorData["image"] = "Image is Required";
      status = true;
    } else {
      errorData["image"] = "";
    }
    setErrorMessage(errorData);
    if (!status) {
      const transformedData = handTableData?.map((item: any) => {
        if (item?.amount) {
          const diamondData = item?.diamond?.map((i: any) => i?._id).join(", ");
          return {
            ...item,
            diamondId: diamondData,
            diamond: undefined,
          };
        } else {
          if (item && item?.diamond) {
            const diamondData = item?.diamond?.map((i: any) => ({
              titleId: i?.titleId,
              qty: i?.qty,
              title: undefined,
            }));
            return {
              ...item,
              diamondId: undefined,
              diamond: diamondData,
            };
          }
          return item;
        }
      });
      setIsUpdating(true);
      let finalData = {
        partyId: selectedParty.value,
        designNo: "S-" + cardData?.designNo?.replace("S-", ""),
        date: cardData?.date,
        image: images,
        job: jobWorkTableData,
        foil: foilTableData,
        fabrics: fabricTableData,
        stitching: stitchTableData,
        hand: transformedData,
        daying: dyeTableData,
        jobNotes: jobNotes,
        foilNotes: foilNotes,
        dayingNotes: dyeNotes,
        handNotes: handNotes,
        stitchingNotes: stitchNotes,
        fabricsNotes: fabricNotes,
        finalNotes: finalNotes,
        value: isValue,
        finalCosting: {
          work: {
            rate: 0,
            ParPct: jobPar,
            valueAdd: 0,
            amount:
              jobTotal + Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100)),
            isSelected: isJobSelect ? isJobSelect : false,
            itemName: "Job Works",
          },
          foil: {
            rate: 0,
            ParPct: foilPar,
            valueAdd: 0,
            amount:
              foilTotal +
              Math.ceil(foilTotal * ((foilPar ? foilPar : 0) / 100)),
            isSelected: isFoilSelect ? isFoilSelect : false,
            itemName: "Foil",
          },
          daying: {
            rate: 0,
            valueAdd: 0,
            ParPct: dyePar,
            amount:
              dyeTotal + Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100)),
            isSelected: isDyeSelect ? isDyeSelect : false,
            itemName: "Dye Costing",
          },
          stitching: {
            rate: 0,
            ParPct: stitchPar,
            valueAdd: 0,
            amount:
              stitchTotal +
              Math.ceil(stitchTotal * ((stitchPar ? stitchPar : 0) / 100)),
            isSelected: isStitchSelect ? isStitchSelect : false,
            itemName: "Stitching",
          },
          fabrics: {
            rate: 0,
            ParPct: fabricPar,
            valueAdd: 0,
            amount:
              fabricTotal +
              Math.ceil(fabricTotal * ((fabricPar ? fabricPar : 0) / 100)),
            isSelected: isFabricSelect ? isFabricSelect : false,
            itemName: "Fabrics",
          },
          hand: {
            rate: 0,
            valueAdd: 0,
            ParPct: handPar,
            amount: handTableData[0]?.amount
              ? Math.ceil(
                  amountTotal + amountTotal * ((handPar ? handPar : 0) / 100)
                )
              : Math.ceil(
                  pointTotal + pointTotal * ((handPar ? handPar : 0) / 100)
                ),
            isSelected: isHandSelect ? isHandSelect : false,
            itemName: "HandWork Costing",
          },
        },
      };
      updateCard({
        payload: finalData,
        query: {
          srId: id,
        },
      }).then((data: any) => {
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Saree Rate Card Successfully Updated !!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger("");
          deleteImageOnSaveUpdate();
        }
      });
    }
  };
  let errorData: any = {
    party: "",
    jobwork: "",
    image: "",
    item: "",
    item1: "",
    item2: "",
    item3: "",
    item4: "",
    stitch: "",
    area: "",
    meter: "",
    meter1: "",
    code: "",
    rate: "",
    rate1: "",
    rate3: "",
    foilColor: "",
    fabricParty: "",
    qty: "",
    qty1: "",
    qty2: "",
    rate2: "",
    cloth: "",
    rate4: "",
    amount1: "",
    amount: "",
    amount2: "",
    amount3: "",
    diamond: "",
  };
  const addData = () => {
    let status = false;
    if (Object.keys(selectedParty)?.length === 0) {
      errorData["party"] = "Party is Required";
      status = true;
    } else errorData["party"] = "";
    if (jobWorkTableData?.length === 0) {
      errorData["jobwork"] = "Job Work is Required";
      status = true;
    } else {
      errorData["jobwork"] = "";
    }
    if (!images) {
      errorData["image"] = "Image is Required";
      status = true;
    } else {
      errorData["image"] = "";
    }
    setErrorMessage(errorData);
    if (!status) {
      const transformedData = handTableData?.map((item: any) => {
        if (item?.amount) {
          const diamondData = item?.diamond?.map((i: any) => i?._id).join(", ");
          return {
            ...item,
            diamondId: diamondData,
            diamond: undefined,
          };
        } else {
          if (item && item?.diamond) {
            const diamondData = item?.diamond?.map((i: any) => ({
              titleId: i?.titleId,
              qty: i?.qty,
              title: undefined,
            }));
            return {
              ...item,
              diamondId: undefined,
              diamond: diamondData,
            };
          }
          return item;
        }
      });
      setIsUpdating(true);
      let finalData = {
        partyId: selectedParty.value,
        invoiceNo: selectedInvoice,
        cardNo: selectedInvoiceCard,
        designNo: "S-" + cardData?.designNo?.replace("S-", ""),
        date: cardData?.date,
        image: images,
        job: jobWorkTableData,
        foil: foilTableData,
        fabrics: fabricTableData,
        stitching: stitchTableData,
        hand: transformedData,
        daying: dyeTableData,
        jobNotes: jobNotes,
        foilNotes: foilNotes,
        dayingNotes: dyeNotes,
        handNotes: handNotes,
        stitchingNotes: stitchNotes,
        fabricsNotes: fabricNotes,
        finalNotes: finalNotes,
        value: isValue,
        finalCosting: {
          work: {
            rate: 0,
            ParPct: jobPar,
            valueAdd: 0,
            amount:
              jobTotal + Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100)),
            isSelected: isJobSelect ? isJobSelect : false,
            itemName: "Job Works",
          },
          foil: {
            rate: 0,
            ParPct: foilPar,
            valueAdd: 0,
            amount:
              foilTotal +
              Math.ceil(foilTotal * ((foilPar ? foilPar : 0) / 100)),
            isSelected: isFoilSelect ? isFoilSelect : false,
            itemName: "Foil",
          },
          daying: {
            rate: 0,
            valueAdd: 0,
            ParPct: dyePar,
            amount:
              dyeTotal + Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100)),
            isSelected: isDyeSelect ? isDyeSelect : false,
            itemName: "Dye Costing",
          },
          stitching: {
            rate: 0,
            ParPct: stitchPar,
            valueAdd: 0,
            amount:
              stitchTotal +
              Math.ceil(stitchTotal * ((stitchPar ? stitchPar : 0) / 100)),
            isSelected: isStitchSelect ? isStitchSelect : false,
            itemName: "Stitching",
          },
          fabrics: {
            rate: 0,
            ParPct: fabricPar,
            valueAdd: 0,
            amount:
              fabricTotal +
              Math.ceil(fabricTotal * ((fabricPar ? fabricPar : 0) / 100)),
            isSelected: isFabricSelect ? isFabricSelect : false,
            itemName: "Fabrics",
          },
          hand: {
            rate: 0,
            valueAdd: 0,
            ParPct: handPar,
            amount: handTableData[0]?.amount
              ? Math.ceil(
                  amountTotal + amountTotal * ((handPar ? handPar : 0) / 100)
                )
              : Math.ceil(
                  pointTotal + pointTotal * ((handPar ? handPar : 0) / 100)
                ),
            isSelected: isHandSelect ? isHandSelect : false,
            itemName: "HandWork Costing",
          },
        },
      };
      addCard(finalData).then((data: any) => {
        setSavedId(data?.data?.data?.insertedIds[0]);
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Saree Rate Card Successfully Added!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger("");
          deleteImageOnSaveUpdate();
        }
      });
    }
  };
  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
      <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
        <div className="invisible lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-4 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-lg">Saree Rate Card</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 w-2/5">
                <div>
                  <Select
                    id="invoice"
                    name="invoice"
                    htmlFor="invoice"
                    label="Card No."
                    options={invoice}
                    onChange={(e: any) => handleInvoices(e)}
                    value={selectedInvoice}
                  />
                </div>
                <div className="ml-2">
                  <Select
                    id="invoiceno"
                    name="invoiceno"
                    htmlFor="invoiceno"
                    label="Card No. B"
                    options={invoiceNo}
                    onChange={(e: any) => handleInvoiceChange(e)}
                    value={selectedInvoiceCard}
                  />
                </div>
              </div>
              <div className="px-3 w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={showSareeRatePreview}
                  onClick={openInvoicePreview}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button>
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={isCardUpdate ? updateData : addData}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">
                    {isCardUpdate ? "Update" : "Save"}
                  </span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/sareeratedashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
          <div className="w-full overflow-auto">
            <div className="w-full h-full rounded-xl bg-white">
              <div className="grid grid-cols-1 md:grid-cols-2 p-4 lg:p-0 xl:p-0 gap-2 lg:hidden visible xl:hidden lg:invisible xl:invisible">
                <div className="mt-2 md:mt-0">
                  <Select
                    id="invoice"
                    name="invoice"
                    htmlFor="invoice"
                    label="Card No."
                    options={invoice}
                    onChange={(e: any) => handleInvoices(e)}
                    value={selectedInvoice}
                  />
                </div>
                <div className="ml-2">
                  <Select
                    id="invoiceno"
                    name="invoiceno"
                    htmlFor="invoiceno"
                    label="Card No. B"
                    options={invoiceNo}
                    onChange={(e: any) => handleInvoiceChange(e)}
                    value={selectedInvoiceCard}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-3 p-2 pt-3 pb-0">
                <div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="partyName"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "party");
                        setErrorMessage({
                          ...errorMessage,
                          party: "",
                        });
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.party
                            ? "border-red-600"
                            : "border-gray-500",
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getPartyNameTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllPartyName = result.data?.data?.party?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllPartyName) {
                      //             setParty(getAllPartyName)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setPartyNameAdd(data);
                        setShowAddParty(true);
                      }}
                      options={Party && Party}
                      value={selectedParty}
                    />
                    <label
                      htmlFor="partyName"
                      className={`absolute z-20 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.party ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Party Name
                    </label>
                  </div>
                  <span className="text-sm text-red-500 text-left">
                    {errorMessage?.party}
                  </span>
                </div>
                <div className="relative">
                  <div className="rounded-md">
                    <div className="pointer-events-none absolute inset-y-0 h-10 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">S -</span>
                    </div>
                    <input
                      type="number"
                      name="designNo"
                      id="designNo"
                      className="block w-full py-1.5 pl-8 sm:text-sm sm:leading-6 first-line:block px-2.5 h-10 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                      placeholder=""
                      autoComplete="off"
                      value={cardData?.designNo?.split?.("-")?.[1]}
                      onChange={(e: any) => handleChanges(e)}
                    />
                  </div>
                  <label
                    className={`absolute capitalize text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-1`}
                  >
                    Design No
                  </label>
                </div>
                <TextBox
                  id="date"
                  type="date"
                  name="date"
                  tabIndex={-1}
                  htmlFor="date"
                  label="Date"
                  placeholder=" "
                  value={cardData?.date}
                  onChange={(e: any) => handleChanges(e)}
                />
              </div>
              <div className="p-2 pb-0">
                <fieldset
                  className={`border-2 mt-2 rounded-2 border-gray-400 p-2 ${
                    errorMessage?.jobwork ? "border-red-500" : "border-gray-400"
                  }`}
                >
                  <legend
                    className={`capitalize px-2 ${
                      errorMessage?.jobwork ? "text-red-500" : ""
                    }`}
                  >
                    Job Work
                  </legend>
                  <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                    <div className="relative rounded-md">
                      <CreatableSelect
                        ref={jobWorkRef}
                        name="item"
                        classNamePrefix="customSelect"
                        filterOption={createFilter(selectFilterConfig)}
                        className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                        onChange={(e: any) => {
                          handleChange(e, "item");
                          setErrorMessage({
                            ...errorMessage,
                            item: "",
                          });
                        }}
                        classNames={{
                          control: () =>
                            errorMessage?.item
                              ? "border-red-500 focus:border-red-500"
                              : "border-gray-500 focus:border-themeColor",
                        }}
                        // onInputChange={(e: any) => {
                        //     if (e.length >= 1) {
                        //         getItemTrigger(e)
                        //     }
                        //     if (e.length == 0) {
                        //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                        //             return {
                        //                 label: name,
                        //                 value: _id,
                        //             };
                        //         });
                        //         if (getAllItem) {
                        //             setItem(getAllItem)
                        //         }
                        //     }
                        // }}
                        onCreateOption={(data: any) => {
                          setItemAdd(data);
                          setShowAddItem(true);
                          setItemType("");
                        }}
                        options={Item}
                        value={selectedItem}
                      />
                      <label
                        htmlFor="item"
                        className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                          errorMessage?.item ? "text-red-500" : "text-gray-500"
                        }`}
                      >
                        Item
                      </label>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.item}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="stitch"
                        type="number"
                        name="stitch"
                        htmlFor="stitch"
                        label="stitch"
                        hasError={errorMessage?.stitch}
                        placeholder=" "
                        value={jobWorkData?.stitch}
                        onChange={(e: any) => {
                          handleJobWorkChanges(e);
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.stitch}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="area"
                        type="text"
                        name="area"
                        hasError={errorMessage?.area}
                        htmlFor="area"
                        label="area"
                        placeholder=" "
                        value={jobWorkData?.area}
                        onChange={(e: any) => handleJobWorkChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.area}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="meter"
                        type="number"
                        name="meter"
                        htmlFor="meter1"
                        hasError={errorMessage?.meter}
                        label="meter"
                        placeholder=" "
                        value={jobWorkData?.meter}
                        onChange={(e: any) => handleJobWorkChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.meter}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="code"
                        type="number"
                        name="code"
                        htmlFor="code"
                        hasError={errorMessage?.code}
                        label="code"
                        placeholder=" "
                        value={jobWorkData?.code}
                        onChange={(e: any) => handleJobWorkChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.code}
                      </span>
                    </div>

                    <div>
                      <TextBox
                        id="rate"
                        type="number"
                        name="rate"
                        disabled={true}
                        hasError={errorMessage?.rate}
                        htmlFor="rate1"
                        label="rate"
                        placeholder=" "
                        value={jobWorkData?.rate}
                        onChange={(e: any) => {
                          handleJobWorkChanges(e);
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.rate}
                      </span>
                    </div>
                    <div className="w-full">
                      <button
                        className="w-full px-2 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        onClick={() => {
                          if (isJobUpdate) {
                            let rate = Math.floor(
                              (Number(jobWorkData?.stitch) /
                                Number(jobWorkData?.area)) *
                                Number(jobWorkData?.meter) *
                                Number(jobWorkData?.code)
                            );

                            let record = {
                              _id: selectedJobRow?._id,
                              area: jobWorkData?.area,
                              itemId: selectedItem?.value,
                              stitch: jobWorkData?.stitch,
                              code: jobWorkData?.code,
                              rate: rate,
                              meter: jobWorkData?.meter,
                            };
                            if (Object?.keys(selectedItem)?.length === 0) {
                              errorData["item"] = "Item is Required";
                            } else {
                              errorData["item"] = "";
                            }
                            if (
                              !jobWorkData?.stitch ||
                              jobWorkData?.stitch == ""
                            ) {
                              errorData["stitch"] = "Stitch is Required";
                            } else errorData["stitch"] = "";
                            if (!jobWorkData?.area || jobWorkData?.area == "") {
                              errorData["area"] = "Area is Required";
                            } else errorData["area"] = "";
                            if (
                              !jobWorkData?.meter ||
                              jobWorkData?.meter == ""
                            ) {
                              errorData["meter"] = "Meter is Required";
                            } else errorData["meter"] = "";
                            if (!jobWorkData?.code || jobWorkData?.code == "") {
                              errorData["code"] = "Code is Required";
                            } else errorData["code"] = "";
                            if (!rate) {
                              errorData["rate"] = "Rate is Required";
                            } else errorData["rate"] = "";
                            setErrorMessage(errorData);

                            const result: any = sareeJobWorkValidation(record);
                            if (result === true) {
                              const elementsIndex = jobWorkTableData.findIndex(
                                (element: any) =>
                                  element?._id == selectedJobRow?._id
                              );

                              let newArray = [...jobWorkTableData];

                              newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                ...record,
                              };

                              setJobWorkTableData(newArray);

                              setJobWorkData({
                                area: "",
                                stitch: "",
                                code: "",
                                rate: "",
                                meter: "",
                              });
                              setSelectedItem({});
                              setIsJobUpdate(false);
                            } else {
                              Swal.fire({
                                toast: true,
                                icon: "error",
                                title: result.errorMessage,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: ERROR_MESSAGE_TIMEOUT,
                                timerProgressBar: true,
                              });
                            }
                          } else {
                            let rate = Math.floor(
                              Math.floor(
                                (Number(jobWorkData?.stitch) /
                                  Number(jobWorkData?.area)) *
                                  Number(jobWorkData?.meter) *
                                  Number(jobWorkData?.code)
                              )
                            );
                            let object = {
                              _id: ObjectID().toHexString(),
                              area: jobWorkData?.area || "",
                              itemId: selectedItem?.value || "",
                              stitch: jobWorkData?.stitch || "",
                              code: jobWorkData?.code || "",
                              rate: rate,
                              meter: jobWorkData?.meter || "",
                            };
                            if (Object?.keys(selectedItem)?.length === 0) {
                              errorData["item"] = "Item is Required";
                            } else {
                              errorData["item"] = "";
                            }
                            if (
                              !jobWorkData?.stitch ||
                              jobWorkData?.stitch == ""
                            ) {
                              errorData["stitch"] = "Stitch is Required";
                            } else errorData["stitch"] = "";
                            if (!jobWorkData?.area || jobWorkData?.area == "") {
                              errorData["area"] = "Area is Required";
                            } else errorData["area"] = "";
                            if (
                              !jobWorkData?.meter ||
                              jobWorkData?.meter == ""
                            ) {
                              errorData["meter"] = "Meter is Required";
                            } else errorData["meter"] = "";
                            if (!jobWorkData?.code || jobWorkData?.code == "") {
                              errorData["code"] = "Code is Required";
                            } else errorData["code"] = "";
                            if (!rate) {
                              errorData["rate"] = "Rate is Required";
                            } else errorData["rate"] = "";
                            setErrorMessage(errorData);
                            const result: any = sareeJobWorkValidation(object);
                            if (result === true) {
                              setJobWorkTableData([
                                ...jobWorkTableData,
                                object,
                              ]);

                              setJobWorkData({
                                area: "",
                                stitch: "",
                                code: "",
                                rate: "",
                                meter: "",
                              });
                              setSelectedItem({});
                            } else {
                              Swal.fire({
                                toast: true,
                                icon: "error",
                                title: result.errorMessage,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: ERROR_MESSAGE_TIMEOUT,
                                timerProgressBar: true,
                              });
                            }
                          }
                          jobWorkRef.current.focus();
                        }}
                      >
                        <i className="fa fa-plus"></i>
                        <span className="ml-2">
                          {isJobUpdate ? "Update" : "Add"} Job work
                        </span>
                      </button>
                    </div>
                    <div className="col-span-2 lg:col-span-7">
                      <div className="border border-gray-300 rounded-3 overflow-hidden">
                        <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="overflow-hidden">
                              <div className="p-0 overflow-hidden">
                                {jobWorkTableData &&
                                jobWorkTableData?.length > 0 ? (
                                  <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                    <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                      <thead className="bg-gray-50">
                                        <tr className="capitalize">
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Sr No
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Item
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Stitch
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Area
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Meter
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Code
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            Rate
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 font-bold text-center text-gray-900"
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                        {jobWorkTableData?.map(
                                          (item: any, index: number) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="hover:bg-gray-50"
                                              >
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {index + 1}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {itemState[item?.itemId]}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {item?.stitch}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {item?.area}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {item?.meter}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {item?.code}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {item?.rate}
                                                </td>
                                                <td>
                                                  <div>
                                                    <button
                                                      className="m-2"
                                                      type="button"
                                                      onClick={() => {
                                                        setSelectedJobRow(item);
                                                        setSelectedItem({
                                                          value: item?.itemId,
                                                          label:
                                                            itemState[
                                                              item?.itemId
                                                            ],
                                                        });

                                                        setJobWorkData({
                                                          area: item?.area,
                                                          stitch: item?.stitch,
                                                          code: item?.code,
                                                          rate: item?.rate,
                                                          meter: item?.meter,
                                                        });

                                                        setIsJobUpdate(true);
                                                      }}
                                                    >
                                                      <i className="fas fa-pen hover:text-green-600"></i>
                                                    </button>
                                                    <button
                                                      className="m-2"
                                                      onClick={() => {
                                                        Swal.fire({
                                                          title:
                                                            "Are you sure?",
                                                          text: "Once deleted, you will not be able to recover this record!",
                                                          icon: "warning",
                                                          showCancelButton:
                                                            true,
                                                          confirmButtonColor:
                                                            "#3085d6",
                                                          cancelButtonColor:
                                                            "#d33",
                                                          confirmButtonText:
                                                            "Delete",
                                                        }).then(
                                                          (willDelete) => {
                                                            if (
                                                              willDelete.isConfirmed
                                                            ) {
                                                              let newData =
                                                                jobWorkTableData.filter(
                                                                  (
                                                                    record: any
                                                                  ) => {
                                                                    return (
                                                                      record?._id !==
                                                                      item?._id
                                                                    );
                                                                  }
                                                                );
                                                              setJobWorkTableData(
                                                                newData
                                                              );
                                                              setIsJobUpdate(
                                                                false
                                                              );
                                                              setJobWorkData({
                                                                area: "",
                                                                stitch: "",
                                                                code: "",
                                                                rate: "",
                                                                meter: "",
                                                              });
                                                              setSelectedItem(
                                                                {}
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <i className="fas fa-trash hover:text-red-600"></i>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr className="border-t border-gray-400">
                                          <td
                                            colSpan={6}
                                            className=" text-gray-900 px-5"
                                          >
                                            <input
                                              id="notes"
                                              name="notes"
                                              value={jobNotes}
                                              placeholder="Notes"
                                              onChange={(e) => {
                                                setJobNotes(e.target.value);
                                              }}
                                              className={`first-line:block px-2.5 h-7 w-full my-2 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                                            />
                                          </td>
                                          <td className="px-6 font-bold text-gray-900">
                                            {jobTotal}
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="text-center py-3 text-gray-600 font-bold">
                                    No Job Work Data Found
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="text-sm text-red-500 text-left">
                    {errorMessage?.jobwork}
                  </span>
                </fieldset>
              </div>

              {/* Foil Configaration */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Foil</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      ref={foilRef}
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      classNames={{
                        control: () =>
                          errorMessage?.item1
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "foilitem");
                        setErrorMessage({
                          ...errorMessage,
                          item1: "",
                        });
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("foil");
                      }}
                      options={Item}
                      value={selectedFoilItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.item1 ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Item
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.item1}
                    </span>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="foilColor"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      classNames={{
                        control: () =>
                          errorMessage?.foilColor
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "foilColor");
                        setErrorMessage({
                          ...errorMessage,
                          foilColor: "",
                        });
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getFoilColorTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllFoil = foilResult?.data?.data?.foilColor?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllFoil) {
                      //             setFoil(getAllFoil)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setFoilAdd(data);
                        setShowAddFoil(true);
                      }}
                      options={Foil}
                      value={selectedFoil}
                    />
                    <label
                      htmlFor="foilColor"
                      className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.foilColor
                          ? "text-red-500"
                          : "text-gray-500"
                      }`}
                    >
                      Foil Color
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.foilColor}
                    </span>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="remarks"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "remarks");
                        } else {
                          setSelectedRemarks({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getRemarkTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         remarksTrigger('')
                      //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllRemark) {
                      //             setRemarks(getAllRemark)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setRemarksAdd(data);
                        setShowAddRemarks(true);
                        setItemType("");
                      }}
                      options={Remarks}
                      value={selectedRemarks}
                    />
                    <label
                      htmlFor="remarks"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 `}
                    >
                      Remarks
                    </label>
                  </div>
                  <div>
                    <TextBox
                      id="qty"
                      type="number"
                      name="qty"
                      htmlFor="qty1"
                      label="qty"
                      hasError={errorMessage?.qty}
                      placeholder=" "
                      value={foilData?.qty}
                      onChange={(e: any) => {
                        handleFoilChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          qty: "",
                        });
                      }}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.qty}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="rate"
                      type="number"
                      name="rate"
                      htmlFor="rate2"
                      label="rate"
                      placeholder=" "
                      hasError={errorMessage?.rate1}
                      value={foilData?.rate}
                      onChange={(e: any) => {
                        handleFoilChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          rate1: "",
                        });
                      }}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.rate1}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="amount"
                      type="number"
                      name="amount"
                      htmlFor="amount1"
                      label="amount"
                      placeholder=" "
                      hasError={errorMessage?.amount}
                      value={foilData?.amount}
                      disabled
                      onChange={(e: any) => handleFoilChanges(e)}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.amount}
                    </span>
                  </div>
                  <div className="w-full">
                    <button
                      className="px-3 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      onClick={() => {
                        if (isFoilUpdate) {
                          let amount = Math.floor(
                            Number(foilData?.qty) * Number(foilData?.rate)
                          );
                          let record = {
                            _id: selectedFoilRow?._id || "",
                            colorId: selectedFoil?.value || "",
                            itemId: selectedFoilItem?.value || "",
                            remarkId: selectedRemarks?.value || "",
                            qty: foilData?.qty || "",
                            rate: foilData?.rate || "",
                            amount: amount || "",
                          };
                          if (Object?.keys(selectedFoilItem)?.length === 0) {
                            errorData["item"] = "Item is Required";
                          } else {
                            errorData["item"] = "";
                          }
                          if (Object?.keys(selectedFoil)?.length === 0) {
                            errorData["foilColor"] = "FoilColor is Required";
                          } else errorData["foilColor"] = "";
                          if (!foilData?.qty || foilData?.qty == "") {
                            errorData["qty"] = "Quantity  is Required";
                          } else errorData["qty"] = "";
                          if (!foilData?.rate || foilData?.rate == "") {
                            errorData["rate1"] = "Rate is Required";
                          } else errorData["rate1"] = "";
                          if (!amount) {
                            errorData["amount"] = "Amount is Required";
                          } else errorData["amount"] = "";
                          setErrorMessage(errorData);
                          const result: any = sareeFoilValidation(record);
                          if (result === true) {
                            const elementsIndex = foilTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedFoilRow?._id
                            );

                            let newArray = [...foilTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setFoilTableData(newArray);

                            setFoilData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedFoil({});
                            setSelectedFoilItem({});
                            setSelectedRemarks({});

                            setIsFoilUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount = Math.floor(
                            Number(foilData?.qty) * Number(foilData?.rate)
                          );
                          let object = {
                            _id: ObjectID().toHexString(),
                            colorId: selectedFoil?.value || "",
                            itemId: selectedFoilItem?.value || "",
                            remarkId: selectedRemarks?.value || "",
                            qty: foilData?.qty || "",
                            rate: foilData?.rate || "",
                            amount: amount || "",
                          };

                          if (Object?.keys(selectedFoilItem)?.length === 0) {
                            errorData["item1"] = "Item is Required";
                          } else {
                            errorData["item1"] = "";
                          }
                          if (Object?.keys(selectedFoil)?.length === 0) {
                            errorData["foilColor"] = "FoilColor is Required";
                          } else errorData["foilColor"] = "";
                          if (!foilData?.qty || foilData?.qty == "") {
                            errorData["qty"] = "Quantity  is Required";
                          } else errorData["qty"] = "";
                          if (!foilData?.rate || foilData?.rate == "") {
                            errorData["rate1"] = "Rate is Required";
                          } else errorData["rate1"] = "";
                          if (!amount) {
                            errorData["amount"] = "Amount is Required";
                          } else errorData["amount"] = "";
                          setErrorMessage(errorData);
                          const result: any = sareeFoilValidation(object);
                          if (result === true) {
                            setFoilTableData([...foilTableData, object]);

                            setFoilData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedFoil({});
                            setSelectedFoilItem({});
                            setSelectedRemarks({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                          foilRef.current.focus();
                        }
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isFoilUpdate ? "Update" : "Add"} foil
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {foilTableData && foilTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          foil color
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          qty
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          rate
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {foilTableData?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {foilState[item?.colorId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {remarksState[item?.remarkId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.qty}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.rate}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.amount}
                                              </td>
                                              <td>
                                                <div>
                                                  <button
                                                    className="m-2"
                                                    type="button"
                                                    onClick={() => {
                                                      setSelectedFoilRow(item);
                                                      setSelectedFoilItem({
                                                        value: item?.itemId,
                                                        label:
                                                          itemState[
                                                            item?.itemId
                                                          ],
                                                      });
                                                      setSelectedFoil({
                                                        value: item?.colorId,
                                                        label:
                                                          foilState[
                                                            item?.colorId
                                                          ],
                                                      });
                                                      setSelectedRemarks({
                                                        value: item?.remarkId,
                                                        label:
                                                          remarksState[
                                                            item?.remarkId
                                                          ],
                                                      });

                                                      setFoilData({
                                                        qty: item?.qty,
                                                        amount: item?.amount,
                                                        rate: item?.rate,
                                                      });

                                                      setIsFoilUpdate(true);
                                                    }}
                                                  >
                                                    <i className="fas fa-pen hover:text-green-600"></i>
                                                  </button>
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            foilTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setFoilTableData(
                                                            newData
                                                          );
                                                          setIsFoilUpdate(
                                                            false
                                                          );
                                                          setFoilData({
                                                            qty: "",
                                                            rate: "",
                                                            amount: "",
                                                          });
                                                          setSelectedFoil({});
                                                          setSelectedFoilItem(
                                                            {}
                                                          );
                                                          setSelectedRemarks(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr className="border-t border-gray-400">
                                        <td
                                          colSpan={6}
                                          className="px-6 text-right text-gray-900"
                                        >
                                          <input
                                            id="foilNotes"
                                            name="foilNotes"
                                            value={foilNotes}
                                            onChange={(e) => {
                                              setFoilNotes(e.target.value);
                                            }}
                                            placeholder="Notes"
                                            className={`first-line:block px-2.5 h-7 w-full my-2 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                                          />
                                        </td>
                                        <td className="px-6 font-bold text-gray-900">
                                          {foilTotal}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Foil Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Stitching */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Stitching</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      ref={stitchingRef}
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "stitchitem");
                        setErrorMessage({
                          ...errorMessage,
                          item2: "",
                        });
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.item2
                            ? "border-red-600 focus:border-red-600 "
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("stitch");
                      }}
                      options={Item}
                      value={selectedStitchItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.item2 ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Item
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.item2}
                    </span>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="material"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "material");
                        } else {
                          setSelectedMaterial({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getMaterialTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         materialTrigger('')
                      //         let getAllMaterial = materialResult.data?.data?.material?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllMaterial) {
                      //             setMaterial(getAllMaterial)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setMaterialAdd(data);
                        setShowAddMaterial(true);
                      }}
                      options={Material}
                      value={selectedMaterial}
                    />
                    <label
                      htmlFor="foilColor"
                      className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor text-gray-500 backdrop:peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                    >
                      {" "}
                      Material
                    </label>
                  </div>
                  <div>
                    <TextBox
                      id="meter"
                      type="number"
                      name="meter"
                      htmlFor="meter2"
                      label="patti/ mtr"
                      placeholder=" "
                      value={stitchData?.meter}
                      onChange={(e: any) => handleStitchChanges(e)}
                    />
                  </div>
                  <div>
                    <TextBox
                      id="qty"
                      type="number"
                      name="qty"
                      htmlFor="qty2"
                      label="qty"
                      placeholder=" "
                      hasError={errorMessage?.qty1}
                      value={stitchData?.qty1}
                      onChange={(e: any) => {
                        handleStitchChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          qty1: "",
                        });
                      }}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.qty1}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="rate"
                      type="number"
                      name="rate"
                      htmlFor="rate3"
                      label="rate"
                      hasError={errorMessage?.rate2}
                      placeholder=" "
                      value={stitchData?.rate}
                      onChange={(e: any) => {
                        handleStitchChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          rate2: "",
                        });
                      }}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.rate2}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="amount"
                      type="number"
                      name="amount"
                      htmlFor="amount2"
                      label="amount"
                      placeholder=" "
                      hasError={errorMessage?.amount1}
                      value={stitchData?.amount}
                      disabled
                      onChange={(e: any) => handleStitchChanges(e)}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.amount1}
                    </span>
                  </div>
                  <div className="w-full">
                    <button
                      className="px-2 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      onClick={() => {
                        if (isStitchUpdate) {
                          let amount: any;
                          if (stitchData?.meter) {
                            amount = Math.floor(
                              (Number(stitchData?.qty) /
                                Number(stitchData?.meter)) *
                                Number(stitchData?.rate)
                            );
                          } else {
                            amount = Math.floor(
                              Number(stitchData?.qty) * Number(stitchData?.rate)
                            );
                          }

                          let record = {
                            _id: selectedStitchRow?._id || "",
                            materialId: selectedMaterial?.value || "",
                            itemId: selectedStitchItem?.value || "",
                            meter: stitchData?.meter || "",
                            qty: stitchData?.qty || "",
                            rate: stitchData?.rate || "",
                            amount: amount || "",
                          };
                          if (Object?.keys(selectedStitchItem)?.length === 0) {
                            errorData["item2"] = "Item is Required";
                          } else {
                            errorData["item2"] = "";
                          }
                          if (!stitchData?.qty || stitchData?.qty == "") {
                            errorData["qty1"] = "Quantity  is Required";
                          } else errorData["qty1"] = "";
                          if (!stitchData?.rate || stitchData?.rate == "") {
                            errorData["rate2"] = "Rate is Required";
                          } else errorData["rate2"] = "";
                          if (!amount) {
                            errorData["amount1"] = "Amount is Required";
                          } else errorData["amount1"] = "";
                          setErrorMessage(errorData);
                          const result: any = sareeStitchValidation(record);
                          if (result === true) {
                            const elementsIndex = stitchTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedStitchRow?._id
                            );

                            let newArray = [...stitchTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setStitchTableData(newArray);

                            setStitchData({
                              qty: "",
                              rate: "",
                              amount: "",
                              meter: "",
                            });
                            setSelectedMaterial({});
                            setSelectedStitchItem({});

                            setIsStitchUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount: any;
                          if (stitchData?.meter) {
                            amount = Math.floor(
                              (Number(stitchData?.qty) /
                                Number(stitchData?.meter)) *
                                Number(stitchData?.rate)
                            );
                          } else {
                            amount = Math.floor(
                              Number(stitchData?.qty) * Number(stitchData?.rate)
                            );
                          }
                          let object = {
                            _id: ObjectID().toHexString(),
                            materialId: selectedMaterial?.value || "",
                            itemId: selectedStitchItem?.value || "",
                            meter: stitchData?.meter || "",
                            qty: stitchData?.qty || "",
                            rate: stitchData?.rate || "",
                            amount: amount || "",
                          };
                          if (Object?.keys(selectedStitchItem)?.length === 0) {
                            errorData["item2"] = "Item is Required";
                          } else {
                            errorData["item2"] = "";
                          }
                          if (!stitchData?.qty || stitchData?.qty == "") {
                            errorData["qty1"] = "Quantity  is Required";
                          } else errorData["qty1"] = "";
                          if (!stitchData?.rate || stitchData?.rate == "") {
                            errorData["rate2"] = "Rate is Required";
                          } else errorData["rate2"] = "";
                          if (!amount) {
                            errorData["amount1"] = "Amount is Required";
                          } else errorData["amount1"] = "";
                          setErrorMessage(errorData);
                          const result: any = sareeStitchValidation(object);
                          if (result === true) {
                            setStitchTableData([...stitchTableData, object]);

                            setStitchData({
                              qty: "",
                              rate: "",
                              amount: "",
                              meter: "",
                            });
                            setSelectedMaterial({});
                            setSelectedStitchItem({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        }
                        stitchingRef.current.focus();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isStitchUpdate ? "Update" : "Add"} Stitching
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {stitchTableData &&
                              stitchTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          material
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Patti/ meter
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          qty
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          rate
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {stitchTableData?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {
                                                  materialState[
                                                    item?.materialId
                                                  ]
                                                }
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.meter}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.qty}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.rate}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.amount}
                                              </td>
                                              <td>
                                                <div>
                                                  <button
                                                    className="m-2"
                                                    type="button"
                                                    onClick={() => {
                                                      setSelectedStitchRow(
                                                        item
                                                      );
                                                      setSelectedStitchItem({
                                                        value: item?.itemId,
                                                        label:
                                                          itemState[
                                                            item?.itemId
                                                          ],
                                                      });
                                                      setSelectedMaterial({
                                                        value: item?.materialId,
                                                        label:
                                                          materialState[
                                                            item?.materialId
                                                          ],
                                                      });
                                                      setStitchData({
                                                        qty: item?.qty,
                                                        amount: item?.amount,
                                                        rate: item?.rate,
                                                        meter: item?.meter,
                                                      });
                                                      setIsStitchUpdate(true);
                                                    }}
                                                  >
                                                    <i className="fas fa-pen hover:text-green-600"></i>
                                                  </button>
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            stitchTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setStitchTableData(
                                                            newData
                                                          );
                                                          setIsStitchUpdate(
                                                            false
                                                          );
                                                          setStitchData({
                                                            qty: "",
                                                            rate: "",
                                                            amount: "",
                                                            meter: "",
                                                          });
                                                          setSelectedMaterial(
                                                            {}
                                                          );
                                                          setSelectedStitchItem(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr className="border-t border-gray-400">
                                        <td
                                          colSpan={6}
                                          className="px-6 text-right text-gray-900"
                                        >
                                          <input
                                            id="stitchNotes"
                                            name="stitchNotes"
                                            value={stitchNotes}
                                            onChange={(e) => {
                                              setStitchNotes(e.target.value);
                                            }}
                                            placeholder="Notes"
                                            className={`first-line:block px-2.5 h-7 w-full my-2 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                                          />
                                        </td>
                                        <td className="px-6 font-bold text-gray-900">
                                          {stitchTotal}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Stitching Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Hand Work Configaration */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Hand Work</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      ref={handWorkRef}
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "handitem");
                        setErrorMessage({
                          ...errorMessage,
                          item3: "",
                        });
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.item3
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("hand");
                      }}
                      options={Item}
                      value={selectedHandItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.item3 ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Item
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.item3}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="head"
                      type="text"
                      name="head"
                      htmlFor="head"
                      label="head"
                      hasError={errorMessage?.head}
                      placeholder=" "
                      value={handData?.head}
                      onChange={(e: any) => {
                        handleHandChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          head: "",
                        });
                      }}
                    />

                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.head}
                    </span>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="remarks"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "handremarks");
                        } else {
                          setSelectedHandRemarks({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getRemarkTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         remarksTrigger('')
                      //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllRemark) {
                      //             setRemarks(getAllRemark)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setRemarksAdd(data);
                        setShowAddRemarks(true);
                        setItemType("hand");
                      }}
                      options={Remarks}
                      value={selectedHandRemarks}
                    />
                    <label
                      htmlFor="remarks"
                      className={`absolute text-md  text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 `}
                    >
                      Remarks
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      isMulti
                      name="diamond"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block mb-2 text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any, action: any) => {
                        if (action.action === "remove-value") {
                          handleRemoveOption(action.removedValue);
                        }
                        if (action.action === "select-option") {
                          handleChange(e, "diamond");
                        }
                        setErrorMessage({
                          ...errorMessage,
                          diamond: "",
                        });
                      }}
                      onCreateOption={(data: any) => {
                        setDiamondAdd(data);
                        setShowAddDiamond(true);
                      }}
                      options={creatableOptions}
                      value={handTableData[0]?.amount ? null : isOldDiamond}
                      isDisabled={
                        (handTableData.length === 1 && isHandUpdate) ||
                        handTableData.length < 1
                          ? false
                          : true
                      }
                      classNames={{
                        control: () =>
                          errorMessage?.diamond
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                    />
                    <label
                      htmlFor="diamond"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.diamond ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Diamond
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.diamond}
                    </span>
                  </div>
                  {isOldDiamond?.map((item: any, index: any) => {
                    return handTableData[0]?.amount ? null : (
                      <div className="relative rounded-md">
                        <TextBox
                          className="mb-2"
                          key={index}
                          name="qty"
                          type="number"
                          id="qty"
                          htmlFor="qty"
                          onChange={(e: any) => {
                            let diamond: any = isOldDiamond;
                            diamond[index].qty = e.target.value;
                            setIsOldDiamond([...diamond]);
                          }}
                          value={item?.qty}
                          label={item?.label}
                        />
                      </div>
                    );
                  })}

                  <div className="w-full">
                    <button
                      disabled={handTableData[0]?.amount}
                      className={`px-2 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px ${
                        !handTableData[0]?.amount
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        setHandData({
                          diamond: [],
                          head: "",
                        });
                        if (isHandUpdate) {
                          let amount = Math.floor(
                            (Number(handData?.qty) * Number(handData?.rate)) /
                              100
                          );
                          let record = {
                            _id: selectedHandRow?._id,
                            itemId: selectedHandItem?.value || "",
                            remarkId: selectedHandRemarks?.value || "",
                            head: Number(handData?.head) || "",
                            diamond: isOldDiamond?.map((item: any) => {
                              return {
                                titleId: item?.value,
                                qty: Number(item?.qty),
                                title: {
                                  _id: item?.value,
                                  name: item?.label,
                                },
                              };
                            }),
                          };
                          if (Object?.keys(selectedHandItem)?.length === 0) {
                            errorData["item3"] = "Item is Required";
                          } else {
                            errorData["item3"] = "";
                          }
                          if (Object?.keys(isOldDiamond)?.length === 0) {
                            errorData["diamond"] = "Diamond  is Required";
                          } else errorData["diamond"] = "";
                          if (handData?.head === "") {
                            errorData["head"] = "Head is Required";
                          } else {
                            errorData["head"] = "";
                          }
                          setErrorMessage(errorData);
                          const result: any = sareeHandValidation(record);
                          if (result === true) {
                            const elementsIndex = handTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedHandRow?._id
                            );

                            let newArray = [...handTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setHandTableData(newArray);

                            setHandData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedDiamond({});
                            setSelectedHandItem({});
                            setSelectedHandRemarks({});

                            setIsHandUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount = Math.floor(
                            (Number(handData?.qty) * Number(handData?.rate)) /
                              100
                          );
                          let object = {
                            _id: ObjectID().toHexString(),
                            itemId: selectedHandItem?.value || "",
                            remarkId: selectedHandRemarks?.value || "",
                            head: Number(handData?.head) || "",
                            diamond: isOldDiamond?.map((item: any) => {
                              return {
                                titleId: item?.value,
                                qty: Number(item?.qty),
                                title: {
                                  _id: item?.value,
                                  name: item?.label,
                                },
                              };
                            }),
                          };

                          if (Object?.keys(selectedHandItem)?.length === 0) {
                            errorData["item3"] = "Item is Required";
                          } else {
                            errorData["item3"] = "";
                          }
                          if (Object?.keys(isOldDiamond)?.length === 0) {
                            errorData["diamond"] = "Diamond  is Required";
                          } else errorData["diamond"] = "";
                          if (handData?.head === "") {
                            errorData["head"] = "Head is Required";
                          } else {
                            errorData["head"] = "";
                          }
                          setErrorMessage(errorData);
                          const result: any = sareeHandValidation(object);
                          if (result === true) {
                            setHandTableData([...handTableData, object]);
                            setSelectedHandItem({});
                            setSelectedHandRemarks({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        }
                        handWorkRef.current.focus();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isHandUpdate ? "Update" : "Add"} Handwork
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {handTableData && handTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          remarks
                                        </th>
                                        {handTableData[0]?.amount ? (
                                          <>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Diamond
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Qty
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Rate
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Amount
                                            </th>
                                          </>
                                        ) : (
                                          <>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              head
                                            </th>
                                            {
                                              <>
                                                {Object.entries(
                                                  diamondTotal
                                                ).map(
                                                  (
                                                    [titleId, title]: any,
                                                    index: number
                                                  ) => {
                                                    const matchingOldDiamond =
                                                      isOldDiamond?.[index]
                                                        ?.label;

                                                    return (
                                                      <>
                                                        <th
                                                          scope="col"
                                                          className="px-6 font-bold text-gray-900"
                                                        >
                                                          Rate
                                                        </th>
                                                        <th
                                                          className="px-6 font-bold text-gray-900"
                                                          key={titleId}
                                                        >
                                                          {matchingOldDiamond}
                                                        </th>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            }
                                          </>
                                        )}
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {handTableData?.map(
                                        (item: any, index: number) => {
                                          let total = 0;
                                          let totalDiamond = 0;
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {remarksState[item?.remarkId]
                                                  ? remarksState[item?.remarkId]
                                                  : "-"}
                                              </td>
                                              {item?.amount ? (
                                                <>
                                                  {item?.diamond?.length > 0 ? (
                                                    item?.diamond?.map(
                                                      (i: any) => {
                                                        return (
                                                          <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                            {" "}
                                                            {i?.name}
                                                          </td>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {" "}
                                                      -
                                                    </td>
                                                  )}

                                                  <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                    {item?.qty}
                                                  </td>
                                                  <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                    {item?.rate}
                                                  </td>
                                                  <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                    {item?.amount}
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                    {item?.head}
                                                  </td>
                                                  {item?.diamond?.length !==
                                                    0 &&
                                                    item?.diamond?.map(
                                                      (
                                                        diamond: any,
                                                        index: any
                                                      ) => {
                                                        total =
                                                          Math.floor(
                                                            Number(item?.head) *
                                                              Number(
                                                                diamond?.qty
                                                              )
                                                          ) + total;
                                                        totalDiamond =
                                                          totalDiamond + total;

                                                        return (
                                                          <>
                                                            <td
                                                              scope="col"
                                                              key={index}
                                                              className="gap-3 px-6 py-2 font-normal text-gray-900 text-left"
                                                            >
                                                              {diamond?.qty}
                                                            </td>

                                                            <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                              {Math.floor(
                                                                Number(
                                                                  item?.head
                                                                ) *
                                                                  Number(
                                                                    diamond?.qty
                                                                  )
                                                              )}
                                                            </td>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                              <td>
                                                <div>
                                                  {handTableData[0]?.amount ||
                                                  handTableData[0]?.amount ===
                                                    "" ? null : (
                                                    <button
                                                      className="m-2"
                                                      type="button"
                                                      onClick={() => {
                                                        setSelectedHandRow(
                                                          item
                                                        );
                                                        setIsOldDiamond(
                                                          item?.diamond?.map(
                                                            (item: any) => {
                                                              return {
                                                                value:
                                                                  item?.titleId,
                                                                qty: Number(
                                                                  item?.qty
                                                                ),
                                                                label:
                                                                  item?.title
                                                                    ?.name,
                                                              };
                                                            }
                                                          )
                                                        );
                                                        setSelectedHandItem({
                                                          value: item?.itemId,
                                                          label:
                                                            itemState[
                                                              item?.itemId
                                                            ],
                                                        });
                                                        setSelectedDiamond({
                                                          value:
                                                            item?.diamondId,
                                                          label:
                                                            diamondState[
                                                              item?.diamondId
                                                            ],
                                                        });
                                                        setSelectedHandRemarks({
                                                          value: item?.remarkId,
                                                          label:
                                                            remarksState[
                                                              item?.remarkId
                                                            ],
                                                        });
                                                        setHandData({
                                                          qty: item?.qty,
                                                          amount: item?.amount,
                                                          rate: item?.rate,
                                                          head: item?.head,
                                                          diamond:
                                                            item?.diamond,
                                                        });

                                                        setIsHandUpdate(true);
                                                      }}
                                                    >
                                                      <i className="fas fa-pen hover:text-green-600"></i>
                                                    </button>
                                                  )}
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            handTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setHandTableData(
                                                            newData
                                                          );
                                                          setIsHandUpdate(
                                                            false
                                                          );
                                                          setHandData({
                                                            qty: "",
                                                            rate: "",
                                                            amount: "",
                                                            head: "",
                                                            diamond: [],
                                                          });
                                                          setSelectedHandItem(
                                                            {}
                                                          );
                                                          setSelectedHandRemarks(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot className="border-t bg-gray-50">
                                      <tr>
                                        <td
                                          colSpan={amountTotal ? 6 : 4}
                                          className="px-6 py-1 font-bold text-gray-900 text-left "
                                        >
                                          <div className="flex items-center">
                                            <span>Total</span>
                                            {isOldDiamond?.map(
                                              (item: any, index: any) => {
                                                return handTableData[0]
                                                  ?.amount ? null : (
                                                  <div
                                                    className="relative rounded-md ml-3 mt-2"
                                                    style={{ width: "100px" }}
                                                  >
                                                    <TextBox
                                                      className="mb-2"
                                                      key={index}
                                                      name="value"
                                                      type="number"
                                                      id="value"
                                                      htmlFor="value"
                                                      onChange={(e: any) => {
                                                        let updatedValues = [
                                                          ...isValue,
                                                        ];

                                                        updatedValues[index] =
                                                          Number(
                                                            e.target.value
                                                          );

                                                        if (
                                                          updatedValues.every(
                                                            (value) =>
                                                              value === ""
                                                          )
                                                        ) {
                                                          updatedValues = [];
                                                        }
                                                        setIsValue(
                                                          updatedValues
                                                        );
                                                      }}
                                                      value={
                                                        isValue[index] === 0
                                                          ? ""
                                                          : isValue[index]
                                                      }
                                                      label={item?.label}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </td>
                                        {
                                          <>
                                            {handTableData[0]?.amount === "" ||
                                            !handTableData[0]?.amount ? (
                                              <>
                                                {Object.values(
                                                  diamondTotal
                                                ).map(
                                                  (
                                                    item: any,
                                                    index: number
                                                  ) => {
                                                    let point: any =
                                                      parseFloat(
                                                        item?.totalQty
                                                      ) / 100;
                                                    let calculatedValue: any =
                                                      isValue[index] === "" ||
                                                      !isValue[index]
                                                        ? Math.floor(
                                                            Number(point)
                                                          ).toFixed(0)
                                                        : Math.floor(
                                                            point *
                                                              parseFloat(
                                                                isValue[index]
                                                              )
                                                          );
                                                    // let finalQty: any =
                                                    //   Math.floor(
                                                    //     Math.floor(
                                                    //       item?.total *
                                                    //         parseFloat(isValue[index])
                                                    //     ) / 100
                                                    //   );
                                                    return (
                                                      <>
                                                        <td
                                                          className="px-6 font-bold text-gray-900 text-left fill2"
                                                          key={index}
                                                        >
                                                          {calculatedValue
                                                            ? calculatedValue
                                                            : point}
                                                        </td>
                                                        <td
                                                          className="px-6 font-bold text-gray-900 text-left fill"
                                                          key={index}
                                                        >
                                                          {/* {finalQty
                                                            ? finalQty
                                                            : 0} */}
                                                          {item?.totalQty}
                                                        </td>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {handTableData[0]?.amount !==
                                                "" ? (
                                                  amountTotal ? (
                                                    <td
                                                      colSpan={2}
                                                      className="px-6 font-bold text-gray-900 text-left blank"
                                                    >
                                                      {amountTotal}
                                                    </td>
                                                  ) : null
                                                ) : null}
                                              </>
                                            )}
                                          </>
                                        }
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Hand Work Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Fabrics */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Fabrics</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      ref={fabricsRef}
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "fabricitem");
                        setErrorMessage({
                          ...errorMessage,
                          item4: "",
                        });
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.item4
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("fabric");
                      }}
                      options={Item}
                      value={selectedFabricItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.item4 ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      {" "}
                      Item
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.item4}
                    </span>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="cloth"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "cloth");
                        setErrorMessage({
                          ...errorMessage,
                          cloth: "",
                        });
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.cloth
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getClothTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         clothTrigger('')
                      //         let getAllCloth = clothResult?.data?.data?.clothe?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllCloth) {
                      //             setCloth(getAllCloth)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setClothAdd(data);
                        setShowAddCloth(true);
                      }}
                      options={Cloth}
                      value={selectedCloth}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.cloth ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Fabric
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.cloth}
                    </span>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="clothParty"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "clothParty");
                        setErrorMessage({
                          ...errorMessage,
                          fabricParty: "",
                        });
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.fabricParty
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getClothPartyTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         clothPartyTrigger('')
                      //         let getAllClothParty = clothPartyResult?.data?.data?.clotheParty?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllClothParty) {
                      //             setClothParty(getAllClothParty)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setClothPartyAdd(data);
                        setShowAddClothParty(true);
                      }}
                      options={clothParty}
                      value={selectedClothParty}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.fabricParty
                          ? "text-red-500"
                          : "text-gray-500"
                      }`}
                    >
                      Fabric Party
                    </label>
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.fabricParty}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="meter"
                      type="number"
                      name="meter"
                      htmlFor="meter3"
                      label="meter"
                      hasError={errorMessage?.meter1}
                      placeholder=" "
                      value={fabricData?.meter}
                      onChange={(e: any) => {
                        handleFabricChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          meter1: "",
                        });
                      }}
                    />
                    <span className="text-sm text-red-500 text-left">
                      {errorMessage?.meter1}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="rate"
                      type="number"
                      name="rate"
                      htmlFor="rate5"
                      label="rate"
                      hasError={errorMessage?.rate4}
                      placeholder=" "
                      value={fabricData?.rate}
                      onChange={(e: any) => {
                        handleFabricChanges(e);
                        setErrorMessage({
                          ...errorMessage,
                          rate4: "",
                        });
                      }}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.rate4}
                    </span>
                  </div>
                  <div>
                    <TextBox
                      id="amount"
                      type="number"
                      name="amount"
                      htmlFor="amount4"
                      hasError={errorMessage?.amount3}
                      label="Total Rate"
                      placeholder=" "
                      value={fabricData?.amount}
                      disabled
                      onChange={(e: any) => handleFabricChanges(e)}
                    />
                    <span className="text-sm text-red-500 flex justify-start">
                      {errorMessage?.amount3}
                    </span>
                  </div>
                  <div className="w-full">
                    <button
                      className="px-2 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      onClick={() => {
                        if (isFabricUpdate) {
                          let amount = Math.floor(
                            Number(fabricData?.meter) * Number(fabricData?.rate)
                          );
                          let record = {
                            _id: selectedFabricRow?._id,
                            clotheId: selectedCloth?.value || "",
                            clothePartyId: selectedClothParty?.value || "",
                            itemId: selectedFabricItem?.value || "",
                            meter: fabricData?.meter || "",
                            rate: fabricData?.rate || "",
                            amount: amount || "",
                          };
                          if (Object?.keys(selectedFabricItem)?.length === 0) {
                            errorData["item4"] = "Item is Required";
                          } else {
                            errorData["item4"] = "";
                          }
                          if (Object?.keys(selectedCloth)?.length === 0) {
                            errorData["cloth"] = "Fabric  is Required";
                          } else errorData["cloth"] = "";
                          if (Object?.keys(selectedClothParty)?.length === 0) {
                            errorData["fabricParty"] =
                              "Fabric Party is Required";
                          } else errorData["fabricParty"] = "";
                          if (!fabricData?.rate || fabricData?.rate == "") {
                            errorData["rate4"] = "Rate is Required";
                          } else errorData["rate4"] = "";
                          if (!fabricData?.meter || fabricData?.meter == "") {
                            errorData["meter1"] = "Meter is Required";
                          } else errorData["meter1"] = "";
                          if (!amount) {
                            errorData["amount3"] = "Amount is Required";
                          } else errorData["amount3"] = "";
                          setErrorMessage(errorData);
                          const result: any = sareeFabricValidation(record);
                          if (result === true) {
                            const elementsIndex = fabricTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedFabricRow?._id
                            );

                            let newArray = [...fabricTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setFabricTableData(newArray);

                            setFabricData({
                              meter: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedCloth({});
                            setSelectedFabricItem({});
                            setSelectedClothParty({});

                            setIsFabricUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount = Math.floor(
                            Number(fabricData?.meter) * Number(fabricData?.rate)
                          );
                          let object = {
                            _id: ObjectID().toHexString(),
                            clotheId: selectedCloth?.value || "",
                            clothePartyId: selectedClothParty?.value || "",
                            itemId: selectedFabricItem?.value || "",
                            meter: fabricData?.meter || "",
                            rate: fabricData?.rate || "",
                            amount: amount || "",
                          };
                          if (Object?.keys(selectedFabricItem)?.length === 0) {
                            errorData["item4"] = "Item is Required";
                          } else {
                            errorData["item4"] = "";
                          }
                          if (Object?.keys(selectedCloth)?.length === 0) {
                            errorData["cloth"] = "Fabric  is Required";
                          } else errorData["cloth"] = "";
                          if (Object?.keys(selectedClothParty)?.length === 0) {
                            errorData["fabricParty"] =
                              "Fabric Party is Required";
                          } else errorData["fabricParty"] = "";
                          if (!fabricData?.rate || fabricData?.rate == "") {
                            errorData["rate4"] = "Rate is Required";
                          } else errorData["rate4"] = "";
                          if (!fabricData?.meter || fabricData?.meter == "") {
                            errorData["meter1"] = "Meter is Required";
                          } else errorData["meter1"] = "";
                          if (!amount) {
                            errorData["amount3"] = "Amount is Required";
                          } else errorData["amount3"] = "";
                          setErrorMessage(errorData);
                          const result: any = sareeFabricValidation(object);
                          if (result === true) {
                            setFabricTableData([...fabricTableData, object]);

                            setFabricData({
                              meter: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedCloth({});
                            setSelectedFabricItem({});
                            setSelectedClothParty({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        }
                        fabricsRef.current.focus();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isFabricUpdate ? "Update" : "Add"} Fabrics
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {fabricTableData &&
                              fabricTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Fabric
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Fabric party
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          meter
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          rate
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {fabricTableData?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {clotheState[item?.clotheId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {
                                                  clothePartyState[
                                                    item?.clothePartyId
                                                  ]
                                                }
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.meter}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.rate}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.amount}
                                              </td>
                                              <td>
                                                <div>
                                                  <button
                                                    className="m-2"
                                                    type="button"
                                                    onClick={() => {
                                                      setSelectedFabricRow(
                                                        item
                                                      );
                                                      setSelectedFabricItem({
                                                        value: item?.itemId,
                                                        label:
                                                          itemState[
                                                            item?.itemId
                                                          ],
                                                      });
                                                      setSelectedCloth({
                                                        value: item?.clotheId,
                                                        label:
                                                          clotheState[
                                                            item?.clotheId
                                                          ],
                                                      });
                                                      setSelectedClothParty({
                                                        value:
                                                          item?.clothePartyId,
                                                        label:
                                                          clothePartyState[
                                                            item?.clothePartyId
                                                          ],
                                                      });
                                                      setFabricData({
                                                        meter: item?.meter,
                                                        amount: item?.amount,
                                                        rate: item?.rate,
                                                      });

                                                      setIsFabricUpdate(true);
                                                    }}
                                                  >
                                                    <i className="fas fa-pen hover:text-green-600"></i>
                                                  </button>
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            fabricTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setFabricTableData(
                                                            newData
                                                          );
                                                          setIsFabricUpdate(
                                                            false
                                                          );
                                                          setFabricData({
                                                            meter: "",
                                                            rate: "",
                                                            amount: "",
                                                          });
                                                          setSelectedCloth({});
                                                          setSelectedFabricItem(
                                                            {}
                                                          );
                                                          setSelectedClothParty(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr className="border-t border-gray-400">
                                        <td
                                          colSpan={6}
                                          className="px-6 text-right text-gray-900"
                                        >
                                          <input
                                            id="fabricNotes"
                                            name="fabricNotes"
                                            value={fabricNotes}
                                            onChange={(e) => {
                                              setFabricNotes(e.target.value);
                                            }}
                                            placeholder="Notes"
                                            className={`first-line:block px-2.5 h-7 w-full my-2 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                                          />
                                        </td>
                                        <td className="px-6 font-bold text-gray-900">
                                          {fabricTotal}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Fabrics Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Dye Configaration */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Daying</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "dyeitem");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("dye");
                      }}
                      options={Item}
                      value={selectedDyeItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                    >
                      Item
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="foilColor"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "dyeColor");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getFoilColorTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllFoil = foilResult?.data?.data?.foilColor?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllFoil) {
                      //             setFoil(getAllFoil)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setFoilAdd(data);
                        setShowAddFoil(true);
                      }}
                      options={Foil}
                      value={selectedDye}
                    />
                    <label
                      htmlFor="foilColor"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                    >
                      Dye color
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="dyeremarks"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "dyeremarks");
                        } else {
                          setSelectedDyeRemarks({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getRemarkTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         remarksTrigger('')
                      //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllRemark) {
                      //             setRemarks(getAllRemark)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setRemarksAdd(data);
                        setShowAddRemarks(true);
                        setItemType("dye");
                      }}
                      options={Remarks}
                      value={selectedDyeRemarks}
                    />
                    <label
                      htmlFor="dyeremarks"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                    >
                      Remarks
                    </label>
                  </div>
                  <TextBox
                    id="qty"
                    type="number"
                    name="qty"
                    htmlFor="qty1"
                    label="qty"
                    placeholder=" "
                    value={dyeData?.qty}
                    onChange={(e: any) => handleDyeChanges(e)}
                  />
                  <TextBox
                    id="rate"
                    type="number"
                    name="rate"
                    htmlFor="rate2"
                    label="rate"
                    placeholder=" "
                    value={dyeData?.rate}
                    onChange={(e: any) => handleDyeChanges(e)}
                  />
                  <TextBox
                    id="amount"
                    type="number"
                    name="amount"
                    htmlFor="amount1"
                    label="amount"
                    placeholder=" "
                    value={dyeData?.amount}
                    disabled
                    onChange={(e: any) => handleDyeChanges(e)}
                  />
                  <div className="w-full">
                    <button
                      className="px-3 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      onClick={() => {
                        if (isDyeUpdate) {
                          let amount = Math.floor(
                            Number(dyeData?.qty) * Number(dyeData?.rate)
                          );
                          let record = {
                            _id: selectedDyeRow?._id || "",
                            colorId: selectedDye?.value || "",
                            itemId: selectedDyeItem?.value || "",
                            remarkId: selectedDyeRemarks?.value || "",
                            qty: dyeData?.qty || "",
                            rate: dyeData?.rate || "",
                            amount: amount || "",
                          };
                          const result: any = sareeFoilValidation(record);
                          if (result === true) {
                            const elementsIndex = dyeTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedDyeRow?._id
                            );

                            let newArray = [...dyeTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setDyeTableData(newArray);

                            setDyeData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedDye({});
                            setSelectedDyeItem({});
                            setSelectedDyeRemarks({});

                            setIsDyeUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount = Math.floor(
                            Number(dyeData?.qty) * Number(dyeData?.rate)
                          );
                          let object = {
                            _id: ObjectID().toHexString(),
                            colorId: selectedDye?.value || "",
                            itemId: selectedDyeItem?.value || "",
                            remarkId: selectedDyeRemarks?.value || "",
                            qty: dyeData?.qty || "",
                            rate: dyeData?.rate || "",
                            amount: amount || "",
                          };
                          const result: any = sareeFoilValidation(object);
                          if (result === true) {
                            setDyeTableData([...dyeTableData, object]);

                            setDyeData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedDye({});
                            setSelectedDyeItem({});
                            setSelectedDyeRemarks({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        }
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isDyeUpdate ? "Update" : "Add"} Dye
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {dyeTableData && dyeTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          dye color
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          qty
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          rate
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {dyeTableData?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {foilState[item?.colorId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {remarksState[item?.remarkId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.qty}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.rate}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.amount}
                                              </td>
                                              <td>
                                                <div>
                                                  <button
                                                    className="m-2"
                                                    type="button"
                                                    onClick={() => {
                                                      setSelectedDyeRow(item);
                                                      setSelectedDyeItem({
                                                        value: item?.itemId,
                                                        label:
                                                          itemState[
                                                            item?.itemId
                                                          ],
                                                      });
                                                      setSelectedDye({
                                                        value: item?.colorId,
                                                        label:
                                                          foilState[
                                                            item?.colorId
                                                          ],
                                                      });
                                                      setSelectedDyeRemarks({
                                                        value: item?.remarkId,
                                                        label:
                                                          remarksState[
                                                            item?.remarkId
                                                          ],
                                                      });

                                                      setDyeData({
                                                        qty: item?.qty,
                                                        amount: item?.amount,
                                                        rate: item?.rate,
                                                      });

                                                      setIsDyeUpdate(true);
                                                    }}
                                                  >
                                                    <i className="fas fa-pen hover:text-green-600"></i>
                                                  </button>
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            dyeTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setDyeTableData(
                                                            newData
                                                          );
                                                          setIsDyeUpdate(false);
                                                          setDyeData({
                                                            qty: "",
                                                            rate: "",
                                                            amount: "",
                                                          });
                                                          setSelectedDye({});
                                                          setSelectedDyeItem(
                                                            {}
                                                          );
                                                          setSelectedDyeRemarks(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr className="border-t border-gray-400">
                                        <td
                                          colSpan={6}
                                          className="px-6 text-right text-gray-900"
                                        >
                                          <input
                                            id="dyeNotes"
                                            name="dyeNotes"
                                            value={dyeNotes}
                                            onChange={(e) => {
                                              setDyeNotes(e.target.value);
                                            }}
                                            placeholder="Notes"
                                            className={`first-line:block px-2.5 h-7 w-full my-2 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                                          />
                                        </td>
                                        <td className="px-6 font-bold text-gray-900">
                                          {dyeTotal}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Daying Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 px-2">
                <div className="col-span-4 md:col-span-1 px-2 row-span-3">
                  <ImageUploading
                    multiple={false}
                    onChange={(e: any) => handlepostImage(e)}
                    maxNumber={1}
                    acceptType={["png", "jpg", "jpeg"]}
                    maxFileSize={FILE_UPLOAD_SIZE}
                    dataURLKey="data_url"
                    value={images}
                  >
                    {({ onImageUpload, errors }) => {
                      return (
                        <div className="text-center">
                          {errors && (
                            <div>
                              {errors.acceptType && (
                                <span className="text-red-500">
                                  <i className="	fa fa-warning"></i>Your selected
                                  file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span className="text-red-500">
                                  <i className="fa fa-warning"></i>Selected file
                                  size exceed maxFileSize
                                </span>
                              )}
                            </div>
                          )}
                          {images ? (
                            <div className="">
                              <button
                                className="mt-1 w-full h-full flex justify-center rounded-md"
                                onClick={() => onImageUpload()}
                              ></button>

                              <div className="relative">
                                <button
                                  className="absolute top-0 right-0 bg-white rounded-lg"
                                  onClick={() => {
                                    imageDelete(images);
                                  }}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                                <ProgressiveImage src={images} placeholder="">
                                  {(src: any) => (
                                    <img
                                      className="object-contain h-90 w-full px-2"
                                      src={src}
                                      alt="img"
                                    />
                                  )}
                                </ProgressiveImage>
                              </div>
                            </div>
                          ) : (
                            <button
                              className={`mt-1 h-full w-full flex justify-center  rounded-md border-2 border-solid px-3 py-10 ${
                                errorMessage?.image
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }`}
                              onClick={() => onImageUpload()}
                            >
                              <div className="w-full h-full flex justify-center items-center content-center flex-wrap space-y-1 text-center">
                                <img
                                  src="../../../assets/images/imageIcon.svg"
                                  alt="Image"
                                />
                                <div className="w-full text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                  >
                                    <span>Upload a file</span>
                                  </label>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PNG, JPG Upto 1.5 MB
                                </p>
                              </div>
                            </button>
                          )}
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage.image}
                          </span>
                        </div>
                      );
                    }}
                  </ImageUploading>
                </div>
                {/* Final Costing */}
                <div className="grid capitalize gap-2 col-span-3 grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 p-2">
                  <div className="flex items-center justify-center text-2xl font-bold text-gray-700">
                    Final costing
                  </div>
                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isJobSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl  ${
                      isJobSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isJobSelect) {
                        setIsJobSelect(false);
                      } else {
                        setIsJobSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-black font-bold">
                        job work
                      </h5>
                      <p className="mb-3 font-bold text-lg">
                        {jobTotal} +{" "}
                        {Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100))} ={" "}
                        {jobTotal +
                          Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100))}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{jobTotal}</div>
                          <div>Jobs</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="stitchPar"
                              type="number"
                              name="stitchPar"
                              placeholder="0"
                              value={jobPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setJobPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isJobSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2 ">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>
                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isFoilSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isFoilSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isFoilSelect) {
                        setIsFoilSelect(false);
                      } else {
                        setIsFoilSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-black font-bold">
                        foil costing
                      </h5>
                      <p className="mb-3 font-bold text-lg">
                        {foilTotal} +{" "}
                        {Math.ceil(foilTotal * ((foilPar ? foilPar : 0) / 100))}{" "}
                        ={" "}
                        {foilTotal +
                          Math.ceil(
                            foilTotal * ((foilPar ? foilPar : 0) / 100)
                          )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{foilTotal}</div>
                          <div>foil</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="foilPar"
                              type="number"
                              name="foilPar"
                              placeholder="0"
                              value={foilPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setFoilPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isFoilSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2 ">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isStitchSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl  ${
                      isStitchSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isStitchSelect) {
                        setIsStitchSelect(false);
                      } else {
                        setIsStitchSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-black font-bold">
                        Stitch costing
                      </h5>
                      <p className="mb-3 font-bold text-lg">
                        {stitchTotal} +{" "}
                        {Math.ceil(
                          stitchTotal * ((stitchPar ? stitchPar : 0) / 100)
                        )}{" "}
                        ={" "}
                        {stitchTotal +
                          Math.ceil(
                            stitchTotal * ((stitchPar ? stitchPar : 0) / 100)
                          )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{stitchTotal}</div>
                          <div>Stitchings</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="stitchPar"
                              type="number"
                              name="stitchPar"
                              placeholder="0"
                              value={stitchPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setStitchPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isStitchSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2 ">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isHandSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl  ${
                      isHandSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isHandSelect) {
                        setIsHandSelect(false);
                      } else {
                        setIsHandSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        HandWork costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {!handTableData[0]?.amount ? (
                          <>
                            {handTableData?.length > 0 ? (
                              <></> ? (
                                <>
                                  {Object.entries(diamondTotal).map(
                                    ([titleId, total]: any, index: number) => {
                                      // let point: any = Math.floor(
                                      //   total?.totalQty / 100
                                      // );
                                      // let setTotal =
                                      //   isValue[index] === "" || !isValue[index]
                                      //     ? (parseFloat(total?.totalQty) / 100)
                                      //     : Math.ceil(point * isValue[index]);
                                      let point: any =
                                        parseFloat(total?.totalQty) / 100;
                                      let setTotal: any =
                                        isValue[index] === "" || !isValue[index]
                                          ? Math.floor(Number(point))
                                          : Math.floor(
                                              point * parseFloat(isValue[index])
                                            );
                                      pointTotal += setTotal;
                                    }
                                  )}
                                  {pointTotal}
                                </>
                              ) : (
                                0
                              )
                            ) : (
                              0
                            )}{" "}
                            +
                            {handTableData?.length > 0
                              ? Math.ceil(
                                  pointTotal * ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                            =
                            {handTableData?.length > 0
                              ? Math.ceil(
                                  pointTotal +
                                    pointTotal * ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                          </>
                        ) : (
                          <>
                            {handTableData?.length > 0
                              ? amountTotal
                                ? amountTotal
                                : 0
                              : 0}
                            +
                            {handTableData?.length > 0
                              ? Math.ceil(
                                  amountTotal * ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                            =
                            {handTableData?.length > 0
                              ? Math.ceil(
                                  amountTotal +
                                    amountTotal *
                                      ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                          </>
                        )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>
                            {handTableData?.length > 0
                              ? !handTableData[0]?.amount
                                ? pointTotal
                                : amountTotal
                              : 0}
                          </div>
                          <div>HandWork</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="handPar"
                              type="number"
                              name="handPar"
                              placeholder="0"
                              value={handPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white only:focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setHandPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isHandSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2 ">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isFabricSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl  ${
                      isFabricSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isFabricSelect) {
                        setIsFabricSelect(false);
                      } else {
                        setIsFabricSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-black font-bold">
                        Fabric costing
                      </h5>
                      <p className="mb-3 font-bold text-lg">
                        {fabricTotal} +{" "}
                        {Math.ceil(
                          fabricTotal * ((fabricPar ? fabricPar : 0) / 100)
                        )}{" "}
                        ={" "}
                        {fabricTotal +
                          Math.ceil(
                            fabricTotal * ((fabricPar ? fabricPar : 0) / 100)
                          )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{fabricTotal}</div>
                          <div>Fabrics</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="fabricPar"
                              type="number"
                              name="fabricPar"
                              placeholder="0"
                              value={fabricPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setFabricPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isFabricSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2 ">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isDyeSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isDyeSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isDyeSelect) {
                        setIsDyeSelect(false);
                      } else {
                        setIsDyeSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        Daying costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {dyeTotal} +{" "}
                        {Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100))} ={" "}
                        {dyeTotal +
                          Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100))}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{dyeTotal}</div>
                          <div>Daying</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="dyePar"
                              type="number"
                              name="dyePar"
                              placeholder="0"
                              value={dyePar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white only:focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setDyePar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isDyeSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <div className="flex justify-center items-center bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ">
                    <div className="flex flex-col items-center p-4 leading-normal">
                      <h5 className="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 ">
                        Total costing
                      </h5>
                      <p className="mb-3 font-bold text-4xl text-gray-700">
                        {(isJobSelect
                          ? jobTotal +
                            Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100))
                          : 0) +
                          (isFoilSelect
                            ? foilTotal +
                              Math.ceil(
                                foilTotal * ((foilPar ? foilPar : 0) / 100)
                              )
                            : 0) +
                          (isDyeSelect
                            ? dyeTotal +
                              Math.ceil(
                                dyeTotal * ((dyePar ? dyePar : 0) / 100)
                              )
                            : 0) +
                          (isStitchSelect
                            ? stitchTotal +
                              Math.ceil(
                                stitchTotal *
                                  ((stitchPar ? stitchPar : 0) / 100)
                              )
                            : 0) +
                          (isHandSelect
                            ? handTableData?.length > 0
                              ? amountTotal
                                ? Math.ceil(
                                    amountTotal +
                                      amountTotal *
                                        ((handPar ? handPar : 0) / 100)
                                  )
                                : Math.ceil(
                                    pointTotal +
                                      pointTotal *
                                        ((handPar ? handPar : 0) / 100)
                                  )
                              : 0
                            : 0) +
                          (isFabricSelect
                            ? fabricTotal +
                              Math.ceil(
                                fabricTotal *
                                  ((fabricPar ? fabricPar : 0) / 100)
                              )
                            : 0)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="px-3 col-span-3 w-full">
                  <input
                    id="finalNotes"
                    name="finalNotes"
                    value={finalNotes}
                    onChange={(e) => {
                      setFinalNotes(e.target.value);
                    }}
                    placeholder="Final Notes"
                    className={`first-line:block px-2.5 py-1 w-full my-2 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible p-2 gap-2">
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showSareeRatePreview}
                    onClick={openInvoicePreview}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={isCardUpdate ? updateData : addData}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">
                      {isCardUpdate ? "Update" : "Save"}
                    </span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/sareeratedashboard");
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Pdf Preview */}
            {showSareeRatePreview && (
              <SareeRateCardPreview
                showSareeRatePreview={showSareeRatePreview}
                setShowSareeRatePreview={setShowSareeRatePreview}
                singleCard={singleCard}
                pageName="Saree Rate card"
              />
            )}
            {/* Modal */}
            {showAddParty && (
              <PartyAdd
                setShowAddParty={setShowAddParty}
                showAddParty={showAddParty}
                setPartyNameAdd={setPartyNameAdd}
                partyNameAdd={partyNameAdd}
                getData={getData}
              />
            )}
            {showAddCloth && (
              <ClothAdd
                setShowAddCloth={setShowAddCloth}
                showAddCloth={showAddCloth}
                setClothAdd={setClothAdd}
                clothAdd={clothAdd}
                getClothData={getClothData}
              />
            )}
            {showAddClothParty && (
              <ClothPartyAdd
                setShowAddClothParty={setShowAddClothParty}
                showAddClothParty={showAddClothParty}
                setClothPartyAdd={setClothPartyAdd}
                clothPartyAdd={clothPartyAdd}
                getClothPartyData={getClothPartyData}
              />
            )}
            {showAddItem && (
              <ItemAdd
                setShowAddItem={setShowAddItem}
                showAddItem={showAddItem}
                setItemAdd={setItemAdd}
                itemAdd={itemAdd}
                getItemData={getItemData}
                itemType={itemType}
              />
            )}
            {showAddMaterial && (
              <MaterialAdd
                setShowAddMaterial={setShowAddMaterial}
                showAddMaterial={showAddMaterial}
                setMaterialAdd={setMaterialAdd}
                materialAdd={materialAdd}
                getMaterialData={getMaterialData}
              />
            )}
            {showAddDiamond && (
              <DiamondAdd
                setShowAddDiamond={setShowAddDiamond}
                showAddDiamond={showAddDiamond}
                setDiamondAdd={setDiamondAdd}
                diamondAdd={diamondAdd}
                getDiamondData={getDiamondData}
                setIsOldDiamond={setIsOldDiamond}
                isOldDiamond={isOldDiamond}
              />
            )}
            {showAddFoil && (
              <FoilColorAdd
                setShowAddFoil={setShowAddFoil}
                showAddFoil={showAddFoil}
                setFoilAdd={setFoilAdd}
                foilAdd={foilAdd}
                getFoilData={getFoilData}
              />
            )}
            {showAddRemarks && (
              <RemarksAdd
                setShowAddRemarks={setShowAddRemarks}
                showAddRemarks={showAddRemarks}
                setRemarksAdd={setRemarksAdd}
                remarksAdd={remarksAdd}
                getRemarksData={getRemarksData}
                itemType={itemType}
              />
            )}
          </div>
        </div>
      </div>
      {isSareeFetching ? <Loader /> : null}
    </div>
  );
}
