import Modal from "react-responsive-modal";

export const SettingTableAction = (props: any) => {
  const {
    header,
    isActionModalOpen,
    setIsActionModalOpen,
    selectedRow,
    handleDelete,
    editFunction,
    setIsModalPasswordOpen,
    setIsModalOpen,
  } = props;
  const Close = (
    <i className="fas fa-times bg-black text-white rounded-full px-2 py-1.5"></i>
  );

  return (
    <div>
      <Modal
        open={isActionModalOpen}
        closeIcon={Close}
        onClose={() => {
          setIsActionModalOpen(false);
        }}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onOverlayClick={() => {
          setIsActionModalOpen(false);
        }}
        onEscKeyDown={() => {
          setIsActionModalOpen(false);
        }}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          modal: { background: "#e9ecef" },
          overlay: {
            height: "auto",
          },
        }}
      >
        <div className="p-2 overflow-hidden mx-6">
          <div className="font-bold text-center">
            Selected Record : {header}
          </div>
          <div className="flex justify-center">
            <ul className="float-left min-w-max list-none rounded-lg border-none bg-clip-padding text-left text-base p-1 flex gap-2">
              <li>
                <button
                  type="button"
                  className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none hover:text-lime-500"
                  onClick={() => {
                    editFunction(selectedRow);
                    setIsActionModalOpen(false);
                  }}
                >
                  <i className="fas fa-edit text-lg -ml-px" title="Edit"></i>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none "
                  onClick={() => {
                    editFunction(selectedRow);
                    setIsModalPasswordOpen(true);
                    setIsActionModalOpen(false);
                    setIsModalOpen(false);
                  }}
                >
                  <i className="fas fa-solid fa-key text-lg -ml-px"></i>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none hover:text-red-500"
                  onClick={() => {
                    handleDelete(selectedRow);
                    setIsActionModalOpen(false);
                  }}
                >
                  <i
                    className="fas fa-trash-alt text-lg -ml-px"
                    title="Delete"
                  ></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};
