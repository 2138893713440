import { useState, useEffect, useRef } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../components/form/Select";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ClothPartyAdd } from "../../components/modal/ClothPartyAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import {
  useLazyPartyQuery,
  useLazyNewDressRateInvoiceNumberQuery,
  useLazyClothQuery,
  useLazyItemQuery,
  useUpdateDressRateCardMutation,
  useLazyFabricColorQuery,
  useLazyGetDressRateCardQuery,
  useAddDressRateCardMutation,
  useLazyClothPartyQuery,
  useLazyMaterialQuery,
  useLazyGetClothQuery,
  useLazyGetMaterialQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyGetRemarkQuery,
  useLazyRemarksQuery,
  useLazyDressRateCardQuery,
  useLazyFoilColorQuery,
  useLazyDiamondsQuery,
  useLazyGetDiamondQuery,
  useLazyGetFoilColorQuery,
  useLazyAllDressRateInvoiceNumberQuery,
} from "../../servicesRtkQuery/publicApi";
import { useSelector } from "react-redux";
import {
  jobWorkValidation,
  fabricMeasureValidation,
  fabricCostValidation,
  stitchingValidation,
  sareeFoilValidation,
  sareeHandValidation,
} from "../../utils/validation";
import ProgressiveImage from "react-progressive-graceful-image";
import { FabricAdd } from "../../components/modal/FabricAdd";
import { MaterialAdd } from "../../components/modal/MaterialAdd";
import uuid from "react-uuid";
import S3 from "aws-sdk/clients/s3";
import ObjectID from "bson-objectid";
import Swal from "sweetalert2";
import Loader from "../../components/page/loader";
import { RemarksAdd } from "../../components/modal/RemarksAdd";
import { ERROR_MESSAGE_TIMEOUT, FILE_UPLOAD_SIZE } from "../../utils/constants";
import { DressRateCardPreview } from "../../components/pdf/ratecard/dressRateCardPreview";
import ReactSelect from "react-select";
import { DiamondAdd } from "../../components/modal/DiamondAdd";
import { FoilColorAdd } from "../../components/modal/FoilColorAdd";
import {
  setDefaultDate,
  selectFilterConfig,
} from "../../utils/globalFunctions";
import { createFilter } from "react-select";

export default function DressRateCard() {
  let pointTotal = 0;
  const bucketName: any = process.env.REACT_APP_AWS_BUCKET_NAME;
  const region: any = process.env.REACT_APP_AWS_BUCKET_REGION;
  const accessKeyId: any = process.env.REACT_APP_AWS_ACCESS_KEY;
  const secretAccessKey: any = process.env.REACT_APP_AWS_SECRET_KEY;

  const s3 = new S3({
    region: region,
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  });
  const [isDressCardUpdate, setIsDressCardUpdate] = useState(false);
  const [isCardCopy, setIsCardCopy] = useState(false);
  let url = window.location.pathname;
  let { id } = useParams();
  useEffect(() => {
    if (url == "/ratecard/dress/copy") {
      setIsCardCopy(true);
    } else if (url == "/ratecard/dress/add") {
      clearData();
      setIsDressCardUpdate(false);
      setIsCardCopy(false);
    } else {
      if (id) {
        setIsDressCardUpdate(true);
        setIsCardCopy(false);
      } else {
        setIsDressCardUpdate(false);
      }
    }
  }, [id]);
  // Redux Data State
  const rateCardData = useSelector(
    (state: any) => state.rateCardSlice.rateCardData
  );

  // Global Sample Card Data State
  const [cardData, setCardData]: any = useState();

  const [jobWorkData, setJobWorkData]: any = useState({});
  const [foilData, setFoilData]: any = useState({});
  const [handData, setHandData]: any = useState({});
  const [statchingData, setStatchingData]: any = useState();
  const [measureData, setMeasureData]: any = useState();
  const [costData, setCostData]: any = useState();
  const [dyeData, setDyeData]: any = useState({});
  const [dyeTableData, setDyeTableData] = useState<any>([]);
  const [selectedDyeRow, setSelectedDyeRow]: any = useState("");
  const [selectedDyeItem, setSelectedDyeItem]: any = useState({});
  const [selectedDye, setSelectedDye]: any = useState({});
  const [isDyeUpdate, setIsDyeUpdate] = useState(false);
  const [dyeTotal, setDyeTotal]: any = useState(0);
  const [selectedDyeRemarks, setSelectedDyeRemarks]: any = useState({});
  const [dyePar, setDyePar]: any = useState();
  const [isDyeSelect, setIsDyeSelect] = useState(false);

  const [jobWorkTableData, setJobWorkTableData] = useState<any>([]);
  const [measureTableData, setMeasureTableData] = useState<any>([]);
  const [costTableData, setCostTableData] = useState<any>([]);
  const [statchingTableData, setStatchingTableData] = useState<any>([]);
  const [foilTableData, setFoilTableData] = useState<any>([]);
  const [handTableData, setHandTableData] = useState<any>([]);

  const [selectedJobRow, setSelecetdJobRow]: any = useState("");
  const [selectedMeasureRow, setSelectedMeasureRow]: any = useState("");
  const [selectedCostRow, setSelectedCostRow]: any = useState("");
  const [selectedStatchRow, setSelectedStatchRow]: any = useState("");
  const [selectedFoilRow, setSelectedFoilRow]: any = useState("");
  const [selectedHandRow, setSelectedHandRow]: any = useState("");

  const [isOldDiamond, setIsOldDiamond]: any = useState([]);
  const handleRemoveOption = (removedOption: any) => {
    const updatedOptions: any = isOldDiamond.filter(
      (option: any) => option.value !== removedOption.value
    );
    setIsOldDiamond(updatedOptions);
  };
  var numberOptions = [
    {
      label: "16/18",
      value: "16/18",
    },
    {
      label: "20/22",
      value: "20/22",
    },
    {
      label: "24/26",
      value: "24/26",
    },
    {
      label: "28/30",
      value: "28/30",
    },
    {
      label: "32/34",
      value: "32/34",
    },
    {
      label: "36/38",
      value: "36/38",
    },
  ];

  var farmaOptions = [
    {
      label: "Regular",
      value: "Regular",
    },
    {
      label: "L",
      value: "L",
    },
    {
      label: "XL",
      value: "XL",
    },
    {
      label: "XXL",
      value: "XXL",
    },
  ];

  const [trigger, result] = useLazyPartyQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [invoicetrigger, invoiceResult] =
    useLazyNewDressRateInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceerror,
  } = invoiceResult;

  const [allInvoicetrigger, allInvoiceResult] =
    useLazyAllDressRateInvoiceNumberQuery();
  const {
    isSuccess: isAllInvoiceSuccess,
    isFetching: isAllInvoiceFetching,
    isError: isAllInvoiceError,
    error: allInvoiceerror,
  } = allInvoiceResult;

  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [clothPartyTrigger, clothPartyResult] = useLazyClothPartyQuery();
  const {
    isSuccess: isClothPartySuccess,
    isFetching: isClothPartyFetching,
    isError: isClothPartyError,
    error: clothPartyError,
  } = clothPartyResult;

  const [itemTrigger, itemResult] = useLazyItemQuery();
  const {
    isSuccess: isItemSuccess,
    isFetching: isItemFetching,
    isError: isItemError,
    error: itemError,
  } = itemResult;

  const [fabricTrigger, fabricResult] = useLazyFabricColorQuery();
  const {
    isSuccess: isFabricSuccess,
    isFetching: isFabricFetching,
    isError: isFabricError,
    error: fabricError,
  } = fabricResult;

  const [materialTrigger, materialResult] = useLazyMaterialQuery();
  const {
    isSuccess: isMaterialSuccess,
    isFetching: isMaterialFetching,
    isError: isMaterialError,
    error: materialError,
  } = materialResult;

  const [foilTrigger, foilResult] = useLazyFoilColorQuery();
  const {
    isSuccess: isFoilSuccess,
    isFetching: isFoilFetching,
    isError: isFoilError,
    error: FoilError,
  } = foilResult;

  const [diamondTrigger, diamondResult] = useLazyDiamondsQuery();
  const {
    isSuccess: isDiamondSuccess,
    isFetching: isDiamondFetching,
    isError: isDiamondError,
    error: diamondError,
  } = diamondResult;

  const [dressTrigger, dressResult] = useLazyGetDressRateCardQuery();
  const {
    isSuccess: isDressSuccess,
    isFetching: isDressFetching,
    isError: isDressError,
    error: dressError,
  } = dressResult;

  const [pdfTrigger, pdfResult] = useLazyGetDressRateCardQuery();
  const {
    isSuccess: isPdfSuccess,
    isFetching: isPdfFetching,
    isError: isPdfError,
    error: pdfError,
  } = pdfResult;

  const [allDressTrigger, allDressResult] = useLazyDressRateCardQuery();
  const {
    isSuccess: isAllDressSuccess,
    isFetching: isAllDressFetching,
    isError: isAllDressError,
    error: allDressError,
  } = allDressResult;

  const [remarksTrigger, remarksResult] = useLazyRemarksQuery();
  const {
    isSuccess: isRemarksSuccess,
    isFetching: isRemarksFetching,
    isError: isRemarksError,
    error: RemarksError,
  } = remarksResult;

  const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
  const {
    isSuccess: isgetClothSuccess,
    isFetching: isgetClothFetching,
    isError: isgetClothError,
    error: getClothError,
  } = getClothResult;

  //Getting paginated records
  const [getMaterialTrigger, getMaterialResult] = useLazyGetMaterialQuery();
  const {
    isSuccess: isgetMaterialSuccess,
    isFetching: isgetMaterialFetching,
    isError: isgetMaterialError,
    error: getMaterialError,
  } = getMaterialResult;

  const [getItemTrigger, getItemResult] = useLazyGetItemQuery();
  const {
    isSuccess: isgetItemSuccess,
    isFetching: isgetItemFetching,
    isError: isgetItemError,
    error: getItemError,
  } = getItemResult;

  const [getPartyNameTrigger, getPartyNameResult] = useLazyGetPartyNameQuery();
  const {
    isSuccess: isgetPartyNameSuccess,
    isFetching: isgetPartyNameFetching,
    isError: isgetPartyNameError,
    error: getPartyNameError,
  } = getPartyNameResult;

  const [getRemarkTrigger, getRemarkResult] = useLazyGetRemarkQuery();
  const {
    isSuccess: isGetRemarksSuccess,
    isFetching: isGetRemarksFetching,
    isError: isGetRemarksError,
    error: getRemarksError,
  } = remarksResult;

  const [getDiamondTrigger, getDiamondResult] = useLazyGetDiamondQuery();
  const {
    isSuccess: isGetDiamondSuccess,
    isFetching: isGetDiamondFetching,
    isError: isGetDiamondError,
    error: getdiamondError,
  } = getDiamondResult;

  const [getFoilColorTrigger, getFoilColorResult] = useLazyGetFoilColorQuery();
  const {
    isSuccess: isFoilColorSuccess,
    isFetching: isFoilColorFetching,
    isError: isFoilColorError,
    error: FoilColorError,
  } = getFoilColorResult;

  const [showDressRatePreview, setShowDressRatePreview] = useState(false);
  const [savedId, setSavedId]: any = useState("");
  const [errorMessage, setErrorMessage]: any = useState("");

  useEffect(() => {
    trigger("");
    invoicetrigger();
    clothTrigger("");
    clothPartyTrigger("");
    itemTrigger("");
    fabricTrigger("");
    materialTrigger("");
    diamondTrigger("");
    foilTrigger("");
    remarksTrigger("");
  }, []);
  const navigate = useNavigate();

  // Party Creatable State
  const [showAddParty, setShowAddParty] = useState(false);
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [Party, setParty]: any = useState();
  const [selectedParty, setSelectedParty]: any = useState({});

  // Cloth Creatable State
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [clothState, setClothState]: any = useState({});

  // Item Creatable State
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemAdd, setItemAdd] = useState("");
  const [Item, setItem]: any = useState<any>([]);
  const [selectedItem, setSelectedItem]: any = useState({});
  const [itemState, setItemState]: any = useState({});
  const [selectedFoilItem, setSelectedFoilItem]: any = useState({});
  const [selectedHandItem, setSelectedHandItem]: any = useState({});

  // Job Item Creatable State
  const [selectedJobItem, setSelectedJobItem]: any = useState({});

  // Fabric Color Creatable State
  const [showAddFabric, setShowAddFabric] = useState(false);
  const [fabricAdd, setFabricAdd] = useState("");
  const [Fabric, setFabric]: any = useState([]);
  const [selectedFabric, setSelectedFabric]: any = useState({});
  const [fabricState, setFabricState]: any = useState({});

  // Material Creatable State
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [materialAdd, setMaterialAdd] = useState("");
  const [Material, setMaterial]: any = useState([]);
  const [selectedMaterial, setSelectedMaterial]: any = useState({});
  const [materialState, setMaterialState]: any = useState({});

  // Cloth Party Creatable State
  const [showAddClothParty, setShowAddClothParty] = useState(false);
  const [clothPartyAdd, setClothPartyAdd] = useState("");
  const [clothParty, setClothParty]: any = useState<any>([]);

  // Diamond Creatable State
  const [showAddDiamond, setShowAddDiamond] = useState(false);
  const [diamondAdd, setDiamondAdd] = useState("");
  const [Diamond, setDiamond]: any = useState([]);
  const [diamondState, setDiamondState]: any = useState({});

  // Foil Color Creatable State
  const [showAddFoil, setShowAddFoil] = useState(false);
  const [foilAdd, setFoilAdd] = useState("");
  const [Foil, setFoil]: any = useState([]);
  const [selectedFoil, setSelectedFoil]: any = useState({});
  const [foilState, setFoilState]: any = useState({});

  const [invoice, setInvoice]: any = useState<any>([]);
  const [invoiceNo, setInvoiceNo]: any = useState<any>([]);
  const [newRateCard, setNewRateCard]: any = useState<any>([]);

  const [images, setImages]: any = useState();
  const [selectedInvoice, setSelectedInvoice]: any = useState("");
  const [selectedInvoiceCard, setSelectedInvoiceCard]: any = useState("");

  // Is SampleCard Update State
  const [isJobUpdate, setIsJobUpdate] = useState(false);
  const [isMeasureUpdate, setIsMeasureUpdate] = useState(false);
  const [isCostUpdate, setIsCostUpdate] = useState(false);
  const [isStatchUpdate, setIsStatchUpdate] = useState(false);
  const [isFoilUpdate, setIsFoilUpdate] = useState(false);
  const [isHandUpdate, setIsHandUpdate] = useState(false);

  //Total States
  const [jobTotal, setJobTotal]: any = useState(0);
  const [stitchTotal, setStitchTotal]: any = useState(0);
  const [handTotal, setHandTotal]: any = useState(0);
  const [foilTotal, setFoilTotal]: any = useState(0);
  const [costTotal, setCostTotal]: any = useState(0);

  //Remarks States
  const [showAddRemarks, setShowAddRemarks] = useState(false);
  const [remarksAdd, setRemarksAdd] = useState("");
  const [Remarks, setRemarks]: any = useState([]);
  const [selectedRemarks, setSelectedRemarks]: any = useState({});
  const [selectedCostRemarks, setSelectedCostRemarks]: any = useState({});
  const [selectedStitchRemarks, setSelectedStitchRemarks]: any = useState({});
  const [selectedMeasureRemarks, setSelectedMeasureRemarks]: any = useState({});
  const [selectedHandRemarks, setSelectedHandRemarks]: any = useState({});
  const [selectedFoilRemarks, setSelectedFoilRemarks]: any = useState({});
  const [remarksState, setRemarksState]: any = useState({});

  const [jobPar, setJobPar]: any = useState();
  const [stitchPar, setStitchPar]: any = useState();
  const [handPar, setHandPar]: any = useState();
  const [foilPar, setFoilPar]: any = useState();
  const [costPar, setCostPar]: any = useState();

  const [isJobSelect, setIsJobSelect] = useState(false);
  const [isMeasureSelect, setIsMeasureSelect] = useState(false);
  const [isCostSelect, setIsCostSelect] = useState(false);
  const [isFoilSelect, setIsFoilSelect] = useState(false);
  const [isStitchSelect, setIsStitchSelect] = useState(false);
  const [isHandSelect, setIsHandSelect] = useState(false);

  const [updateDressRateCard] = useUpdateDressRateCardMutation();
  const [addDressRateCard] = useAddDressRateCardMutation();
  const [measureTableTotal, setMeasureTableTotal]: any = useState();
  const [shortNet, setShortNet]: any = useState("");
  const [plain, setPlain]: any = useState("");
  const [sizeType, setSizeType]: any = useState({});
  const [size, setSize]: any = useState({});
  const [sizeOptions, setSizeOptions]: any = useState([]);
  const [isValue, setIsValue]: any = useState([]);
  const [diamondTotal, setDimondTotal]: any = useState({});

  // reference props
  const jobWorkRef: any = useRef(null);
  const stitchingRef: any = useRef(null);
  const foilRef: any = useRef(null);
  const handWorkRef: any = useRef(null);

  //diamonds

  useEffect(() => {
    if (isCardCopy) {
      let copyId = localStorage.getItem("copy");

      if (rateCardData?.dressCard) {
        let apiData = rateCardData?.dressCard?.filter((item: any) => {
          return item._id === copyId;
        })[0];
        setCardData({
          ...apiData,
          image: "",
        });
      } else {
        dressTrigger(copyId);
      }
    } else if (isDressCardUpdate) {
      if (rateCardData?.dressCard) {
        let apiData = rateCardData?.dressCard?.filter((item: any) => {
          return item._id === id;
        })[0];
        setCardData(apiData);
      } else {
        dressTrigger(id);
      }
    } else {
      setCardData({});

      const date: any = setDefaultDate();
      setCardData({
        ...cardData,
        date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
        image: "",
      });
    }
  }, [isCardCopy, isDressCardUpdate, fabricState]);

  useEffect(() => {
    if (isDressCardUpdate || isCardCopy) {
      if (isDressSuccess && !isDressFetching) {
        let apiData = dressResult?.data?.data?.dressCard?.[0];

        if (isDressCardUpdate) {
          setCardData(apiData);
        } else {
          const date: any = setDefaultDate();
          setCardData({
            ...apiData,
            date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
            image: "",
          });
        }
        if (apiData?.value && Array.isArray(apiData?.value)) {
          setIsValue(apiData.value);
        }
        let workdata: any = [];
        apiData?.works?.map((item: any) => {
          let record = {
            _id: item?._id,
            itemId: item?.item?._id,
            remark: item?.remark,
            stitch: item?.stitch,
            area: item?.area,
            rate: item?.rate,
            Pcs: item?.Pcs,
            code: item?.code,
            totalQty: item?.totalQty,
            valueAdd: item?.valueAdd,
            totalAmt: item?.totalAmt,
          };

          workdata.push(record);
        });

        setJobWorkTableData(workdata);

        let foilData: any = [];
        if (Object.keys(apiData?.foil[0]).length > 0) {
          apiData?.foil?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              colorId: item?.color?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            foilData.push(object);
          });
        }
        setFoilTableData(foilData);

        let dyeData: any = [];
        if (apiData?.daying?.[0]) {
          if (Object.keys(apiData?.daying?.[0]).length > 0) {
            apiData?.daying?.forEach((item: any) => {
              let object = {
                _id: item?._id,
                colorId: item?.color?._id,
                itemId: item?.item?._id,
                remarkId: item?.remark?._id,
                qty: item?.qty,
                rate: item?.rate,
                amount: item?.amount,
              };
              dyeData.push(object);
            });
          }
        }

        setDyeTableData(dyeData);

        let handData: any = [];
        if (Object.keys(apiData?.hand[0])?.length > 0) {
          apiData?.hand?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              head: Number(item?.head),
              diamond: item?.diamond,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            handData.push(object);
          });
        }
        setHandTableData(handData);

        let measureData: any = [];
        apiData?.measurements?.map((item: any) => {
          let measureRecord = {
            _id: item?._id,
            itemId: item?.item?._id,
            clotheId: item?.clothe?._id,
            fabricColorId: item?.fabricColor?._id,
            remark: item?.remark,
            PIM: item?.PIM,
            Pcs: item?.Pcs,
            measurement: item?.measurement,
            totalQty: item?.totalQty,
            fabrics: item?.fabrics,
            meter: item?.meter,
          };
          measureData.push(measureRecord);
        });

        setMeasureTableData(measureData);

        let costData: any = [];
        apiData?.costing?.map((item: any) => {
          let costRecord = {
            _id: item?._id,
            itemId: item?.item?._id,
            remark: item?.remark,
            totalQty: item?.totalQty,
            clotheId: item?.clothe?._id,
            PcM: item?.PcM,
            rate: item?.rate,
            amount: item?.amount,
          };

          costData.push(costRecord);
        });

        setCostTableData(costData);

        let stitchData: any = [];
        if (Object.keys(apiData?.stitching?.[0]).length > 0) {
          apiData?.stitching?.map((item: any) => {
            let statching = {
              _id: item?._id,
              materialId: item?.materialId,
              remark: item?.remark,
              itemId: item?.item?._id,
              amount: item?.amount,
              meter: item?.meter,
              qty: item?.qty,
              rate: item?.rate,
            };
            stitchData.push(statching);
          });
        }
        setStatchingTableData(stitchData);

        setImages(apiData?.image);
      }
    } else {
      setCardData({});
      const date: any = setDefaultDate();
      setCardData({
        ...cardData,
        date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
      });
    }
  }, [isDressSuccess, isDressFetching, fabricState]);
  const clearData = () => {
    setCardData({
      designNo: "0-0",
      date: "",
      image: "",
    });
    setJobWorkTableData([]);
    setJobWorkData({
      srNo: "",
      area: "",
      itemId: "",
      reamrksId: "",
      Pcs: "",
      code: "",
      stitch: "",
      totalQty: "",
      valueAdd: "",
      totalAmt: "",
      rate: "",
    });
    setSelectedJobItem({});
    setSelectedRemarks({});
    setIsJobUpdate(false);
    setMeasureTableData([]);
    setMeasureData({
      PIM: "",
      totalQty: "",
      measurement: "",
    });
    setShortNet("");
    setPlain("");
    setSelectedCloth({});
    setSelectedMeasureRemarks({});
    setSelectedFabric({});
    setCostTableData([]);
    setCostData({
      PcM: "",
      rate: "",
    });
    setSelectedCostRemarks({});
    setFoilTableData([]);
    setFoilData({
      qty: "",
      rate: "",
      amount: "",
    });
    setSelectedFoil({});
    setSelectedFoilItem({});
    setSelectedFoilRemarks({});

    setIsFoilUpdate(false);
    setDyeTableData([]);
    setDyeData({
      qty: "",
      rate: "",
      amount: "",
    });
    setSelectedDye({});
    setSelectedDyeItem({});
    setSelectedDyeRemarks({});

    setIsDyeUpdate(false);
    setStatchingTableData([]);

    setStatchingData({
      amount: "",
      meter: "",
      qty: "",
      rate: "",
    });
    setSelectedStitchRemarks({});
    setSelectedItem({});
    setSelectedMaterial({});

    setIsStatchUpdate(false);
    setHandTableData([]);
    setHandData({
      qty: "",
      rate: "",
      amount: "",
      head: "",
      diamond: [],
    });
    setSelectedHandItem({});
    setSelectedHandRemarks({});

    setIsHandUpdate(false);
    setSelectedParty({});
    setImages(null);
    setIsJobSelect(false);
    setIsMeasureSelect(false);
    setIsCostSelect(false);
    setIsStitchSelect(false);
    setIsFoilSelect(false);
    setIsHandSelect(false);
    setJobPar();
    setStitchPar();
    setCostPar();
    setFoilPar();
    setHandPar();
  };
  useEffect(() => {
    clearData();
  }, [isDressCardUpdate]);
  useEffect(() => {
    if (isSuccess && !isFetching) {
      let getAllParty = result.data?.data?.party.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setParty(getAllParty ? getAllParty : []);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
      let clothes: any = [];
      getAllCloth?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        clothes[id] = label;
      });

      setClothState(clothes);
    }
  }, [isClothSuccess, isClothFetching]);

  useEffect(() => {
    if (isGetRemarksSuccess && !isGetRemarksFetching) {
      let getAllRemarks = getRemarkResult.data?.data?.remark?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setRemarks(getAllRemarks ? getAllRemarks : []);
      let remarks: any = {};
      getAllRemarks?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        remarks[id] = label;
      });
      setRemarksState(remarks);
    }
  }, [isGetRemarksSuccess, isGetRemarksFetching]);

  useEffect(() => {
    if (isFoilColorSuccess && !isFoilColorFetching) {
      let getAllFoil = getFoilColorResult.data?.data?.foilColor?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setFoil(getAllFoil ? getAllFoil : []);
      let foils: any = {};
      getAllFoil?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        foils[id] = label;
      });
      setFoilState(foils);
    }
  }, [isFoilColorSuccess, isFoilColorFetching]);

  useEffect(() => {
    if (isDiamondSuccess && !isDiamondFetching) {
      let getAllDiamond = diamondResult.data?.data?.diamond?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setDiamond(getAllDiamond ? getAllDiamond : []);
      let diamonds: any = {};
      getAllDiamond?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        diamonds[id] = label;
      });
      setDiamondState(diamonds);
    }
  }, [isDiamondSuccess, isDiamondFetching]);
  const creatableOptions = Diamond.filter(
    (option: any) => !isOldDiamond.includes(option?.label)
  );
  useEffect(() => {
    if (isFoilSuccess && !isFoilFetching) {
      let getAllFoil = foilResult.data?.data?.foilColor?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setFoil(getAllFoil ? getAllFoil : []);
      let foils: any = {};
      getAllFoil?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        foils[id] = label;
      });
      setFoilState(foils);
    }
  }, [isFoilSuccess, isFoilFetching]);

  useEffect(() => {
    if (isGetDiamondSuccess && !isGetDiamondFetching) {
      let getAllDiamond = getDiamondResult.data?.data?.diamond?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setDiamond(getAllDiamond ? getAllDiamond : []);
      let diamonds: any = {};
      getAllDiamond?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        diamonds[id] = label;
      });
      setDiamondState(diamonds);
    }
  }, [isGetDiamondSuccess, isGetDiamondFetching]);

  useEffect(() => {
    if (isgetItemSuccess && !isgetItemFetching) {
      let item = getItemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      if (item) {
        setItem(item);
      }
    }
  }, [isgetItemSuccess, isgetItemFetching]);

  useEffect(() => {
    if (isgetPartyNameSuccess && !isgetPartyNameFetching) {
      let party = getPartyNameResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (party) {
        setParty(party);
      }
    }
  }, [isgetPartyNameSuccess, isgetPartyNameFetching]);

  useEffect(() => {
    if (isgetMaterialSuccess && !isgetMaterialFetching) {
      let getAllMaterial = getMaterialResult.data?.data?.material?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setMaterial(getAllMaterial ? getAllMaterial : []);
      let materials: any = {};
      getAllMaterial?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;
        materials[id] = label;
      });
      setMaterialState(materials);
    }
  }, [isgetMaterialSuccess, isgetMaterialFetching]);

  useEffect(() => {
    if (isClothPartySuccess && !isClothPartyFetching) {
      let getAllClothParty = clothPartyResult.data?.data?.clotheParty.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setClothParty(getAllClothParty ? getAllClothParty : []);
    }
  }, [isClothPartySuccess, isClothPartyFetching]);

  useEffect(() => {
    if (isFabricSuccess && !isFabricFetching) {
      let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );

      setFabric(getAllFabric ? getAllFabric : []);

      let fabricColors: any = {};

      getAllFabric?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        fabricColors[id] = label;
      });

      setFabricState(fabricColors);
    }
  }, [isFabricSuccess, isFabricFetching]);

  useEffect(() => {
    if (isMaterialSuccess && !isMaterialFetching) {
      let getAllMaterial = materialResult.data?.data?.material?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setMaterial(getAllMaterial ? getAllMaterial : []);

      let materials: any = {};

      getAllMaterial?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        materials[id] = label;
      });

      setMaterialState(materials);
    }
  }, [isMaterialSuccess, isMaterialFetching]);

  useEffect(() => {
    if (isItemSuccess && !isItemFetching) {
      let getAllItem = itemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setItem(getAllItem ? getAllItem : []);
      let items: any = {};

      getAllItem?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        items[id] = label;
      });
      setItemState(items);
    }
  }, [isItemSuccess, isItemFetching]);

  useEffect(() => {
    if (isgetClothSuccess && !isgetClothFetching) {
      let cloth = getClothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (cloth) {
        setCloth(cloth);
      }
    }
  }, [isgetClothSuccess, isgetClothFetching]);

  const getData = (data: any) => {
    setSelectedParty(data);
    setParty([...Party, data]);
    setShowAddParty(false);
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const getClothPartyData = (data: any) => {
    setClothParty([...clothParty, data]);
    setShowAddClothParty(false);
  };

  const getDiamondData = (data: any) => {
    setDiamond([...Diamond, data]);
    setShowAddDiamond(false);
  };

  const getFoilData = (data: any) => {
    setSelectedFoil(data);
    setFoil([...Foil, data]);
    setShowAddFoil(false);
  };

  const getRemarksData = (data: any, type: any) => {
    if (type === "") {
      setSelectedRemarks(data);
    } else if (type === "measure") {
      setSelectedMeasureRemarks(data);
    } else if (type === "cost") {
      setSelectedCostRemarks(data);
    } else if (type === "stitch") {
      setSelectedStitchRemarks(data);
    } else if (type === "foil") {
      setSelectedFoilRemarks(data);
    } else if (type === "hand") {
      setSelectedHandRemarks(data);
    } else if (type === "dye") {
      setSelectedDyeRemarks(data);
    }
    setRemarks([...Remarks, data]);
    setShowAddRemarks(false);
  };

  const [itemType, setItemType] = useState("");

  const getItemData = (data: any, type: any) => {
    if (type === "") {
      setSelectedItem(data);
    } else if (type === "job") {
      setSelectedJobItem(data);
    } else if (type === "foil") {
      setSelectedFoilItem(data);
    } else if (type === "dye") {
      setSelectedDyeItem(data);
    } else if (type === "hand") {
      setSelectedHandItem(data);
    }
    setItem([...Item, data]);
    setShowAddItem(false);
  };

  const getFabricData = (data: any) => {
    setSelectedFabric(data);
    setFabric([...Fabric, data]);
    setShowAddFabric(false);
  };

  const getMaterialData = (data: any) => {
    setSelectedMaterial(data);
    setMaterial([...Material, data]);
    setShowAddMaterial(false);
  };

  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "party":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "item":
        let selectedItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedItem(selectedItem);
        break;
      case "foilitem":
        let selectedFoilItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedFoilItem(selectedFoilItem);
        break;
      case "dyeitem":
        let selectedDyeItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedDyeItem(selectedDyeItem);
        break;
      case "handitem":
        let handitem = {
          label: e.label,
          value: e.value,
        };
        setSelectedHandItem(handitem);
        break;
      case "jobitem":
        let selectedJobItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedJobItem(selectedJobItem);
        break;
      case "remarks":
        let remarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedRemarks(remarks);
        break;
      case "measureremarks":
        let measureremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedMeasureRemarks(measureremarks);
        break;
      case "costremarks":
        let costremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedCostRemarks(costremarks);
        break;
      case "stitchremarks":
        let stitchremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedStitchRemarks(stitchremarks);
        break;
      case "material":
        let selecetdMaterial = {
          label: e.label,
          value: e.value,
        };
        setSelectedMaterial(selecetdMaterial);
        break;
      case "diamond":
        let additional = {
          label: e[e.length - 1].label,
          value: e[e.length - 1].value,
          qty: "",
        };
        setIsOldDiamond((prevValue: any) => [...prevValue, additional]);
        break;
      case "foilColor":
        let foilColor = {
          label: e.label,
          value: e.value,
        };
        setSelectedFoil(foilColor);
        break;
      case "dyeColor":
        let dyeColor = {
          label: e.label,
          value: e.value,
        };
        setSelectedDye(dyeColor);
        break;
      case "foilremarks":
        let foilremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedFoilRemarks(foilremarks);
        break;
      case "dyeremarks":
        let dyeremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedDyeRemarks(dyeremarks);
        break;
      case "handremarks":
        let handremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedHandRemarks(handremarks);
        break;
      case "fabricColor":
        let fabricremarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedFabric(fabricremarks);
        break;
    }
  };
  useEffect(() => {
    setJobWorkData({
      ...jobWorkData,
      rate: Math.ceil(
        (Number(jobWorkData?.stitch) / Number(jobWorkData?.area)) *
          Number(jobWorkData?.code)
      ),
    });
    setErrorMessage({
      ...errorMessage,
      rate: "",
    });
  }, [jobWorkData?.stitch, jobWorkData?.area, jobWorkData?.code]);

  useEffect(() => {
    if (jobWorkData?.valueAdd) {
      setJobWorkData({
        ...jobWorkData,
        totalAmt: Math.ceil(
          Number(jobWorkData?.totalQty) * Number(jobWorkData?.rate) +
            Number(jobWorkData?.totalQty) * Number(jobWorkData?.valueAdd)
        ),
      });
    } else {
      setJobWorkData({
        ...jobWorkData,
        totalAmt: Math.ceil(
          Number(jobWorkData?.totalQty) * Number(jobWorkData?.rate)
        ),
      });
    }
    setErrorMessage({
      ...errorMessage,
      totalAmt: "",
    });
  }, [jobWorkData?.totalQty, jobWorkData?.rate, jobWorkData?.valueAdd]);

  useEffect(() => {
    setStatchingData({
      ...statchingData,
      amount: Number(statchingData?.qty) * Number(statchingData?.rate),
    });
  }, [statchingData?.qty, statchingData?.rate]);

  useEffect(() => {
    if (isFabricSuccess && !isFabricFetching) {
      let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );

      setFabric(getAllFabric ? getAllFabric : []);

      let fabricColors: any = {};

      getAllFabric?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        fabricColors[id] = label;
      });

      setFabricState(fabricColors);
    }
  }, [isFabricSuccess, isFabricFetching]);

  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      var invoices: any = [];
      invoiceResult &&
        invoiceResult.data?.data?.map((invoice: any) => {
          if (invoices.indexOf(invoice?.invoiceNo) === -1) {
            invoices.push(invoice?.invoiceNo);
            if (id) {
              // To select the invoice number when editing
              if (id === invoice?._id) {
                setSelectedInvoice(invoice?.invoiceNo);
                allInvoicetrigger({ invoiceNo: Number(invoice?.invoiceNo) });
              }
            } else {
              setSelectedInvoice(invoices[0]);
              allInvoicetrigger({ invoiceNo: Number(invoices[0]) });
            }
          }
        });
      setInvoice(invoices);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);

  useEffect(() => {
    if (isAllInvoiceSuccess && !isAllInvoiceFetching) {
      var data: any = [];
      setNewRateCard(allInvoiceResult.data.data);
      let status = false;
      // allInvoiceResult && allInvoiceResult.data?.data?.map((invoice: any) => {
      //     if (id) {
      //         if (id == invoice?._id) {
      //             data.push(invoice?.invoiceNo + "-" + invoice?.cardNo)
      //             setSelectedInvoiceCard(invoice?.invoiceNo + "-" + invoice?.cardNo)
      //             setSelectedInvoice(invoice?.invoiceNo)
      //             console.log("all")
      //             status = true;
      //         }
      //     }

      // })
      allInvoiceResult &&
        allInvoiceResult.data?.data?.map((invoice: any) => {
          data.push(invoice?.cardNo);
          if (id && isDressCardUpdate) {
            if (id === invoice?._id) {
              setSelectedInvoiceCard(invoice?.cardNo);
              setSelectedInvoice(invoice?.invoiceNo);
              status = true;
            }
          } else {
            setSelectedInvoiceCard(data[0]);
            status = true;
          }
        });
      if (!status) {
        navigate(`/ratecard/dress`);
      }
      setInvoiceNo(data);
    }
  }, [isAllInvoiceSuccess, isAllInvoiceFetching]);

  useEffect(() => {
    if (isRemarksSuccess && !isRemarksFetching) {
      let getAllRemarks = remarksResult.data?.data?.remark?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setRemarks(getAllRemarks ? getAllRemarks : []);
      let remarks: any = {};
      getAllRemarks?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        remarks[id] = label;
      });
      setRemarksState(remarks);
    }
  }, [isRemarksSuccess, isRemarksFetching]);
  useEffect(() => {
    var set: any = [];
    const allDiamonds: any = [];
    handTableData?.map((item: any) => {
      return item?.diamond?.map((textbox: any, index: any) => {
        if (!allDiamonds.includes(textbox?.title?._id)) {
          allDiamonds.push(textbox?.title?._id);
          set.push({
            value: textbox?.title?._id,
            label: textbox?.title?.name,
            qty: "",
          });
        }
      });
    });
    setIsOldDiamond(set);
    if (handTableData?.length <= 0) {
      setIsValue([]);
    }
  }, [handTableData]);
  const handleInvoices = (e: any) => {
    setSelectedInvoice(e.target.value);
    setIsDressCardUpdate(false);
    allInvoicetrigger({ invoiceNo: Number(e.target.value) });
  };

  const handleInvoiceChange = (e: any) => {
    newRateCard?.map((item: any) => {
      if (
        item.invoiceNo + "-" + item.cardNo ===
        selectedInvoice + "-" + e.target.value
      ) {
        setSelectedInvoiceCard(e.target.value);
        if (item._id) {
          navigate(`/ratecard/dress/${item?._id}`);
          dressTrigger(item._id);
        } else {
          navigate(`/ratecard/dress/add`);
        }
      }
    });
  };

  const handleJobWorkChanges = (e: any) => {
    setJobWorkData({
      ...jobWorkData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleStatchingChanges = (e: any) => {
    setStatchingData({
      ...statchingData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFoilChanges = (e: any) => {
    setFoilData({
      ...foilData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDyeChanges = (e: any) => {
    setDyeData({
      ...dyeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleHandChanges = (e: any) => {
    setHandData({
      ...handData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMeasureChanges = (e: any) => {
    setMeasureData({
      ...measureData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCostChanges = (e: any) => {
    setCostData({
      ...costData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChanges = (e: any) => {
    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSizeTypeChanges = (e: any) => {
    setSizeType({
      label: e.label,
      value: e.value,
    });
    if (e.value === "Number") {
      setSizeOptions(numberOptions);
      setSize(numberOptions[0]);
    }
    if (e.value === "Farma") {
      setSizeOptions(farmaOptions);
      setSize(farmaOptions[0]);
    }
  };
  useEffect(() => {
    if (isDressCardUpdate || isCardCopy) {
      invoicetrigger("");
      let workdata: any = [];
      cardData?.works?.map((item: any) => {
        let record = {
          _id: item?._id,
          itemId: item?.item?._id,
          stitch: item?.stitch,
          remark: item?.remark,
          area: item?.area,
          rate: item?.rate,
          Pcs: item?.Pcs,
          code: item?.code,
          totalQty: item?.totalQty,
          valueAdd: item?.valueAdd,
          totalAmt: item?.totalAmt,
        };
        workdata.push(record);
      });

      setJobWorkTableData(workdata);

      let foilData: any = [];

      if (cardData?.foil) {
        if (Object.keys(cardData?.foil[0]).length > 0) {
          cardData?.foil?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              colorId: item?.color?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            foilData.push(object);
          });
        }
      }

      setFoilTableData(foilData);

      let dyeData: any = [];

      if (cardData?.daying?.[0]) {
        if (Object.keys(cardData?.daying?.[0]).length > 0) {
          cardData?.daying?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              colorId: item?.color?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            dyeData.push(object);
          });
        }
      }

      setDyeTableData(dyeData);

      let handData: any = [];
      if (cardData?.hand) {
        if (Object.keys(cardData?.hand[0]).length > 0) {
          cardData?.hand?.forEach((item: any, index: any) => {
            let object = {
              _id: item?._id,
              itemId: item?.item?._id,
              remarkId: item?.remark?._id,
              head: Number(item?.head),
              diamond: item?.diamond,
              qty: item?.qty,
              rate: item?.rate,
              amount: item?.amount,
            };
            handData.push(object);
          });
        }
      }
      setHandTableData(handData);

      let measureData: any = [];
      cardData?.measurements?.map((item: any) => {
        let measureRecord = {
          _id: item?._id,
          itemId: item?.item?._id,
          clotheId: item?.clothe?._id,
          fabricColorId: item?.fabricColor?._id,
          remark: item?.remark,
          PIM: item?.PIM,
          Pcs: item?.Pcs,
          measurement: item?.measurement,
          totalQty: item?.totalQty,
          fabrics: item?.fabrics,
          meter: item?.meter,
        };
        measureData.push(measureRecord);
      });

      setMeasureTableData(measureData);
      setShortNet(cardData?.shortNet);
      setPlain(cardData?.plain);
      let costData: any = [];
      cardData?.costing?.map((item: any) => {
        let costRecord = {
          _id: item?._id,
          itemId: item?.item?._id,
          totalQty: item?.totalQty,
          clotheId: item?.clothe?._id,
          remark: item?.remark,
          PcM: item?.PcM,
          rate: item?.rate,
          amount: item?.amount,
        };
        costData.push(costRecord);
      });

      setCostTableData(costData);

      let stitchData: any = [];
      if (cardData?.stitching) {
        if (Object?.keys?.(cardData?.stitching?.[0]).length > 0) {
          cardData?.stitching?.map((item: any) => {
            let statching = {
              _id: item?._id,
              materialId: item?.materials?._id,
              remark: item?.remark,
              itemId: item?.item?._id,
              amount: item?.amount,
              meter: item?.meter,
              qty: item?.qty,
              rate: item?.rate,
            };
            stitchData.push(statching);
          });
        }
      }
      setStatchingTableData(stitchData);

      setImages(cardData?.image);
      setJobWorkData({
        srNo: "",
        area: "",
        itemId: "",
        remark: "",
        Pcs: "",
        code: "",
        stitch: "",
        totalQty: "",
        valueAdd: "",
        totalAmt: "",
      });
      setSelectedParty({
        label: cardData?.party?.name,
        value: cardData?.party?._id,
      });
      setSizeType({
        label: cardData?.size?.type,
        value: cardData?.size?.type,
      });
      setSize({
        label: cardData?.size?.value,
        value: cardData?.size?.value,
      });
      if (cardData?.size?.type === "Number") {
        setSizeOptions(numberOptions);
      } else {
        setSizeOptions(farmaOptions);
      }
      setSelectedRemarks({});
      setSelectedItem({});
      setIsJobUpdate(false);
      setIsJobSelect(cardData?.finalCosting?.work?.isSelected);
      setIsMeasureSelect(cardData?.finalCosting?.measurements?.isSelected);
      setIsCostSelect(cardData?.finalCosting?.costing?.isSelected);
      setIsStitchSelect(cardData?.finalCosting?.stitching?.isSelected);
      setIsFoilSelect(cardData?.finalCosting?.foil?.isSelected);
      setIsDyeSelect(cardData?.finalCosting?.daying?.isSelected);
      setIsHandSelect(cardData?.finalCosting?.hand?.isSelected);
      cardData?.finalCosting?.work?.ParPct
        ? setJobPar(cardData?.finalCosting?.work?.ParPct)
        : setJobPar();
      cardData?.finalCosting?.stitching?.ParPct
        ? setStitchPar(cardData?.finalCosting?.stitching?.ParPct)
        : setStitchPar();
      cardData?.finalCosting?.costing?.ParPct
        ? setCostPar(cardData?.finalCosting?.costing?.ParPct)
        : setCostPar();
      cardData?.finalCosting?.foil?.ParPct
        ? setFoilPar(cardData?.finalCosting?.foil?.ParPct)
        : setFoilPar();
      cardData?.finalCosting?.daying?.ParPct
        ? setDyePar(cardData?.finalCosting?.daying?.ParPct)
        : setDyePar();
      cardData?.finalCosting?.hand?.ParPct
        ? setHandPar(cardData?.finalCosting?.hand?.ParPct)
        : setHandPar();
    } else {
      let date = document?.getElementById("date") as HTMLInputElement | any;
      let today = new Date() as any;
      let month = today?.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day =
        today?.getDate() < 10 ? "0" + today?.getDate() : today?.getDate();

      date.value = today?.getFullYear() + "-" + month + "-" + day;
    }
  }, [cardData]);

  useEffect(() => {
    let total = 0;
    jobWorkTableData?.forEach((item: any) => {
      total = total + Number(item?.totalAmt);
    });
    setJobTotal(Math.floor(total));
  }, [jobWorkTableData]);

  useEffect(() => {
    let total = 0;
    statchingTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setStitchTotal(Math.floor(total));
  }, [statchingTableData]);

  useEffect(() => {
    let total = 0;
    foilTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setFoilTotal(Math.floor(total));
  }, [foilTableData]);

  useEffect(() => {
    let total = 0;
    dyeTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setDyeTotal(Math.floor(total));
  }, [dyeTableData]);
  const [amountTotal, setAmountTotal]: any = useState(0);
  useEffect(() => {
    let totalAmount = 0;
    handTableData?.forEach((item: any) => {
      if (item?.amount !== "") {
        totalAmount = totalAmount + Number(item?.amount);
      }
    });
    setAmountTotal(Math.floor(totalAmount));
  }, [handTableData]);

  useEffect(() => {
    let totalDiamond: any = {};
    handTableData?.forEach((item: any) => {
      item?.diamond?.map((i: any, index: any) => {
        totalDiamond[i?.titleId] = {
          totalQty:
            (totalDiamond[i?.titleId]?.totalQty
              ? totalDiamond[i?.titleId]?.totalQty
              : 0) + Math.floor(Number(item?.head) * Number(i?.qty)),
          total: (totalDiamond[i?.titleId]?.total || 0) + Number(i?.qty),
        };
      });
      setDimondTotal(totalDiamond);
    });
  }, [handTableData]);

  useEffect(() => {
    let total: any = 0;
    measureTableData?.forEach((item: any) => {
      total = total + Number(item?.meter);
    });

    if (shortNet !== "") {
      if (plain !== "") {
        setMeasureTableTotal(
          parseFloat(total + Number(shortNet) + Number(plain)).toFixed(2)
        );
      } else {
        setMeasureTableTotal(parseFloat(total + Number(shortNet).toFixed(2)));
      }
    } else {
      if (plain !== "") {
        setMeasureTableTotal(parseFloat((total + Number(plain)).toFixed(2)));
      } else {
        setMeasureTableTotal(parseFloat(total.toFixed(2)));
      }
    }
  }, [measureTableData]);

  useEffect(() => {
    let total = 0;
    measureTableData?.forEach((item: any) => {
      total = total + Number(item?.meter);
    });
    if (plain !== "") {
      setMeasureTableTotal(
        parseFloat((total + Number(shortNet) + Number(plain)).toFixed(2))
      );
    } else {
      setMeasureTableTotal(parseFloat((total + Number(shortNet)).toFixed(2)));
    }
  }, [shortNet]);

  useEffect(() => {
    let total = 0;
    measureTableData?.forEach((item: any) => {
      total = total + Number(item?.meter);
    });
    if (shortNet !== "") {
      setMeasureTableTotal(
        parseFloat((total + Number(shortNet) + Number(plain)).toFixed(2))
      );
    } else {
      setMeasureTableTotal(parseFloat((total + Number(plain)).toFixed(2)));
    }
  }, [plain]);

  useEffect(() => {
    setFoilData({
      ...foilData,
      amount: Math.floor(Number(foilData?.qty) * Number(foilData?.rate)),
    });
  }, [foilData?.qty, foilData?.rate]);

  useEffect(() => {
    setDyeData({
      ...dyeData,
      amount: Math.floor(Number(dyeData?.qty) * Number(dyeData?.rate)),
    });
  }, [dyeData?.qty, dyeData?.rate]);

  useEffect(() => {
    setHandData({
      ...handData,
      amount: Math.floor(
        (Number(handData?.qty) * Number(handData?.rate)) / 100
      ),
    });
  }, [handData?.qty, handData?.rate]);

  useEffect(() => {
    let total = 0;
    costTableData?.forEach((item: any) => {
      total = total + Number(item?.amount);
    });

    setCostTotal(Math.floor(total));
  }, [costTableData]);

  useEffect(() => {
    if (isAllDressSuccess && !isAllDressFetching) {
      if (!isDressCardUpdate) {
        navigate(`/ratecard/dress/${savedId}`);
      }
    }
  }, [isAllDressSuccess, isAllDressFetching]);
  const handlepostImage = (image: any) => {
    setErrorMessage({
      ...errorMessage,
      image: "",
    });
    imageUpload(image[0], (url: any) => {
      setImages(url);
    });
  };

  const [imageCopy, setImageCopy]: any = useState([]);
  const imageDelete = (imageFile: any) => {
    setImageCopy([...imageCopy, imageFile]);
    setImages("");
    // s3.deleteObject({
    //     Bucket: bucketName,
    //     Key: imageFile.replace("https://cnt-satv.s3.ap-south-1.amazonaws.com/", "")
    // }, function (err: any, data: any) {
    //     if (data)
    //         setImages("")
    //     return data
    // })
  };

  const deleteImageOnSaveUpdate = () => {
    if (imageCopy?.length > 0) {
      imageCopy?.map((image: any) => {
        s3.deleteObject({
          Bucket: bucketName,
          Key: image?.replace(
            "https://cnt-satv.s3.ap-south-1.amazonaws.com/",
            ""
          ),
        });
      });
      setImageCopy([]);
    }
  };

  const imageUpload = async (imageFile: any, callback: any) => {
    const rawBytes = uuid();
    const imageName =
      rawBytes.toString() + "." + imageFile?.file?.name?.split(".")[1];

    const uploadURL = await s3.getSignedUrlPromise("putObject", {
      Bucket: bucketName,
      Key: "Rate-cards/" + imageName,
      Expires: 60,
    });

    await fetch(uploadURL, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: imageFile.file,
    });

    const imageUrl = uploadURL.split("?")[0];
    callback(imageUrl);
  };

  const [isUpdating, setIsUpdating]: any = useState(false);

  const updateData = () => {
    setIsUpdating(true);
    const transformedData = handTableData?.map((item: any) => {
      if (item?.amount) {
        const diamondData = item?.diamond?.map((i: any) => i?._id).join(", ");
        return {
          ...item,
          diamondId: diamondData,
          diamond: undefined,
        };
      } else {
        if (item && item?.diamond) {
          const diamondData = item?.diamond?.map((i: any) => ({
            titleId: i?.titleId,
            qty: i?.qty,
            title: undefined,
          }));
          return {
            ...item,
            diamondId: undefined,
            diamond: diamondData,
          };
        }
        return item;
      }
    });
    let finalData = {
      partyId: selectedParty.value,
      designNo: "D-" + cardData?.designNo?.replace("D-", ""),
      date: cardData?.date,
      image: images,
      works: jobWorkTableData,
      measurements: measureTableData,
      costing: costTableData,
      stitching: statchingTableData,
      shortNet: shortNet,
      plain: plain,
      size: {
        type: sizeType?.value,
        value: size?.value,
      },
      foil: foilTableData,
      daying: dyeTableData,
      hand: transformedData,
      value: isValue,
      finalCosting: {
        work: {
          ParPct: jobPar,
          amount:
            jobTotal + Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100)),
          isSelected: isJobSelect ? isJobSelect : false,
          itemName: "Job Works",
        },
        measurements: {
          totalMeter: measureTableTotal,
          isSelected: isMeasureSelect ? isMeasureSelect : false,
          itemName: "Fabric Measurements",
        },
        stitching: {
          ParPct: stitchPar,
          amount:
            stitchTotal +
            Math.ceil(stitchTotal * ((stitchPar ? stitchPar : 0) / 100)),
          isSelected: isStitchSelect ? isStitchSelect : false,
          itemName: "Stitching",
        },
        costing: {
          ParPct: costPar,
          amount:
            costTotal + Math.ceil(costTotal * ((costPar ? costPar : 0) / 100)),
          isSelected: isCostSelect ? isCostSelect : false,
          itemName: "Fabric Costing",
        },
        foil: {
          ParPct: foilPar,
          amount:
            foilTotal + Math.ceil(foilTotal * ((foilPar ? foilPar : 0) / 100)),
          isSelected: isFoilSelect ? isFoilSelect : false,
          itemName: "Foil Costing",
        },
        daying: {
          ParPct: dyePar,
          amount:
            dyeTotal + Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100)),
          isSelected: isDyeSelect ? isDyeSelect : false,
          itemName: "Dye Costing",
        },
        hand: {
          ParPct: handPar,
          amount: handTableData[0]?.amount
            ? Math.ceil(
                amountTotal + amountTotal * ((handPar ? handPar : 0) / 100)
              )
            : pointTotal +
              Math.ceil(pointTotal * ((handPar ? handPar : 0) / 100)),
          isSelected: isHandSelect ? isHandSelect : false,
          itemName: "HandWork Costing",
        },
      },
    };

    updateDressRateCard({
      payload: finalData,
      query: {
        drId: id,
      },
    }).then((data: any) => {
      setIsUpdating(false);
      if (data.error) {
        Swal.fire({
          toast: true,
          icon: "error",
          title: data.error.data.message,
          position: "top-end",
          showConfirmButton: false,
          timer: ERROR_MESSAGE_TIMEOUT,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Great",
          text: "Dress Rate Card Successfully Updated !!",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,
        });
        allDressTrigger("");
        deleteImageOnSaveUpdate();
      }
    });
  };

  const addData = () => {
    setIsUpdating(true);
    let errorData = {
      party: "",
      designNo: "",
      image: "",
      work: "",
      measure: "",
    };

    if (Object.keys(selectedParty).length == 0) {
      errorData["party"] = "Party is required";
    } else {
      errorData["party"] = "";
    }
    if (!cardData?.designNo) {
      errorData["designNo"] = "Design No is required";
    } else {
      errorData["designNo"] = "";
    }
    if (!images) {
      errorData["image"] = "Image is required";
    } else {
      errorData["image"] = "";
    }
    if (jobWorkTableData?.length == 0) {
      errorData["work"] = "Job Work is Required";
    } else {
      errorData["work"] = "";
    }
    if (!measureTableData?.[0]?.clotheId) {
      errorData["measure"] = "Fabric Measurement is Required";
    } else {
      errorData["measure"] = "";
    }
    setErrorMessage(errorData);
    const transformedData = handTableData?.map((item: any) => {
      if (item?.amount) {
        const diamondData = item?.diamond?.map((i: any) => i?._id).join(", ");
        return {
          ...item,
          diamondId: diamondData,
          diamond: undefined,
        };
      } else {
        if (item && item?.diamond) {
          const diamondData = item?.diamond?.map((i: any) => ({
            titleId: i?.titleId,
            qty: i?.qty,
            title: undefined,
          }));
          return {
            ...item,
            diamondId: undefined,
            diamond: diamondData,
          };
        }
        return item;
      }
    });
    let finalData = {
      partyId: selectedParty.value,
      invoiceNo: selectedInvoice,
      cardNo: selectedInvoiceCard,
      designNo: "D-" + cardData?.designNo?.replace("D-", ""),
      date: cardData?.date,
      image: images,
      works: jobWorkTableData,
      measurements: measureTableData,
      costing: costTableData,
      stitching: statchingTableData,
      shortNet: shortNet,
      plain: plain,
      size: {
        type: sizeType?.value,
        value: size?.value,
      },
      foil: foilTableData,
      daying: dyeTableData,
      hand: transformedData,
      value: isValue,
      finalCosting: {
        work: {
          ParPct: jobPar,
          amount:
            jobTotal + Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100)),
          isSelected: isJobSelect ? isJobSelect : false,
          itemName: "Job Works",
        },
        measurements: {
          totalMeter: measureTableTotal,
          isSelected: isMeasureSelect ? isMeasureSelect : false,
          itemName: "Fabric Measurements",
        },
        stitching: {
          ParPct: stitchPar,
          amount:
            stitchTotal +
            Math.ceil(stitchTotal * ((stitchPar ? stitchPar : 0) / 100)),
          isSelected: isStitchSelect ? isStitchSelect : false,
          itemName: "Stitching",
        },
        costing: {
          ParPct: costPar,
          amount:
            costTotal + Math.ceil(costTotal * ((costPar ? costPar : 0) / 100)),
          isSelected: isCostSelect ? isCostSelect : false,
          itemName: "Fabric Costing",
        },
        foil: {
          ParPct: foilPar,
          amount:
            foilTotal + Math.ceil(foilTotal * ((foilPar ? foilPar : 0) / 100)),
          isSelected: isFoilSelect ? isFoilSelect : false,
          itemName: "Foil Costing",
        },
        daying: {
          ParPct: dyePar,
          amount:
            dyeTotal + Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100)),
          isSelected: isDyeSelect ? isDyeSelect : false,
          itemName: "Dye Costing",
        },
        hand: {
          ParPct: handPar,
          amount: handTableData[0]?.amount
            ? Math.ceil(
                amountTotal + amountTotal * ((handPar ? handPar : 0) / 100)
              )
            : pointTotal +
              Math.ceil(pointTotal * ((handPar ? handPar : 0) / 100)),
          isSelected: isHandSelect ? isHandSelect : false,
          itemName: "HandWork Costing",
        },
      },
    };
    addDressRateCard(finalData).then((data: any) => {
      setSavedId(data?.data?.data?.insertedIds[0]);
      setIsUpdating(false);
      if (data.error) {
        Swal.fire({
          toast: true,
          icon: "error",
          title: data.error.data.message,
          position: "top-end",
          showConfirmButton: false,
          timer: ERROR_MESSAGE_TIMEOUT,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Great",
          text: "Dress Rate Card Successfully Added!!",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,
        });
        allDressTrigger("");
        deleteImageOnSaveUpdate();
      }
    });
  };
  const [singleCard, setSingleCard] = useState();

  const openInvoicePreview = () => {
    if (id || savedId) {
      pdfTrigger(isDressCardUpdate ? id : savedId);
    }
  };

  useEffect(() => {
    if (isPdfSuccess && !isPdfFetching) {
      setSingleCard(pdfResult?.data?.data?.dressCard?.[0]);
      setShowDressRatePreview(true);
    }
  }, [isPdfSuccess, isPdfFetching]);
  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
      <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
        <div className="invisible lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-3 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-xl">Dress Rate</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 w-2/5">
                <div>
                  <Select
                    id="invoice"
                    name="invoice"
                    htmlFor="invoice"
                    label="Card No."
                    options={invoice}
                    onChange={(e: any) => handleInvoices(e)}
                    value={selectedInvoice}
                  />
                </div>
                <div className="ml-2">
                  <Select
                    id="invoiceno"
                    name="invoiceno"
                    htmlFor="invoiceno"
                    label="Card No. B"
                    options={invoiceNo}
                    onChange={(e: any) => handleInvoiceChange(e)}
                    value={selectedInvoiceCard}
                  />
                </div>
              </div>
              <div className="px-3 w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={showDressRatePreview}
                  onClick={openInvoicePreview}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button>
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={isDressCardUpdate ? updateData : addData}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">
                    {isDressCardUpdate ? "Update" : "Save"}
                  </span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/dressratecarddashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
          <div className="w-full overflow-auto">
            <div className="w-full h-full rounded-xl bg-white px-2">
              <div className="p-2 lg:p-0 xl:p-0 lg:hidden visible xl:hidden lg:invisible xl:invisible">
                <div className="mt-2 md:mt-0">
                  <Select
                    id="invoice"
                    name="invoice"
                    htmlFor="invoice"
                    label="Card No."
                    options={invoice}
                    onChange={(e: any) => handleInvoices(e)}
                    value={selectedInvoice}
                  />
                </div>
                <div className="ml-2">
                  <Select
                    id="invoiceno"
                    name="invoiceno"
                    htmlFor="invoiceno"
                    label="Card No. B"
                    options={invoiceNo}
                    onChange={(e: any) => handleInvoiceChange(e)}
                    value={selectedInvoiceCard}
                  />
                </div>
              </div>

              <div className="p-2 pb-0">
                <div>
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-5 md:pt-3 pb-0">
                    <div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="partyName"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block z-40 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          classNames={{
                            control: () =>
                              errorMessage?.party
                                ? "border-red-600"
                                : "border-grey-300",
                          }}
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "party");
                              setErrorMessage({
                                ...errorMessage,
                                party: "",
                              });
                            } else {
                              setSelectedParty({});
                            }
                          }}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getPartyNameTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllPartyName = result.data?.data?.party?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllPartyName) {
                          //             setParty(getAllPartyName)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setPartyNameAdd(data);
                            setShowAddParty(true);
                          }}
                          options={Party && Party}
                          value={selectedParty}
                        />
                        <label
                          htmlFor="partyName"
                          className={`absolute z-40 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            errorMessage?.party
                              ? "text-red-500"
                              : "text-gray-500"
                          }`}
                        >
                          Party Name
                        </label>
                      </div>
                      <span className="text-xs text-red-500 text-left">
                        {errorMessage?.party}
                      </span>
                    </div>
                    <div>
                      <div className="relative">
                        <div className="rounded-md">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">
                              D -
                            </span>
                          </div>
                          <input
                            type="number"
                            name="designNo"
                            id="designNo"
                            className={`block w-full py-1.5 pl-8 sm:text-sm sm:leading-6 first-line:block px-2.5 h-10 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 peer ${
                              errorMessage?.designNo
                                ? "border-red-500 focus:border-red-500"
                                : "border-gray-300 focus:border-themeColor"
                            }`}
                            placeholder=""
                            autoComplete="off"
                            value={cardData?.designNo?.split?.("-")?.[1]}
                            onChange={(e: any) => {
                              handleChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                designNo: "",
                              });
                            }}
                          />
                        </div>
                        <label
                          className={`absolute capitalize text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${
                            errorMessage?.designNo
                              ? "text-red-500"
                              : "text-gray-500"
                          }`}
                        >
                          Design No
                        </label>
                      </div>
                      <span className="text-xs text-red-500 text-left">
                        {errorMessage?.designNo}
                      </span>
                    </div>
                    <TextBox
                      id="date"
                      type="date"
                      name="date"
                      htmlFor="date"
                      label="Date"
                      tabIndex={-1}
                      placeholder=" "
                      value={cardData?.date}
                      onChange={(e: any) => handleChanges(e)}
                    />
                    <div className="relative rounded-md">
                      <ReactSelect
                        className="basic-single block z-30 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                        classNamePrefix="select"
                        isSearchable={true}
                        value={sizeType}
                        name="sizeType"
                        onChange={(e: any) => handleSizeTypeChanges(e)}
                        options={[
                          {
                            label: "Number",
                            value: "Number",
                          },
                          {
                            label: "Farma",
                            value: "Farma",
                          },
                        ]}
                      />
                      <label
                        htmlFor="sizeType"
                        className={`absolute z-30 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                      >
                        Size Type
                      </label>
                    </div>
                    <div className="relative rounded-md">
                      <ReactSelect
                        className="basic-single block z-30 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                        classNamePrefix="select"
                        isSearchable={true}
                        value={size}
                        name="size"
                        onChange={(e: any) => {
                          setSize({
                            label: e.label,
                            value: e.value,
                          });
                        }}
                        options={sizeOptions}
                      />
                      <label
                        htmlFor="size"
                        className={`absolute z-30 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                      >
                        Size
                      </label>
                    </div>
                  </div>

                  <div>
                    <fieldset
                      className={`border-2 mt-2 rounded-2 ${
                        errorMessage?.work
                          ? "border-red-500"
                          : "border-gray-400"
                      } p-2`}
                    >
                      <legend
                        className={`capitalize px-2 ${
                          errorMessage?.work ? "text-red-500" : ""
                        }`}
                      >
                        Job Work
                      </legend>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-5">
                        <div className="relative rounded-md">
                          <CreatableSelect
                            ref={jobWorkRef}
                            name="jobitem"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "jobitem");
                                setErrorMessage({
                                  ...errorMessage,
                                  jobItem: "",
                                });
                              } else {
                                setSelectedJobItem({});
                              }
                            }}
                            classNames={{
                              control: () =>
                                errorMessage?.jobItem
                                  ? "border-red-500"
                                  : "border-grey-300",
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getItemTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllItem) {
                            //             setItem(getAllItem)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setItemAdd(data);
                              setShowAddItem(true);
                              setItemType("job");
                            }}
                            options={Item}
                            value={selectedJobItem}
                          />
                          <label
                            htmlFor="item"
                            className={`absolute z-20 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                              errorMessage?.jobItem
                                ? "text-red-500"
                                : "text-gray-500"
                            }`}
                          >
                            {" "}
                            Item
                          </label>
                          <span className="text-xs text-red-500 flex justify-start">
                            {errorMessage?.jobItem}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            htmlFor="stitch"
                            id="stitch"
                            name="stitch"
                            label="Stitch"
                            type="number"
                            placeholder=" "
                            className={`px-0 ${
                              errorMessage?.stitch
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.stitch
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            value={jobWorkData?.stitch}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.stitch}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="area"
                            name="area"
                            htmlFor="area"
                            label="Area"
                            placeholder=" "
                            type="number"
                            value={jobWorkData?.area}
                            className={`px-0 ${
                              errorMessage?.area
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.area
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.area}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="Pcs"
                            name="Pcs"
                            htmlFor="Pcs"
                            label="Pcs"
                            placeholder=" "
                            type="number"
                            value={jobWorkData?.Pcs}
                            className={`px-0 ${
                              errorMessage?.Pcs
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.Pcs
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.Pcs}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="code"
                            name="code"
                            htmlFor="code"
                            label="Code"
                            placeholder=" "
                            type="number"
                            value={jobWorkData?.code}
                            className={`px-0 ${
                              errorMessage?.code
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.code
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.code}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="rate"
                            name="rate"
                            htmlFor="rate1"
                            label="Rate"
                            placeholder=" "
                            type="number"
                            value={jobWorkData?.rate}
                            disabled
                            className={`px-0 ${
                              errorMessage?.rate
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.rate
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.rate}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="totalQty"
                            name="totalQty"
                            htmlFor="totalQty"
                            label="Total Qty"
                            placeholder=" "
                            type="number"
                            value={jobWorkData?.totalQty}
                            className={`px-0 ${
                              errorMessage?.totalQty
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.totalQty
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.totalQty}
                          </span>
                        </div>
                        <TextBox
                          id="valueAdd"
                          name="valueAdd"
                          htmlFor="valueAdd"
                          label="Value Add"
                          placeholder=" "
                          type="number"
                          value={jobWorkData?.valueAdd}
                          onChange={(e: any) => handleJobWorkChanges(e)}
                        />
                        <div>
                          <TextBox
                            id="totalAmt"
                            name="totalAmt"
                            htmlFor="totalAmt"
                            label="Total Amount"
                            placeholder=" "
                            type="number"
                            value={jobWorkData?.totalAmt}
                            disabled
                            className={`px-0 ${
                              errorMessage?.totalAmt
                                ? "border-red-500 focus:border-red-500"
                                : "focus:border-themeColor"
                            }`}
                            labelClassName={`${
                              errorMessage?.totalAmt
                                ? "text-red-500 peer-focus:text-red-500"
                                : "text-gray-500"
                            }`}
                            onChange={(e: any) => handleJobWorkChanges(e)}
                          />
                          <span className="text-xs text-red-500 text-left">
                            {errorMessage?.totalAmt}
                          </span>
                        </div>
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="remarks"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "remarks");
                              } else {
                                setSelectedRemarks({});
                              }
                            }}
                            isClearable={true}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getRemarkTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         remarksTrigger('')
                            //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllRemark) {
                            //             setRemarks(getAllRemark)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setRemarksAdd(data);
                              setShowAddRemarks(true);
                              setItemType("");
                            }}
                            options={Remarks}
                            value={selectedRemarks}
                          />
                          <label
                            htmlFor="remarks"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                          >
                            Remarks
                          </label>
                        </div>
                        <button
                          className="w-full px-4 py-2 text-sm leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={() => {
                            if (isJobUpdate) {
                              let record = {
                                _id: selectedJobRow,
                                itemId: selectedJobItem?.value || "",
                                remark: selectedRemarks?.label || "",
                                stitch: Number(jobWorkData?.stitch) || "",
                                area: Number(jobWorkData?.area),
                                Pcs: Number(jobWorkData?.Pcs) || "",
                                code: Number(jobWorkData?.code) || "",
                                totalQty: Number(jobWorkData?.totalQty) || "",
                                valueAdd: jobWorkData?.valueAdd
                                  ? Number(jobWorkData?.valueAdd)
                                  : "",
                                totalAmt: Number(jobWorkData?.totalAmt) || "",
                                rate: Number(jobWorkData?.rate) || "",
                              };
                              let errorData = {
                                jobItem: "",
                                stitch: "",
                                area: "",
                                Pcs: "",
                                code: "",
                                rate: "",
                                totalQty: "",
                                totalAmt: "",
                              };

                              if (Object.keys(selectedJobItem).length == 0) {
                                errorData["jobItem"] = "Item is required";
                              } else {
                                errorData["jobItem"] = "";
                              }
                              if (!jobWorkData?.stitch) {
                                errorData["stitch"] = "Stitch is required";
                              } else {
                                errorData["stitch"] = "";
                              }
                              if (!jobWorkData?.area) {
                                errorData["area"] = "Area is required";
                              } else {
                                errorData["area"] = "";
                              }
                              if (!jobWorkData?.Pcs) {
                                errorData["Pcs"] = "Pcs is required";
                              } else {
                                errorData["Pcs"] = "";
                              }
                              if (!jobWorkData?.code) {
                                errorData["code"] = "Code is required";
                              } else {
                                errorData["code"] = "";
                              }
                              if (!jobWorkData?.rate) {
                                errorData["rate"] = "Rate is required";
                              } else {
                                errorData["rate"] = "";
                              }
                              if (!jobWorkData?.totalQty) {
                                errorData["totalQty"] = "Total Qty is required";
                              } else {
                                errorData["totalQty"] = "";
                              }
                              if (!jobWorkData?.totalAmt) {
                                errorData["totalAmt"] =
                                  "Total Amount is required";
                              } else {
                                errorData["totalAmt"] = "";
                              }
                              setErrorMessage(errorData);
                              const result: any = jobWorkValidation(record);
                              if (result === true) {
                                const elementsIndex =
                                  jobWorkTableData.findIndex(
                                    (element: any) =>
                                      element?._id == selectedJobRow
                                  );

                                let newArray = [...jobWorkTableData];

                                newArray[elementsIndex] = {
                                  ...newArray[elementsIndex],
                                  ...record,
                                };

                                let measureRow =
                                  measureTableData[elementsIndex];
                                let fabrics: any;
                                if (measureRow?.PIM == "") {
                                  fabrics =
                                    Number(measureRow?.measurement) *
                                    Number(measureRow?.Pcs);
                                } else {
                                  fabrics = (
                                    (Number(measureRow?.measurement) /
                                      Number(measureRow?.PIM)) *
                                    Number(measureRow?.Pcs)
                                  ).toFixed(2);
                                }
                                let measureRecord = {
                                  itemId: selectedJobItem?.value,
                                  clotheId: measureRow?.clotheId,
                                  remark: selectedMeasureRemarks?.label || "",
                                  PIM: measureRow?.PIM
                                    ? Number(measureRow?.PIM)
                                    : "",
                                  Pcs: Number(measureRow?.Pcs),
                                  measurement: Number(measureRow?.measurement),
                                  totalQty: Number(measureRow?.totalQty),
                                  fabrics: fabrics,
                                  meter: (
                                    fabrics * Number(measureRow?.totalQty)
                                  ).toFixed(2),
                                };

                                let newMeasureArray = [...measureTableData];

                                newMeasureArray[elementsIndex] = {
                                  ...newMeasureArray[elementsIndex],
                                  ...measureRecord,
                                };

                                let costRow = costTableData[elementsIndex];
                                let amount = Math.ceil(
                                  Number(costRow?.PcM) * Number(costRow?.rate)
                                );
                                let costRecord = {
                                  itemId: selectedJobItem?.value,
                                  totalQty: Number(jobWorkData?.totalQty),
                                  remark: selectedCostRemarks?.label || "",
                                  clotheId: costRow?.clotheId,
                                  PcM: Number(costRow?.PcM),
                                  rate: Number(costRow?.rate),
                                  amount: amount,
                                };

                                let newCostArray = [...costTableData];

                                newCostArray[elementsIndex] = {
                                  ...newCostArray[elementsIndex],
                                  ...costRecord,
                                };
                                setJobWorkData({
                                  srNo: "",
                                  area: "",
                                  itemId: "",
                                  reamrksId: "",
                                  Pcs: "",
                                  code: "",
                                  stitch: "",
                                  totalQty: "",
                                  valueAdd: "",
                                  totalAmt: "",
                                  rate: "",
                                });
                                setSelectedJobItem({});
                                setSelectedRemarks({});
                                setIsJobUpdate(false);
                                setJobWorkTableData(newArray);
                                setMeasureTableData(newMeasureArray);
                                setCostTableData(newCostArray);
                              } else {
                                Swal.fire({
                                  toast: true,
                                  icon: "error",
                                  title: result.errorMessage,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              }
                            } else {
                              let record = {
                                _id: ObjectID().toHexString(),
                                itemId: selectedJobItem?.value || "",
                                remark: selectedRemarks?.label || "",
                                stitch: Number(jobWorkData?.stitch) || "",
                                area: Number(jobWorkData?.area),
                                Pcs: Number(jobWorkData?.Pcs) || "",
                                code: Number(jobWorkData?.code) || "",
                                totalQty: Number(jobWorkData?.totalQty) || "",
                                valueAdd: jobWorkData?.valueAdd
                                  ? Number(jobWorkData?.valueAdd)
                                  : "",
                                totalAmt: Number(jobWorkData?.totalAmt) || "",
                                rate: Number(jobWorkData?.rate) || "",
                              };
                              let errorData = {
                                jobItem: "",
                                stitch: "",
                                area: "",
                                Pcs: "",
                                code: "",
                                rate: "",
                                totalQty: "",
                                totalAmt: "",
                              };

                              if (Object.keys(selectedJobItem).length == 0) {
                                errorData["jobItem"] = "Item is required";
                              } else {
                                errorData["jobItem"] = "";
                              }
                              if (!jobWorkData?.stitch) {
                                errorData["stitch"] = "Stitch is required";
                              } else {
                                errorData["stitch"] = "";
                              }
                              if (!jobWorkData?.area) {
                                errorData["area"] = "Area is required";
                              } else {
                                errorData["area"] = "";
                              }
                              if (!jobWorkData?.Pcs) {
                                errorData["Pcs"] = "Pcs is required";
                              } else {
                                errorData["Pcs"] = "";
                              }
                              if (!jobWorkData?.code) {
                                errorData["code"] = "Code is required";
                              } else {
                                errorData["code"] = "";
                              }
                              if (!jobWorkData?.rate) {
                                errorData["rate"] = "Rate is required";
                              } else {
                                errorData["rate"] = "";
                              }
                              if (!jobWorkData?.totalQty) {
                                errorData["totalQty"] = "Total Qty is required";
                              } else {
                                errorData["totalQty"] = "";
                              }
                              if (!jobWorkData?.totalAmt) {
                                errorData["totalAmt"] =
                                  "Total Amount is required";
                              } else {
                                errorData["totalAmt"] = "";
                              }
                              setErrorMessage(errorData);
                              const result: any = jobWorkValidation(record);
                              if (result === true) {
                                setErrorMessage({
                                  ...errorMessage,
                                  work: "",
                                });
                                setJobWorkTableData([
                                  ...jobWorkTableData,
                                  record,
                                ]);

                                let measureRecord = {
                                  _id: ObjectID().toHexString(),
                                  itemId: selectedJobItem?.value,
                                  clotheId: "",
                                  remark: "",
                                  PIM: "",
                                  Pcs: Number(jobWorkData?.Pcs),
                                  measurement: "",
                                  totalQty: Number(jobWorkData?.totalQty),
                                  fabrics: "",
                                  meter: "",
                                };

                                setMeasureTableData([
                                  ...measureTableData,
                                  measureRecord,
                                ]);

                                let costRecord = {
                                  _id: ObjectID().toHexString(),
                                  itemId: selectedJobItem?.value,
                                  totalQty: Number(jobWorkData?.totalQty),
                                  remark: "",
                                  clotheId: "",
                                  PcM: "",
                                  rate: "",
                                  amount: "",
                                };

                                setCostTableData([
                                  ...costTableData,
                                  costRecord,
                                ]);
                                setJobWorkData({
                                  srNo: "",
                                  area: "",
                                  itemId: "",
                                  remark: "",
                                  Pcs: "",
                                  code: "",
                                  stitch: "",
                                  totalQty: "",
                                  valueAdd: "",
                                  totalAmt: "",
                                  rate: "",
                                });
                                setSelectedJobItem({});
                                setSelectedRemarks({});
                              } else {
                                Swal.fire({
                                  toast: true,
                                  icon: "error",
                                  title: result.errorMessage,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              }
                            }
                            jobWorkRef.current.focus();
                          }}
                        >
                          {isJobUpdate ? "Update Job Work" : "Add Job Work"}{" "}
                        </button>
                      </div>
                      {/* Table */}
                      <div className="grid grid-cols-1 mt-2">
                        <div className="border border-gray-300 rounded-3 overflow-hidden">
                          <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="min-w-full sm:px-6 lg:px-0">
                                <div className="overflow-hidden">
                                  <div className="flex-auto px-0 pt-0">
                                    <div className="p-0 overflow-hidden">
                                      {jobWorkTableData &&
                                      jobWorkTableData.length > 0 ? (
                                        <div className="max-h-80 overflow-auto shadow-md">
                                          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                            <thead className="bg-gray-50 capitalize">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Sr No.
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  item
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  stitch
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  area
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  pcs
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  code
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  rate
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  total qty
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  value add
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  total amount
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  remarks
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 font-bold text-center text-gray-900"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                              {jobWorkTableData?.map(
                                                (row: any, index: number) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="hover:bg-gray-50"
                                                    >
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {index + 1}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {itemState[row?.itemId]}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.stitch}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.area}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.Pcs}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.code}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.rate}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.totalQty}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.valueAdd}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.totalAmt}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.remark}
                                                      </td>
                                                      <td>
                                                        <div>
                                                          <button
                                                            className="m-2"
                                                            type="button"
                                                            onClick={() => {
                                                              setSelecetdJobRow(
                                                                row?._id
                                                              );
                                                              setJobWorkData({
                                                                srNo: row?.srNo,
                                                                area: row?.area,
                                                                itemId:
                                                                  row?.itemId,
                                                                Pcs: row?.Pcs,
                                                                code: row?.code,
                                                                stitch:
                                                                  row?.stitch,
                                                                totalQty:
                                                                  row?.totalQty,
                                                                valueAdd:
                                                                  row?.valueAdd,
                                                                totalAmt:
                                                                  row?.totalAmt,
                                                                rate: row?.rate,
                                                              });
                                                              setSelectedJobItem(
                                                                {
                                                                  label:
                                                                    itemState[
                                                                      row
                                                                        ?.itemId
                                                                    ],
                                                                  value:
                                                                    row.itemId,
                                                                }
                                                              );
                                                              setSelectedRemarks(
                                                                {
                                                                  label:
                                                                    row?.remark,
                                                                  value:
                                                                    row.remark,
                                                                }
                                                              );
                                                              setIsJobUpdate(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-pen hover:text-green-600"></i>
                                                          </button>
                                                          <button
                                                            className="m-2"
                                                            onClick={() => {
                                                              Swal.fire({
                                                                title:
                                                                  "Are you sure?",
                                                                text: "Once deleted, you will not be able to recover this record!",
                                                                icon: "warning",
                                                                showCancelButton:
                                                                  true,
                                                                confirmButtonColor:
                                                                  "#3085d6",
                                                                cancelButtonColor:
                                                                  "#d33",
                                                                confirmButtonText:
                                                                  "Delete",
                                                              }).then(
                                                                (
                                                                  willDelete
                                                                ) => {
                                                                  if (
                                                                    willDelete.isConfirmed
                                                                  ) {
                                                                    const elementsIndex =
                                                                      jobWorkTableData.findIndex(
                                                                        (
                                                                          element: any
                                                                        ) =>
                                                                          element?._id ==
                                                                          row?._id
                                                                      );
                                                                    let newData =
                                                                      jobWorkTableData.filter(
                                                                        (
                                                                          item: any,
                                                                          index: any
                                                                        ) => {
                                                                          return (
                                                                            item?._id !==
                                                                            row?._id
                                                                          );
                                                                        }
                                                                      );

                                                                    let newMeasureData =
                                                                      measureTableData.filter(
                                                                        (
                                                                          item: any,
                                                                          index: any
                                                                        ) => {
                                                                          return (
                                                                            index !==
                                                                            elementsIndex
                                                                          );
                                                                        }
                                                                      );

                                                                    let newCostData =
                                                                      costTableData.filter(
                                                                        (
                                                                          item: any,
                                                                          index: any
                                                                        ) => {
                                                                          return (
                                                                            index !==
                                                                            elementsIndex
                                                                          );
                                                                        }
                                                                      );

                                                                    setJobWorkTableData(
                                                                      newData
                                                                    );
                                                                    setMeasureTableData(
                                                                      newMeasureData
                                                                    );
                                                                    setCostTableData(
                                                                      newCostData
                                                                    );
                                                                    setJobWorkData(
                                                                      {
                                                                        srNo: "",
                                                                        area: "",
                                                                        itemId:
                                                                          "",
                                                                        remark:
                                                                          "",
                                                                        Pcs: "",
                                                                        code: "",
                                                                        stitch:
                                                                          "",
                                                                        totalQty:
                                                                          "",
                                                                        valueAdd:
                                                                          "",
                                                                        totalAmt:
                                                                          "",
                                                                        rate: "",
                                                                      }
                                                                    );
                                                                    setSelectedJobItem(
                                                                      {}
                                                                    );
                                                                    setSelectedRemarks(
                                                                      {}
                                                                    );
                                                                    setIsJobUpdate(
                                                                      false
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-trash hover:text-red-600"></i>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                            <tfoot className="border-t bg-gray-50">
                                              <tr>
                                                <td
                                                  colSpan={9}
                                                  className="px-6 py-1 font-bold text-gray-900 text-left"
                                                >
                                                  Total
                                                </td>
                                                <td
                                                  colSpan={3}
                                                  className="px-6 font-bold text-gray-900 text-left"
                                                >
                                                  {jobTotal}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-3 text-gray-600 font-bold">
                                          No Job Work Found
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <span className="text-xs text-red-500 flex justify-start">
                      {errorMessage?.work}
                    </span>
                  </div>
                </div>
              </div>

              {/* Fabric Measurement */}
              <div className="px-2">
                <div>
                  <fieldset
                    className={`border-2 mt-2 rounded-2 p-2 ${
                      errorMessage?.measure
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  >
                    <legend
                      className={`capitalize px-2 ${
                        errorMessage?.measure ? "text-red-500" : ""
                      }`}
                    >
                      Fabric Measurement
                    </legend>
                    <div className="grid gap-4 grid-cols-1 xl:grid-cols-6">
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="fabricColor"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "fabricColor");
                            } else {
                              setSelectedFabric({});
                            }
                          }}
                          isClearable={true}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getFabricColorTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllFabric = fabricResult.data?.data?.fabricColor?.map(({ _id, colorName }: any) => {
                          //             return {
                          //                 label: colorName,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllFabric) {
                          //             setFabric(getAllFabric)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setFabricAdd(data);
                            setShowAddFabric(true);
                          }}
                          options={Fabric}
                          value={selectedFabric}
                        />
                        <label
                          htmlFor="item"
                          className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                        >
                          {" "}
                          Fabric Color
                        </label>
                      </div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="cloth"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-themeColor"
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "cloth");
                              setErrorMessage({
                                ...errorMessage,
                                cloth: "",
                              });
                            } else {
                              setSelectedCloth({});
                            }
                          }}
                          classNames={{
                            control: () =>
                              errorMessage?.cloth
                                ? "border-red-500 focus:border-red-500"
                                : "border-gray-500 focus:border-themeColor",
                          }}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getClothTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllCloth = clothResult.data?.data?.clothe?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllCloth) {
                          //             setCloth(getAllCloth)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setClothAdd(data);
                            setShowAddCloth(true);
                          }}
                          options={Cloth}
                          value={selectedCloth}
                        />
                        <label
                          htmlFor="cloth"
                          className={`absolute text-md text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            errorMessage?.cloth
                              ? "text-red-500"
                              : "text-gray-500"
                          }`}
                        >
                          {" "}
                          Fabric
                        </label>
                        <span className="text-xs text-red-500 text-left">
                          {errorMessage?.cloth}
                        </span>
                      </div>
                      <TextBox
                        htmlFor="PIM"
                        id="PIM"
                        name="PIM"
                        label="Patti In Meter"
                        type="number"
                        placeholder=" "
                        className="px-0"
                        disabled={isMeasureUpdate ? false : true}
                        value={measureData?.PIM}
                        onChange={(e: any) => handleMeasureChanges(e)}
                      />
                      <div>
                        <TextBox
                          htmlFor="totalQty1"
                          id="totalQty"
                          name="totalQty"
                          label="Total Quantity"
                          type="number"
                          placeholder=" "
                          className={`px-0 ${
                            errorMessage?.totalQty
                              ? "border-red-500 focus:border-red-500"
                              : "focus:border-themeColor"
                          }`}
                          labelClassName={`${
                            errorMessage?.totalQty
                              ? "text-red-500 peer-focus:text-red-500"
                              : "text-gray-500"
                          }`}
                          disabled={isMeasureUpdate ? false : true}
                          value={measureData?.totalQty}
                          onChange={(e: any) => handleMeasureChanges(e)}
                        />
                        <span className="text-xs text-red-500 text-left">
                          {errorMessage?.totalQty}
                        </span>
                      </div>
                      <div>
                        <TextBox
                          id="measurement"
                          name="measurement"
                          htmlFor="measurement"
                          label="Measurement"
                          placeholder=" "
                          type="number"
                          value={measureData?.measurement}
                          disabled={isMeasureUpdate ? false : true}
                          className={`px-0 ${
                            errorMessage?.measurement
                              ? "border-red-500 focus:border-red-500"
                              : "focus:border-themeColor"
                          }`}
                          labelClassName={`${
                            errorMessage?.measurement
                              ? "text-red-500 peer-focus:text-red-500"
                              : "text-gray-500"
                          }`}
                          onChange={(e: any) => handleMeasureChanges(e)}
                        />
                        <span className="text-xs text-red-500 text-left">
                          {errorMessage?.measurement}
                        </span>
                      </div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="remarks"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "measureremarks");
                            } else {
                              setSelectedMeasureRemarks({});
                            }
                          }}
                          isClearable={true}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getRemarkTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         remarksTrigger('')
                          //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllRemark) {
                          //             setRemarks(getAllRemark)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setRemarksAdd(data);
                            setShowAddRemarks(true);
                            setItemType("measure");
                          }}
                          options={Remarks}
                          value={selectedMeasureRemarks}
                        />
                        <label
                          htmlFor="measureremarks"
                          className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                        >
                          Remarks
                        </label>
                      </div>
                      {isMeasureUpdate ? (
                        <button
                          className="w-full px-2 py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={() => {
                            let fabrics: any;
                            if (measureData?.PIM == "") {
                              fabrics =
                                Number(measureData?.measurement) *
                                Number(selectedMeasureRow?.Pcs);
                            } else {
                              fabrics = (
                                (Number(measureData?.measurement) /
                                  Number(measureData?.PIM)) *
                                Number(selectedMeasureRow?.Pcs)
                              ).toFixed(2);
                            }
                            let record = {
                              _id: selectedMeasureRow?._id,
                              itemId: selectedMeasureRow?.itemId,
                              clotheId: selectedCloth?.value,
                              fabricColorId: selectedFabric?.value,
                              remark: selectedMeasureRemarks?.label,
                              PIM: measureData?.PIM
                                ? Number(measureData?.PIM)
                                : "",
                              Pcs: Number(selectedMeasureRow?.Pcs),
                              fabrics: fabrics,
                              totalQty: Number(measureData?.totalQty) || "",
                              meter: (
                                fabrics * Number(measureData?.totalQty)
                              ).toFixed(2),
                              measurement:
                                Number(measureData?.measurement) || "",
                            };
                            let errorData = {
                              cloth: "",
                              totalQty: "",
                              measurement: "",
                            };

                            if (Object.keys(selectedCloth).length == 0) {
                              errorData["cloth"] = "Fabric is required";
                            } else {
                              errorData["cloth"] = "";
                            }
                            if (!measureData?.measurement) {
                              errorData["measurement"] =
                                "Measurement is required";
                            } else {
                              errorData["measurement"] = "";
                            }
                            if (!measureData?.totalQty) {
                              errorData["totalQty"] = "Total Qty is required";
                            } else {
                              errorData["totalQty"] = "";
                            }
                            setErrorMessage(errorData);
                            const result: any = fabricMeasureValidation(record);
                            if (result === true) {
                              setErrorMessage({
                                ...errorMessage,
                                measure: "",
                              });
                              setMeasureData({
                                PIM: "",
                                totalQty: "",
                                measurement: "",
                              });
                              setSelectedCloth({});
                              setSelectedMeasureRemarks({});
                              setSelectedFabric({});
                              const elementsIndex = measureTableData.findIndex(
                                (element: any) =>
                                  element?._id == selectedMeasureRow?._id
                              );

                              let newArray = [...measureTableData];

                              newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                ...record,
                              };

                              let costRow = costTableData[elementsIndex];
                              let amount = Math.ceil(
                                Number(costRow?.PcM) * Number(costRow?.rate)
                              );
                              let costRecord = {
                                itemId: costRow?.itemId,
                                remark: costRow?.remark,
                                totalQty: Number(costRow?.totalQty),
                                clotheId: selectedCloth?.value,
                                PcM: Number(costRow?.PcM),
                                rate: Number(costRow?.rate),
                                amount: amount,
                              };

                              let newCostArray = [...costTableData];

                              newCostArray[elementsIndex] = {
                                ...newCostArray[elementsIndex],
                                ...costRecord,
                              };
                              setMeasureTableData(newArray);
                              setCostTableData(newCostArray);
                              setIsMeasureUpdate(false);
                            } else {
                              Swal.fire({
                                toast: true,
                                icon: "error",
                                title: result.errorMessage,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: ERROR_MESSAGE_TIMEOUT,
                                timerProgressBar: true,
                              });
                            }
                          }}
                        >
                          Update
                        </button>
                      ) : null}

                      {/* Table */}
                      <div className="col-span-1 lg:col-span-6 border border-gray-300 rounded-3 overflow-hidden">
                        <div className="border border-gray-300 rounded-3 overflow-hidden">
                          <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="min-w-full sm:px-6 lg:px-0">
                                <div className="overflow-hidden">
                                  <div className="flex-auto px-0 pt-0">
                                    <div className="p-0 overflow-hidden">
                                      {measureTableData &&
                                      measureTableData.length > 0 ? (
                                        <div className="max-h-80 overflow-auto shadow-md">
                                          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                            <thead className="bg-gray-50 capitalize">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  item
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Fabric
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  fabric color
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  patti in meter
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  pcs
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  measurement
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  fabric
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  total qty
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  total fabrics
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  remarks
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 font-bold text-center text-gray-900"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                              {measureTableData?.map(
                                                (row: any, index: number) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="hover:bg-gray-50"
                                                    >
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {itemState[row?.itemId]}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          clothState[
                                                            row?.clotheId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          fabricState[
                                                            row?.fabricColorId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.PIM}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.Pcs}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.measurement}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.fabrics}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.totalQty}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.meter}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.remark}
                                                      </td>
                                                      <td>
                                                        <div>
                                                          <button
                                                            className="m-2"
                                                            type="button"
                                                            onClick={() => {
                                                              setSelectedMeasureRow(
                                                                row
                                                              );
                                                              setMeasureData({
                                                                PIM: row?.PIM,
                                                                totalQty:
                                                                  row?.totalQty,
                                                                measurement:
                                                                  row?.measurement,
                                                              });
                                                              setSelectedFabric(
                                                                {
                                                                  label:
                                                                    fabricState[
                                                                      row
                                                                        ?.fabricColorId
                                                                    ],
                                                                  value:
                                                                    row.fabricColorId,
                                                                }
                                                              );
                                                              setSelectedCloth({
                                                                label:
                                                                  clothState[
                                                                    row
                                                                      ?.clotheId
                                                                  ],
                                                                value:
                                                                  row.clotheId,
                                                              });
                                                              setSelectedMeasureRemarks(
                                                                {
                                                                  label:
                                                                    row?.remark,
                                                                  value:
                                                                    row.remark,
                                                                }
                                                              );
                                                              setIsMeasureUpdate(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-pen hover:text-green-600"></i>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                            <tfoot className="border-t bg-gray-50">
                                              <tr>
                                                <td
                                                  colSpan={2}
                                                  className="px-6 py-1 text-gray-900 text-left"
                                                >
                                                  <TextBox
                                                    htmlFor="shortNet"
                                                    id="shortNet"
                                                    name="shortNet"
                                                    label="Short Fabric"
                                                    type="number"
                                                    placeholder=" "
                                                    className="px-0 bg-white"
                                                    value={shortNet}
                                                    onChange={(e: any) => {
                                                      setShortNet(
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                </td>
                                                <td
                                                  colSpan={2}
                                                  className="px-6 py-1 text-gray-900 text-left"
                                                >
                                                  <TextBox
                                                    htmlFor="plain"
                                                    id="plain"
                                                    name="plain"
                                                    label="Plain"
                                                    type="number"
                                                    placeholder=" "
                                                    className="px-0 bg-white"
                                                    value={plain}
                                                    onChange={(e: any) => {
                                                      setPlain(e.target.value);
                                                    }}
                                                  />
                                                </td>
                                                <td colSpan={4}></td>
                                                <td
                                                  colSpan={3}
                                                  className="px-6 font-bold text-gray-900 text-left"
                                                >
                                                  {measureTableTotal}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-3 text-gray-600 font-bold">
                                          No Fabric Measurement Found
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <span className="text-xs text-red-500 flex justify-start">
                    {errorMessage?.measure}
                  </span>
                </div>
              </div>

              {/* Fabric Costing */}
              <div className="px-2">
                <div>
                  <fieldset className="border-2 mt-2 rounded-2 border-gray-400 p-2">
                    <legend className="capitalize px-2">Fabric Costing</legend>
                    <div className="grid gap-4 grid-cols-1 xl:grid-cols-3">
                      <TextBox
                        htmlFor="PcM"
                        id="PcM"
                        name="PcM"
                        label="Per Pcs"
                        type="number"
                        placeholder=" "
                        className="px-0"
                        disabled={isCostUpdate ? false : true}
                        value={costData?.PcM}
                        onChange={(e: any) => handleCostChanges(e)}
                      />
                      <TextBox
                        id="rate"
                        name="rate"
                        htmlFor="rate2"
                        label="Rate"
                        placeholder=" "
                        type="number"
                        disabled={isCostUpdate ? false : true}
                        value={costData?.rate}
                        onChange={(e: any) => handleCostChanges(e)}
                      />
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="remarks"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "costremarks");
                            } else {
                              setSelectedCostRemarks({});
                            }
                          }}
                          isClearable={true}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getRemarkTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         remarksTrigger('')
                          //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllRemark) {
                          //             setRemarks(getAllRemark)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setRemarksAdd(data);
                            setShowAddRemarks(true);
                            setItemType("cost");
                          }}
                          options={Remarks}
                          value={selectedCostRemarks}
                        />
                        <label
                          htmlFor="costremarks"
                          className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                        >
                          Remarks
                        </label>
                      </div>
                      {isCostUpdate ? (
                        <button
                          className="w-full px-2 py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={() => {
                            let amount = Math.ceil(
                              Number(costData?.PcM) * Number(costData?.rate)
                            );
                            let record = {
                              _id: selectedCostRow?._id,
                              itemId: selectedCostRow?.itemId,
                              remark: selectedCostRemarks?.label,
                              clotheId: selectedCostRow?.clotheId,
                              PcM: Number(costData?.PcM) || "",
                              totalQty: Number(selectedCostRow?.totalQty),
                              rate: Number(costData?.rate) || "",
                              amount: amount,
                            };
                            const result: any = fabricCostValidation(record);
                            if (result === true) {
                              setCostData({
                                PcM: "",
                                rate: "",
                              });
                              setSelectedCostRemarks({});
                              const elementsIndex = costTableData.findIndex(
                                (element: any) =>
                                  element?._id == selectedCostRow?._id
                              );

                              let newArray = [...costTableData];

                              newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                ...record,
                              };

                              setCostTableData(newArray);
                              setIsCostUpdate(false);
                            } else {
                              Swal.fire({
                                toast: true,
                                icon: "error",
                                title: result.errorMessage,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: ERROR_MESSAGE_TIMEOUT,
                                timerProgressBar: true,
                              });
                            }
                          }}
                        >
                          Update
                        </button>
                      ) : null}

                      {/* Table */}
                      <div className="col-span-1 lg:col-span-3 border border-gray-300 rounded-3 overflow-hidden">
                        <div className="border border-gray-300 rounded-3 overflow-hidden">
                          <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="min-w-full sm:px-6 lg:px-0">
                                <div className="overflow-hidden">
                                  <div className="flex-auto px-0 pt-0">
                                    <div className="p-0 overflow-hidden">
                                      {costTableData &&
                                      costTableData.length > 0 ? (
                                        <div className="max-h-80 overflow-auto shadow-md">
                                          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                            <thead className="bg-gray-50 capitalize">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  item
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Fabric
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  total qty
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  pcs / mtr
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  rate
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  amount
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  remarks
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 font-bold text-center text-gray-900"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                              {costTableData?.map(
                                                (row: any, index: number) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="hover:bg-gray-50"
                                                    >
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {itemState[row?.itemId]}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          clothState[
                                                            row?.clotheId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.totalQty}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.PcM}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.rate}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.amount}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row.remark}
                                                      </td>
                                                      <td>
                                                        <div>
                                                          <button
                                                            className="m-2"
                                                            type="button"
                                                            onClick={() => {
                                                              setSelectedCostRow(
                                                                row
                                                              );

                                                              setCostData({
                                                                PcM: row?.PcM,
                                                                rate: row?.rate,
                                                              });
                                                              setSelectedCostRemarks(
                                                                {
                                                                  label:
                                                                    row?.remark,
                                                                  value:
                                                                    row.remark,
                                                                }
                                                              );
                                                              setIsCostUpdate(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-pen hover:text-green-600"></i>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                            <tfoot className="border-t bg-gray-50">
                                              <tr>
                                                <td
                                                  colSpan={5}
                                                  className="px-6 py-1 font-bold text-gray-900 text-left"
                                                >
                                                  Total{" "}
                                                </td>
                                                <td
                                                  colSpan={3}
                                                  className="px-6 font-bold text-gray-900 text-left"
                                                >
                                                  {costTotal}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-3 text-gray-600 font-bold">
                                          No Fabric Costing Found
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              {/* Foil Configaration */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Foil</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      ref={foilRef}
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "foilitem");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("foil");
                      }}
                      options={Item}
                      value={selectedFoilItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                    >
                      {" "}
                      Item
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="foilColor"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "foilColor");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getFoilColorTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllFoil = foilResult?.data?.data?.foilColor?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllFoil) {
                      //             setFoil(getAllFoil)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setFoilAdd(data);
                        setShowAddFoil(true);
                      }}
                      options={Foil}
                      value={selectedFoil}
                    />
                    <label
                      htmlFor="foilColor"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                    >
                      foil color
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="foilremarks"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "foilremarks");
                        } else {
                          setSelectedFoilRemarks({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getRemarkTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         remarksTrigger('')
                      //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllRemark) {
                      //             setRemarks(getAllRemark)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setRemarksAdd(data);
                        setShowAddRemarks(true);
                        setItemType("foil");
                      }}
                      options={Remarks}
                      value={selectedFoilRemarks}
                    />
                    <label
                      htmlFor="foilremarks"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                    >
                      Remarks
                    </label>
                  </div>
                  <TextBox
                    id="qty"
                    type="number"
                    name="qty"
                    htmlFor="qty1"
                    label="qty"
                    placeholder=" "
                    value={foilData?.qty}
                    onChange={(e: any) => handleFoilChanges(e)}
                  />
                  <TextBox
                    id="rate"
                    type="number"
                    name="rate"
                    htmlFor="rate2"
                    label="rate"
                    placeholder=" "
                    value={foilData?.rate}
                    onChange={(e: any) => handleFoilChanges(e)}
                  />
                  <TextBox
                    id="amount"
                    type="number"
                    name="amount"
                    htmlFor="amount1"
                    label="amount"
                    placeholder=" "
                    value={foilData?.amount}
                    disabled
                    onChange={(e: any) => handleFoilChanges(e)}
                  />
                  <div className="w-full">
                    <button
                      className="px-3 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      onClick={() => {
                        if (isFoilUpdate) {
                          let amount = Math.floor(
                            Number(foilData?.qty) * Number(foilData?.rate)
                          );
                          let record = {
                            _id: selectedFoilRow?._id || "",
                            colorId: selectedFoil?.value || "",
                            itemId: selectedFoilItem?.value || "",
                            remarkId: selectedFoilRemarks?.value || "",
                            qty: foilData?.qty || "",
                            rate: foilData?.rate || "",
                            amount: amount || "",
                          };
                          const result: any = sareeFoilValidation(record);
                          if (result === true) {
                            const elementsIndex = foilTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedFoilRow?._id
                            );

                            let newArray = [...foilTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setFoilTableData(newArray);

                            setFoilData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedFoil({});
                            setSelectedFoilItem({});
                            setSelectedFoilRemarks({});

                            setIsFoilUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount = Math.floor(
                            Number(foilData?.qty) * Number(foilData?.rate)
                          );
                          let object = {
                            _id: ObjectID().toHexString(),
                            colorId: selectedFoil?.value || "",
                            itemId: selectedFoilItem?.value || "",
                            remarkId: selectedFoilRemarks?.value || "",
                            qty: foilData?.qty || "",
                            rate: foilData?.rate || "",
                            amount: amount || "",
                          };

                          const result: any = sareeFoilValidation(object);
                          if (result === true) {
                            setFoilTableData([...foilTableData, object]);

                            setFoilData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedFoil({});
                            setSelectedFoilItem({});
                            setSelectedFoilRemarks({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                          foilRef.current.focus();
                        }
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isFoilUpdate ? "Update" : "Add"} foil
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {foilTableData && foilTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          foil color
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          qty
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          rate
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {foilTableData?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {foilState[item?.colorId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {remarksState[item?.remarkId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.qty}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.rate}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.amount}
                                              </td>
                                              <td>
                                                <div>
                                                  <button
                                                    className="m-2"
                                                    type="button"
                                                    onClick={() => {
                                                      setSelectedFoilRow(item);
                                                      setSelectedFoilItem({
                                                        value: item?.itemId,
                                                        label:
                                                          itemState[
                                                            item?.itemId
                                                          ],
                                                      });
                                                      setSelectedFoil({
                                                        value: item?.colorId,
                                                        label:
                                                          foilState[
                                                            item?.colorId
                                                          ],
                                                      });
                                                      setSelectedFoilRemarks({
                                                        value: item?.remarkId,
                                                        label:
                                                          remarksState[
                                                            item?.remarkId
                                                          ],
                                                      });

                                                      setFoilData({
                                                        qty: item?.qty,
                                                        amount: item?.amount,
                                                        rate: item?.rate,
                                                      });

                                                      setIsFoilUpdate(true);
                                                    }}
                                                  >
                                                    <i className="fas fa-pen hover:text-green-600"></i>
                                                  </button>
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            foilTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setFoilTableData(
                                                            newData
                                                          );
                                                          setIsFoilUpdate(
                                                            false
                                                          );
                                                          setFoilData({
                                                            qty: "",
                                                            rate: "",
                                                            amount: "",
                                                          });
                                                          setSelectedFoil({});
                                                          setSelectedFoilItem(
                                                            {}
                                                          );
                                                          setSelectedFoilRemarks(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td
                                          colSpan={6}
                                          className="px-6 py-1 font-bold text-gray-900 text-left"
                                        >
                                          Total{" "}
                                        </td>
                                        <td
                                          colSpan={2}
                                          className="px-6 font-bold text-gray-900 text-left"
                                        >
                                          {foilTotal}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Foil Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Stitching */}
              <div className="px-2">
                <div>
                  <fieldset className="border-2 mt-2 rounded-2 border-gray-400 p-2">
                    <legend className="capitalize px-2">Stitching</legend>
                    <div>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-4">
                        <div className="relative rounded-md">
                          <CreatableSelect
                            ref={stitchingRef}
                            name="item"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "item");
                              } else {
                                setSelectedItem({});
                              }
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getItemTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllItem) {
                            //             setItem(getAllItem)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setItemAdd(data);
                              setShowAddItem(true);
                              setItemType("");
                            }}
                            options={Item}
                            value={selectedItem}
                          />
                          <label
                            htmlFor="item"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                          >
                            {" "}
                            Item
                          </label>
                        </div>
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="material"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "material");
                              } else {
                                setSelectedMaterial({});
                              }
                            }}
                            isClearable={true}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getMaterialTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         materialTrigger('')
                            //         let getAllMaterial = materialResult.data?.data?.material?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });

                            //         if (getAllMaterial) {
                            //             setMaterial(getAllMaterial)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setMaterialAdd(data);
                              setShowAddMaterial(true);
                            }}
                            options={Material}
                            value={selectedMaterial}
                          />
                          <label
                            htmlFor="material"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                          >
                            {" "}
                            Material
                          </label>
                        </div>
                        <TextBox
                          htmlFor="meter"
                          id="meter"
                          name="meter"
                          label="Meter"
                          type="number"
                          placeholder=" "
                          className="px-0"
                          value={statchingData?.meter}
                          onChange={(e: any) => handleStatchingChanges(e)}
                        />
                        <TextBox
                          htmlFor="qty"
                          id="qty"
                          name="qty"
                          label="Qty"
                          type="number"
                          placeholder=" "
                          className="px-0"
                          value={statchingData?.qty}
                          onChange={(e: any) => handleStatchingChanges(e)}
                        />
                        <TextBox
                          id="rate"
                          name="rate"
                          htmlFor="rate3"
                          label="Rate"
                          placeholder=" "
                          type="number"
                          value={statchingData?.rate}
                          onChange={(e: any) => handleStatchingChanges(e)}
                        />
                        <TextBox
                          id="amount"
                          name="amount"
                          htmlFor="amount"
                          label="Amount"
                          placeholder=" "
                          type="number"
                          value={statchingData?.amount}
                          disabled
                          onChange={(e: any) => handleStatchingChanges(e)}
                        />
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="remarks"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "stitchremarks");
                              } else {
                                setSelectedStitchRemarks({});
                              }
                            }}
                            isClearable={true}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getRemarkTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         remarksTrigger('')
                            //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllRemark) {
                            //             setRemarks(getAllRemark)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setRemarksAdd(data);
                              setShowAddRemarks(true);
                              setItemType("stitch");
                            }}
                            options={Remarks}
                            value={selectedStitchRemarks}
                          />
                          <label
                            htmlFor="stitchremarks"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                          >
                            Remarks
                          </label>
                        </div>
                        <div>
                          <button
                            className="w-full px-8 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                            onClick={() => {
                              if (isStatchUpdate) {
                                let statching = {
                                  _id: selectedStatchRow?._id,
                                  materialId: selectedMaterial?.value || "",
                                  remark: selectedStitchRemarks?.label || "",
                                  itemId: selectedItem?.value || "",
                                  amount: Math.ceil(
                                    Number(statchingData?.qty) *
                                      Number(statchingData?.rate)
                                  ),
                                  meter: Number(statchingData?.meter) || "",
                                  qty: Number(statchingData?.qty) || "",
                                  rate: Number(statchingData?.rate) || "",
                                };
                                const result: any =
                                  stitchingValidation(statching);
                                if (result === true) {
                                  const elementsIndex =
                                    statchingTableData.findIndex(
                                      (element: any) =>
                                        element?._id == selectedStatchRow?._id
                                    );

                                  let newArray = [...statchingTableData];

                                  newArray[elementsIndex] = {
                                    ...newArray[elementsIndex],
                                    ...statching,
                                  };

                                  setStatchingTableData(newArray);
                                  setStatchingData({
                                    amount: "",
                                    meter: "",
                                    qty: "",
                                    rate: "",
                                  });
                                  setSelectedStitchRemarks({});
                                  setSelectedItem({});
                                  setSelectedMaterial({});

                                  setIsStatchUpdate(false);
                                } else {
                                  Swal.fire({
                                    toast: true,
                                    icon: "error",
                                    title: result.errorMessage,
                                    position: "top-end",
                                    showConfirmButton: false,
                                    timer: ERROR_MESSAGE_TIMEOUT,
                                    timerProgressBar: true,
                                  });
                                }
                              } else {
                                let statching = {
                                  _id: ObjectID().toHexString(),
                                  materialId: selectedMaterial?.value || "",
                                  itemId: selectedItem?.value || "",
                                  remark: selectedStitchRemarks?.label || "",
                                  amount: Math.ceil(
                                    Number(statchingData?.qty) *
                                      Number(statchingData?.rate)
                                  ),
                                  meter: Number(statchingData?.meter) || "",
                                  qty: Number(statchingData?.qty) || "",
                                  rate: Number(statchingData?.rate) || "",
                                };
                                const result: any =
                                  stitchingValidation(statching);
                                if (result === true) {
                                  setStatchingTableData([
                                    ...statchingTableData,
                                    statching,
                                  ]);

                                  setStatchingData({
                                    amount: "",
                                    meter: "",
                                    qty: "",
                                    rate: "",
                                  });
                                  setSelectedStitchRemarks({});
                                  setSelectedItem({});
                                  setSelectedMaterial({});
                                } else {
                                  Swal.fire({
                                    toast: true,
                                    icon: "error",
                                    title: result.errorMessage,
                                    position: "top-end",
                                    showConfirmButton: false,
                                    timer: ERROR_MESSAGE_TIMEOUT,
                                    timerProgressBar: true,
                                  });
                                }
                              }
                              stitchingRef.current.focus();
                            }}
                          >
                            {isStatchUpdate ? "Update" : "Add"} Stitching{" "}
                          </button>
                        </div>
                        {/* Table */}
                        <div className="col-span-1 mt-2 lg:col-span-4 border border-gray-300 rounded-3 overflow-hidden">
                          <div className="border border-gray-300 rounded-3 overflow-hidden">
                            <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="min-w-full sm:px-6 lg:px-0">
                                  <div className="overflow-hidden">
                                    <div className="flex-auto px-0 pt-0">
                                      <div className="p-0 overflow-hidden">
                                        {statchingTableData &&
                                        statchingTableData.length > 0 ? (
                                          <div className="max-h-80 overflow-auto shadow-md">
                                            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                              <thead className="bg-gray-50 capitalize">
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    item
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    material
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    meter
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    qty
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    rate
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    amount
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-3 font-bold text-gray-900"
                                                  >
                                                    remarks
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-center text-gray-900"
                                                  >
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                                {statchingTableData?.map(
                                                  (row: any, index: number) => {
                                                    return (
                                                      <tr
                                                        key={index}
                                                        className="hover:bg-gray-50"
                                                      >
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {
                                                            itemState[
                                                              row?.itemId
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {
                                                            materialState[
                                                              row?.materialId
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {row.meter}
                                                        </td>
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {row.qty}
                                                        </td>
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {row.rate}
                                                        </td>
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {row.amount}
                                                        </td>
                                                        <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                          {row.remark}
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <button
                                                              className="m-2"
                                                              type="button"
                                                              onClick={() => {
                                                                setSelectedStatchRow(
                                                                  row
                                                                );
                                                                setStatchingData(
                                                                  {
                                                                    amount:
                                                                      row?.amount,
                                                                    meter:
                                                                      row?.meter,
                                                                    qty: row?.qty,
                                                                    rate: row?.rate,
                                                                  }
                                                                );
                                                                setSelectedItem(
                                                                  {
                                                                    value:
                                                                      row?.itemId,
                                                                    label:
                                                                      itemState[
                                                                        row
                                                                          ?.itemId
                                                                      ],
                                                                  }
                                                                );
                                                                setSelectedMaterial(
                                                                  {
                                                                    value:
                                                                      row?.materialId,
                                                                    label:
                                                                      materialState[
                                                                        row
                                                                          ?.materialId
                                                                      ],
                                                                  }
                                                                );
                                                                setSelectedStitchRemarks(
                                                                  {
                                                                    value:
                                                                      row?.remark,
                                                                    label:
                                                                      row?.remark,
                                                                  }
                                                                );
                                                                setIsStatchUpdate(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <i className="fas fa-pen hover:text-green-600"></i>
                                                            </button>
                                                            <button
                                                              className="m-2"
                                                              onClick={() => {
                                                                Swal.fire({
                                                                  title:
                                                                    "Are you sure?",
                                                                  text: "Once deleted, you will not be able to recover this record!",
                                                                  icon: "warning",
                                                                  showCancelButton:
                                                                    true,
                                                                  confirmButtonColor:
                                                                    "#3085d6",
                                                                  cancelButtonColor:
                                                                    "#d33",
                                                                  confirmButtonText:
                                                                    "Delete",
                                                                }).then(
                                                                  (
                                                                    willDelete
                                                                  ) => {
                                                                    if (
                                                                      willDelete.isConfirmed
                                                                    ) {
                                                                      let newData =
                                                                        statchingTableData.filter(
                                                                          (
                                                                            item: any
                                                                          ) => {
                                                                            return (
                                                                              item._id !==
                                                                              row?._id
                                                                            );
                                                                          }
                                                                        );

                                                                      setStatchingTableData(
                                                                        newData
                                                                      );

                                                                      setStatchingData(
                                                                        {
                                                                          amount:
                                                                            "",
                                                                          meter:
                                                                            "",
                                                                          qty: "",
                                                                          rate: "",
                                                                        }
                                                                      );
                                                                      setSelectedStitchRemarks(
                                                                        {}
                                                                      );
                                                                      setSelectedItem(
                                                                        {}
                                                                      );
                                                                      setSelectedMaterial(
                                                                        {}
                                                                      );
                                                                      setIsStatchUpdate(
                                                                        false
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <i className="fas fa-trash hover:text-red-600"></i>
                                                            </button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                              <tfoot className="border-t bg-gray-50">
                                                <tr>
                                                  <td
                                                    colSpan={5}
                                                    className="px-6 py-1 font-bold text-gray-900 text-left"
                                                  >
                                                    Total{" "}
                                                  </td>
                                                  <td
                                                    colSpan={3}
                                                    className="px-6 font-bold text-gray-900 text-left"
                                                  >
                                                    {stitchTotal}
                                                  </td>
                                                </tr>
                                              </tfoot>
                                            </table>
                                          </div>
                                        ) : (
                                          <div className="text-center py-3 text-gray-600 font-bold">
                                            No Stitching Found
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              {/* Hand Work Configaration */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Hand Work</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      ref={handWorkRef}
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "handitem");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("hand");
                      }}
                      options={Item}
                      value={selectedHandItem}
                    />
                    <label
                      htmlFor="item"
                      className="absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                    >
                      Item
                    </label>
                  </div>
                  <TextBox
                    id="head"
                    type="text"
                    name="head"
                    htmlFor="head"
                    label="head"
                    placeholder=" "
                    value={handData?.head}
                    onChange={(e: any) => handleHandChanges(e)}
                  />
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="remarks"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "handremarks");
                        } else {
                          setSelectedHandRemarks({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getRemarkTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         remarksTrigger('')
                      //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllRemark) {
                      //             setRemarks(getAllRemark)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setRemarksAdd(data);
                        setShowAddRemarks(true);
                        setItemType("hand");
                      }}
                      options={Remarks}
                      value={selectedHandRemarks}
                    />
                    <label
                      htmlFor="remarks"
                      className="absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize"
                    >
                      Remarks
                    </label>
                  </div>

                  <div className="relative rounded-md">
                    <CreatableSelect
                      isMulti
                      name="diamond"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block mb-2 text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any, action: any) => {
                        if (action.action === "remove-value") {
                          handleRemoveOption(action.removedValue);
                        }
                        if (action.action === "select-option") {
                          handleChange(e, "diamond");
                        }
                      }}
                      onCreateOption={(data: any) => {
                        setDiamondAdd(data);
                        setShowAddDiamond(true);
                      }}
                      options={creatableOptions}
                      value={handTableData[0]?.amount ? null : isOldDiamond}
                      isDisabled={
                        (handTableData.length === 1 && isHandUpdate) ||
                        handTableData.length < 1
                          ? false
                          : true
                      }
                    />
                    <label
                      htmlFor="diamond"
                      className="absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize"
                    >
                      Diamond
                    </label>
                  </div>

                  {isOldDiamond?.map((item: any, index: any) => {
                    return handTableData[0]?.amount ? null : (
                      <div className="relative rounded-md">
                        <TextBox
                          className="mb-2"
                          key={index}
                          name="qty"
                          type="number"
                          id="qty"
                          htmlFor="qty"
                          onChange={(e: any) => {
                            let diamond: any = isOldDiamond;
                            diamond[index].qty = e.target.value;
                            setIsOldDiamond([...diamond]);
                          }}
                          value={item?.qty}
                          label={item?.label}
                        />
                      </div>
                    );
                  })}
                  <div className="w-full">
                    <button
                      disabled={handTableData[0]?.amount}
                      className={`px-2 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px ${
                        !handTableData[0]?.amount
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        setHandData({
                          diamond: [],
                          head: "",
                        });
                        if (isHandUpdate) {
                          let record = {
                            _id: selectedHandRow?._id,
                            itemId: selectedHandItem?.value || "",
                            remarkId: selectedHandRemarks?.value || "",
                            head: Number(handData?.head) || "",
                            diamond: isOldDiamond?.map((item: any) => {
                              return {
                                titleId: item?.value,
                                qty: Number(item?.qty),
                                title: {
                                  _id: item?.value,
                                  name: item?.label,
                                },
                              };
                            }),
                          };
                          const result: any = sareeHandValidation(record);
                          if (result === true) {
                            const elementsIndex = handTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedHandRow?._id
                            );

                            let newArray = [...handTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setHandTableData(newArray);
                            setSelectedHandItem({});
                            setSelectedHandRemarks({});

                            setIsHandUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let object = {
                            _id: ObjectID().toHexString(),
                            itemId: selectedHandItem?.value || "",
                            remarkId: selectedHandRemarks?.value || "",
                            head: Number(handData?.head) || "",
                            diamond: isOldDiamond?.map((item: any) => {
                              return {
                                titleId: item?.value,
                                qty: Number(item?.qty),
                                title: {
                                  _id: item?.value,
                                  name: item?.label,
                                },
                              };
                            }),
                          };
                          const result: any = sareeHandValidation(object);
                          if (result === true) {
                            setHandTableData([...handTableData, object]);
                            setSelectedHandItem({});
                            setSelectedHandRemarks({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        }
                        handWorkRef.current.focus();
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isHandUpdate ? "Update" : "Add"} Handwork
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {handTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          remarks
                                        </th>

                                        {handTableData[0]?.amount >= 0 ? (
                                          <>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Diamond
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Qty
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Rate
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              Amount
                                            </th>
                                          </>
                                        ) : (
                                          <>
                                            <th
                                              scope="col"
                                              className="px-6 font-bold text-gray-900"
                                            >
                                              head
                                            </th>
                                            {
                                              <>
                                                {Object.entries(
                                                  diamondTotal
                                                ).map(
                                                  (
                                                    [titleId, title]: any,
                                                    index: number
                                                  ) => {
                                                    const matchingOldDiamond =
                                                      isOldDiamond?.[index]
                                                        ?.label;

                                                    return (
                                                      <>
                                                        <th
                                                          scope="col"
                                                          className="px-6 font-bold text-gray-900"
                                                        >
                                                          Rate
                                                        </th>
                                                        <th
                                                          className="px-6 font-bold text-gray-900"
                                                          key={titleId}
                                                        >
                                                          {matchingOldDiamond}
                                                        </th>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            }
                                          </>
                                        )}

                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {handTableData &&
                                        handTableData?.map(
                                          (item: any, index: number) => {
                                            let total = 0;
                                            let totalDiamond = 0;
                                            return (
                                              <tr
                                                key={index}
                                                className="hover:bg-gray-50"
                                              >
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {index + 1}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {itemState[item?.itemId]}
                                                </td>
                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                  {remarksState[item?.remarkId]}
                                                </td>
                                                {item?.amount ? (
                                                  <>
                                                    {item?.diamond?.length >
                                                    0 ? (
                                                      item?.diamond?.map(
                                                        (i: any) => {
                                                          return (
                                                            <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                              {" "}
                                                              {i?.name}
                                                            </td>
                                                          );
                                                        }
                                                      )
                                                    ) : (
                                                      <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                        {" "}
                                                        -
                                                      </td>
                                                    )}

                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.qty}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.rate}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.amount}
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.head}
                                                    </td>
                                                    {item?.diamond?.length !==
                                                      0 &&
                                                      item?.diamond?.map(
                                                        (
                                                          diamond: any,
                                                          index: any
                                                        ) => {
                                                          total =
                                                            Math.floor(
                                                              Number(
                                                                item?.head
                                                              ) *
                                                                Number(
                                                                  diamond?.qty
                                                                )
                                                            ) + total;
                                                          totalDiamond =
                                                            totalDiamond +
                                                            total;

                                                          return (
                                                            <>
                                                              <td
                                                                scope="col"
                                                                key={index}
                                                                className="gap-3 px-6 py-2 font-normal text-gray-900 text-left"
                                                              >
                                                                {diamond?.qty}
                                                              </td>

                                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                                {Math.floor(
                                                                  Number(
                                                                    item?.head
                                                                  ) *
                                                                    Number(
                                                                      diamond?.qty
                                                                    )
                                                                )}
                                                              </td>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                  </>
                                                )}

                                                <td>
                                                  <div>
                                                    {!handTableData[0]
                                                      ?.amount ? (
                                                      <button
                                                        className="m-2"
                                                        type="button"
                                                        onClick={() => {
                                                          setIsOldDiamond(
                                                            item?.diamond?.map(
                                                              (item: any) => {
                                                                return {
                                                                  value:
                                                                    item?.titleId,
                                                                  qty: Number(
                                                                    item?.qty
                                                                  ),
                                                                  label:
                                                                    item?.title
                                                                      ?.name,
                                                                };
                                                              }
                                                            )
                                                          );
                                                          setSelectedHandRow(
                                                            item
                                                          );
                                                          setSelectedHandItem({
                                                            value: item?.itemId,
                                                            label:
                                                              itemState[
                                                                item?.itemId
                                                              ],
                                                          });
                                                          setSelectedHandRemarks(
                                                            {
                                                              value:
                                                                item?.remarkId,
                                                              label:
                                                                remarksState[
                                                                  item?.remarkId
                                                                ],
                                                            }
                                                          );
                                                          setHandData({
                                                            qty: item?.qty,
                                                            amount:
                                                              item?.amount,
                                                            rate: item?.rate,
                                                            head: item?.head,
                                                            diamond:
                                                              item?.diamond,
                                                          });
                                                          setIsHandUpdate(true);
                                                        }}
                                                      >
                                                        <i className="fas fa-pen hover:text-green-600"></i>
                                                      </button>
                                                    ) : null}
                                                    <button
                                                      className="m-2"
                                                      onClick={() => {
                                                        Swal.fire({
                                                          title:
                                                            "Are you sure?",
                                                          text: "Once deleted, you will not be able to recover this record!",
                                                          icon: "warning",
                                                          showCancelButton:
                                                            true,
                                                          confirmButtonColor:
                                                            "#3085d6",
                                                          cancelButtonColor:
                                                            "#d33",
                                                          confirmButtonText:
                                                            "Delete",
                                                        }).then(
                                                          (willDelete) => {
                                                            if (
                                                              willDelete.isConfirmed
                                                            ) {
                                                              let newData =
                                                                handTableData.filter(
                                                                  (
                                                                    record: any
                                                                  ) => {
                                                                    return (
                                                                      record?._id !==
                                                                      item?._id
                                                                    );
                                                                  }
                                                                );
                                                              setHandTableData(
                                                                newData
                                                              );
                                                              setIsHandUpdate(
                                                                false
                                                              );
                                                              setHandData({
                                                                qty: "",
                                                                rate: "",
                                                                amount: "",
                                                                head: "",
                                                                diamond: [],
                                                              });
                                                              setSelectedHandItem(
                                                                {}
                                                              );
                                                              setSelectedHandRemarks(
                                                                {}
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <i className="fas fa-trash hover:text-red-600"></i>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot className="border-t bg-gray-50">
                                      <tr>
                                        <td
                                          colSpan={amountTotal ? 6 : 4}
                                          className="px-6 py-1 font-bold text-gray-900 text-left "
                                        >
                                          <div className="flex items-center">
                                            <span>Total</span>
                                            {isOldDiamond?.map(
                                              (item: any, index: any) => {
                                                return handTableData[0]
                                                  ?.amount ? null : (
                                                  <div
                                                    className="relative rounded-md ml-3 mt-2"
                                                    style={{ width: "100px" }}
                                                  >
                                                    <TextBox
                                                      className="mb-2"
                                                      key={index}
                                                      name="value"
                                                      type="number"
                                                      id="value"
                                                      htmlFor="value"
                                                      onChange={(e: any) => {
                                                        let updatedValues = [
                                                          ...isValue,
                                                        ];
                                                        updatedValues[index] =
                                                          Number(
                                                            e.target.value
                                                          );

                                                        if (
                                                          updatedValues.every(
                                                            (value) =>
                                                              value === ""
                                                          )
                                                        ) {
                                                          updatedValues = [];
                                                        }
                                                        setIsValue(
                                                          updatedValues
                                                        );
                                                      }}
                                                      value={
                                                        isValue[index] === 0
                                                          ? ""
                                                          : isValue[index]
                                                      }
                                                      label={item?.label}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </td>

                                        {
                                          <>
                                            {handTableData[0]?.amount === "" ||
                                            !handTableData[0]?.amount ? (
                                              <>
                                                {Object.values(
                                                  diamondTotal
                                                ).map(
                                                  (
                                                    item: any,
                                                    index: number
                                                  ) => {
                                                    let point: any =
                                                      parseFloat(
                                                        item?.totalQty
                                                      ) / 100;
                                                    let calculatedValue: any =
                                                      isValue[index] === "" ||
                                                      !isValue[index]
                                                        ? Math.floor(
                                                            Number(point)
                                                          ).toFixed(0)
                                                        : Math.floor(
                                                            point *
                                                              parseFloat(
                                                                isValue[index]
                                                              )
                                                          );
                                                    // let finalQty: any =
                                                    //   Math.floor(
                                                    //     Math.floor(
                                                    //       item?.total *
                                                    //         parseFloat(isValue[index])
                                                    //     ) / 100
                                                    //   );
                                                    return (
                                                      <>
                                                        <td
                                                          className="px-6 font-bold text-gray-900 text-left fill2"
                                                          key={index}
                                                        >
                                                          {calculatedValue
                                                            ? calculatedValue
                                                            : point}
                                                        </td>
                                                        <td
                                                          className="px-6 font-bold text-gray-900 text-left fill"
                                                          key={index}
                                                        >
                                                          {item?.totalQty}
                                                        </td>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {handTableData[0]?.amount !==
                                                "" ? (
                                                  amountTotal ? (
                                                    <td
                                                      colSpan={2}
                                                      className="px-6 font-bold text-gray-900 text-left blank"
                                                    >
                                                      {amountTotal}
                                                    </td>
                                                  ) : null
                                                ) : null}
                                              </>
                                            )}
                                          </>
                                        }
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Hand Work Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Dye Configaration */}
              <fieldset className="border-2 m-2 p-2 rounded-2 border-gray-400">
                <legend className="capitalize px-2">Daying</legend>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="item"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "dyeitem");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getItemTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllItem) {
                      //             setItem(getAllItem)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setItemAdd(data);
                        setShowAddItem(true);
                        setItemType("dye");
                      }}
                      options={Item}
                      value={selectedDyeItem}
                    />
                    <label
                      htmlFor="item"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                    >
                      Item
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="foilColor"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        handleChange(e, "dyeColor");
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getFoilColorTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllFoil = foilResult?.data?.data?.foilColor?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllFoil) {
                      //             setFoil(getAllFoil)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setFoilAdd(data);
                        setShowAddFoil(true);
                      }}
                      options={Foil}
                      value={selectedDye}
                    />
                    <label
                      htmlFor="foilColor"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                    >
                      Dye color
                    </label>
                  </div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="dyeremarks"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "dyeremarks");
                        } else {
                          setSelectedDyeRemarks({});
                        }
                      }}
                      isClearable={true}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getRemarkTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         remarksTrigger('')
                      //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllRemark) {
                      //             setRemarks(getAllRemark)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setRemarksAdd(data);
                        setShowAddRemarks(true);
                        setItemType("dye");
                      }}
                      options={Remarks}
                      value={selectedDyeRemarks}
                    />
                    <label
                      htmlFor="dyeremarks"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                    >
                      Remarks
                    </label>
                  </div>
                  <TextBox
                    id="qty"
                    type="number"
                    name="qty"
                    htmlFor="qty1"
                    label="qty"
                    placeholder=" "
                    value={dyeData?.qty}
                    onChange={(e: any) => handleDyeChanges(e)}
                  />
                  <TextBox
                    id="rate"
                    type="number"
                    name="rate"
                    htmlFor="rate2"
                    label="rate"
                    placeholder=" "
                    value={dyeData?.rate}
                    onChange={(e: any) => handleDyeChanges(e)}
                  />
                  <TextBox
                    id="amount"
                    type="number"
                    name="amount"
                    htmlFor="amount1"
                    label="amount"
                    placeholder=" "
                    value={dyeData?.amount}
                    disabled
                    onChange={(e: any) => handleDyeChanges(e)}
                  />
                  <div className="w-full">
                    <button
                      className="px-3 w-full py-2 text-md leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      onClick={() => {
                        if (isDyeUpdate) {
                          let amount = Math.floor(
                            Number(dyeData?.qty) * Number(dyeData?.rate)
                          );
                          let record = {
                            _id: selectedDyeRow?._id || "",
                            colorId: selectedDye?.value || "",
                            itemId: selectedDyeItem?.value || "",
                            remarkId: selectedDyeRemarks?.value || "",
                            qty: dyeData?.qty || "",
                            rate: dyeData?.rate || "",
                            amount: amount || "",
                          };
                          const result: any = sareeFoilValidation(record);
                          if (result === true) {
                            const elementsIndex = dyeTableData.findIndex(
                              (element: any) =>
                                element?._id == selectedDyeRow?._id
                            );

                            let newArray = [...dyeTableData];

                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              ...record,
                            };

                            setDyeTableData(newArray);

                            setDyeData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedDye({});
                            setSelectedDyeItem({});
                            setSelectedDyeRemarks({});

                            setIsDyeUpdate(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        } else {
                          let amount = Math.floor(
                            Number(dyeData?.qty) * Number(dyeData?.rate)
                          );
                          let object = {
                            _id: ObjectID().toHexString(),
                            colorId: selectedDye?.value || "",
                            itemId: selectedDyeItem?.value || "",
                            remarkId: selectedDyeRemarks?.value || "",
                            qty: dyeData?.qty || "",
                            rate: dyeData?.rate || "",
                            amount: amount || "",
                          };
                          const result: any = sareeFoilValidation(object);
                          if (result === true) {
                            setDyeTableData([...dyeTableData, object]);

                            setDyeData({
                              qty: "",
                              rate: "",
                              amount: "",
                            });
                            setSelectedDye({});
                            setSelectedDyeItem({});
                            setSelectedDyeRemarks({});
                          } else {
                            Swal.fire({
                              toast: true,
                              icon: "error",
                              title: result.errorMessage,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: ERROR_MESSAGE_TIMEOUT,
                              timerProgressBar: true,
                            });
                          }
                        }
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      <span className="ml-2">
                        {isDyeUpdate ? "Update" : "Add"} Dye
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 lg:col-span-7">
                    <div className="border border-gray-300 rounded-3 overflow-hidden">
                      <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="overflow-hidden">
                            <div className="p-0 overflow-hidden">
                              {dyeTableData && dyeTableData?.length > 0 ? (
                                <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                  <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                    <thead className="bg-gray-50">
                                      <tr className="capitalize">
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          Sr No.
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          item
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          dye color
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          qty
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          rate
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-gray-900"
                                        >
                                          amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 font-bold text-center text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                      {dyeTableData?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="hover:bg-gray-50"
                                            >
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {index + 1}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {itemState[item?.itemId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {foilState[item?.colorId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {remarksState[item?.remarkId]}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.qty}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.rate}
                                              </td>
                                              <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                {item?.amount}
                                              </td>
                                              <td>
                                                <div>
                                                  <button
                                                    className="m-2"
                                                    type="button"
                                                    onClick={() => {
                                                      setSelectedDyeRow(item);
                                                      setSelectedDyeItem({
                                                        value: item?.itemId,
                                                        label:
                                                          itemState[
                                                            item?.itemId
                                                          ],
                                                      });
                                                      setSelectedDye({
                                                        value: item?.colorId,
                                                        label:
                                                          foilState[
                                                            item?.colorId
                                                          ],
                                                      });
                                                      setSelectedDyeRemarks({
                                                        value: item?.remarkId,
                                                        label:
                                                          remarksState[
                                                            item?.remarkId
                                                          ],
                                                      });

                                                      setDyeData({
                                                        qty: item?.qty,
                                                        amount: item?.amount,
                                                        rate: item?.rate,
                                                      });

                                                      setIsDyeUpdate(true);
                                                    }}
                                                  >
                                                    <i className="fas fa-pen hover:text-green-600"></i>
                                                  </button>
                                                  <button
                                                    className="m-2"
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this record!",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                          "#3085d6",
                                                        cancelButtonColor:
                                                          "#d33",
                                                        confirmButtonText:
                                                          "Delete",
                                                      }).then((willDelete) => {
                                                        if (
                                                          willDelete.isConfirmed
                                                        ) {
                                                          let newData =
                                                            dyeTableData.filter(
                                                              (record: any) => {
                                                                return (
                                                                  record?._id !==
                                                                  item?._id
                                                                );
                                                              }
                                                            );
                                                          setDyeTableData(
                                                            newData
                                                          );
                                                          setIsDyeUpdate(false);
                                                          setDyeData({
                                                            qty: "",
                                                            rate: "",
                                                            amount: "",
                                                          });
                                                          setSelectedDye({});
                                                          setSelectedDyeItem(
                                                            {}
                                                          );
                                                          setSelectedDyeRemarks(
                                                            {}
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i className="fas fa-trash hover:text-red-600"></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td
                                          colSpan={6}
                                          className="px-6 py-1 font-bold text-gray-900 text-left"
                                        >
                                          Total{" "}
                                        </td>
                                        <td
                                          colSpan={2}
                                          className="px-6 font-bold text-gray-900 text-left"
                                        >
                                          {dyeTotal}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center py-3 text-gray-600 font-bold">
                                  No Daying Data Found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 px-2">
                <div className="col-span-1">
                  <div className="mt-2 col-span-3 md:col-span-1">
                    <ImageUploading
                      multiple={false}
                      onChange={(e: any) => handlepostImage(e)}
                      maxNumber={1}
                      acceptType={["png", "jpg", "jpeg"]}
                      maxFileSize={FILE_UPLOAD_SIZE}
                      dataURLKey="data_url"
                      value={images}
                    >
                      {({ onImageUpload, errors }) => {
                        return (
                          <div className="text-center">
                            {errors && (
                              <div>
                                {errors.acceptType && (
                                  <span className="text-red-500">
                                    <i className="	fa fa-warning"></i>Your
                                    selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span className="text-red-500">
                                    <i className="fa fa-warning"></i>Selected
                                    file size exceed maxFileSize
                                  </span>
                                )}
                              </div>
                            )}
                            {images ? (
                              <div className="">
                                <button
                                  className="mt-1 w-full h-full flex justify-center rounded-md"
                                  onClick={() => onImageUpload()}
                                ></button>

                                <div className="relative">
                                  <button
                                    className="absolute top-0 right-0 bg-white rounded-lg"
                                    onClick={() => {
                                      imageDelete(images);
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                  <ProgressiveImage src={images} placeholder="">
                                    {(src: any) => (
                                      <img
                                        className="object-contain h-90 w-full px-2"
                                        src={src}
                                        alt="img"
                                      />
                                    )}
                                  </ProgressiveImage>
                                </div>
                              </div>
                            ) : (
                              <button
                                className={`mt-1 h-full w-full flex justify-center  rounded-md border-2 border-solid px-3 py-10 ${
                                  errorMessage?.image
                                    ? "border-red-500"
                                    : "border-gray-300 "
                                }`}
                                onClick={() => onImageUpload()}
                              >
                                <div className="w-full h-full flex justify-center items-center content-center flex-wrap space-y-1 text-center">
                                  <img
                                    src="../../../assets/images/imageIcon.svg"
                                    alt="Image"
                                  />

                                  <div className="w-full text-sm text-gray-600">
                                    <label
                                      htmlFor="file-upload"
                                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                      <span>Upload a file</span>
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                  </div>
                                  <p className="text-xs text-gray-500">
                                    PNG, JPG Upto 1.5 MB
                                  </p>
                                </div>
                              </button>
                            )}
                          </div>
                        );
                      }}
                    </ImageUploading>
                  </div>
                  <span className="text-xs text-red-500 flex justify-start">
                    {errorMessage?.image}
                  </span>
                </div>
                <div className="grid capitalize gap-2 col-span-3 grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 py-2">
                  {/* <div className='flex items-center justify-center text-2xl font-bold text-gray-700'>
                                        Final costing
                                    </div> */}
                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isJobSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isJobSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isJobSelect) {
                        setIsJobSelect(false);
                      } else {
                        setIsJobSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        Job costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {jobTotal} +{" "}
                        {Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100))} ={" "}
                        {jobTotal +
                          Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100))}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center ">
                          <div>{jobTotal}</div>
                          <div>Jobs</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="jobPar"
                              type="number"
                              name="jobPar"
                              placeholder="0"
                              value={jobPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setJobPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isJobSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative inline-flex p-3 items-center text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isMeasureSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } justify-center capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isMeasureSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isMeasureSelect) {
                        setIsMeasureSelect(false);
                      } else {
                        setIsMeasureSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col leading-normal items-center">
                      <h5 className="mb-2 text-lg text-center text-gray-800 font-bold">
                        Fabric Measurement
                      </h5>
                      <p className="mb-3 font-bold text-4xl text-gray-700">
                        {measureTableTotal}
                      </p>
                    </div>
                    {isMeasureSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isCostSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isCostSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isCostSelect) {
                        setIsCostSelect(false);
                      } else {
                        setIsCostSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        Fabric costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {costTotal} +{" "}
                        {Math.ceil(costTotal * ((costPar ? costPar : 0) / 100))}{" "}
                        ={" "}
                        {costTotal +
                          Math.ceil(
                            costTotal * ((costPar ? costPar : 0) / 100)
                          )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center ">
                          <div>{costTotal}</div>
                          <div>Costing</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="costPar"
                              type="number"
                              name="costPar"
                              placeholder="0"
                              value={costPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setCostPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isCostSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isFoilSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isFoilSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isFoilSelect) {
                        setIsFoilSelect(false);
                      } else {
                        setIsFoilSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        Foil costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {foilTotal} +{" "}
                        {Math.ceil(foilTotal * ((foilPar ? foilPar : 0) / 100))}{" "}
                        ={" "}
                        {foilTotal +
                          Math.ceil(
                            foilTotal * ((foilPar ? foilPar : 0) / 100)
                          )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{foilTotal}</div>
                          <div>Foil</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="foilPar"
                              type="number"
                              name="foilPar"
                              placeholder="0"
                              value={foilPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white only:focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setFoilPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isFoilSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isStitchSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isStitchSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isStitchSelect) {
                        setIsStitchSelect(false);
                      } else {
                        setIsStitchSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        Stitch costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {stitchTotal} +{" "}
                        {Math.ceil(
                          stitchTotal * ((stitchPar ? stitchPar : 0) / 100)
                        )}{" "}
                        ={" "}
                        {stitchTotal +
                          Math.ceil(
                            stitchTotal * ((stitchPar ? stitchPar : 0) / 100)
                          )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{stitchTotal}</div>
                          <div>Stitchings</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="stitchPar"
                              type="number"
                              name="stitchPar"
                              placeholder="0"
                              value={stitchPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white only:focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setStitchPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isStitchSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isHandSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isHandSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isHandSelect) {
                        setIsHandSelect(false);
                      } else {
                        setIsHandSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        HandWork costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {!handTableData[0]?.amount ? (
                          <>
                            {handTableData?.length > 0 ? (
                              <></> ? (
                                <>
                                  {Object.entries(diamondTotal).map(
                                    ([titleId, total]: any, index: number) => {
                                      // let point: any = Math.floor(
                                      //   total?.totalQty / 100
                                      // );
                                      // let setTotal =
                                      //   isValue[index] === "" || !isValue[index]
                                      //     ? (parseFloat(total?.totalQty) / 100)
                                      //     : Math.ceil(point * isValue[index]);
                                      let point: any =
                                        parseFloat(total?.totalQty) / 100;
                                      let setTotal: any =
                                        isValue[index] === "" || !isValue[index]
                                          ? Math.floor(Number(point))
                                          : Math.floor(
                                              point * parseFloat(isValue[index])
                                            );
                                      pointTotal += setTotal;
                                    }
                                  )}
                                  {pointTotal}
                                </>
                              ) : (
                                0
                              )
                            ) : (
                              0
                            )}{" "}
                            +
                            {handTableData?.length > 0
                              ? Math.ceil(
                                  pointTotal * ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                            =
                            {handTableData?.length > 0
                              ? Math.ceil(
                                  pointTotal +
                                    pointTotal * ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                          </>
                        ) : (
                          <>
                            {handTableData?.length > 0
                              ? amountTotal
                                ? amountTotal
                                : 0
                              : 0}
                            +
                            {handTableData?.length > 0
                              ? Math.floor(
                                  amountTotal * ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                            =
                            {handTableData?.length > 0
                              ? Math.floor(
                                  amountTotal +
                                    amountTotal *
                                      ((handPar ? handPar : 0) / 100)
                                )
                              : 0}
                          </>
                        )}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>
                            {handTableData?.length > 0
                              ? !handTableData[0]?.amount
                                ? pointTotal
                                : amountTotal
                              : 0}
                          </div>
                          <div>HandWork</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="handPar"
                              type="number"
                              name="handPar"
                              placeholder="0"
                              value={handPar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white only:focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setHandPar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isHandSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <button
                    type="button"
                    className={`relative flex justify-center items-center p-3 text-center text-black focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                      isDyeSelect
                        ? "focus:bg-themeColor/60"
                        : "focus:bg-gray-400"
                    } capitalize bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl ${
                      isDyeSelect ? "bg-themeColor/60" : "bg-gray-400"
                    }`}
                    onClick={() => {
                      if (isDyeSelect) {
                        setIsDyeSelect(false);
                      } else {
                        setIsDyeSelect(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center leading-normal">
                      <h5 className="mb-2 text-base text-center text-gray-800 font-bold">
                        Daying costing
                      </h5>
                      <p className="mb-3 font-bold text-sm text-gray-700">
                        {dyeTotal} +{" "}
                        {Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100))} ={" "}
                        {dyeTotal +
                          Math.ceil(dyeTotal * ((dyePar ? dyePar : 0) / 100))}
                      </p>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col items-center">
                          <div>{dyeTotal}</div>
                          <div>Daying</div>
                        </div>
                        <div className="row-span-2 flex justify-center items-center">
                          X
                        </div>
                        <div className="flex flex-col items-center">
                          <div>
                            <input
                              id="dyePar"
                              type="number"
                              name="dyePar"
                              placeholder="0"
                              value={dyePar}
                              className="first-line:block px-2 w-16 text-sm bg-transparent rounded-lg border border-1 border-themeColor bg-white only:focus:outline-none focus:ring-0 focus:border-themeColor peer"
                              onChange={(e: any) => {
                                setDyePar(e.target.value);
                              }}
                            />
                          </div>
                          <div>Add %</div>
                        </div>
                      </div>
                    </div>
                    {isDyeSelect ? (
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2">
                        <i className="fas fa-check-circle text-base"></i>
                      </div>
                    ) : null}
                  </button>

                  <div className="flex justify-center items-center bg-gray-400 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl">
                    <div className="flex flex-col items-center p-4 leading-normal">
                      <h5 className="mb-2 text-xl text-center font-bold tracking-tight text-gray-900 ">
                        Total costing
                      </h5>
                      <p className="mb-3 font-bold text-4xl text-gray-700">
                        {(isJobSelect
                          ? jobTotal +
                            Math.ceil(jobTotal * ((jobPar ? jobPar : 0) / 100))
                          : 0) +
                          (isStitchSelect
                            ? stitchTotal +
                              Math.ceil(
                                stitchTotal *
                                  ((stitchPar ? stitchPar : 0) / 100)
                              )
                            : 0) +
                          (isCostSelect
                            ? costTotal +
                              Math.ceil(
                                costTotal * ((costPar ? costPar : 0) / 100)
                              )
                            : 0) +
                          (isFoilSelect
                            ? foilTotal +
                              Math.ceil(
                                foilTotal * ((foilPar ? foilPar : 0) / 100)
                              )
                            : 0) +
                          (isDyeSelect
                            ? dyeTotal +
                              Math.ceil(
                                dyeTotal * ((dyePar ? dyePar : 0) / 100)
                              )
                            : 0) +
                          (isHandSelect
                            ? handTableData?.length > 0
                              ? amountTotal
                                ? Math.floor(
                                    amountTotal +
                                      amountTotal *
                                        ((handPar ? handPar : 0) / 100)
                                  )
                                : Math.floor(
                                    pointTotal +
                                      pointTotal *
                                        ((handPar ? handPar : 0) / 100)
                                  )
                              : 0
                            : 0)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible p-2 gap-2">
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showDressRatePreview}
                    onClick={openInvoicePreview}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={isDressCardUpdate ? updateData : addData}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">
                      {isDressCardUpdate ? "Update" : "Save"}
                    </span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/dressratecarddashboard");
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Pdf Preview */}
            {showDressRatePreview && (
              <DressRateCardPreview
                showDressRatePreview={showDressRatePreview}
                setShowDressRatePreview={setShowDressRatePreview}
                singleCard={singleCard}
                pageName="Dress Rate card"
              />
            )}

            {/* Modal */}
            {showAddParty && (
              <PartyAdd
                setShowAddParty={setShowAddParty}
                showAddParty={showAddParty}
                setPartyNameAdd={setPartyNameAdd}
                partyNameAdd={partyNameAdd}
                getData={getData}
              />
            )}
            {showAddCloth && (
              <ClothAdd
                setShowAddCloth={setShowAddCloth}
                showAddCloth={showAddCloth}
                setClothAdd={setClothAdd}
                clothAdd={clothAdd}
                getClothData={getClothData}
              />
            )}
            {showAddClothParty && (
              <ClothPartyAdd
                setShowAddClothParty={setShowAddClothParty}
                showAddClothParty={showAddClothParty}
                setClothPartyAdd={setClothPartyAdd}
                clothPartyAdd={clothPartyAdd}
                getClothPartyData={getClothPartyData}
              />
            )}
            {showAddItem && (
              <ItemAdd
                setShowAddItem={setShowAddItem}
                showAddItem={showAddItem}
                setItemAdd={setItemAdd}
                itemAdd={itemAdd}
                getItemData={getItemData}
                itemType={itemType}
              />
            )}
            {showAddFabric && (
              <FabricAdd
                setShowAddFabric={setShowAddFabric}
                showAddFabric={showAddFabric}
                setFabricAdd={setFabricAdd}
                fabricAdd={fabricAdd}
                getFabricData={getFabricData}
              />
            )}
            {showAddMaterial && (
              <MaterialAdd
                setShowAddMaterial={setShowAddMaterial}
                showAddMaterial={showAddMaterial}
                setMaterialAdd={setMaterialAdd}
                materialAdd={materialAdd}
                getMaterialData={getMaterialData}
              />
            )}
            {showAddRemarks && (
              <RemarksAdd
                setShowAddRemarks={setShowAddRemarks}
                showAddRemarks={showAddRemarks}
                setRemarksAdd={setRemarksAdd}
                remarksAdd={remarksAdd}
                getRemarksData={getRemarksData}
                itemType={itemType}
              />
            )}
            {showAddDiamond && (
              <DiamondAdd
                setShowAddDiamond={setShowAddDiamond}
                showAddDiamond={showAddDiamond}
                setDiamondAdd={setDiamondAdd}
                diamondAdd={diamondAdd}
                getDiamondData={getDiamondData}
                setIsOldDiamond={setIsOldDiamond}
                isOldDiamond={isOldDiamond}
              />
            )}
            {showAddFoil && (
              <FoilColorAdd
                setShowAddFoil={setShowAddFoil}
                showAddFoil={showAddFoil}
                setFoilAdd={setFoilAdd}
                foilAdd={foilAdd}
                getFoilData={getFoilData}
              />
            )}
          </div>
        </div>
      </div>
      {isDressFetching ? <Loader /> : null}
    </div>
  );
}
