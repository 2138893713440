import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from 'react-to-print';

export const EmployeeReportPreview = (props: any) => {
    const exportRef: any = useRef(null);
    const reportData = props.singleReport
    const [today, setToday]: any = useState("")
    const [firstDate, setFirstDate]: any = useState("")

    useEffect(() => {
        let date = new Date() as any
        let month = date.getMonth() + 1
        month = month < 10 ? "0" + month : month
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
        setToday(date.getFullYear() + "-" + month + "-" + day)
        
        //Set pdf date to first date of selected month
        let date1 = new Date(reportData?.work[0]?.date) as any
        setFirstDate(new Date(date1.getFullYear(), date1.getMonth(), 1));
    }, [])

    return (
        <div>
            <Modal open={props.showReportPreview} showCloseIcon={false}
                closeOnEsc={false}
                onEscKeyDown={() => props.setShowReportPreview(false)}
                onOverlayClick={() => props.setShowReportPreview(false)}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl"
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto"
                    }
                }}
                aria-labelledby="dressrate-card-pdf"
                aria-describedby="dressrate-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setShowReportPreview(false)}>
                <div className="overflow-auto p-2">
                    <div ref={exportRef} className={`overflow-hidden a4-portrait m-auto`}>
                        <div className="border border-gray-600">
                            <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100 text-5">
                                <div className="text-themeColor capitalize">
                                    Name: {reportData?.name}
                                </div>
                                <div className="text-themeColor text-right capitalize">
                                    Date : {moment(firstDate).format("DD/MM/YYYY")}
                                </div>
                            </div>
                            <div className="p-2 border-y border-black">
                                <div className={`text-sm`}>
                                    <table className="w-full border border-collapse bg-white text-left">
                                        <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                                            <tr>
                                                <th scope="col" className="py-1.5 w-12 border-r text-red-800 border-black capitalize text-center">sr no</th>
                                                <th scope="col" className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center">date</th>
                                                <th scope="col" className="py-1.5 w-16 border-r text-red-800 border-black capitalize text-center">card no</th>
                                                <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">party name</th>
                                                <th scope="col" className="py-1.5 w-20 border-r text-red-800 border-black capitalize text-center">chalan no</th>
                                                <th scope="col" className="py-1.5 w-19 border-r text-red-800 border-black capitalize text-center">d no.</th>
                                                <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">Fabric</th>
                                                <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">pcs</th>
                                                <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">rate</th>
                                                <th scope="col" className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center">total salary</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                                            {
                                                reportData?.work?.map((item: any, i: any) => {
                                                    return (
                                                        <tr>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">{i + 1}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">{item?.date ? moment(item?.date).format("DD-MM-YYYY") : moment(today).format("DD-MM-YYYY")}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">{item?.cardNo}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.party?.name}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.challanNo}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.designNo}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.clothe?.name}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.pcs}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.rate}</td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{item?.totalWork}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot className=" bg-gray-100 text-center divide-black border border-black text-5">
                                            <tr>
                                                <td colSpan={5} className="gap-3 text-left border-r pl-2 border-black font-bold capitalize text-red-900 py-1.5">
                                                    total Pcs =
                                                    <span className="ml-2 font-bold">
                                                        {reportData?.totalPcs}
                                                    </span>
                                                </td>
                                                <td colSpan={5} className="gap-3 text-right border-r pr-2 border-black font-bold capitalize text-red-900 py-1.5">
                                                    total salary =
                                                    <span className="ml-2 font-bold">
                                                        {reportData?.totalRate}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-3 flex flex-col lg:flex-row gap-2">
                        <div>
                            <ReactToPrint
                                trigger={() =>
                                    <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2'>
                                        Print <i className='fas fa-file-download'></i>
                                    </button>
                                }
                                content={() => exportRef.current} documentTitle={reportData?.name + "-" + props.pageName}
                            />
                        </div>
                        <div>
                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => props.setShowReportPreview(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
