import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import {
  useAddEmployeeReportMutation,
  useLazyClothQuery,
  useLazyGetSareeReportDataQuery,
  useLazyGetSingleReportDataQuery,
  useLazyNewMatchingInvoiceNumberQuery,
  useLazyPartyQuery,
  useUpdateEmployeeReportMutation,
} from "../../../servicesRtkQuery/publicApi";
import React, { useEffect, useRef, useState } from "react";
import TextBox from "../../../components/form/TextBox";
import { reportItemValidation } from "../../../utils/validation";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";
import moment from "moment";
import { EmployeeReportPreview } from "../../../components/pdf/report/employeeReportPreview";
import Select from "../../../components/form/Select";
import DataTable from "react-data-table-component";
import customStyles from "../../../utils/globalFunctions";
import DataTableLoader from "../../../components/page/DataTableLoader";

export default function CreateEmployeeReport() {
  const navigate = useNavigate();
  let { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const startDate = queryParams.get("startDate");
  const [allCardNo, setAllCardNo]: any = useState();
  const [selectedCardNo, setSelectedCardNo]: any = useState({});
  const [selectedParty, setSelectedParty]: any = useState({});
  const [finalData, setFinalData]: any = useState({});
  const [name, setName]: any = useState("");
  const [date, setDate]: any = useState(new Date().toISOString().split("T")[0]);
  const [totalRate, setTotalRate]: any = useState(0);
  const [totalWork, setTotalWork]: any = useState(0);
  const [record, setRecord]: any = useState({});
  const [tableData, setTableData]: any = useState([]);
  const [Party, setParty]: any = useState([]);
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [globalWorkData, setGlobalWorkData]: any = useState([]);
  const [singleReportTrigger, singleReportResult] =
    useLazyGetSingleReportDataQuery();
  const {
    isSuccess: isSingleReportSuccess,
    isFetching: isSingleReportFetching,
    isError: isSingleReportError,
    error: singlereporterror,
  } = singleReportResult;
  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [partyTrigger, partyResult] = useLazyPartyQuery();
  const { isSuccess: isPartySuccess, isFetching: isPartyFetching } =
    partyResult;

  const [invoicetrigger, invoiceResult] =
    useLazyNewMatchingInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceerror,
  } = invoiceResult;

  const [sareeDataTrigger, sareeDataResult] = useLazyGetSareeReportDataQuery();
  const {
    isSuccess: isSareeDataSuccess,
    isFetching: isSareeDataFetching,
    isError: isSareeDataError,
    error: sareedataerror,
  } = sareeDataResult;

  const [addReportTrigger] = useAddEmployeeReportMutation();
  const [updateReportTrigger] = useUpdateEmployeeReportMutation();

  const [isUpdating, setIsUpdating]: any = useState(false);
  const [isUpdate, setIsUpdate]: any = useState(false);

  const [pdfData, setPDFData]: any = useState();
  const [showReportPreview, setShowReportPreview] = useState(false);
  const [today, setToday]: any = useState("");
  const [errorMessage, setErrorMessage]: any = useState({});

  const [yearList, setYearList]: any = useState([]);
  const [selectedYear, setSelectedYear]: any = useState();
  const [monthList, setMonthList]: any = useState([]);
  const [selectedMonth, setSelectedMonth]: any = useState();
  const [recordDateChanged, setRecordDateChanged]: any = useState(false);
  const [isFilterApplied, setIsFilterApplied]: any = useState(false);

  // Filtering states
  const [search, setSearch]: any = useState({
    fromDate: "",
    toDate: "",
    cardNo: "",
    type: "",
  });
  useEffect(() => {
    if (startDate) {
      const selectedYear = yearList?.find(
        (item: any) => item?.label === Number(moment(startDate).format("YYYY"))
      );
      const selectedMonth = monthList?.find(
        (item: any) => item?.value === Number(moment(startDate).format("MM"))
      );
      setSelectedYear(selectedYear);
      setSelectedMonth(selectedMonth);
    }
  }, [startDate, yearList, monthList]);
  function GenerateYearMonthData(maxYears: number) {
    let thisYear = 2023; // Set the first year to 2023
    let allYears: any = [];

    for (let x = 0; x <= maxYears; x++) {
      allYears.push({
        label: thisYear + x,
        value: thisYear + x,
      });
    }

    setYearList(allYears);

    let allMonths: any = [];
    moment.monthsShort().map((month, index) => {
      allMonths.push({
        label: month,
        value: index + 1,
      });
    });

    setMonthList(allMonths);
  }

  useEffect(() => {
    let date = new Date() as any;
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    setToday(date.getFullYear() + "-" + month + "-" + day);
    GenerateYearMonthData(27);
  }, []);

  useEffect(() => {
    if (record?.type === "sample") {
      partyTrigger();
      clothTrigger();
    }
    if (record?.type !== "Select Type") {
      if (record?.type !== "sample") invoicetrigger({ type: record?.type });
    } else {
      setAllCardNo([]);
      ClearRecord();
    }
  }, [record?.type]);

  useEffect(() => {
    if (id) {
      singleReportTrigger({
        rId: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (isPartySuccess && !isPartyFetching) {
      let getAllParty = partyResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setParty(getAllParty ? getAllParty : []);
    }
  }, [isPartySuccess, isPartyFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
    }
  }, [isClothSuccess, isClothFetching]);

  // useEffect(() => {
  //   if (isSingleReportSuccess && !isSingleReportFetching) {
  //     setName(singleReportResult?.data?.data?.report?.[0]?.name);
  //     setDate(
  //       moment(singleReportResult?.data?.data?.report?.[0]?.date).format(
  //         "yyyy-MM-DD"
  //       )
  //     );
  //     if (!startDate) {
  //       console.log("if")
  //       console.log(singleReportResult?.data?.data?.report?.[0]?.work)
  //       setTableData(singleReportResult?.data?.data?.report?.[0]?.work);
  //     }
  //     setGlobalWorkData([...singleReportResult?.data?.data?.report?.[0]?.work]);

  //     //setting Excel data
  //     CreatePDFData(
  //       singleReportResult?.data?.data?.report?.[0]?.work,
  //       singleReportResult?.data?.data?.report?.[0]?.totalRate
  //     );
  //   }
  // }, [isSingleReportSuccess, isSingleReportFetching]);
  // useEffect(() => {
  //   if (selectedYear && selectedMonth?.label && tableData?.length !== 0) {
  //       if (isSingleReportSuccess && !isSingleReportFetching) {
  //       setIsFilterApplied(true);
  //       let filtered: any = singleReportResult?.data?.data?.report?.[0]?.work?.filter((row: any) => {
  //         return (
  //           Number(moment(row?.date).format("YYYY")) === selectedYear.value &&
  //           Number(moment(row?.date).format("MM")) === selectedMonth.value
  //         );
  //       });
  //       setTableData(filtered);
  //     } else if (isSingleReportSuccess && !isSingleReportFetching) {
  //       setIsFilterApplied(false);

  //         setTableData([...globalWorkData]);
  //       }
  //   }
  // }, [selectedYear, selectedMonth]);
  useEffect(() => {
    if (isSingleReportSuccess && !isSingleReportFetching) {
      setName(singleReportResult?.data?.data?.report?.[0]?.name);
      setDate(
        moment(singleReportResult?.data?.data?.report?.[0]?.date).format(
          "yyyy-MM-DD"
        )
      );

      const newTableData =
        singleReportResult?.data?.data?.report?.[0]?.work || [];
      setGlobalWorkData([...newTableData]);

      // setting Excel data
      CreatePDFData(
        newTableData,
        singleReportResult?.data?.data?.report?.[0]?.totalRate
      );

      if (selectedYear && selectedMonth) {
        setIsFilterApplied(true);

        const filtered = newTableData.filter((row: any) => {
          return (
            Number(moment(row?.date).format("YYYY")) === selectedYear.value &&
            Number(moment(row?.date).format("MM")) === selectedMonth.value
          );
        });
        setTableData(filtered);
      } else {
        setIsFilterApplied(false);
        setTableData([...newTableData]);
      }
    }
  }, [
    isSingleReportSuccess,
    isSingleReportFetching,
    selectedYear,
    selectedMonth,
    singleReportResult,
  ]);
  useEffect(() => {
    CreatePDFData(globalWorkData, totalRate);
  }, [isFilterApplied]);

  const CreatePDFData = (data: any, totalRate: any) => {
    const workData: any = [];
    var workTotal: any = 0;
    var totalPcs: any = 0;
    let today = new Date() as any;
    if (isFilterApplied) {
      totalPcs = data
        ?.filter((row: any) => row?.status === true)
        .reduce(
          (total: number, currentItem: any) =>
            total + parseInt(currentItem.pcs),
          0
        );
      setPDFData({
        work: [...data?.filter((data: any) => data?.status === true)],
        name: name,
        date: date,
        totalRate: totalRate,
        totalPcs: totalPcs,
      });
    } else {
      console.log(data);
      
      data?.forEach((element: any, index: any) => {
        var date = new Date(element.date);

        if (
          element.status === true &&
          date.getMonth() + 1 === today.getMonth() + 1 && date.getFullYear() === today.getFullYear()
        ) {
          workData?.push({
            srNo: index + 1,
            type: element?.type,
            date: element?.date,
            cardNo: element?.cardNo,
            party: element?.party,
            challanNo: element?.challanNo,
            designNo: element?.designNo,
            clothe: element?.clothe,
            pcs: element?.pcs,
            rate: element?.rate,
            totalWork: element?.totalWork,
            status: element?.status,
          });
        }
      });
      workTotal = workData
        ?.filter((row: any) => row?.status === true)
        .reduce(
          (total: number, currentItem: any) =>
            total + parseInt(currentItem.pcs) * parseInt(currentItem.rate),
          0
        );
      totalPcs = workData
        ?.filter((row: any) => row?.status === true)
        .reduce(
          (total: number, currentItem: any) =>
            total + parseInt(currentItem.pcs),
          0
        );
      setPDFData({
        work: [...workData],
        name: name,
        date: date,
        totalRate: workTotal,
        totalPcs: totalPcs,
      });
    }
  };

  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      var invoices: any = [];
      invoiceResult &&
        invoiceResult?.data?.data?.map((invoice: any) => {
          if (invoice?._id) {
            invoices.push({
              label: invoice?.invoiceNo.toString(),
              value: invoice?._id,
            });
          }
        });
      setAllCardNo(invoices);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);

  useEffect(() => {
    if (isSareeDataSuccess && !isSareeDataFetching) {
      setRecord({
        // type:  sareeDataResult?.data?.data?.type,
        ...record,
        cardNo: sareeDataResult?.data?.data?.invoiceNo,
        challanNo: sareeDataResult?.data?.data?.challanNo,
        clothe: sareeDataResult?.data?.data?.clothe,
        designNo: sareeDataResult?.data?.data?.designNo,
        party: sareeDataResult?.data?.data?.party,
        pcs: "",
        rate: "",
        totalWork: "",
      });
      setErrorMessage({
        ...errorMessage,
        cardNo: "",
        challanNo: "",
        party: "",
        designNo: "",
        clothe: "",
      });
    }
  }, [isSareeDataSuccess, isSareeDataFetching]);

  const handleSampleChanges = (e: any) => {
    setRecord({
      ...record,
      date: today,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleWorkChanges = (e: any) => {
    if (e.target.name === "date") {
      setRecordDateChanged(true);
    }
    setRecord({
      ...record,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFinalData({
      ...finalData,
      name: name,
      date: date,
    });
  }, [name, date]);

  useEffect(() => {
    const totalWork: any = record?.pcs * record?.rate;
    setTotalWork(totalWork);
    if (record?.pcs >= 0 && record?.rate >= 0) {
      setTotalWork(totalWork);
      setErrorMessage({
        ...errorMessage,
        totalWork: "",
      });
    }
  }, [record?.pcs, record?.rate]);

  const columns: any = [
    {
      name: "Sr No",
      selector: (row: any, index: any) => index + 1,
    },
    {
      name: "Date",
      selector: (row: { [x: string]: any }) =>
        row?.date ? moment(row?.date).format("DD-MM-yyyy") : "",
    },
    {
      name: "Type",
      selector: (row: { [x: string]: any }) => row?.type,
    },
    {
      name: "Card No",
      selector: (row: { [x: string]: any }) => row?.cardNo,
    },
    {
      name: "Party Name",
      selector: (row: { [x: string]: any }) =>
        row?.party?.name ? row?.party?.name : row?.party,
    },
    {
      name: "Challan No",
      selector: (row: { [x: string]: any }) => row?.challanNo,
    },
    {
      name: "Design No",
      selector: (row: { [x: string]: any }) => row?.designNo,
    },
    {
      name: "Fabric",
      selector: (row: { [x: string]: any }) =>
        row?.clothe?.name ? row?.clothe?.name : row?.clothe,
    },
    {
      name: "Pcs",
      selector: (row: { [x: string]: any }) => row?.pcs,
    },
    {
      name: "Rate",
      selector: (row: { [x: string]: any }) => row?.rate,
    },
    {
      name: "Total Salary",
      selector: (row: { [x: string]: any }) => row?.totalWork,
    },
    {
      name: "Action",
      selector: (row: any, index: any) => (
        <div>
          <button
            className="m-2"
            type="button"
            onClick={() => {
              setUpdateIndex(row.srNo);
              setIsUpdate(true);

              setSelectedCardNo({
                label: row?.cardNo,
                value: row?.cardNo,
              });

              setRecord({
                ...record,
                srNo: row?.srNo,
                type: row?.type,
                date: today,
                cardNo: row?.cardNo,
                challanNo: row?.challanNo,
                designNo: row?.designNo,
                party: row?.party,
                clothe: row?.clothe,
                pcs: row?.pcs,
                rate: row?.rate,
                totalWork: row?.totalWork,
              });
            }}
          >
            <i className="fas fa-pen hover:text-green-600"></i>
          </button>
          <button
            className="m-2"
            onClick={() => {
              DeleteRecord(row.srNo);
            }}
          >
            <i className="fas fa-trash hover:text-red-600"></i>
          </button>
        </div>
      ),
    },
  ];

  const DeleteRecord = (index: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let newData: any = [];
        tableData?.map((item: any, i: any) => {
          if (item.status === true) {
            if (item.srNo === index) {
              newData.push({ ...item, status: false });
            } else {
              newData.push({ ...item, status: true });
            }
          } else {
            newData.push({ ...item, status: false });
          }
        });

        setTableData(newData ? newData : []);

        let newWorkData: any = [];
        singleReportResult?.data?.data?.report?.[0]?.work?.map(
          (item: any, i: any) => {
            if (item.status === true) {
              if (item.srNo === index) {
                newWorkData.push({ ...item, status: false });
              } else {
                newWorkData.push({ ...item, status: true });
              }
            } else {
              newWorkData.push({ ...item, status: false });
            }
          }
        );
        setGlobalWorkData(newWorkData ? newWorkData : []);
      }
    });
  };

  useEffect(() => {
    var workTotal = tableData
      ?.filter((row: any) => row?.status === true)
      .reduce(
        (total: number, currentItem: any) =>
          total + parseInt(currentItem.pcs) * parseInt(currentItem.rate),
        0
      );

    setTotalRate(workTotal);
    var work: any = [];
    tableData?.forEach((element: any, index: any) => {
      work.push({
        srNo: element?.srNo,
        type: element?.type,
        date: element?.date ? element?.date : today,
        cardNo: element?.cardNo,
        challanNo: element?.challanNo,
        designNo: element?.designNo,
        partyId: element?.party?._id,
        clotheId: element?.clothe?._id,
        pcs: element?.pcs,
        rate: element?.rate,
        totalWork: element?.pcs * element?.rate,
        status: element?.status,
      });
    });
    setFinalData({
      name: name,
      date: date,
      work: work,
      totalRate: workTotal,
    });
    CreatePDFData(tableData, workTotal);
    setRecordDateChanged(false);
  }, [tableData]);

  const ClearRecord = () => {
    setRecord({
      type: "Select Type",
      date: "",
      cardNo: "",
      pcs: "",
      rate: "",
      challanNo: "",
      totalWork: "",
      designNo: "",
      clothe: {
        _id: "",
        name: "",
      },
      party: {
        _id: "",
        name: "",
      },
    });
    setTotalWork("");
    setSelectedCardNo({});
  };

  // useEffect(() => {
  //   if (search?.fromDate && search?.toDate !== "") {
  //     setIsFilterApplied(true);
  //     let filtered: any = globalWorkData?.filter((row: any) => {
  //       return row.date >= search.fromDate && row.date <= search.toDate;
  //     });
  //     setTableData(filtered);
  //   } else if (search?.cardNo !== "") {
  //     setIsFilterApplied(true);
  //     let cardNo = Number(search?.cardNo);
  //     let filtered: any = globalWorkData?.filter((row: any) => {
  //       return row?.cardNo === cardNo;
  //     });
  //     setTableData(filtered);
  //   } else if (search?.type !== "") {
  //     setIsFilterApplied(true);
  //     if (search?.type === "Select Type") {
  //       setTableData([...globalWorkData]);
  //     } else {
  //       let filtered: any = globalWorkData?.filter((row: any) => {
  //         return row?.type === search?.type;
  //       });
  //       setTableData(filtered);
  //     }
  //   } else {
  //     setIsFilterApplied(false);
  //     if (isSingleReportSuccess && !isSingleReportFetching && !startDate) {
  //       setTableData([...globalWorkData]);
  //     }
  //   }
  // }, [search]);

  const addRecord = () => {
    let errorData: any = {
      date: "",
      type: "",
      cardNo: "",
      party: "",
      designNo: "",
      clothe: "",
      pcs: "",
      rate: "",
      totalWork: "",
    };
    if (!record?.date) {
      errorData["date"] = "Date is required";
    } else {
      errorData["date"] = "";
    }
    if (!record?.type) {
      errorData["type"] = "Type is required";
    } else {
      errorData["type"] = "";
    }
    if (!record?.cardNo) {
      errorData["cardNo"] = "Card No is required";
    } else {
      errorData["cardNo"] = "";
    }
    if (!record?.party?.name) {
      errorData["party"] = "Party is required";
    } else {
      errorData["party"] = "";
    }
    if (!record?.designNo) {
      errorData["designNo"] = "Design No is required";
    } else {
      errorData["designNo"] = "";
    }
    if (!record?.clothe?.name) {
      errorData["clothe"] = "Fabric is required";
    } else {
      errorData["clothe"] = "";
    }
    if (!record?.pcs) {
      errorData["pcs"] = "Pcs is required";
    } else {
      errorData["pcs"] = "";
    }
    if (!record?.rate) {
      errorData["rate"] = "Rate is required";
    } else {
      errorData["rate"] = "";
    }

    setErrorMessage(errorData);
    var result: any = reportItemValidation(record);
    if (result === true) {
      setTableData([
        ...tableData,
        {
          ...record,
          srNo: tableData.length + 1,
          totalWork: totalWork,
          status: true,
        },
      ]);

      setGlobalWorkData([
        ...globalWorkData,
        {
          ...record,
          srNo: globalWorkData.length + 1,
          totalWork: totalWork,
          status: true,
        },
      ]);
      ClearRecord();
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
  };

  const [updateIndex, setUpdateIndex]: any = useState("");
  const updateRecord = () => {
    let errorData: any = {
      date: "",
      type: "",
      cardNo: "",
      party: "",
      designNo: "",
      clothe: "",
      pcs: "",
      rate: "",
      totalWork: "",
    };
    if (!record?.date) {
      errorData["date"] = "Date is required";
    } else {
      errorData["date"] = "";
    }
    if (!record?.type) {
      errorData["type"] = "Type is required";
    } else {
      errorData["type"] = "";
    }
    if (!record?.cardNo) {
      errorData["cardNo"] = "Card No is required";
    } else {
      errorData["cardNo"] = "";
    }
    if (!record?.party?.name) {
      errorData["party"] = "Party is required";
    } else {
      errorData["party"] = "";
    }
    if (!record?.designNo) {
      errorData["designNo"] = "Design No is required";
    } else {
      errorData["designNo"] = "";
    }
    if (!record?.clothe?.name) {
      errorData["clothe"] = "Fabric is required";
    } else {
      errorData["clothe"] = "";
    }
    if (!record?.pcs) {
      errorData["pcs"] = "Pcs is required";
    } else {
      errorData["pcs"] = "";
    }
    if (!record?.rate) {
      errorData["rate"] = "Rate is required";
    } else {
      errorData["rate"] = "";
    }
    setErrorMessage(errorData);
    var result: any = reportItemValidation(record);
    if (result === true) {
      tableData?.filter((item: any) => {
        if (item?.srNo === updateIndex && item?.status === true) {
          record["totalWork"] = totalWork;
          if (!recordDateChanged) record["date"] = today;
          // record["srNo"] = updateIndex + 1
          record["status"] = true;
          const newState = tableData.map((obj: any) => {
            if (obj.srNo === updateIndex) {
              return { ...obj, ...record };
            }
            return obj;
          });
          const newRecordState = globalWorkData.map((obj: any) => {
            if (obj.srNo === updateIndex) {
              return { ...obj, ...record };
            }
            return obj;
          });
          setGlobalWorkData(newRecordState);

          setTableData(newState);
        }
      });
      ClearRecord();
      setIsUpdate(false);
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
  };

  const saveReport = () => {
    let errorData: any = {
      name: "",
      work: "",
    };
    let status = false;
    if (!finalData?.name) {
      errorData["name"] = "Name is required";
      status = true;
    } else {
      errorData["name"] = "";
    }
    if (finalData?.work?.length === 0) {
      errorData["work"] = "Work is required";
      status = true;
    } else {
      errorData["work"] = "";
    }
    setErrorMessage(errorData);
    if (!status) {
      setIsUpdating(true);
      addReportTrigger(finalData).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          ClearRecord();
          Swal.fire({
            title: "Great",
            text: "Report Created Successfully!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate(
            `/createemployeereport/${data?.data?.data?.insertedIds?.[0]}`
          );
        }
        setIsUpdating(false);
      });
    }
  };

  const updateReport = () => {
    let errorData: any = {
      name: "",
      work: "",
    };
    let status = false;
    if (!finalData?.name) {
      errorData["name"] = "Name is required";
      status = true;
    } else {
      errorData["name"] = "";
    }
    if (finalData?.work?.length === 0) {
      errorData["work"] = "Work is required";
      status = true;
    } else {
      errorData["work"] = "";
    }
    setErrorMessage(errorData);
    if (!status) {
      setIsUpdating(true);
      let updateReportData = { ...finalData };

      var workTotal = globalWorkData
        .filter((row: any) => row.status === true)
        .reduce(
          (total: number, currentItem: any) =>
            total + parseInt(currentItem.pcs) * parseInt(currentItem.rate),
          0
        );
      updateReportData["work"] = globalWorkData?.map(
        (element: any, index: any) => {
          return {
            srNo: element?.srNo,
            type: element?.type,
            date: element?.date ? element?.date : today,
            cardNo: element?.cardNo,
            challanNo: element?.challanNo,
            designNo: element?.designNo,
            partyId: element?.party?._id,
            clotheId: element?.clothe?._id,
            pcs: element?.pcs,
            rate: element?.rate,
            totalWork: element?.pcs * element?.rate,
            status: element?.status,
          };
        }
      );
      updateReportData["totalRate"] = workTotal;

      updateReportTrigger({
        payload: updateReportData,
        query: {
          rId: id,
        },
      }).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          ClearRecord();
          Swal.fire({
            title: "Great",
            text: "Report Updated Successfully!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsUpdating(false);
      });
    }
  };

  const actions: any = (
    <button
      className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
      onClick={() => {
        setShowReportPreview(true);
        setIsFilterApplied(true);
      }}
    >
      <i className="fa fa-download"></i>
      <span className="ml-2">Print All</span>
    </button>
  );

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
      <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
        <div className="invisible lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-3 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-xl">Employee Report</span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <TextBox
                    id="name"
                    type="text"
                    name="name"
                    htmlFor="name"
                    value={name}
                    label="Employee Name"
                    placeholder=" "
                    onChange={(e: any) => {
                      setName(e.target.value);
                      setErrorMessage({
                        ...errorMessage,
                        name: "",
                      });
                    }}
                    hasError={errorMessage?.name}
                  />
                </div>
                <TextBox
                  id="date"
                  type="date"
                  name="date"
                  htmlFor="date"
                  value={date}
                  tabIndex={-1}
                  label="Date"
                  placeholder=" "
                  onChange={(e: any) => {
                    setDate(e.target.value);
                  }}
                />
              </div>
              <div className="px-3 md:w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    setShowReportPreview(true);
                    setIsFilterApplied(false);
                  }}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print Current Month</span>
                </button>
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={id ? updateReport : saveReport}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">{id ? "Update" : "Save"}</span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/employeereportdashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
          <div className="w-full overflow-visible">
            <div className="w-full rounded-xl bg-white">
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-8 p-2 pt-3 pb-0">
                <div className="visible lg:hidden">
                  <TextBox
                    id="name"
                    type="text"
                    name="name"
                    htmlFor="name"
                    value={name}
                    label="Employee Name"
                    placeholder=" "
                    onChange={(e: any) => {
                      setName(e.target.value);
                      setErrorMessage({
                        ...errorMessage,
                        name: "",
                      });
                    }}
                    hasError={errorMessage?.name}
                  />
                </div>
                <div className="visible lg:hidden">
                  <TextBox
                    id="date"
                    type="date"
                    name="date"
                    htmlFor="date"
                    value={date}
                    label="Date"
                    placeholder=" "
                    tabIndex={-1}
                    onChange={(e: any) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>
                <div className="border-b border-gray-500 visible lg:hidden"></div>
                <div>
                  <TextBox
                    id="recorddate"
                    type="date"
                    name="date"
                    htmlFor="recorddate"
                    value={record?.date}
                    label="record date"
                    placeholder=" "
                    onChange={(e: any) => {
                      handleWorkChanges(e);
                      setErrorMessage({
                        ...errorMessage,
                        date: "",
                      });
                    }}
                    hasError={errorMessage?.date}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.date}
                  </span>
                </div>
                <div>
                  <Select
                    id="type"
                    name="type"
                    htmlFor="type"
                    label="Type"
                    options={[
                      "Select Type",
                      "dress",
                      "saree",
                      "lump",
                      "sample",
                    ]}
                    onChange={(e: any) => handleWorkChanges(e)}
                    value={record?.type}
                  />
                </div>
                {record?.type === "sample" ? (
                  <div>
                    <TextBox
                      id="cardNo"
                      type="text"
                      name="cardNo"
                      value={record?.cardNo}
                      htmlFor="cardNo"
                      label="Card No"
                      placeholder=" "
                      hasError={errorMessage?.cardNo}
                      onChange={(e: any) => {
                        handleSampleChanges(e);
                      }}
                    />
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.cardNo}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className="relative rounded-md z-30">
                      <ReactSelect
                        className="basic-single block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                        classNamePrefix="customSelect"
                        classNames={{
                          control: () =>
                            errorMessage?.cardNo
                              ? "border-red-600"
                              : "border-grey-300",
                        }}
                        value={selectedCardNo}
                        id="cardNo"
                        name="cardNo"
                        onChange={(e: any) => {
                          setSelectedCardNo(e);
                          if (e && record?.type) {
                            sareeDataTrigger({
                              mcId: e.value,
                              type: record?.type,
                            });
                            setErrorMessage({
                              ...errorMessage,
                              cardNo: "",
                            });
                          } else {
                            ClearRecord();
                          }
                        }}
                        filterOption={(option: any, inputValue) =>
                          option?.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        options={allCardNo}
                      />
                      <label
                        htmlFor="cardNo"
                        className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                          errorMessage?.cardNo
                            ? "text-red-500"
                            : "text-gray-500"
                        }`}
                      >
                        Card No
                      </label>
                    </div>
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.cardNo}
                    </span>
                  </div>
                )}
                {record?.type === "sample" ? (
                  <div>
                    <div className="relative rounded-md">
                      <ReactSelect
                        name="party"
                        classNamePrefix="customSelect"
                        className="block z-40 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                        classNames={{
                          control: () =>
                            errorMessage?.party
                              ? "border-red-600"
                              : "border-gray-300",
                        }}
                        onChange={(e: any) => {
                          if (e) {
                            setSelectedParty(e);
                            setRecord({
                              ...record,
                              party: {
                                _id: e.value,
                                name: e.label,
                              },
                            });
                            setErrorMessage({
                              ...errorMessage,
                              party: "",
                            });
                          }
                        }}
                        // filterOption={(option: any, inputValue) =>
                        // //   option?.label
                        // //     .toLowerCase()
                        // //     .includes(inputValue.toLowerCase())
                        // // }
                        options={Party && Party}
                        value={selectedParty}
                      />
                      <label
                        htmlFor="partyName"
                        className={`absolute z-40 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                          errorMessage?.party ? "text-red-500" : "text-gray-500"
                        }`}
                      >
                        Party
                      </label>
                    </div>
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.party}
                    </span>
                  </div>
                ) : (
                  <div>
                    <TextBox
                      id="party"
                      type="text"
                      name="party"
                      value={record?.party?.name}
                      htmlFor="party"
                      label="Party"
                      placeholder=" "
                      hasError={errorMessage?.party}
                      onChange={(e: any) => {
                        if (record?.type === "sample") {
                          handleSampleChanges(e);
                        }
                      }}
                    />
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.party}
                    </span>
                  </div>
                )}
                <div>
                  <TextBox
                    id="challanNo"
                    type="text"
                    value={record?.challanNo}
                    name="challanNo"
                    htmlFor="challanNo"
                    label="Challan No"
                    placeholder=" "
                    disabled={record?.type === "sample" ? false : true}
                    onChange={(e: any) => {
                      if (record?.type === "sample") {
                        handleSampleChanges(e);
                      }
                    }}
                  />
                </div>
                <div>
                  <TextBox
                    id="designNo"
                    type="text"
                    name="designNo"
                    value={record?.designNo}
                    htmlFor="designNo"
                    label="D. No"
                    placeholder=" "
                    disabled={record?.type === "sample" ? false : true}
                    hasError={errorMessage?.designNo}
                    onChange={(e: any) => {
                      if (record?.type === "sample") {
                        handleSampleChanges(e);
                      }
                    }}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.designNo}
                  </span>
                </div>
                {record?.type === "sample" ? (
                  <div className="relative rounded-md z-10">
                    <ReactSelect
                      name="clothe"
                      classNamePrefix="customSelect"
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          setSelectedCloth(e);
                          setRecord({
                            ...record,
                            clothe: {
                              _id: e.value,
                              name: e.label,
                            },
                          });
                          setErrorMessage({
                            ...errorMessage,
                            cloth: "",
                          });
                        } else {
                          setSelectedCloth({});
                        }
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.cloth
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}
                      options={Cloth && Cloth}
                      value={selectedCloth}
                    />
                    <label
                      htmlFor="cloth"
                      className={`absolute text-md text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.cloth ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      {" "}
                      Fabric
                    </label>
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.cloth}
                    </span>
                  </div>
                ) : (
                  <div>
                    <TextBox
                      id="clothe"
                      type="text"
                      name="clothe"
                      value={record?.clothe?.name}
                      htmlFor="clothe"
                      label="Fabric"
                      placeholder=" "
                      hasError={errorMessage?.clothe}
                      onChange={(e: any) => {
                        if (record?.type === "sample") {
                          handleSampleChanges(e);
                        }
                      }}
                    />
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.clothe}
                    </span>
                  </div>
                )}
                <div>
                  <TextBox
                    id="pcs"
                    type="number"
                    name="pcs"
                    htmlFor="pcs"
                    value={record?.pcs}
                    label="Pcs"
                    placeholder=" "
                    onChange={(e: any) => {
                      handleWorkChanges(e);
                      setErrorMessage({
                        ...errorMessage,
                        pcs: "",
                      });
                    }}
                    hasError={errorMessage?.pcs}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.pcs}
                  </span>
                </div>
                <div>
                  <TextBox
                    id="rate"
                    type="number"
                    name="rate"
                    htmlFor="rate"
                    value={record?.rate}
                    label="Rate"
                    placeholder=" "
                    onChange={(e: any) => {
                      handleWorkChanges(e);
                      setErrorMessage({
                        ...errorMessage,
                        rate: "",
                      });
                    }}
                    hasError={errorMessage?.rate}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.rate}
                  </span>
                </div>
                <div>
                  <TextBox
                    id="totalwork"
                    type="text"
                    name="totalwork"
                    htmlFor="totalwork"
                    label="total salary"
                    placeholder=" "
                    disabled
                    value={totalWork ? totalWork : 0}
                  />
                </div>
                <div>
                  <button
                    className="px-3 py-2 w-full text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    type="button"
                    onClick={() => {
                      if (isUpdate) {
                        updateRecord();
                      } else {
                        addRecord();
                      }
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="ml-2">{isUpdate ? "Update" : "Add"}</span>
                  </button>
                </div>
                <div className="flex col-span-1 lg:col-span-5 justify-end gap-2 items-center">
                  <label>Total Salary</label>
                  <TextBox placeholder=" " disabled value={totalRate} />
                </div>
              </div>
              <div className="grid grid-cols-1 px-2 mt-3">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 w-full gap-2 my-2">
                  <div>
                    <div className="relative rounded-md z-20">
                      <ReactSelect
                        className="basic-single block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                        classNamePrefix="select"
                        isClearable
                        value={selectedYear}
                        id="year"
                        name="year"
                        onChange={(e: any) => {
                          setSelectedYear(e);
                        }}
                        options={yearList}
                      />
                      <label
                        htmlFor="year"
                        className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 text-gray-500"}`}
                      >
                        Year
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="relative rounded-md z-10 capitalize">
                      <ReactSelect
                        className="basic-single block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                        classNamePrefix="select"
                        isClearable
                        value={selectedMonth}
                        id="month"
                        name="month"
                        onChange={(e: any) => {
                          setSelectedMonth(e);
                        }}
                        options={monthList}
                      />
                      <label
                        htmlFor="month"
                        className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 text-gray-500"}`}
                      >
                        month
                      </label>
                    </div>
                  </div>
                  <div>
                    <TextBox
                      id="fromDate"
                      type="date"
                      placeholder=" "
                      label="From"
                      onChange={(e: any) => {
                        if (e.target.value.length !== 0) {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              fromDate: e.target.value,
                            };
                          });
                        } else {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              fromDate: "",
                            };
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <TextBox
                      id="toDate"
                      type="date"
                      placeholder=" "
                      label="To"
                      onChange={(e: any) => {
                        if (e.target.value.length !== 0) {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              toDate: e.target.value,
                            };
                          });
                        } else {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              toDate: "",
                            };
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Select
                      id="type"
                      name="type"
                      htmlFor="type"
                      label="Type"
                      options={[
                        "Select Type",
                        "dress",
                        "saree",
                        "lump",
                        "sample",
                      ]}
                      onChange={(e: any) => {
                        if (e.target.value !== "Select Type") {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              type: e.target.value,
                            };
                          });
                        } else {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              type: "",
                            };
                          });
                        }
                      }}
                      value={search?.type}
                    />
                  </div>
                  <div>
                    <TextBox
                      id="cardNo"
                      type="cardNo"
                      placeholder=""
                      label="Card No"
                      onChange={(e: any) => {
                        if (e.target.value !== "") {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              cardNo: e.target.value,
                            };
                          });
                        } else {
                          setSearch((previous: any) => {
                            return {
                              ...previous,
                              cardNo: "",
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`border rounded-3 overflow-hidden ${
                    errorMessage?.work ? "border-red-500" : "border-gray-300"
                  }`}
                >
                  <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="min-w-full sm:px-6 lg:px-0">
                        <div className="overflow-hidden">
                          <div className="flex-auto px-0 pt-0">
                            <div className="p-0 overflow-hidden">
                              <DataTable
                                title={name}
                                className="touch-auto"
                                columns={columns}
                                progressPending={isSareeDataFetching}
                                progressComponent={<DataTableLoader />}
                                data={tableData?.filter((row: any) => {
                                  return row.status === true;
                                })}
                                actions={actions}
                                fixedHeader={true}
                                defaultSortAsc={true}
                                pagination
                                striped={true}
                                responsive={true}
                                persistTableHead={true}
                                highlightOnHover
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="text-xs text-red-500 text-left">
                  {errorMessage?.work}
                </span>
              </div>
              <div className="flex flex-wrap justify-between lg:hidden p-2 gap-2">
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showReportPreview}
                    onClick={() => {
                      setShowReportPreview(true);
                    }}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={id ? updateReport : saveReport}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">{id ? "Update" : "Save"}</span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/employeereportdashboard");
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pdf Preview */}
      {showReportPreview && (
        <EmployeeReportPreview
          showReportPreview={showReportPreview}
          setShowReportPreview={setShowReportPreview}
          singleReport={pdfData}
          pageName="Report"
        />
      )}
    </div>
  );
}
