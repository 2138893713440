import S3 from "aws-sdk/clients/s3";

export async function convertImageToBase64(imgUrl: any, callback: any) {
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
    const region = process.env.REACT_APP_AWS_BUCKET_REGION
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY
    const imageurl = new URL(imgUrl);

    const key = decodeURIComponent(imageurl.pathname.substring(1));
    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });
    const params: any = {
        Bucket: bucketName,
        Key: key,
        ResponseContentType: 'image/jpeg'
    };
    try {
        const response: any = await s3.getObject(params).promise();
        const imageData = `data:image/jpeg;base64,${response.Body.toString('base64')}`

        callback && callback(imageData)
    } catch (error) {
    }
}

export function setDefaultDate() {
    let today = new Date() as any
    let month = today?.getMonth() + 1
    month = month < 10 ? "0" + month : month
    let day = today?.getDate() < 10 ? "0" + today?.getDate() : today?.getDate()
    return {
        today: today,
        month: month,
        day: day
    }
}

export function getFormattedNumber(number: any) {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(number).replace("₹", "")
}

export function groupBy(xs: any, key: any) {
    return xs?.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export function groupByMonth(xs: any, key: any) {
    return xs?.reduce(function (rv: any, x: any) {
        (rv[x[key]?.split("-")[1]] = rv[x[key]?.split("-")[1]] || []).push(x);
        return rv;
    }, {});
};

export function capitalize(str: any,) {
    const arr = str?.split(" ");
    for (var i = 0; i < arr?.length; i++) {
        arr[i] = arr[i]?.charAt(0)?.toUpperCase() + arr[i]?.slice(1).toLowerCase();
    }
    const str2 = arr?.join(" ");
    return str2;
};

// Data table styles
const customStyles = {
    header: {
        style: {
            minHeight: 0
        }
    },
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            backgroundColor: 'rgb(230, 244, 244)',
            color: '#202124',
            fontSize: '15px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            width:'max-content'
        },
    },
    rows: {
        style: {
            fontSize: '15px',
            cursor: 'pointer'
        },
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};
export default customStyles

export const selectFilterConfig: any = {
    ignoreCase: true,
    matchFrom: ('start' as const),
};