import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../components/form/Select";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import {
  useLazyPartyQuery,
  useLazyClothQuery,
  useLazyItemQuery,
  useLazyFabricColorQuery,
  useLazyGetFabricColorQuery,
  useLazyGetClothQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyNewJobWorkInvoiceNumberQuery,
  useLazyJobWorkQuery,
  useLazyGetJobWorkNameQuery,
  useLazyNewMatchingInvoiceNumberQuery,
  useLazyGetMatchingCardQuery,
  useLazyGetDescQuery,
  useLazyDescQuery,
  useLazyChallanRemarksQuery,
} from "../../servicesRtkQuery/publicApi";
import { useSelector } from "react-redux";
import { challanJobWorkValidation } from "../../utils/validation";
import { FabricAdd } from "../../components/modal/FabricAdd";
import ObjectID from "bson-objectid";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import { useLazyGetJobWorkQuery } from "../../servicesRtkQuery/publicApi";
import { useAddJobWorkMutation } from "../../servicesRtkQuery/publicApi";
import { useUpdateJobWorkMutation } from "../../servicesRtkQuery/publicApi";
import { JobWorkPreview } from "../../components/pdf/challan/jobWorkPreview";
import { NameAdd } from "../../components/modal/NameAdd";
import ReactSelect, { createFilter } from "react-select";
import { DescAdd } from "../../components/modal/DescAdd";
import { ChallanRemarksAdd } from "../../components/modal/ChallanRemarksAdd";
import { selectFilterConfig } from "../../utils/globalFunctions";
import moment from "moment";

export default function Challan() {
  const [isChallanUpdate, setIsChallanUpdate] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsChallanUpdate(true);
    } else {
      setIsChallanUpdate(false);
    }
  }, [id]);
  // Redux Data State
  const jobWorkData = useSelector(
    (state: any) => state.jobWorkSlice.jobWorkData
  );

  // Global Data State
  const [cardData, setCardData]: any = useState();
  const [jobData, setJobData]: any = useState();

  const [jobWorkTableData, setJobWorkTableData] = useState<any>([]);

  const [selectedJobRow, setSelecetdJobRow]: any = useState("");

  const [trigger, result] = useLazyPartyQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [itemTrigger, itemResult] = useLazyItemQuery();
  const {
    isSuccess: isItemSuccess,
    isFetching: isItemFetching,
    isError: isItemError,
    error: itemError,
  } = itemResult;

  const [descTrigger, descResult] = useLazyDescQuery();
  const {
    isSuccess: isDescSuccess,
    isFetching: isDescFetching,
    isError: isDescError,
    error: descError,
  } = descResult;

  const [matchingInvoiceTrigger, matchingInvoiceResult] =
    useLazyNewMatchingInvoiceNumberQuery();
  const {
    isSuccess: isMatchingInvoiceSuccess,
    isFetching: isMatchingInvoiceFetching,
    isError: isMatchingInvoiceError,
    error: matchingInvoiceerror,
  } = matchingInvoiceResult;

  const [matchingTrigger, matchingResult] = useLazyGetMatchingCardQuery();
  const {
    isSuccess: isMatchingSuccess,
    isFetching: isMatchingFetching,
    isError: isMatchingError,
    error: matchingError,
  } = matchingResult;

  const [nameTrigger, nameResult] = useLazyGetJobWorkNameQuery();
  const {
    isSuccess: isNameSuccess,
    isFetching: isNameFetching,
    isError: isNameError,
    error: nameError,
  } = nameResult;

  const [fabricTrigger, fabricResult] = useLazyFabricColorQuery();
  const {
    isSuccess: isFabricSuccess,
    isFetching: isFabricFetching,
    isError: isFabricError,
    error: fabricError,
  } = fabricResult;

  const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
  const {
    isSuccess: isgetClothSuccess,
    isFetching: isgetClothFetching,
    isError: isgetClothError,
    error: getClothError,
  } = getClothResult;

  const [getFabricColorTrigger, getFabricColorResult] =
    useLazyGetFabricColorQuery();
  const {
    isSuccess: isgetFabricColorSuccess,
    isFetching: isgetFabricColorFetching,
    isError: isgetFabricColorError,
    error: getFabricColorError,
  } = getFabricColorResult;

  const [invoiceTrigger, invoiceResult] = useLazyNewJobWorkInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceError,
  } = invoiceResult;

  const [challanTrigger, challanResult] = useLazyGetJobWorkQuery();
  const {
    isSuccess: isChallanSuccess,
    isFetching: isChallanFetching,
    isError: isChallanError,
    error: challanError,
  } = challanResult;

  //Getting paginated records
  const [getItemTrigger, getItemResult] = useLazyGetItemQuery();
  const {
    isSuccess: isgetItemSuccess,
    isFetching: isgetItemFetching,
    isError: isgetItemError,
    error: getItemError,
  } = getItemResult;

  const [getPartyNameTrigger, getPartyNameResult] = useLazyGetPartyNameQuery();
  const {
    isSuccess: isgetPartyNameSuccess,
    isFetching: isgetPartyNameFetching,
    isError: isgetPartyNameError,
    error: getPartyNameError,
  } = getPartyNameResult;

  const [getDescTrigger, getDescResult] = useLazyGetDescQuery();
  const {
    isSuccess: isgetDescSuccess,
    isFetching: isgetDescFetching,
    isError: isgetDescError,
    error: getDescError,
  } = getDescResult;

  const [getNameTrigger, getNameResult] = useLazyGetJobWorkNameQuery();
  const {
    isSuccess: isgetNameSuccess,
    isFetching: isgetNameFetching,
    isError: isgetNameError,
    error: getNameError,
  } = getNameResult;

  const [showPreview, setShowPreview] = useState(false);
  const [savedId, setSavedId]: any = useState("");

  const [allTrigger, allResult] = useLazyJobWorkQuery();
  const {
    isSuccess: isAllSuccess,
    isFetching: isAllFetching,
    isError: isAllError,
    error: allError,
  } = allResult;

  const [remarksTrigger, remarksResult] = useLazyChallanRemarksQuery();
  const {
    isSuccess: isRemarksSuccess,
    isFetching: isRemarksFetching,
    isError: isRemarksError,
    error: RemarksError,
  } = remarksResult;

  useEffect(() => {
    trigger("");
    clothTrigger("");
    itemTrigger("");
    fabricTrigger("");
    invoiceTrigger("");
    nameTrigger("");
    descTrigger("");
    remarksTrigger("");
  }, []);
  const navigate = useNavigate();

  // Party Creatable State
  const [showAddParty, setShowAddParty] = useState(false);
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [Party, setParty]: any = useState();
  const [selectedParty, setSelectedParty]: any = useState({});

  // Cloth Creatable State
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [clothState, setClothState]: any = useState({});

  // Reamrks Creatable State
  const [showAddRemarks, setShowAddRemarks] = useState(false);
  const [remarksAdd, setRemarksAdd] = useState("");
  const [Remarks, setRemarks]: any = useState([]);
  const [selectedRemarks, setSelectedRemarks]: any = useState({});
  const [remarksState, setRemarksState]: any = useState({});

  // Item Creatable State
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemAdd, setItemAdd] = useState("");
  const [Item, setItem]: any = useState<any>([]);
  const [selectedItem, setSelectedItem]: any = useState({});
  const [itemState, setItemState]: any = useState({});

  // Item Creatable State
  const [showAddDesc, setShowAddDesc] = useState(false);
  const [descAdd, setDescAdd] = useState("");
  const [Desc, setDesc]: any = useState<any>([]);
  const [selectedDesc, setSelectedDesc]: any = useState({});
  const [descState, setDescState]: any = useState({});

  // Name Creatable State
  const [showAddName, setShowAddName] = useState(false);
  const [nameAdd, setNameAdd] = useState("");
  const [Name, setName]: any = useState<any>([]);
  const [addressState, setAddressState]: any = useState<any>([]);
  const [address, setAddress]: any = useState<any>();
  const [selectedName, setSelectedName]: any = useState({});
  const [nameState, setNameState]: any = useState({});
  const [selectedCardNo, setSelectedCardNo]: any = useState({});
  const [options, setOptions]: any = useState([]);

  // Fabric Creatable State
  const [showAddFabric, setShowAddFabric] = useState(false);
  const [fabricAdd, setFabricAdd] = useState("");
  const [Fabric, setFabric]: any = useState([]);
  const [selectedFabric, setSelectedFabric]: any = useState({});
  const [fabricState, setFabricState]: any = useState({});
  const [jobChallanNo, setJobChallanNo]: any = useState<any>([]);

  const [images, setImages]: any = useState();
  const [selectedJobChallanNo, setSelectedJobChallanNo]: any = useState("");

  // Is SampleCard Update State
  const [isJobUpdate, setIsJobUpdate] = useState(false);

  //Total States
  const [pcsTotal, setPcsTotal]: any = useState(0);

  // error message state
  const [errorMessage, setErrorMessage]: any = useState({});

  const [updateChallan, updateResult] = useUpdateJobWorkMutation();
  const [addChallan, addResult] = useAddJobWorkMutation();
  // reference props
  const jobWorkRef: any = useRef(null);

  useEffect(() => {
    let date = document.getElementById("date") as HTMLInputElement | any;
    let today = new Date() as any;
    let month = today.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

    date.value = today.getFullYear() + "-" + month + "-" + day;
    if (isChallanUpdate) {
      if (jobWorkData?.jobWorks) {
        let apiData = jobWorkData?.jobWorks?.filter((item: any) => {
          return item._id === id;
        })[0];
        if (apiData) {
          setCardData({
            ...apiData,
            date: apiData?.date
              ? apiData?.date
              : today.getFullYear() + "-" + month + "-" + day,
          });
          setAddress(apiData?.cName?.address);
          setSelectedName({
            label: apiData?.cName?.name,
            value: apiData?.cName?._id,
          });
          setSelectedCardNo({
            label: apiData?.card?.number,
            value: apiData?.card?._id,
          });
          setType(apiData?.type);
          matchingInvoiceTrigger({ type: apiData?.type?.toLowerCase() });
          setSelectedItem({
            label: apiData?.item?.name,
            value: apiData?.item?._id,
          });
          setSelectedParty({
            label: apiData?.party?.name,
            value: apiData?.party?._id,
          });
          let jobData: any = [];
          apiData?.work?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              clotheId: item?.clothe?._id,
              fabricColorId: item?.fabricColor?._id,
              PM: item?.PM,
              descriptionId: item?.description?._id,
              remarkId: item?.remark?._id,
            };
            jobData.push(object);
          });
          setJobWorkTableData(jobData);
        } else {
          challanTrigger(id);
        }
      } else {
        challanTrigger(id);
      }
    } else {
      setCardData({});
      setCardData({
        ...cardData,
        date: today.getFullYear() + "-" + month + "-" + day,
      });
    }
  }, [fabricState]);

  useEffect(() => {
    if (isChallanUpdate) {
      if (isChallanSuccess && !isChallanFetching) {
        let apiData = challanResult?.data?.data?.jobWorks?.[0];
        setAddress(apiData?.cName?.address);
        setCardData(apiData);
        setSelectedName({
          label: apiData?.cName?.name,
          value: apiData?.cName?._id,
        });
        setSelectedCardNo({
          label: apiData?.card?.number,
          value: apiData?.card?._id,
        });
        setType(apiData?.type);
        matchingInvoiceTrigger({ type: apiData?.type?.toLowerCase() });
        setSelectedItem({
          label: apiData?.item?.name,
          value: apiData?.item?._id,
        });
        setSelectedParty({
          label: apiData?.party?.name,
          value: apiData?.party?._id,
        });
        let jobData: any = [];
        apiData?.work?.forEach((item: any) => {
          let object = {
            _id: item?._id,
            clotheId: item?.clothe?._id,
            fabricColorId: item?.fabricColor?._id,
            PM: item?.PM,
            descriptionId: item?.description?._id,
            remarkId: item?.remark?._id,
          };
          jobData.push(object);
        });
        setJobWorkTableData(jobData);
      }
    }
  }, [isChallanSuccess, isChallanFetching]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      let getAllParty = result.data?.data?.party.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });

      setParty(getAllParty ? getAllParty : []);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isAllSuccess && !isAllFetching) {
      if (!isChallanUpdate) {
        navigate(`/jobwork/challan/${savedId}`);
      }
    }
  }, [isAllSuccess, isAllFetching]);

  useEffect(() => {
    if (isRemarksSuccess && !isRemarksFetching) {
      let getAllRemarks = remarksResult.data?.data?.remark?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setRemarks(getAllRemarks ? getAllRemarks : []);
      let remarks: any = {};
      getAllRemarks?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        remarks[id] = label;
      });
      setRemarksState(remarks);
    }
  }, [isRemarksSuccess, isRemarksFetching]);

  useEffect(() => {
    if (isgetItemSuccess && !isgetItemFetching) {
      let item = getItemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      if (item) {
        setItem(item);
      }
    }
  }, [isgetItemSuccess, isgetItemFetching]);

  useEffect(() => {
    if (isgetPartyNameSuccess && !isgetPartyNameFetching) {
      let party = getPartyNameResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (party) {
        setParty(party);
      }
    }
  }, [isgetPartyNameSuccess, isgetPartyNameFetching]);

  useEffect(() => {
    if (isgetDescSuccess && !isgetDescFetching) {
      let desc = getDescResult.data?.data?.description.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (desc) {
        setDesc(desc);
      }
    }
  }, [isgetDescSuccess, isgetDescFetching]);

  useEffect(() => {
    if (isgetNameSuccess && !isgetNameFetching) {
      let name = getNameResult.data?.data?.challanName.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (name) {
        setName(name);
      }
    }
  }, [isgetNameSuccess, isgetNameFetching]);

  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      var data: any = [];
      invoiceResult &&
        invoiceResult?.data?.data?.map((invoice: any) => {
          if (id) {
            // If Updating Challan
            if (invoice._id) {
              data.push(invoice.jobNo);
              if (id === invoice?._id) {
                setSelectedJobChallanNo(invoice.jobNo);
              }
            }
          } else {
            // If Adding Challan
            data.push(invoice.jobNo);
            setSelectedJobChallanNo(data[0]);
          }
        });
      setJobChallanNo(data);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
      let clothes: any = [];
      getAllCloth?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        clothes[id] = label;
      });

      setClothState(clothes);
    }
  }, [isClothSuccess, isClothFetching]);

  useEffect(() => {
    if (isFabricSuccess && !isFabricFetching) {
      let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );

      setFabric(getAllFabric ? getAllFabric : []);

      let fabricColors: any = {};

      getAllFabric?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        fabricColors[id] = label;
      });

      setFabricState(fabricColors);
    }
  }, [isFabricSuccess, isFabricFetching]);

  useEffect(() => {
    if (isItemSuccess && !isItemFetching) {
      let getAllItem = itemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setItem(getAllItem ? getAllItem : []);
      let items: any = {};

      getAllItem?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        items[id] = label;
      });

      setItemState(items);
    }
  }, [isItemSuccess, isItemFetching]);

  useEffect(() => {
    if (isDescSuccess && !isDescFetching) {
      let getAllDesc = descResult.data?.data?.description.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setDesc(getAllDesc ? getAllDesc : []);
      let items: any = {};

      getAllDesc?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        items[id] = label;
      });

      setDescState(items);
    }
  }, [isDescSuccess, isDescFetching]);

  useEffect(() => {
    if (isNameSuccess && !isNameFetching) {
      let getAllname: any = [];
      let allAddress: any = {};
      nameResult.data?.data?.challanName.forEach((item: any) => {
        let object: any = {
          label: item?.name,
          value: item?._id,
        };
        getAllname.push(object);
        allAddress[item?.name] = item?.address;
      });
      setName(getAllname ? getAllname : []);
      setAddressState(allAddress);
      let names: any = {};

      getAllname?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        names[id] = label;
      });

      setNameState(names);
    }
  }, [isNameSuccess, isNameFetching]);

  useEffect(() => {
    if (isgetClothSuccess && !isgetClothFetching) {
      let cloth = getClothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (cloth) {
        setCloth(cloth);
      }
    }
  }, [isgetClothSuccess, isgetClothFetching]);

  const [type, setType]: any = useState<any>(null);

  const handleTypeChange = (e: any) => {
    if (e.target.value !== "Select Type") {
      setType(e.target.value);
      matchingInvoiceTrigger({ type: e.target.value?.toLowerCase() });
      setSelectedItem(
        Item?.filter(
          (f: any) => f.label.toLowerCase() === e.target.value.toLowerCase()
        )[0]
      );
    } else {
      setType(null);
    }
  };
  useEffect(() => {
    if (isMatchingInvoiceSuccess && !isMatchingInvoiceFetching) {
      var invoices: any = [];
      matchingInvoiceResult &&
        matchingInvoiceResult.data?.data?.map((invoice: any) => {
          if (invoice?._id) {
            let option: any = {
              label: invoice?.invoiceNo + "-" + invoice?.cardNo,
              value: invoice?._id,
            };
            invoices.push(option);
          }
        });

      // Move setOptions and console.log inside the if block
      setOptions(invoices);
    }
  }, [
    isMatchingInvoiceSuccess,
    isMatchingInvoiceFetching,
    matchingInvoiceResult,
  ]);
  useEffect(() => {
    if (isMatchingSuccess && !isMatchingFetching) {
      let matchingData = matchingResult?.data?.data?.matchingCard?.[0];

      setCardData({
        ...cardData,
        designNo: matchingData?.designNo,
        PCNo: matchingData?.challanNo,
      });

      setSelectedParty({
        label: matchingData?.party?.name,
        value: matchingData?.party?._id,
      });

      let jobData: any = [];
      matchingData?.fabricColors?.forEach((item: any) => {
        let object = {
          _id: item?._id,
          clotheId: matchingData?.clothe?._id,
          fabricColorId: item?.fabricColorId?._id,
          PM: "",
        };
        jobData.push(object);
      });
      setJobWorkTableData(jobData);
    }
  }, [isMatchingSuccess, isMatchingFetching]);

  useEffect(() => {
    let total = 0;
    jobWorkTableData.forEach((item: any) => {
      total = total + item?.PM;
    });
    setPcsTotal(total);
  }, [jobWorkTableData]);

  const getData = (data: any) => {
    setSelectedParty(data);
    setParty([...Party, data]);
    setShowAddParty(false);
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const getItemData = (data: any, type: any) => {
    setSelectedItem(data);
    setItem([...Item, data]);
    setShowAddItem(false);
  };
  const getDescData = (data: any, type: any) => {
    setSelectedDesc(data);
    setDesc([...Desc, data]);
    setShowAddDesc(false);
  };

  const getFabricData = (data: any) => {
    setSelectedFabric(data);
    setFabric([...Fabric, data]);
    setShowAddFabric(false);
  };

  const getNameData = (data: any) => {
    setSelectedName(data);
    setName([...Name, data]);
    setShowAddName(false);
  };

  const getRemarksData = (data: any, type: any) => {
    setSelectedRemarks(data);
    setRemarks([...Remarks, data]);
    setShowAddRemarks(false);
  };

  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "party":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "description":
        let selectedDesc = {
          label: e.label,
          value: e.value,
        };
        setSelectedDesc(selectedDesc);
        break;
      case "remarks":
        let remarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedRemarks(remarks);
        break;
      case "item":
        let selectedItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedItem(selectedItem);
        break;
      case "name":
        let selectedName = {
          label: e.label,
          value: e.value,
        };
        setSelectedName(selectedName);
        setAddress(addressState[e.label]);
        break;
      case "fabricColor":
        let selectedFabric = {
          label: e.label,
          value: e.value,
        };
        setSelectedFabric(selectedFabric);
        break;
      default:
    }
  };

  const handleChanges = (e: any) => {
    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCardNoChanges = (e: any) => {
    setSelectedCardNo({
      label: e.label,
      value: e.value,
    });
    let payload = {
      mcId: e.value,
      query: {
        isSingle: true,
      },
    };
    matchingTrigger(payload);
  };
  const handleJobChanges = (e: any) => {
    setJobData({
      ...jobData,
      [e.target.name]: e.target.value,
    });
  };

  const [isUpdating, setIsUpdating]: any = useState(false);
  const updateData = () => {
    let status = false;
    if (Object?.keys(selectedParty)?.length === 0) {
      errorData["partyName"] = "Party is Required";
      status = true;
    } else {
      errorData["partyName"] = "";
    }
    if (Object?.keys(selectedName)?.length === 0) {
      errorData["name"] = "Name is Required";
      status = true;
    } else {
      errorData["name"] = "";
    }
    if (
      moment(cardData?.date).format("YYYY-MM-DD") >
      moment(cardData?.receiveDate).format("YYYY-MM-DD")
    ) {
      errorData["receiveDate"] = "receiveDate is Invalid";
      status = true;
    } else {
      errorData["receiveDate"] = "";
    }
    if (!type) {
      errorData["type"] = "Type is Required";
      status = true;
    } else {
      errorData["type"] = "";
    }
    if (Object?.keys(selectedCardNo)?.length === 0) {
      errorData["cardNo"] = "CardNo is Required";
      status = true;
    } else {
      errorData["cardNo"] = "";
    }
    if (!cardData?.PCNo) {
      errorData["PCNo"] = "Party Challan No  is Required";
      status = true;
    } else {
      errorData["PCNo"] = "";
    }
    if (!cardData?.designNo) {
      errorData["designNo"] = "DesignNo is Required";
      status = true;
    } else {
      errorData["designNo"] = "";
    }
    if (Object?.keys(selectedItem)?.length === 0) {
      errorData["item"] = "Item is Required";
      status = true;
    } else {
      errorData["item"] = "";
    }
    if (jobWorkTableData?.length === 0) {
      errorData["jobwork"] = "Job Work is Required";
      status = true;
    } else {
      errorData["jobwork"] = "";
    }
    if (cardData?.RTPM > pcsTotal) {
      errorData["RTPM"] = "Received Pcs is more than total";
      status = true;
    } else {
      errorData["RTPM"] = "";
    }
    if (
      Number(cardData?.RTPM) +
        Number(cardData?.plain) +
        Number(cardData?.short) >
      pcsTotal
    ) {
      errorData["RTPM"] = "more than total";
      errorData["plain"] = " more than total";
      errorData["short"] = " more than total";
      status = true;
    } else {
      errorData["RTPM"] = "";
    }
    setErrorMessage(errorData);
    if (!status) {
      setIsUpdating(true);

      var TPM = 0;
      TPM = jobWorkTableData.reduce(
        (total: number, currentItem: any) => total + parseInt(currentItem.PM),
        0
      );
      let finalData = {
        partyId: selectedParty.value,
        jobNo: selectedJobChallanNo,
        designNo: cardData?.designNo,
        date: cardData?.date,
        type: type,
        PCNo: cardData?.PCNo,
        nameId: selectedName?.value,
        itemId: selectedItem?.value,
        card: {
          number: selectedCardNo?.label,
          _id: selectedCardNo?.value,
        },
        TPM: TPM,
        rate: cardData?.rate ? Number(cardData?.rate) : null,
        totalAmount: Number(cardData?.rate) * pcsTotal,
        work: jobWorkTableData,
        receiveDate: cardData?.receiveDate,
        RTPM: cardData?.RTPM ? Number(cardData?.RTPM) : null,
        plain: Number(cardData?.plain),
        short: Number(cardData?.short),
      };
      updateChallan({
        payload: finalData,
        query: {
          jwId: id,
        },
      }).then((data: any) => {
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Challan Successfully Updated !!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger();
        }
      });
    }
  };
  let errorData = {
    name: "",
    type: "",
    cardNo: "",
    partyName: "",
    PCNo: "",
    designNo: "",
    item: "",
    rate: "",
    jobwork: "",
    cloth: "",
    fabricColor: "",
    PM: "",
    description: "",
    receiveDate: "",
    RTPM: "",
    plain: "",
    short: "",
  };

  const addData = () => {
    let status = false;
    if (Object?.keys(selectedParty)?.length === 0) {
      errorData["partyName"] = "Party is Required";
      status = true;
    } else {
      errorData["partyName"] = "";
    }
    if (Object?.keys(selectedName)?.length === 0) {
      errorData["name"] = "Name is Required";
      status = true;
    } else {
      errorData["name"] = "";
    }
    if (
      moment(cardData?.date).format("YYYY-MM-DD") >
      moment(cardData?.receiveDate).format("YYYY-MM-DD")
    ) {
      errorData["receiveDate"] = "receiveDate is Invalid";
      status = true;
    } else {
      errorData["receiveDate"] = "";
    }
    if (cardData?.RTPM > pcsTotal) {
      errorData["RTPM"] = "Received Pcs is more than total";
      status = true;
    } else {
      errorData["RTPM"] = "";
    }
    if (
      Number(cardData?.RTPM) +
        Number(cardData?.plain) +
        Number(cardData?.short) >
      pcsTotal
    ) {
      errorData["RTPM"] = "more than total";
      errorData["plain"] = " more than total";
      errorData["short"] = " more than total";
      status = true;
    } else {
      errorData["RTPM"] = "";
    }
    if (!type) {
      errorData["type"] = "Type is Required";
      status = true;
    } else {
      errorData["type"] = "";
    }
    if (Object?.keys(selectedCardNo)?.length === 0) {
      errorData["cardNo"] = "CardNo is Required";
      status = true;
    } else {
      errorData["cardNo"] = "";
    }
    if (!cardData?.PCNo) {
      errorData["PCNo"] = "Party Challan No  is Required";
      status = true;
    } else {
      errorData["PCNo"] = "";
    }
    if (!cardData?.designNo) {
      errorData["designNo"] = "DesignNo is Required";
      status = true;
    } else {
      errorData["designNo"] = "";
    }
    if (Object?.keys(selectedItem)?.length === 0) {
      errorData["item"] = "Item is Required";
      status = true;
    } else {
      errorData["item"] = "";
    }
    if (jobWorkTableData?.length === 0) {
      errorData["jobwork"] = "Job Work is Required";
      status = true;
    } else {
      errorData["jobwork"] = "";
    }
    setErrorMessage(errorData);
    if (!status) {
      setIsUpdating(true);
      var TPM = 0;
      TPM = jobWorkTableData.reduce(
        (total: number, currentItem: any) => total + parseInt(currentItem.PM),
        0
      );
      let finalData = {
        partyId: selectedParty.value,
        jobNo: selectedJobChallanNo,
        designNo: cardData?.designNo,
        type: type,
        date: cardData?.date,
        PCNo: cardData?.PCNo,
        nameId: selectedName?.value,
        itemId: selectedItem?.value,
        card: {
          number: selectedCardNo?.label,
          _id: selectedCardNo?.value,
        },
        TPM: TPM,
        rate: cardData?.rate ? Number(cardData?.rate) : null,
        totalAmount: Number(cardData?.rate) * pcsTotal,
        work: jobWorkTableData,
        receiveDate: cardData?.receiveDate,
        RTPM: cardData?.RTPM ? Number(cardData?.RTPM) : null,
        plain: Number(cardData?.plain),
        short: Number(cardData?.short),
      };
      addChallan(finalData).then((data: any) => {
        setSavedId(data?.data?.data?.insertedIds[0]);
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Challan Successfully Added!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger();
        }
      });
    }
  };

  const openInvoicePreview = () => {
    setShowPreview(true);
  };

  return (
    <div>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="invisible mr-6 lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute ml-0 sm:ml-0 md:ml-0 lg:ml-0 sm:top-0 md:top-0 lg:top-0 left-6 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-3 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-xl">Challan</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 w-2/5">
                <div className="w-1/2">
                  <Select
                    id="jobNo"
                    name="jobNo"
                    htmlFor="jobNo"
                    label="Job Challan No."
                    disabled
                    options={jobChallanNo}
                    value={selectedJobChallanNo}
                  />
                </div>
              </div>
              <div className="px-3 w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={showPreview}
                  onClick={openInvoicePreview}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button>
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={isChallanUpdate ? updateData : addData}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">
                    {isChallanUpdate ? "Update" : "Save"}
                  </span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/jobworkdashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="flex mt-0 bg-themeBgColor lg-mt-19 xl:mt-19 items-center p-0 overflow-hidden bg-cover">
          <div className="w-full mx-6">
            <div className="w-full h-full rounded-xl bg-white px-2">
              <div className="p-2 lg:p-0 xl:p-0 lg:hidden visible xl:hidden lg:invisible xl:invisible">
                <div className="mt-2 md:mt-0">
                  <Select
                    id="jobNo"
                    name="jobNo"
                    htmlFor="jobNo"
                    label="Job Challan No."
                    disabled
                    options={jobChallanNo}
                    value={selectedJobChallanNo}
                  />
                </div>
              </div>

              <div className="p-2 pb-0">
                <div>
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-5 md:pt-3 pb-0">
                    <div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="name"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block z-40 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 "
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "name");
                              setErrorMessage({
                                ...errorMessage,
                                name: "",
                              });
                            } else {
                              setSelectedName({});
                            }
                          }}
                          classNames={{
                            control: () =>
                              errorMessage?.name
                                ? "border-red-600"
                                : "border-gray-500 ",
                          }}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getNameTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllName = nameResult.data?.data?.challanName?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllName) {
                          //             setName(getAllName)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setNameAdd(data);
                            setShowAddName(true);
                          }}
                          options={Name && Name}
                          value={selectedName}
                          menuPosition="fixed"
                        />
                        <label
                          htmlFor="name"
                          className={`absolute z-40 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            errorMessage?.name
                              ? "text-red-500"
                              : "text-gray-500"
                          }`}
                        >
                          Name
                        </label>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.name}
                      </span>
                    </div>
                    <TextBox
                      id="address"
                      name="address"
                      htmlFor="address"
                      label="Address"
                      placeholder=" "
                      value={address}
                      disabled
                    />
                    <div>
                      <Select
                        id="type"
                        name="type"
                        htmlFor="type"
                        label="Type"
                        className={`${
                          errorMessage?.type
                            ? "border border-1 border-red-500 focus:border-red-500"
                            : "border border-1 border-gray-300 focus:border-themeColor"
                        }`}
                        LabelClassName={`${
                          errorMessage?.type
                            ? " text-red-500 peer-focus:text-red-500 "
                            : " text-gray-300 peer-focus:text-themeColor "
                        }`}
                        options={["Select Type", "dress", "saree", "lump"]}
                        onChange={(e: any) => {
                          handleTypeChange(e);
                          setErrorMessage({
                            ...errorMessage,
                            type: "",
                          });
                        }}
                        value={type}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.type}
                      </span>
                    </div>
                    <div className="relative rounded-md">
                      <ReactSelect
                        className={`basic-single block z-30 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer 
                        ${
                          errorMessage?.cardNo
                            ? "border-red-600"
                            : "border-gray-500"
                        }`}
                        classNamePrefix="select"
                        isSearchable={true}
                        value={selectedCardNo}
                        name="cardNo"
                        onChange={(e: any) => {
                          handleCardNoChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            cardNo: "",
                            partyName: "",
                            PCNo: "",
                            designNo: "",
                            jobwork: "",
                          });
                        }}
                        classNames={{
                          control: () =>
                            errorMessage?.cardNo
                              ? "border-red-600 focus:border-red-600 "
                              : "border-gray-500 focus:border-themeColor",
                        }}
                        options={options}
                        filterOption={(option: any, inputValue) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        menuPosition="fixed"
                      />

                      <label
                        htmlFor="cardNo"
                        className={`absolute z-30 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                          errorMessage?.cardNo
                            ? "text-red-500"
                            : "text-gray-500"
                        }`}
                      >
                        Card No.
                      </label>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.cardNo}
                      </span>
                    </div>
                    <div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="partyName"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block z-40 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "party");
                              setErrorMessage({
                                ...errorMessage,
                                partyName: "",
                              });
                            } else {
                              setSelectedParty({});
                            }
                          }}
                          classNames={{
                            control: () =>
                              errorMessage?.partyName
                                ? "border-red-600 focus:border-red-600 "
                                : "border-gray-500 focus:border-themeColor",
                          }}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getPartyNameTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllPartyName = result.data?.data?.party?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllPartyName) {
                          //             setParty(getAllPartyName)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setPartyNameAdd(data);
                            setShowAddParty(true);
                          }}
                          options={Party && Party}
                          value={selectedParty}
                          menuPosition="fixed"
                        />
                        <label
                          htmlFor="partyName"
                          className={`absolute z-40 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                            errorMessage?.partyName
                              ? "text-red-500"
                              : "text-gray-500"
                          }`}
                        >
                          Party Name
                        </label>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.partyName}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="PCNo"
                        name="PCNo"
                        htmlFor="PCNo"
                        label="Party Challan No"
                        placeholder=" "
                        hasError={errorMessage?.PCNo}
                        value={cardData?.PCNo}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            PCNo: "",
                          });
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.PCNo}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="designNo"
                        name="designNo"
                        htmlFor="designNo"
                        label="Design No"
                        placeholder=" "
                        hasError={errorMessage?.designNo}
                        value={cardData?.designNo}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            designNo: "",
                          });
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.designNo}
                      </span>
                    </div>
                    <div className="relative rounded-md">
                      <CreatableSelect
                        ref={jobWorkRef}
                        name="item"
                        classNamePrefix="customSelect"
                        filterOption={createFilter(selectFilterConfig)}
                        className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                        onChange={(e: any) => {
                          if (e) {
                            handleChange(e, "item");
                            setErrorMessage({
                              ...errorMessage,
                              item: "",
                            });
                          } else {
                            setSelectedItem({});
                          }
                        }}
                        classNames={{
                          control: () =>
                            errorMessage?.item
                              ? "border-red-500 focus:border-red-500"
                              : "border-gray-500 focus:border-themeColor",
                        }}
                        // onInputChange={(e: any) => {
                        //     if (e.length >= 1) {
                        //         getItemTrigger(e)
                        //     }
                        //     if (e.length == 0) {
                        //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                        //             return {
                        //                 label: name,
                        //                 value: _id,
                        //             };
                        //         });
                        //         if (getAllItem) {
                        //             setItem(getAllItem)
                        //         }
                        //     }
                        // }}
                        onCreateOption={(data: any) => {
                          setItemAdd(data);
                          setShowAddItem(true);
                        }}
                        options={Item}
                        value={selectedItem}
                        menuPosition="fixed"
                      />
                      <label
                        htmlFor="item"
                        className={`absolute z-20 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                          errorMessage?.item ? "text-red-500" : "text-gray-500"
                        }`}
                      >
                        Item
                      </label>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.item}
                      </span>
                    </div>
                    <TextBox
                      id="date"
                      type="date"
                      name="date"
                      htmlFor="date"
                      label="Date"
                      tabIndex={-1}
                      placeholder=" "
                      value={cardData?.date}
                      onChange={(e: any) => handleChanges(e)}
                    />
                    <TextBox
                      id="rate"
                      name="rate"
                      htmlFor="rate"
                      label="Rate"
                      placeholder=" "
                      value={cardData?.rate}
                      onChange={(e: any) => handleChanges(e)}
                    />
                    <div>
                      <TextBox
                        id="receiveDate"
                        type="date"
                        name="receiveDate"
                        htmlFor="receiveDate"
                        label="Receive Date"
                        placeholder=" "
                        value={cardData?.receiveDate}
                        onChange={(e: any) => handleChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.receiveDate}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="date"
                        name="RTPM"
                        htmlFor="RTPM"
                        label="Receive Piece"
                        type="number"
                        placeholder=" "
                        value={cardData?.RTPM}
                        onChange={(e: any) => handleChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.RTPM}
                      </span>
                    </div>

                    <div>
                      <TextBox
                        id="plain"
                        name="plain"
                        htmlFor="plain"
                        label="plain"
                        type="number"
                        placeholder=" "
                        value={cardData?.plain}
                        onChange={(e: any) => handleChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.plain}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="short"
                        name="short"
                        htmlFor="short"
                        label="short"
                        type="number"
                        placeholder=" "
                        value={cardData?.short}
                        onChange={(e: any) => handleChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.short}
                      </span>
                    </div>
                  </div>

                  <div>
                    <fieldset
                      className={`border-2 mt-2 rounded-2 border-gray-400 p-2 ${
                        errorMessage?.jobwork
                          ? "border-red-500"
                          : "border-gray-400"
                      }`}
                    >
                      <legend
                        className={`capitalize px-2 ${
                          errorMessage?.jobwork ? "text-red-500" : ""
                        }`}
                      >
                        Job Work
                      </legend>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-6">
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="cloth"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.cloth
                                  ? "border-red-600"
                                  : "border-gray-500",
                            }}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "cloth");
                                setErrorMessage({
                                  ...errorMessage,
                                  cloth: "",
                                });
                              } else {
                                setSelectedCloth({});
                              }
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getClothTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllCloth = clothResult.data?.data?.clothe?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllCloth) {
                            //             setCloth(getAllCloth)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setClothAdd(data);
                              setShowAddCloth(true);
                            }}
                            options={Cloth}
                            value={selectedCloth}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="cloth"
                            className={`absolute text-md text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                              errorMessage?.cloth
                                ? "text-red-500"
                                : "text-gray-500"
                            }`}
                          >
                            Fabric
                          </label>
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.cloth}
                          </span>
                        </div>
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="fabricColor"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.fabricColor
                                  ? "border-red-600"
                                  : "border-grey-300",
                            }}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "fabricColor");
                                setErrorMessage({
                                  ...errorMessage,
                                  fabricColor: "",
                                });
                              } else {
                                setSelectedFabric({});
                              }
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getFabricColorTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllFabric = fabricResult.data?.data?.fabricColor?.map(({ _id, colorName }: any) => {
                            //             return {
                            //                 label: colorName,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllFabric) {
                            //             setFabric(getAllFabric)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setFabricAdd(data);
                              setShowAddFabric(true);
                            }}
                            options={Fabric}
                            value={selectedFabric}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="item"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                              errorMessage?.fabricColor
                                ? "text-red-500"
                                : "text-gray-500"
                            }`}
                          >
                            Fabric Color
                          </label>
                          <span className="text-sm text-red-500 flex justify-start">
                            {errorMessage?.fabricColor}
                          </span>
                        </div>

                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="remarks"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "remarks");
                              } else {
                                setSelectedRemarks({});
                              }
                            }}
                            isClearable={true}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getRemarkTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         remarksTrigger('')
                            //         let getAllRemark = remarksResult.data?.data?.remark?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllRemark) {
                            //             setRemarks(getAllRemark)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setRemarksAdd(data);
                              setShowAddRemarks(true);
                            }}
                            options={Remarks}
                            value={selectedRemarks}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="remarks"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                          >
                            Quantity
                          </label>
                        </div>
                        <div>
                          <TextBox
                            htmlFor="PM"
                            id="PM"
                            name="PM"
                            label="Pcs / Meater"
                            type="number"
                            placeholder=" "
                            hasError={errorMessage?.PM}
                            value={jobData?.PM}
                            onChange={(e: any) => {
                              handleJobChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                PM: "",
                              });
                            }}
                          />
                          <span className="text-sm text-red-500 flex justify-start">
                            {errorMessage?.PM}
                          </span>
                        </div>

                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="description"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.description
                                  ? "border-red-600"
                                  : "border-gray-500",
                            }}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "description");
                                setErrorMessage({
                                  ...errorMessage,
                                  description: "",
                                });
                              } else {
                                setSelectedDesc({});
                              }
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getDescTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllDesc = result.data?.data?.description?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllDesc) {
                            //             setDesc(getAllDesc)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setDescAdd(data);
                              setShowAddDesc(true);
                            }}
                            options={Desc && Desc}
                            value={selectedDesc}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="description"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                              errorMessage?.description
                                ? "text-red-500"
                                : "text-gray-500"
                            }`}
                          >
                            Description
                          </label>
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.description}
                          </span>
                        </div>
                        <button
                          className="w-full px-4 py-2 text-sm leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={() => {
                            if (isJobUpdate) {
                              let record = {
                                _id: selectedJobRow,
                                clotheId: selectedCloth?.value || "",
                                fabricColorId: selectedFabric?.value || "",
                                PM: Number(jobData?.PM) || "",
                                descriptionId: selectedDesc?.value || "",
                                remarkId: selectedRemarks?.value || "",
                              };
                              if (Object.keys(selectedCloth)?.length === 0) {
                                errorData["cloth"] = "Fabric is Required";
                              } else errorData["cloth"] = "";
                              if (Object?.keys(selectedFabric)?.length == 0) {
                                errorData["fabricColor"] =
                                  "Fabric Color is Required";
                              } else {
                                errorData["fabricColor"] = "";
                              }
                              if (!jobData?.PM) {
                                errorData["PM"] = "Pcs / Mtr is Required";
                              } else {
                                errorData["PM"] = "";
                              }
                              if (!selectedDesc?.value) {
                                errorData["description"] =
                                  "Description is Required";
                              } else {
                                errorData["description"] = "";
                              }
                              setErrorMessage(errorData);
                              const result: any =
                                challanJobWorkValidation(record);
                              if (result === true) {
                                const elementsIndex =
                                  jobWorkTableData.findIndex(
                                    (element: any) =>
                                      element?._id == selectedJobRow
                                  );

                                let newArray = [...jobWorkTableData];

                                newArray[elementsIndex] = {
                                  ...newArray[elementsIndex],
                                  ...record,
                                };

                                setJobData({
                                  PM: "",
                                });
                                setSelectedFabric({});
                                setSelectedCloth({});
                                setSelectedDesc({});
                                setSelectedRemarks({});
                                setIsJobUpdate(false);
                                setJobWorkTableData(newArray);
                              } else {
                                Swal.fire({
                                  toast: true,
                                  icon: "error",
                                  title: result.errorMessage,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              }
                            } else {
                              let record = {
                                _id: ObjectID().toHexString(),
                                clotheId: selectedCloth?.value || "",
                                fabricColorId: selectedFabric?.value || "",
                                PM: Number(jobData?.PM) || "",
                                descriptionId: selectedDesc?.value || "",
                                remarkId: selectedRemarks?.value || "",
                              };
                              if (Object.keys(selectedCloth)?.length === 0) {
                                errorData["cloth"] = "Fabric is Required";
                              } else errorData["cloth"] = "";
                              if (Object?.keys(selectedFabric)?.length === 0) {
                                errorData["fabricColor"] =
                                  "Fabric Color is Required";
                              } else {
                                errorData["fabricColor"] = "";
                              }
                              if (!jobData?.PM) {
                                errorData["PM"] = "Pcs / Mtr is Required";
                              } else {
                                errorData["PM"] = "";
                              }
                              if (Object.keys(selectedDesc)?.length === 0) {
                                errorData["description"] =
                                  "Description is Required";
                              } else {
                                errorData["description"] = "";
                              }
                              setErrorMessage(errorData);
                              const result: any =
                                challanJobWorkValidation(record);
                              if (result === true) {
                                setJobWorkTableData([
                                  ...jobWorkTableData,
                                  record,
                                ]);
                                setJobData({
                                  PM: "",
                                });
                                setSelectedFabric({});
                                setSelectedCloth({});
                                setSelectedDesc({});
                                setSelectedRemarks({});
                              } else {
                                Swal.fire({
                                  toast: true,
                                  icon: "error",
                                  title: result.errorMessage,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              }
                            }
                          }}
                        >
                          {isJobUpdate ? "Update Job Work" : "Add Job Work"}{" "}
                        </button>
                      </div>
                      {/* Table */}
                      <div className="grid grid-cols-1 mt-2">
                        <div className="border border-gray-300 rounded-3 overflow-hidden">
                          <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="min-w-full sm:px-6 lg:px-0">
                                <div className="overflow-hidden">
                                  <div className="flex-auto px-0 pt-0">
                                    <div className="p-0 overflow-hidden">
                                      {jobWorkTableData &&
                                      jobWorkTableData.length > 0 ? (
                                        <div className="max-h-80 overflow-auto shadow-md">
                                          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                            <thead className="bg-gray-50 capitalize">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Sr No.
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Fabric
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Fabric Color
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Quantity
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Pcs
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Description
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 font-bold text-center text-gray-900"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                              {jobWorkTableData?.map(
                                                (row: any, index: number) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="hover:bg-gray-50"
                                                    >
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {index + 1}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          clothState[
                                                            row?.clotheId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          fabricState[
                                                            row?.fabricColorId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          remarksState[
                                                            row?.remarkId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row?.PM}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          descState[
                                                            row?.descriptionId
                                                          ]
                                                        }
                                                      </td>
                                                      <td>
                                                        <div>
                                                          <button
                                                            className="m-2"
                                                            type="button"
                                                            onClick={() => {
                                                              setSelecetdJobRow(
                                                                row?._id
                                                              );
                                                              setJobData({
                                                                srNo: row?.srNo,
                                                                PM: row?.PM,
                                                              });
                                                              setSelectedCloth({
                                                                label:
                                                                  clothState[
                                                                    row
                                                                      ?.clotheId
                                                                  ],
                                                                value:
                                                                  row.clotheId,
                                                              });
                                                              setSelectedFabric(
                                                                {
                                                                  label:
                                                                    fabricState[
                                                                      row
                                                                        ?.fabricColorId
                                                                    ],
                                                                  value:
                                                                    row?.fabricColorId,
                                                                }
                                                              );
                                                              setSelectedDesc({
                                                                label:
                                                                  descState[
                                                                    row
                                                                      ?.descriptionId
                                                                  ],
                                                                value:
                                                                  row?.descriptionId,
                                                              });
                                                              setSelectedRemarks(
                                                                {
                                                                  label:
                                                                    remarksState[
                                                                      row
                                                                        ?.remarkId
                                                                    ],
                                                                  value:
                                                                    row?.remarkId,
                                                                }
                                                              );
                                                              setIsJobUpdate(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-pen hover:text-green-600"></i>
                                                          </button>
                                                          <button
                                                            className="m-2"
                                                            onClick={() => {
                                                              Swal.fire({
                                                                title:
                                                                  "Are you sure?",
                                                                text: "Once deleted, you will not be able to recover this record!",
                                                                icon: "warning",
                                                                showCancelButton:
                                                                  true,
                                                                confirmButtonColor:
                                                                  "#3085d6",
                                                                cancelButtonColor:
                                                                  "#d33",
                                                                confirmButtonText:
                                                                  "Delete",
                                                              }).then(
                                                                (
                                                                  willDelete
                                                                ) => {
                                                                  if (
                                                                    willDelete.isConfirmed
                                                                  ) {
                                                                    let newData =
                                                                      jobWorkTableData.filter(
                                                                        (
                                                                          item: any,
                                                                          index: any
                                                                        ) => {
                                                                          return (
                                                                            item?._id !==
                                                                            row?._id
                                                                          );
                                                                        }
                                                                      );

                                                                    setJobWorkTableData(
                                                                      newData
                                                                    );
                                                                    setJobData({
                                                                      PM: "",
                                                                    });
                                                                    setSelectedFabric(
                                                                      {}
                                                                    );
                                                                    setSelectedCloth(
                                                                      {}
                                                                    );
                                                                    setIsJobUpdate(
                                                                      false
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-trash hover:text-red-600"></i>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                            <tfoot className="border-t bg-gray-50">
                                              <tr>
                                                <td
                                                  colSpan={4}
                                                  className="px-6 py-1 font-bold text-gray-900 text-left"
                                                >
                                                  Total
                                                </td>
                                                <td
                                                  colSpan={2}
                                                  className="px-6 font-bold text-gray-900 text-left"
                                                >
                                                  {pcsTotal}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-3 text-gray-600 font-bold">
                                          No Job Work Found
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.jobwork}
                      </span>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible">
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showPreview}
                    onClick={openInvoicePreview}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={isChallanUpdate ? updateData : addData}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">
                      {isChallanUpdate ? "Update" : "Save"}
                    </span>
                  </button>
                </div>
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/jobworkdashboard");
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Pdf Preview */}
            {showPreview && (
              <JobWorkPreview
                showPreview={showPreview}
                setShowPreview={setShowPreview}
                id={isChallanUpdate ? id : savedId}
                pageName="Challan"
              />
            )}

            {/* Modal */}
            {showAddParty && (
              <PartyAdd
                setShowAddParty={setShowAddParty}
                showAddParty={showAddParty}
                setPartyNameAdd={setPartyNameAdd}
                partyNameAdd={partyNameAdd}
                getData={getData}
              />
            )}

            {showAddCloth && (
              <ClothAdd
                setShowAddCloth={setShowAddCloth}
                showAddCloth={showAddCloth}
                setClothAdd={setClothAdd}
                clothAdd={clothAdd}
                getClothData={getClothData}
              />
            )}
            {showAddItem && (
              <ItemAdd
                setShowAddItem={setShowAddItem}
                showAddItem={showAddItem}
                setItemAdd={setItemAdd}
                itemAdd={itemAdd}
                getItemData={getItemData}
              />
            )}
            {showAddDesc && (
              <DescAdd
                setShowAddDesc={setShowAddDesc}
                showAddDesc={showAddDesc}
                setDescAdd={setDescAdd}
                descAdd={descAdd}
                getDescData={getDescData}
              />
            )}
            {showAddFabric && (
              <FabricAdd
                setShowAddFabric={setShowAddFabric}
                showAddFabric={showAddFabric}
                setFabricAdd={setFabricAdd}
                fabricAdd={fabricAdd}
                getFabricData={getFabricData}
              />
            )}
            {showAddName && (
              <NameAdd
                setShowAddName={setShowAddName}
                showAddName={showAddName}
                setNameAdd={setNameAdd}
                nameAdd={nameAdd}
                getNameData={getNameData}
              />
            )}
            {showAddRemarks && (
              <ChallanRemarksAdd
                setShowAddRemarks={setShowAddRemarks}
                showAddRemarks={showAddRemarks}
                setRemarksAdd={setRemarksAdd}
                remarksAdd={remarksAdd}
                getRemarksData={getRemarksData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
