import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

export const DateComponent = (props: any) => {
  const {
    header,
    isModalOpen,
    setIsModalOpen,
    selectedRow,
    copyTrigger,
    isCopy,
    isPreview,
    openInvoicePreview,
    handleDelete,
    route,
  } = props;
  const Close = (
    <i className="fas fa-times bg-black text-white rounded-full px-2 py-1.5"></i>
  );
  const [startDate, setStartDate]: any = useState();
  const navigate = useNavigate();

  // Define a function to handle the button click and navigate
  const handleEditClick = () => {
    if (selectedRow) {
      // Construct the URL with query parameters to pass data
      const editUrl = `/${route}/${selectedRow?._id}?startDate=${
        startDate ? startDate?.toString() : new Date()
      }`;

      // Navigate to the new screen
      navigate(editUrl);
    }
  };
  var minDate = new Date(2023, 0, 1);

  return (
    <div>
      <Modal
        open={isModalOpen}
        closeIcon={Close}
        onClose={() => {
          setIsModalOpen(false);
        }}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onOverlayClick={() => {
          setIsModalOpen(false);
        }}
        onEscKeyDown={() => {
          setIsModalOpen(false);
        }}
        classNames={{
          modal: "rounded-xl pb-0",
        }}
        styles={{
          modal: { background: "#e9ecef" },
          overlay: {
            height: "auto",
          },
        }}
      >
        <div className="p-2 mt-5 ">
          <div className="font-bold mb-5 text-center">
            Selected Record : {header}
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <DatePicker
                selected={startDate}
                onChange={(date: any) => {
                  setStartDate(date);
                }}
                dateFormat="MMM yyy"
                showMonthYearPicker
                minDate={minDate}
                inline
                onYearChange={(date: any) => {
                  setStartDate(date);
                  const selectedMonth = document.getElementsByClassName(
                    "react-datepicker__month-text--keyboard-selected"
                  );
                }}
                onCalendarOpen={() => {
                  const date = new Date();
                  setStartDate(date);
                  const x = document.getElementsByClassName(
                    "react-datepicker-popper"
                  );
                  const y = document.getElementsByClassName(
                    "react-datepicker__triangle"
                  );
                  const z = document.getElementsByClassName(
                    "react-datepicker__header"
                  );
                  const months = document.getElementsByClassName(
                    "react-datepicker__month-text"
                  );
                  const selectedMonth = document.getElementsByClassName(
                    "react-datepicker__month-text--keyboard-selected"
                  );
                }}
              />
            </div>
          </div>
          <button
            className="px-3 py-2 mr-2 mb-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            onClick={handleEditClick}
          >
            CLick To edit
          </button>
        </div>
      </Modal>
    </div>
  );
};
