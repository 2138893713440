// import axios from "../../config/api";
import { useEffect, useState } from "react";
import { setErrorMessage } from '../../redux/globalSlice'
import { setUserDetailInLocalSorage } from '../../utils/localStorage'
import { useLazyLoginQuery } from '../../servicesRtkQuery/publicApi'
import { useDispatch, useSelector } from "react-redux";
import { loginValidation } from '../../utils/validation';
import { setloginSignupVar } from '../../redux/loginSignupSlice';
import Swal from "sweetalert2";
import Loader from "../../components/page/loader";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

interface LoginData {
    email: string;
    password: string;
}
const Login = () => {
    const dispatch = useDispatch()
    const [logInData, setLogInData] = useState<LoginData>({
        email: "",
        password: ""
    });

    const onHandleChange = (e: any) => {
        setLogInData({
            ...logInData,
            [e.target.name]: e.target.value,
        });
    };

    const [trigger, result] = useLazyLoginQuery()
    const { isSuccess, isFetching, isError, error } = result
    const [passwordVisible, setPasswordVisible] = useState(false)

    const handleSubmit = (e: any) => {
        const result: any = loginValidation(logInData)
        if (result === true) {
            trigger(logInData)
        } else {
            dispatch(setErrorMessage(result))
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.email || result.password,
                position: 'bottom',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
        e.preventDefault();
    }

    useEffect(() => {
        if (isError) {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: error?.data?.message ? error?.data?.message : "Something went wrong!\nPlease contact the administrator.",
                position: 'bottom',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
        if (isSuccess && !isFetching) {
            setUserDetailInLocalSorage(result.data.data)
            dispatch(setloginSignupVar({
                isUserHasToken: result.data.results?.token,
            }))
            dispatch(setloginSignupVar({
                isUserHasToken: result.data.results?.token,
            }))
            window.location.href = "/";
        }
    }, [isSuccess, isFetching])

    return (
        <section className="text-gray-600 body-font w-full bg-white">
            <main className="mt-0 transition-all duration-200 ease-in-out">
                <section>
                    <div className="relative flex dark:bg-black items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
                        <div className="container z-1">
                            <div className="flex flex-wrap -mx-3">
                                <div className="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-5/12">
                                    <img src="../../assets/images/logo.png" className="inline h-full m-auto w-30 transition-all duration-200 ease-nav-brand" alt="Satv" />
                                    <div className="relative h-full flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py-4 dark:bg-black rounded-2xl bg-clip-border">
                                        <div className="pb-0 mb-0 dark:text-white">
                                            <h4 className="font-bold dark:text-white">Sign In</h4>
                                            <p className="mb-0">Enter your email and password to sign in</p>
                                        </div>
                                        <div className="flex-auto py-2">
                                            <form role="form" onSubmit={(e) => handleSubmit(e)}>
                                                <div className="mb-4">
                                                    <input type="email" placeholder="Email" className="focus:shadow-primary-outline dark:bg-gray-900 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-themeColor focus:outline-none" name="email" onChange={(e) => onHandleChange(e)} />
                                                </div>
                                                <div className="mb-4">
                                                    <div className='relative'>
                                                        <input type={passwordVisible ? "text" : "password"} placeholder="Password" className="focus:shadow-primary-outline dark:bg-gray-900 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-themeColor focus:outline-none" name="password" onChange={(e) => onHandleChange(e)} />
                                                        <i className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"} text-gray-600 cursor-pointer right-3 top-4 absolute`} onClick={() => setPasswordVisible(!passwordVisible)}></i>
                                                    </div>
                                                </div>
                                                <div className="flex items-center pl-12 mb-0.5 text-left min-h-6">
                                                    <input id="rememberMe" className="mt-0.5 rounded-10 duration-250 ease-in-out after:rounded-circle after:shadow-2xl after:duration-250 checked:after:translate-x-5.3 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-zinc-700/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-themeColor checked:bg-themeColor checked:bg-none checked:bg-right" type="checkbox" />
                                                    <label className="ml-2 font-normal cursor-pointer select-none text-sm text-slate-700 dark:text-white" htmlFor="rememberMe">Remember me</label>
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-themeColor border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25">Sign in <i className='fas fa-unlock-alt ml-2 text-white cursor-pointer'></i></button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="border-black/12.5 rounded-b-2xl border-t-0 border-solid text-center pt-0 px-1 sm:px-6">
                                            <div className="flex justify-center bg-no-repeat  bg-cover h-full md:bg-center">
                                                <div className="flex flex-col items-center justify-end">
                                                    <div className="flex flex-col justify-center items-center text-center max-w-7xl mt-8 py-4 px-10">
                                                        <span className="underline underline-offset-2 text-gray-700-mt-3"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                                                        <div className="flex flex-col items-center text-gray-700 mt-3">
                                                            <h1 className="text-lg font-semibold dark:text-white">Powered By</h1>
                                                            <a href="https://codenettech.com/" target="_blank">
                                                                <img src="../../assets/images/cnt-logo-dark.png" className="inline h-full w-25  transition-all duration-200 dark:hidden ease-nav-brand" alt="CodeNetTech" />
                                                                <img src="../../assets/images/cnt-logo-white.png" className="hidden h-full w-25 transition-all duration-200 dark:inline ease-nav-brand" alt="CodeNetTech" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                                    <div className="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://cnt-satv.s3.ap-south-1.amazonaws.com/login-bg.png')] rounded-xl">
                                        <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-r from-transparent to-black opacity-60"></span>
                                        <h4 className="z-20 mt-12 font-bold text-white">"Creativity is Unique as Embroidery"</h4>
                                        <p className="z-20 text-white">Making LIfe with Thread and Needle</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {
                isFetching ?
                    <Loader />
                    : null
            }
        </section>
    );
};

export default Login;
