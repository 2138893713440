import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useAddRemarksMutation } from "../../servicesRtkQuery/publicApi";
import TextBox from "../form/TextBox";
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../redux/globalSlice';
import Error from "../error";
import Swal from "sweetalert2";
import { capitalize } from "../../utils/globalFunctions";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export const RemarksAdd = (props: any) => {
    const dispatch = useDispatch()
    const [remarksName, setRemarksName]: any = useState()
    useEffect(() => {
        if (props.remarksAdd) {
            setRemarksName(props.remarksAdd)
        }
    }, [props.remarksAdd])

    const handleChange = (e: any) => {
        setRemarksName(e.target.value)
    }

    const [addData, { isSuccess, isError, isLoading, error, data }] = useAddRemarksMutation();
    const create = () => {
        let setError: any = '';
        dispatch(setErrorMessage(setError))

        if (remarksName.trim() === "") {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Please Enter Valid Input !",
                position: 'top-end',
                showConfirmButton: false,
                timer : ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
        else {
            addData({ name: capitalize(remarksName.trim()) })
        }
    }

    useEffect(() => {
        if (isSuccess && !isLoading) {
            let newData: any = {
                label: capitalize(remarksName.trim()),
                value: data.data.insertedIds[0],
            }
            props.getRemarksData(newData, props?.itemType)
        }
        if (isError && !isLoading) {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Remark Already Exists !",
                position: 'top-end',
                showConfirmButton: false,
                timer : ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }, [isSuccess, isError, isLoading])


    return (
        <div>
            <Modal open={props.showAddRemarks} showCloseIcon={false}
                center
                closeOnEsc={false}
                closeOnOverlayClick={false}
                            classNames={{
                                modal: "rounded-xl"
                            }}
                styles={{
                    overlay: {
                        height: "auto",
                    },
                }}
                onClose={() => props.setShowAddRemarks(false)}>
                <div className="inset-0 overflow-auto">
                    <div className="flex items-end sm:items-center justify-center text-center sm:p-0">
                        <div className="relative rounded-lg overflow-hidden text-left shadow-xl">
                            <div className="w-full">
                                <div className="text-lg text-left">Add New Remarks</div>
                                <div className="p-6">
                                    <TextBox
                                        id="remarksName" name="remarksName" htmlFor="remarksName"
                                        label="Remarks Name" placeholder=" " onChange={(e: any) => handleChange(e)} value={remarksName}
                                    />
                                    <div className="m-3 flex justify-between">
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => props.setShowAddRemarks(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => create()}>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >
        </div >
    )
}
