import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import TextBox from "../form/TextBox";
import { useAddDesignCodeMutation } from "../../servicesRtkQuery/publicApi";
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../redux/globalSlice';
import Error from "../error";
import Swal from "sweetalert2";
import { capitalize } from "../../utils/globalFunctions";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export const DesignCodeAdd = (props: any) => {
    const dispatch = useDispatch()
    const [designCodeNew, setDesignCodeNew]: any = useState()
    useEffect(() => {
        if (props.designCodeAdd) {
            setDesignCodeNew(props.designCodeAdd)
        }
    }, [props.designCodeAdd])

    const handleChange = (e: any) => {
        setDesignCodeNew(e.target.value)

    }

    const [addDesignCode, { isSuccess, isError, isLoading, error, data }] = useAddDesignCodeMutation();
    const createDesignCode = () => {
        let setError: any = '';
        dispatch(setErrorMessage(setError))
        if (designCodeNew.trim() === "") {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Please Enter Valid Input !",
                position: 'top-end',
                showConfirmButton: false,
                timer : ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
        else {
            addDesignCode({ designCode: capitalize(designCodeNew.trim()) })
        }
    }

    useEffect(() => {
        if (isSuccess && !isLoading) {
            let newParty: any = {
                label: capitalize(designCodeNew.trim()),
                value: data.data.insertedIds[0],
            }
            props.getDesignCode(newParty, props.codeType)
        }
        if (isError && !isLoading) {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Design Code Already Exists !",
                position: 'top-end',
                showConfirmButton: false,
                timer : ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }, [isSuccess, isError, isLoading])

    return (
        <div>
            <Modal open={props.showAddDesignCode} showCloseIcon={false}
                center
                closeOnEsc={false}
                closeOnOverlayClick={false}
                            classNames={{
                                modal: "rounded-xl"
                            }}
                styles={{
                    overlay: {
                        height: "auto",
                    },
                }}
                onClose={() => props.setShowAddDesignCode(false)}>
                <div className="inset-0 overflow-auto">
                    <div className="flex items-end sm:items-center justify-center text-center sm:p-0">
                        <div className="relative rounded-lg overflow-hidden text-left shadow-xl">
                            <div className="w-full">
                                <div className="text-lg text-left">Add New Design Code</div>
                                <div className="p-6">
                                    <TextBox
                                        id="designCode" name="designCode" htmlFor="designCode"
                                        label="Design Code" placeholder=" " defaultValue={designCodeNew}
                                        onChange={(e: any) => handleChange(e)}
                                    />
                                    <div className="m-3 flex justify-between">
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => props.setShowAddDesignCode(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => createDesignCode()}>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >
        </div >
    )
}
