import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useAddItemMutation, useAddMaterialMutation, useLazyItemQuery, useLazyMaterialQuery, useUpdateItemMutation, useUpdateMaterialMutation } from '../../servicesRtkQuery/publicApi'
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Swal from 'sweetalert2';
import { employeeValidation } from '../../utils/validation';
import TextBox from '../../components/form/TextBox';
import customStyles, { capitalize } from '../../utils/globalFunctions';
import { ERROR_MESSAGE_TIMEOUT } from '../../utils/constants';
import moment from 'moment';
import { SettingTableAction } from '../../components/modal/SettingTableAction';

const Material = () => {
    const [trigger, result] = useLazyMaterialQuery()
    const { isSuccess, isFetching, isError, error } = result
    const [tableData, setTableData]: any = useState<any>([])

    const [newData, setNewData]: any = useState({
        name: ""
    });
    const [totalRows, setTotalRows]: any = useState(0);
    const [perPage, setPerPage]: any = useState(25);
    const [page, setPage]: any = useState(1);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState("");
    const Close = <i className='fas fa-times bg-black text-white rounded-full px-2 py-1.5'></i>
    useEffect(() => {
        if (!filterText) {
            trigger({ page: page, limit: perPage });
        }
    }, [page, perPage]);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setTableData(result?.data?.data?.material)
            setTotalRows(result?.data?.data?.count)
        }
    }, [isSuccess, isFetching])

    function editFunction(row: any) {
        setIsModalOpen(true)
        setUpdateRows(row)
        setIsAdd(false)
        let updatedData = {
            name: row.name,
        }
        setNewData(updatedData)
    }

    const columns: any = [
        {
            name: "Material Name",
            selector: (row: { [x: string]: any; }) => row?.name,
            sortable: true
        },
        {
            name: "Created At",
            selector: (row: { [x: string]: any; }) => moment(row?.createAt).format("DD-MM-YYYY"),
            sortable: true
        }
    ];
    const [addTrigger, addResult] = useAddMaterialMutation()
    const [updateTrigger, updateResult] = useUpdateMaterialMutation()
    const [updateRow, setUpdateRows] = useState<any>();

    const handleUpdate = () => {
        let updatedData = {
            name: capitalize(newData.name.trim()),
        }
        const result: any = employeeValidation(updatedData)
        if (result === true) {
            let id = updateRow._id
            updateTrigger({
                payload: updatedData,
                query: {
                    materialId: id
                }
            }).then((data: any) => {
                if (data.error) {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: data.error.data.message,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: ERROR_MESSAGE_TIMEOUT,
                        timerProgressBar: true
                    })
                }
                else {
                    setIsModalOpen(false)
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Material has been Successfully Updated!",
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    })
                    if (!filterText) {
                        trigger({ page: page, limit: perPage });
                    }
                }
            })

        }
        else {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.errorMessage,
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }

    }

    const handleDelete = (row: any) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let id = row._id
                let updatedData = {
                    name: row.name,
                    status: false
                }
                updateTrigger({
                    payload: updatedData,
                    query: {
                        materialId: id
                    }
                }).then((data: any) => {
                    if (data.error) {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: data.error.data.message,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: ERROR_MESSAGE_TIMEOUT,
                            timerProgressBar: true
                        })
                    }
                    else {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: "Material has been deleted!",
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        })
                        if (!filterText) {
                            trigger({ page: page, limit: perPage });
                        }
                    }
                })
            }
        });

    }

    const onHandleChange = (e: any) => {
        setNewData({
            ...newData,
            [e.target.name]: e.target.value,
        });

    };

    const handleSubmit = (e: any) => {
        let updatedData = {
            name: capitalize(newData.name.trim()),
        }
        const result: any = employeeValidation(updatedData)
        if (result === true) {
            addTrigger(updatedData).then((data: any) => {
                if (data.error) {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: data.error.data.message,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: ERROR_MESSAGE_TIMEOUT,
                        timerProgressBar: true
                    })
                }
                else {
                    setIsModalOpen(false)
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Material has been Successfully Added!",
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    })
                    if (!filterText) {
                        trigger({ page: page, limit: perPage });
                    }
                }
            })
        }
        else {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.errorMessage,
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }

    const modalClose = () => {
        setIsModalOpen(false)
    }
    const [isAdd, setIsAdd] = useState<any>(false)

    // filter search
    const [filterText, setFilterText] = useState('');
    useEffect(() => {
        if (filterText) {
            let payload = {
                name: filterText,
                page: page,
                limit: perPage
            }
            trigger(payload)
        }
        if (!filterText) {
            trigger({ page: page, limit: perPage })
        }
    }, [filterText, page, perPage])
    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            trigger({ page: page, limit: perPage })
        }
    };

    // pagination 
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const handlePerRowsChange = async (newPerPage: any) => {
        setPerPage(newPerPage);
    }
    return (
        <div className="mb-6 break-words border-0 border-transparent border-solid bg-clip-border">
            <div className="px-3 py-4 flex justify-between items-center">
                <button className="px-4 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                    onClick={() => {
                        setIsModalOpen(true);
                        setIsAdd(true);
                        let updatedData = {
                            name: ""
                        }
                        setNewData(updatedData)
                    }}>
                    <i className='fas fa-hat-cowboy'></i><span className='ml-2'>Add Item</span>
                </button>
                <div className='relative w-[90px] md:w-max'>
                    <TextBox
                        id="search"
                        type="text"
                        placeholder=" "
                        label="Search"
                        aria-label="Search Input"
                        value={filterText}
                        onChange={(e: any) => {
                            setFilterText(e?.target?.value)
                        }}
                    />
                    {filterText ?
                        <i className='fas fa-times-circle text-gray-600 cursor-pointer right-3 top-3 absolute' onClick={handleClear}></i>
                        : null
                    }
                </div>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
                <div className="px-5 overflow-x-auto">
                    <DataTable
                        title="Materials"
                        columns={columns}
                        data={tableData}
                        fixedHeader={true}
                        defaultSortFieldId="id"
                        defaultSortAsc={false}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                        paginationDefaultPage={page}
                        paginationPerPage={perPage}
                        striped={true}
                        responsive={true}
                        highlightOnHover
                        persistTableHead
                        customStyles={customStyles}
                        onRowClicked={(e: any) => {
                            setSelectedRow(e);
                            setIsActionModalOpen(true)
                        }}
                    />
                </div>
            </div>

            <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto rounded">
                    {isModalOpen ?
                        <Modal
                            open={isModalOpen}
                            closeIcon={Close}
                            onClose={modalClose}
                            center
                            onEscKeyDown={() => setIsModalOpen(false)}
                            onOverlayClick={() => setIsModalOpen(false)}
                            classNames={{
                                modal: "rounded-xl"
                            }}
                            styles={{
                                overlay: {
                                    height: "auto",
                                },
                            }}
                        >
                            <form className="w-100 p-2">
                                <div className="flex flex-wrap mb-6">
                                    <div className="w-full md:w-4/5 px-3">
                                        <TextBox name="name" htmlFor="name" id="name" type="text" placeholder=" " className="text-lg" label="Name" value={newData?.name} onChange={(e: any) => onHandleChange(e)} />
                                    </div>
                                </div>
                                {
                                    isAdd ?
                                        <button className="ml-4 px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                                            onClick={handleSubmit}>
                                            Add
                                        </button>
                                        :
                                        <button className="ml-4 px-3 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                                            onClick={handleUpdate}>
                                            Update
                                        </button>
                                }
                            </form>

                        </Modal>
                        : null}
                </div>
            </div>

            {isActionModalOpen ?
                <SettingTableAction
                    header={selectedRow?.name}
                    isActionModalOpen={isActionModalOpen}
                    setIsActionModalOpen={setIsActionModalOpen}
                    editFunction={editFunction}
                    selectedRow={selectedRow}
                    handleDelete={handleDelete}
                />
                : null}
        </div>
    )
}

export default Material
