import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useState, useEffect } from "react";
import { DesignCodeAdd } from "../modal/DesignCodeAdd";
import { useLazyDesignCodeQuery } from "../../servicesRtkQuery/publicApi";
import { selectFilterConfig } from "../../utils/globalFunctions";
import TextBox from "./TextBox";

export default function Needles(props: any) {
  const {
    number,
    test,
    patt,
    thr,
    totalWeight,
    totalUsed,
    code1,
    code2,
    disable,
    twoCodes,
    totalWeight1,
    totalUsed1,
  } = props;
  const [showAddDesignCode, setShowAddDesignCode] = useState(false);
  const [designCodeAdd, setDesignCodeAdd] = useState("");
  const [designCode, setDesignCode]: any = useState();
  const [thread, setThread]: any = useState("");
  const [disableDesignCode, setDisableDesignCode] = useState(true);
  const [designCodeType, setDesignCodeType] = useState("designCodeOne");
  const [selectedDesignCodeOne, setSelectedDesignCodeOne] = useState({});
  const [selectedDesignCodeSecond, setSelectedDesignCodeSecond] = useState({});
  const [totalValue, setTotalValue]: any = useState({
    totalWeight: "",
    totalUsed: "",
  });
  const [totalValueOne, setTotalValueOne]: any = useState({
    totalWeight1: "",
    totalUsed1: "",
  });
  const [pattern, setPattern]: any = useState("");
  useEffect(() => {
    if (thr !== "") {
      setThread({
        value: thr,
        label: thr?.[0]?.toUpperCase() + thr?.slice(1),
      });
    }
  }, [thr]);
  const handleThredType = (e: any) => {
    setThread(e);
    if (e != null) {
      if (e.value === "1" || e.value === "2") {
        setSelectedDesignCodeSecond({});
        setDisableDesignCode(true);
      } else {
        setDisableDesignCode(false);
      }
      if (e.value === "1" || e.value === "2") {
        test(
          pattern,
          e,
          selectedDesignCodeOne,
          {},
          number,
          totalValue?.totalWeight,
          totalValue?.totalUsed,
          totalValueOne?.totalWeight1,
          totalValueOne?.totalUsed1
        );
      } else {
        test(
          pattern,
          e,
          selectedDesignCodeOne,
          selectedDesignCodeSecond,
          totalValue?.totalWeight,
          totalValue?.totalUsed,
          totalValueOne?.totalWeight1,
          totalValueOne?.totalUsed1,
          number
        );
      }
    }
  };
  useEffect(() => {
    setTotalValue({
      totalWeight: totalWeight,
      totalUsed: totalUsed,
    });
    setTotalValueOne({
      totalWeight1: totalWeight1,
      totalUsed1: totalUsed1,
    });
  }, [totalWeight, totalUsed, totalWeight1, totalUsed1]);
  useEffect(() => {
    designTrigger("");
    if (
      twoCodes || (thread?.value === "1+1" || thread?.value === "2+2") || (thr === "1+1" || thr === "2+2")) {
      setDisableDesignCode(false);
    } else {
      setDisableDesignCode(true);
    }
    if (patt !== "") {
      setPattern({
        value: patt,
        label: patt?.[0]?.toUpperCase() + patt?.slice(1),
      });
    }
    // if (thread === "" && thr !== "") {
    //   setThread({
    //     value: thr,
    //     label: thr?.[0]?.toUpperCase() + thr?.slice(1),
    //   });
    // }

    test(
      pattern,
      thread,
      selectedDesignCodeOne,
      selectedDesignCodeSecond,
      number,
      totalValue?.totalWeight,
      totalValue?.totalUsed,
      totalValueOne?.totalWeight1,
      totalValueOne?.totalUsed1
    );

    if (JSON.stringify(code1) !== "{}") {
      setSelectedDesignCodeOne({
        label: code1?.designCode,
        value: code1?._id,
      });
      test(
        pattern,
        thread,
        {
          label: code1?.designCode,
          value: code1?._id,
        },
        {
          label: code2?.designCode || "",
          value: code2?._id || "",
        },
        number,
        totalValue?.totalWeight,
        totalValue?.totalUsed,
        totalValueOne?.totalWeight1,
        totalValueOne?.totalUsed1
      );
    }

    if (JSON.stringify(code2) !== "{}") {
      setDisableDesignCode(false);
      setSelectedDesignCodeSecond({
        label: code2?.designCode,
        value: code2?._id,
      });
      test(
        pattern,
        thread,
        {
          label: code1?.designCode,
          value: code1?._id,
        },
        {
          label: code2?.designCode || "",
          value: code2?._id || "",
        },
        number,
        totalValue?.totalWeight,
        totalValue?.totalUsed,
        totalValueOne?.totalWeight1,
        totalValueOne?.totalUsed1
      );
    }
  }, [code1, code2, totalValue, totalValueOne]);

  const patternOptions: any = [
    { value: "चैन", label: "चैन" },
    { value: "मल्टी", label: "मल्टी" },
    { value: "कोडिंग", label: "कोडिंग" },
    { value: "सीक्वन्स", label: "सीक्वन्स" },
  ];
  const threadOption: any = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "1+1", label: "1+1" },
    { value: "2+2", label: "2+2" },
  ];
  const getDesignCode = (data: any, type: any = null) => {
    if (type === "designCodeOne") {
      setSelectedDesignCodeOne(data);
      test(pattern, thread, data, selectedDesignCodeSecond, number);
    } else if (type === "designCodeSecond") {
      setSelectedDesignCodeSecond(data);
      test(pattern, thread, selectedDesignCodeOne, data, number);
    }
    setDesignCode([...designCode, data]);
    setShowAddDesignCode(false);
  };

  const [designTrigger, designResult] = useLazyDesignCodeQuery();
  const {
    isSuccess: isDesignSuccess,
    isFetching: isDesignFetching,
    isError: isDesignError,
    error: designError,
  } = designResult;

  useEffect(() => {
    if (isDesignSuccess && !isDesignFetching) {
      let getAllDesign = designResult.data?.data?.designCode.map(
        ({ _id, designCode }: any) => {
          return {
            label: designCode,
            value: _id,
          };
        }
      );
      setDesignCode(getAllDesign ? getAllDesign : []);
    }
  }, [isDesignSuccess, isDesignFetching]);

  const patternChange = (e: any) => {
    setPattern(e);
    test(e, thread, selectedDesignCodeOne, selectedDesignCodeSecond, number);
  };

  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "designCode1":
        setSelectedDesignCodeOne(e);
        let selectDesignCode = {
          label: e.label,
          value: e.value,
        };
        test(
          pattern,
          thread,
          selectDesignCode,
          selectedDesignCodeSecond,
          number,
          totalValue?.totalWeight,
          totalValue?.totalUsed,
          totalValueOne?.totalWeight1,
          totalValueOne?.totalUsed1
        );
        break;
      case "designCode2":
        setSelectedDesignCodeSecond(e);
        let selectSecondDesignCode = {
          label: e.label,
          value: e.value,
        };
        test(
          pattern,
          thread,
          selectedDesignCodeOne,
          selectSecondDesignCode,
          number,
          totalValue?.totalWeight,
          totalValue?.totalUsed,
          totalValueOne?.totalWeight1,
          totalValueOne?.totalUsed1
        );
        break;
      case "totalWeight":
        setTotalValue({
          ...totalValue,
          totalWeight: e.target.value,
        });
        test(
          pattern,
          thread,
          selectedDesignCodeOne,
          selectedDesignCodeSecond,
          number,
          e.target.value,
          totalValue?.totalUsed,
          totalValueOne?.totalWeight1,
          totalValueOne?.totalUsed1
        );
        break;
      case "totalUsed":
        setTotalValue({
          ...totalValue,
          totalUsed: e.target.value,
        });
        test(
          pattern,
          thread,
          selectedDesignCodeOne,
          selectedDesignCodeSecond,
          number,
          totalValue?.totalWeight,
          e.target.value,
          totalValueOne?.totalWeight1,
          totalValueOne?.totalUsed1
        );
        break;
      case "totalWeight1":
        setTotalValueOne({
          ...totalValueOne,
          totalWeight1: e.target.value,
        });

        test(
          pattern,
          thread,
          selectedDesignCodeOne,
          selectedDesignCodeSecond,
          number,
          totalValue?.totalWeight,
          totalValue?.totalUsed,
          e.target.value,
          totalValueOne?.totalUsed1
        );
        break;
      case "totalUsed1":
        setTotalValueOne({
          ...totalValueOne,
          totalUsed1: e.target.value,
        });
        test(
          pattern,
          thread,
          selectedDesignCodeOne,
          selectedDesignCodeSecond,
          number,
          totalValue?.totalWeight,
          totalValue?.totalUsed,
          totalValueOne?.totalWeight1,
          e.target.value
        );
        break;
      default:
    }
  };
  return (
    <>
      <div className="w-40 mr-5">
        <div className="flex">
          <div className="text-center w-9/10">
            <h5>N{number}</h5>
          </div>
        </div>
        <div>
          <div>
            <div className="relative rounded-md">
              <Select
                className="basic-single block z-30 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 peer"
                classNamePrefix="select"
                classNames={{
                  control: () =>
                    !pattern
                      ? "border-red-600 focus:border-red-500"
                      : "border-grey-300",
                }}
                isSearchable={true}
                isDisabled={disable}
                value={pattern}
                name="designpattern"
                onChange={(e: any) => patternChange(e)}
                options={patternOptions}
              />
              <label
                htmlFor="designpattern"
                className={`absolute z-30 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                  !pattern ? " text-red-500" : ""
                }`}
              >
                Needle Pattern
              </label>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div>
            <div className="relative rounded-md">
              <Select
                className="basic-single block z-20 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                classNamePrefix="select"
                isSearchable={true}
                classNames={{
                  control: () =>
                    !thread
                      ? "border-red-600 focus:border-red-500"
                      : "border-grey-300",
                }}
                name="threadType"
                value={thread}
                isDisabled={disable}
                onChange={(e: any) => handleThredType(e)}
                options={threadOption}
              />
              <label
                htmlFor="threadType"
                className={`absolute z-20 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                  !thread ? " text-red-500" : ""
                }`}
              >
                Thread Type
              </label>
            </div>
          </div>
        </div>

        <div>
          <div className="relative rounded-md">
            <CreatableSelect
              name="designcode1"
              classNamePrefix="customSelect"
              filterOption={createFilter(selectFilterConfig)}
              className="block pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
              classNames={{
                control: () =>
                  Object.keys(selectedDesignCodeOne).length === 0
                    ? "border-red-600 focus:border-red-500"
                    : "border-grey-300",
              }}
              onChange={(e: any) => {
                if (e) {
                  handleChange(e, "designCode1");
                } else {
                  setSelectedDesignCodeOne({});
                }
              }}
              // onInputChange={(e: any) => {
              //     if (e.length >= 1) {
              //         getDesignCodeTrigger(e)
              //         let designCode = getDesignCodeResult.data?.data.designCode.map(({ _id, designCode }: any) => {
              //             return {
              //                 label: designCode,
              //                 value: _id,
              //             };
              //         });
              //         if(designCode){
              //             setDesignCode(designCode)
              //         }
              //     }
              //     if (e.length == 0) {
              //         let getAllDesignCode = designResult.data?.data.designCode.map(({ _id, designCode }: any) => {
              //             return {
              //                 label: designCode,
              //                 value: _id,
              //             };
              //         });
              //         if(getAllDesignCode){
              //             setDesignCode(getAllDesignCode)
              //         }
              //     }
              // }}
              onCreateOption={(data: any) => {
                setDesignCodeAdd(data);
                setShowAddDesignCode(true);
                setDesignCodeType("designCodeOne");
              }}
              options={designCode}
              value={selectedDesignCodeOne}
            />
            <label
              htmlFor="designcode1"
              className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                Object.keys(selectedDesignCodeOne).length === 0
                  ? " text-red-500"
                  : ""
              }`}
            >
              Thread Color
            </label>
          </div>
        </div>

        {/* <div className="relative rounded-md mt-2">
          <TextBox
            type="text"
            name="totalWeight"
            htmlFor="totalWeight"
            label="totalWeight thread"
            placeholder=" "
            value={totalValue?.totalWeight}
            onChange={(e: any) => {
              handleChange(e, "totalWeight");
            }}
            disabled={disable}
            className={` ${
              disable ? " cursor-not-allowed" : " cursor-pointer "
            }`}
          />
        </div>
        <div className="relative rounded-md mt-2">
          <TextBox
            type="text"
            name="totalUsed"
            htmlFor="totalUsed"
            label="used thread"
            placeholder=" "
            value={totalValue?.totalUsed}
            onChange={(e: any) => {
              handleChange(e, "totalUsed");
            }}
            disabled={disable}
            className={` ${
              disable ? " cursor-not-allowed" : " cursor-pointer "
            }`}
          />
        </div>
        <div className="relative rounded-md mt-2">
          <TextBox
            type="text"
            name=""
            htmlFor=""
            label="totalUsed thread"
            readOnly
            placeholder=" "
            value={
              totalValue?.totalWeight
                ? Math.floor(totalValue?.totalWeight) - totalValue?.totalUsed
                : ""
            }
            disabled
            className={` ${
              disable ? " cursor-not-allowed" : " cursor-pointer "
            }`}
          />
        </div> */}
        {!disableDesignCode ? (
          <div>
            <div className="relative rounded-md">
              <CreatableSelect
                name="designcode2"
                classNamePrefix="customSelect"
                filterOption={createFilter(selectFilterConfig)}
                className="block pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                classNames={{
                  control: () =>
                    Object.keys(selectedDesignCodeSecond).length === 0
                      ? "border-red-600 focus:border-red-500"
                      : "border-grey-300",
                }}
                onChange={(e: any) => {
                  if (e) {
                    handleChange(e, "designCode2");
                  } else {
                    setSelectedDesignCodeSecond({});
                  }
                }}
                // onInputChange={(e: any) => {
                //     if (e.length >= 2) {
                //         getDesignCodeTrigger(e)
                //         let designCode = getDesignCodeResult.data?.data.designCode.map(({ _id, designCode }: any) => {
                //             return {
                //                 label: designCode,
                //                 value: _id,
                //             };
                //         });
                //         if (designCode) {
                //             setDesignCode(designCode)
                //         }
                //     }
                //     if (e.length == 0) {
                //         let getAllDesignCode = designResult.data?.data.designCode.map(({ _id, designCode }: any) => {
                //             return {
                //                 label: designCode,
                //                 value: _id,
                //             };
                //         });
                //         if (getAllDesignCode) {
                //             setDesignCode(getAllDesignCode)
                //         }
                //     }
                // }}
                onCreateOption={(data: any) => {
                  setDesignCodeAdd(data);
                  setShowAddDesignCode(true);
                  setDesignCodeType("designCodeSecond");
                }}
                options={designCode}
                value={selectedDesignCodeSecond}
              />
              <label
                htmlFor="designcode2"
                className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                  Object.keys(selectedDesignCodeSecond).length === 0
                    ? " text-red-500"
                    : ""
                }`}
              >
                Thread Color
              </label>
            </div>
            {/* <div className="relative rounded-md mt-2">
              <TextBox
                type="text"
                name="totalWeight1"
                htmlFor="totalWeight1"
                label="totalWeight thread"
                placeholder=" "
                value={totalValueOne?.totalWeight1}
                onChange={(e: any) => {
                  handleChange(e, "totalWeight1");
                }}
                disabled={disable}
                className={` ${
                  disable ? " cursor-not-allowed" : " cursor-pointer "
                }`}
              />
            </div>
            <div className="relative rounded-md mt-2">
              <TextBox
                type="text"
                name="totalUsed1"
                htmlFor="totalUsed1"
                label="used thread"
                placeholder=" "
                value={totalValueOne?.totalUsed1}
                onChange={(e: any) => {
                  handleChange(e, "totalUsed1");
                }}
                disabled={disable}
                className={` ${
                  disable ? " cursor-not-allowed" : " cursor-pointer "
                }`}
              />
            </div>
            <div className="relative rounded-md mt-2">
              <TextBox
                type="text"
                name=""
                htmlFor=""
                label="totalUsed thread"
                readOnly
                placeholder=" "
                value={
                  totalValueOne?.totalWeight1
                    ? Math.floor(totalValueOne?.totalWeight1) -
                      totalValueOne?.totalUsed1
                    : ""
                }
                disabled
                className={` ${
                  disable ? " cursor-not-allowed" : " cursor-pointer "
                }`}
              />
            </div> */}
          </div>
        ) : (
          ""
        )}
      </div>

      {showAddDesignCode && (
        <DesignCodeAdd
          setShowAddDesignCode={setShowAddDesignCode}
          showAddDesignCode={showAddDesignCode}
          setDesignCodeAdd={setDesignCodeAdd}
          designCodeAdd={designCodeAdd}
          getDesignCode={getDesignCode}
          codeType={designCodeType}
        />
      )}
    </>
  );
}
