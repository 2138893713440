import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  convertImageToBase64,
  getFormattedNumber,
  groupBy,
} from "../../../utils/globalFunctions";
import ReactToPrint from "react-to-print";
import * as htmlToImage from "html-to-image";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";

export const DressRateCardPreview = (props: any) => {
  const exportRef: any = useRef(null);
  const exportPartyRef: any = useRef(null);
  const dressRateCard: any = props.singleCard;
  const [image, setImage]: any = useState();
  const [logo, setLogo]: any = useState();
  const [totalMeter, setTotal]: any = useState();
  const [toggleState, setToggleState] = useState(1);
  const [loadingImage, setLoadingImage]: any = useState(false);
  const [originalImage, setOriginalImage]: any = useState(false);
  useEffect(() => {
    if (dressRateCard) {
      let data = dressRateCard;
      let total = 0;
      data = data?.measurements?.map((item: any) => {
        total += item?.meter;
      });
      setTotal(total);
    }
  });

  const downloadImage = async (ref: any, fileName: any) => {
    const dataUrl = await htmlToImage.toPng(ref.current);
    // download image
    const link = document.createElement("a");
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  };
  // const downloadImage = async (ref: any, fileName: any) => {
  //   // Get the current element from the ref
  //   const element = ref.current;
  
  //   try {
  //     // Convert the React component to a data URL (PNG)
  //     const dataUrl = await htmlToImage.toPng(element);
  
  //     // Check if the browser is on iOS
  //     const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  
  //     // If on iOS, provide instructions for taking a screenshot
  //     if (isiOS) {
  //       alert('To download the image, take a screenshot and save it manually.');
  //     } else {
  //       // For non-iOS devices, create a download link
  //       const link = document.createElement('a');
  //       link.download = fileName;
  //       link.href = dataUrl;
  
  //       // Trigger the download directly
  //       link.click();
  //     }
  //   } catch (error) {
  //     console.error('Error downloading image:', error);
  //   }
  // };

  const toggleTab = (index: any) => {
    setToggleState(index);
  };
  let total: any = 0;

  useEffect(() => {
    setLoadingImage(true);
    if (dressRateCard) {
      if (dressRateCard?.image?.startsWith("https:")) {
        convertImageToBase64(dressRateCard.image, setImage);
      } else {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "You have not uploaded any image\nPlease upload a new image.",
          position: "top-end",
          showConfirmButton: false,
          timer: ERROR_MESSAGE_TIMEOUT,
          timerProgressBar: true,
        });
        setLoadingImage(false);
      }
    }
    convertImageToBase64(
      "https://cnt-satv.s3.ap-south-1.amazonaws.com/logo.png",
      setLogo
    );
  }, []);

  useEffect(() => {
    if (image) {
      setLoadingImage(false);
    }
  }, [image]);

  dressRateCard?.works?.forEach((card: any) => {
    total = total + Math.ceil(card?.totalQty * (card?.rate + card?.valueAdd));
  });

  let groupData: any = [];
  dressRateCard?.measurements?.forEach((item: any) => {
    let object = {
      _id: item?._id,
      clothe: item?.clothe?.name,
      fabrics: item?.fabrics,
      meter: item?.meter,
    };
    groupData.push(object);
  });
  var data = groupBy(groupData, "clothe");
  var clothes: any = Object.keys(data);
  let finalData: any = {};
  clothes.forEach((item: any) => {
    let total: any = 0;
    data[item]?.forEach((data: any) => {
      total = total + data?.meter;
    });
    finalData[item] = (
      total +
      (total * Number(dressRateCard?.shortNet)) / 100 +
      Number(dressRateCard?.plain)
    ).toFixed(2);
  });
  const DressCardPdf = () => {
    return (
      <div className="overflow-auto p-2">
        <div ref={exportRef} className={`overflow-hidden a4-portrait bg-white`}>
          <div className="border border-gray-600">
            <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
              <div className="text-themeColor pl-25 capitalize">
                <img src={logo} alt="सत्व" />
              </div>
              <div className="text-themeColor text-7 text-left capitalize">
                design no : {dressRateCard?.designNo}{" "}
                {dressRateCard?.size?.value
                  ? "(" + dressRateCard?.size?.value + ")"
                  : null}
              </div>
            </div>
            <div className="p-2 border-y border-black">
              <div className={`text-sm grid grid-cols-6 gap-2`}>
                <div className="flex justify-center col-span-2 row-span-2 overflow-hidden max-h-120">
                  {image ? (
                    <img
                      src={image}
                      className={`max-h-120 h-full w-full ${
                        originalImage ? "object-scale-down" : "object-fill"
                      }`}
                      alt=""
                    />
                  ) : null}
                </div>
                <div className="col-span-4">
                  <table className="w-full border border-collapse bg-white text-left">
                    <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                      <tr>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-25 border-r text-red-800 border-black capitalize text-center"
                        >
                          Pcs/ Meater
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-16 border-r text-red-800 border-black capitalize text-center"
                        >
                          Rate
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-20 border-r text-red-800 border-black capitalize text-center"
                        >
                          total
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          remark
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                      {dressRateCard?.works?.map((card: any) => {
                        return (
                          <tr>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                              {card?.item?.name}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {getFormattedNumber(card?.totalQty)}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card?.rate + card?.valueAdd}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {Math.ceil(
                                card?.totalQty * (card?.rate + card?.valueAdd)
                              )}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card?.remark}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className=" bg-gray-100 text-center divide-black border border-black text-5">
                      <tr>
                        <td
                          colSpan={6}
                          className="gap-3 border-r pl-2 border-black font-bold capitalize text-red-900 py-1.5"
                        >
                          total rate =
                          <span className="ml-2 font-bold">
                            {Math.floor(total)}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="col-span-4 mt-2">
                  <table className="w-full border border-collapse bg-white text-left capitalize">
                    <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                      <tr>
                        <th
                          colSpan={7}
                          className="text-center border-b border-black text-themeColor py-2 font-bold text-5"
                        >
                          fabric measurements
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          item
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          Fabric
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          fabric color
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center"
                        >
                          pcs/ Meater
                        </th>
                        <th
                          scope="col"
                          className="border-r w-16 py-1.5 text-red-800 border-black capitalize text-center"
                        >
                          mej.
                        </th>
                        <th
                          scope="col"
                          className="border-r w-16 py-1.5 text-red-800 border-black capitalize text-center"
                        >
                          Meater
                        </th>
                        <th
                          scope="col"
                          className="border-r w-34 py-1.5 text-red-800 border-black capitalize text-center"
                        >
                          remark
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                      {dressRateCard?.measurements?.map(
                        (card: any, index: any) => {
                          return (
                            <tr>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">
                                {card?.item?.name}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">
                                {card?.clothe?.name}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">
                                {card?.fabricColor?.colorName}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                {card.totalQty}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                {card.fabrics}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                {card.meter}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                {card?.remark}
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          Plain
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          {dressRateCard?.plain}
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                      </tr>
                      <tr>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          Short fabric
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          {dressRateCard?.shortNet}       
                          {/* {((totalMeter*dressRateCard?.shortNet)/100).toFixed(2)} */}
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                      </tr>
                    </tbody>
                    <tfoot className=" bg-gray-100 divide-black border border-black text-base text-center">
                      <tr>
                        <td
                          colSpan={7}
                          className="gap-3 border border-black font-bold capitalize text-red-900 py-1.5"
                        >
                          {clothes?.length > 1 ? (
                            clothes?.map((item: any) => {
                              return (
                                <span className="mx-1">
                                  Total Meater =
                                  <span className="ml-1">
                                    {finalData[item] + " [" + item + "]"}
                                  </span>
                                </span>
                              );
                            })
                          ) : (
                            <>
                              total Meater =
                              <span className="ml-2">
                                {dressRateCard?.finalCosting?.measurements
                                  ?.totalMeter +
                                  " [" +
                                  clothes[0] +
                                  "]"}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PartyPdf = () => {
    return (
      <div className="overflow-auto p-2">
        <div
          ref={exportPartyRef}
          className={`overflow-hidden a4-portrait bg-white`}
        >
          <div className="border border-gray-600">
            <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
              <div className="text-themeColor pl-25 capitalize">
                <img src={logo} alt="सत्व" />
              </div>
              <div className="text-themeColor text-7 text-left capitalize">
                design no : {dressRateCard?.designNo}{" "}
                {dressRateCard?.size?.value
                  ? "(" + dressRateCard?.size?.value + ")"
                  : null}
              </div>
            </div>
            <div className="p-2 border-y border-black">
              <div className={`text-sm grid grid-cols-6 gap-2`}>
                <div className="flex justify-center col-span-2 row-span-2 overflow-hidden max-h-120">
                  {image ? (
                    <img
                      src={image}
                      className={`max-h-120 h-full w-full ${
                        originalImage ? "object-scale-down" : "object-fill"
                      }`}
                      alt=""
                    />
                  ) : null}
                </div>
                <div className="col-span-4">
                  <table className="w-full border border-collapse bg-white text-left">
                    <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                      <tr>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center"
                        >
                          Pcs/ Meater
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          remark
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                      {dressRateCard?.works?.map((card: any) => {
                        return (
                          <tr>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                              {card?.item?.name}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {getFormattedNumber(card?.totalQty)}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card?.remark}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className=" bg-gray-100 text-center divide-black border border-black text-5">
                      <tr>
                        <td
                          colSpan={6}
                          className="gap-3 border-r pl-2 border-black font-bold capitalize text-red-900 py-1.5"
                        >
                          total rate =
                          <span className="ml-2 font-bold">
                            {(dressRateCard?.finalCosting?.work?.isSelected
                              ? Number(
                                  dressRateCard?.finalCosting?.work?.amount
                                )
                              : 0) +
                              (dressRateCard?.finalCosting?.hand?.isSelected
                                ? Number(
                                    dressRateCard?.finalCosting?.hand?.amount
                                  )
                                : 0) +
                              (dressRateCard?.finalCosting?.stitching
                                ?.isSelected
                                ? Number(
                                    dressRateCard?.finalCosting?.stitching
                                      ?.amount
                                  )
                                : 0) +
                              (dressRateCard?.finalCosting?.foil?.isSelected
                                ? Number(
                                    dressRateCard?.finalCosting?.foil?.amount
                                  )
                                : 0) +
                              (dressRateCard?.finalCosting?.daying?.isSelected
                                ? Number(
                                    dressRateCard?.finalCosting?.daying?.amount
                                  )
                                : 0) +
                              (dressRateCard?.finalCosting?.costing?.isSelected
                                ? Number(
                                    dressRateCard?.finalCosting?.costing?.amount
                                  )
                                : 0)}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="col-span-4 mt-2">
                  <table className="w-full border border-collapse bg-white text-left capitalize">
                    <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                      <tr>
                        <th
                          colSpan={5}
                          className="text-center border-b border-black text-themeColor py-2 font-bold text-5"
                        >
                          fabric measurements
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center"
                        >
                          item
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center"
                        >
                          pcs/ Meater
                        </th>
                        <th
                          scope="col"
                          className="border-r w-16 py-1.5 text-red-800 border-black capitalize text-center"
                        >
                          mej.
                        </th>
                        <th
                          scope="col"
                          className="border-r w-16 py-1.5 text-red-800 border-black capitalize text-center"
                        >
                          Meater
                        </th>
                        <th
                          scope="col"
                          className="border-r w-34 py-1.5 text-red-800 border-black capitalize text-center"
                        >
                          remark
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                      {dressRateCard?.measurements?.map((card: any) => {
                        return (
                          <tr>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">
                              {card?.item?.name}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card.totalQty}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card.fabrics}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card.meter}
                            </td>
                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                              {card?.remark}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          Plain
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          {dressRateCard?.plain}
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                      </tr>
                      <tr>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          Short fabric
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">
                          {dressRateCard?.shortNet}
                        </td>
                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                      </tr>
                    </tbody>
                    <tfoot className=" bg-gray-100 divide-black border border-black text-5 text-center">
                      <tr>
                        <td
                          colSpan={5}
                          className="gap-3 border border-black font-bold capitalize text-red-900 py-1.5"
                        >
                          {clothes?.length > 1 ? (
                            clothes?.map((item: any) => {
                              return (
                                <span className="mx-1">
                                  Total Meater =
                                  <span className="ml-1">
                                    {finalData[item] + " [" + item + "]"}
                                  </span>
                                </span>
                              );
                            })
                          ) : (
                            <>
                              total Meater =
                              <span className="ml-2">
                                {dressRateCard?.finalCosting?.measurements
                                  ?.totalMeter +
                                  " [" +
                                  clothes[0] +
                                  "]"}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={5}
                          className="border-r text-center border-black capitalize bg-gray-100 py-3 font-bold"
                        >
                          Note : With&nbsp;
                          {"Job Work" +
                            (dressRateCard?.finalCosting?.hand?.isSelected
                              ? " & "
                              : "") +
                            (dressRateCard?.finalCosting?.hand?.isSelected
                              ? dressRateCard?.finalCosting?.hand?.itemName
                              : "") +
                            (dressRateCard?.finalCosting?.stitching?.isSelected
                              ? " & "
                              : "") +
                            (dressRateCard?.finalCosting?.stitching?.isSelected
                              ? dressRateCard?.finalCosting?.stitching?.itemName
                              : "") +
                            (dressRateCard?.finalCosting?.costing?.isSelected
                              ? " & "
                              : "") +
                            (dressRateCard?.finalCosting?.costing?.isSelected
                              ? dressRateCard?.finalCosting?.costing?.itemName
                              : "")}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={props.showDressRatePreview}
        showCloseIcon={false}
        closeOnEsc={false}
        onEscKeyDown={() => props.setShowDressRatePreview(false)}
        onOverlayClick={() => props.setShowDressRatePreview(false)}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        aria-labelledby="dressrate-card-pdf"
        aria-describedby="dressrate-card-pdf-description"
        animationDuration={400}
        onClose={() => props.setShowDressRatePreview(false)}
      >
        <div className="overflow-auto">
          <div className="text-center">
            <div className="bg-white border rounded-lg text-left shadow-xl">
              <div className="w-full rounded-xl overflow-hidden p-1">
                <div className="border rounded-xl md:rounded-full p-1.5 bg-gray-50 shadow-sm">
                  <ul
                    className="flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row px-2"
                    role="tablist"
                  >
                    <li
                      className={`${
                        toggleState === 1
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(1)}
                      >
                        dress rate card
                      </button>
                    </li>
                    <li
                      className={`${
                        toggleState === 2
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(2)}
                      >
                        party pdf
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex ml-5">
                <label className="font-normal mr-2 cursor-pointer select-none text-sm text-slate-700 ">
                  Fit Image
                </label>
                <div className="flex flex-row items-center pl-12 mb-0.5 text-left min-h-6">
                  <input
                    id="originalImage"
                    className="mt-0.5 rounded-10 duration-250 ease-in-out after:rounded-circle after:shadow-2xl after:duration-250 checked:after:translate-x-5.3 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-zinc-700/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-themeColor checked:bg-themeColor checked:bg-none checked:bg-right"
                    type="checkbox"
                    onChange={(e: any) => {
                      setOriginalImage(e.target.checked);
                    }}
                  />
                </div>
                <label className="ml-2 font-normal cursor-pointer select-none text-sm text-slate-700 ">
                  Original Image
                </label>
              </div>
              <div className="w-full h-full rounded-xl overflow-hidden bg-white">
                <div
                  className={`transition-opacity duration-150 ease-linear ${
                    toggleState === 1 ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <DressCardPdf />
                  <div className="m-3 flex flex-col lg:flex-row gap-3">
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                            disabled={loadingImage}
                          >
                            {loadingImage ? (
                              <>
                                <span>Loading</span>
                                <i className="fas fa-spinner animate-spin ml-2"></i>
                              </>
                            ) : (
                              <>
                                <span>Dress Rate Print</span>
                                <i className="fas fa-file-download ml-2"></i>
                              </>
                            )}
                          </button>
                        )}
                        content={() => exportRef.current}
                        copyStyles={true}
                        documentTitle={props.pageName + "-Satv"}
                      />
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                        onClick={() => {
                          downloadImage(
                            exportRef,
                            dressRateCard?.designNo + "-Satv"
                          );
                        }}
                        disabled={loadingImage}
                      >
                        {loadingImage ? (
                          <>
                            <span>Loading</span>
                            <i className="fas fa-spinner animate-spin ml-2"></i>
                          </>
                        ) : (
                          <>
                            <span>Dress Rate Image</span>
                            <i className="fas fa-file-image ml-2"></i>
                          </>
                        )}
                      </button>
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                        onClick={() => props.setShowDressRatePreview(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`transition-opacity duration-150 ease-linear ${
                    toggleState === 2 ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <PartyPdf />
                  <div className="m-3 flex flex-col lg:flex-row gap-2">
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                            disabled={loadingImage}
                          >
                            {loadingImage ? (
                              <>
                                <span>Loading</span>
                                <i className="fas fa-spinner animate-spin ml-2"></i>
                              </>
                            ) : (
                              <>
                                <span>Dress Party Print</span>
                                <i className="fas fa-file-download ml-2"></i>
                              </>
                            )}
                          </button>
                        )}
                        content={() => exportPartyRef.current}
                        copyStyles={true}
                        documentTitle={props.pageName + "- Party"}
                      />
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                        onClick={() => {
                          downloadImage(
                            exportPartyRef,
                            dressRateCard?.designNo + "-Party"
                          );
                        }}
                        disabled={loadingImage}
                      >
                        {loadingImage ? (
                          <>
                            <span>Loading</span>
                            <i className="fas fa-spinner animate-spin ml-2"></i>
                          </>
                        ) : (
                          <>
                            <span>Dress Party Image</span>
                            <i className="fas fa-file-image ml-2"></i>
                          </>
                        )}
                      </button>
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                        onClick={() => props.setShowDressRatePreview(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
