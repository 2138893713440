import moment from "moment";
import TextBox from "../../components/form/TextBox";
import {
  useLazyUserQuery,
  useUpdateUserMutation,
} from "../../servicesRtkQuery/publicApi";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { UserProfile } from "../../utils/validation";

export default function Profile() {
  var userData: any = localStorage.getItem("user");
  userData = JSON.parse(userData);

  const [trigger, result] = useLazyUserQuery();
  const { isSuccess, isFetching } = result;
  const [updateUser, updateResult] = useUpdateUserMutation();

  const [user, setUser]: any = useState({});
  const [errorMessage, setErrorMessage]: any = useState();
  const [newUserData, setNewUserData]: any = useState({
    name: "",
    phoneNumber: "",
    password: "",
    cPass: "",
  });

  useEffect(() => {
    trigger({
      userId: userData?._id,
    });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (isSuccess && !isFetching) {
        setUser(result?.data?.data?.users?.[0]);
        setNewUserData({
          name: result?.data?.data?.users?.[0]?.name,
          phoneNumber: result?.data?.data?.users?.[0]?.phoneNumber,
          password: "",
        });
      }
    }
  }, [isSuccess, isFetching]);

  const handleChange = (e: any) => {
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage("");
  };

  const handleUpdate = () => {
    const result: any = UserProfile(newUserData);
    if (result === true) {
      let updatedData = {
        name: newUserData.name,
        phoneNumber: newUserData.phoneNumber,
      };
      updateUser({
        payload: updatedData,
        query: {
          userId: userData?._id,
        },
      }).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          trigger({
            userId: userData?._id,
          });

          Swal.fire({
            toast: true,
            icon: "success",
            title: "User has been Successfully Updated!",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        }
      });
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
  };
  const handleUpdatePassword = () => {
    const result: any = UserProfile(newUserData);
    if (result === true) {
      if (
        newUserData?.password !== "" &&
        newUserData?.password === newUserData?.cPass
      ) {
        let updatedData = {
          password: newUserData?.password,
        };
        setNewUserData({
          cPass: "",
        });
        updateUser({
          payload: updatedData,
          query: {
            userId: userData?._id,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            trigger({
              userId: userData?._id,
            });

            Swal.fire({
              toast: true,
              icon: "success",
              title: "User has been Successfully Updated!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          }
        });
      } else {
        setErrorMessage("Password must be same Confirm Password");
      }
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
  };

  return (
    <div className="m-0 mt-20 pb-4 lg:mt-0 xl:mt-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl flex bg-themeBgColor items-center p-0 bg-cover">
      <div className="w-full mx-6 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
        <div className="mt-5 relative col-span-2">
          <div className=" w-full min-h-50 bg-cover overflow-hidden bg-[url('https://griya.dexignzone.com/xhtml/images/profile/cover.jpg')] rounded-xl"></div>
          <motion.div
            initial={{ y: -300, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="m-10">
              <div className="-mt-25 p-2 inset-x-0 bg-gradient-to-t from-white to-white/80 backdrop-blur-lg text-themeColor shadow-md border-0 rounded-xl flex items-center gap-5">
                <div className="m-2 p-5 max-w-fit px-7 bg-gray-200 text-themeColor shadow-md text-5xl font-bold border-2 rounded-xl">
                  {user?.name?.substring(0, 1)}
                </div>
                <div className="w-full">
                  <div className="text-xl font-bold">{user?.name}</div>
                  <div className="text-gray-500 text-lg">{user?.role}</div>
                </div>
                <div className="flex justify-end w-full">
                  {user?.isActive ? (
                    <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-4 py-1.5 rounded-full">
                      <span className="w-4 h-4 mr-1 bg-green-500 rounded-full"></span>
                      Active
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="row-span-2 rounded-xl bg-white flex flex-col items-center col-span-2 lg:col-span-1">
          <div className="text-themeColor rounded-t-xl shadow-sm font-bold text-5 bg-gray-100 py-2 w-full text-center">
            {user?.name}
          </div>
          <div className="w-full">
            <div className="relative overflow-hidden shadow-md">
              <table className="text-base w-full h-full text-left">
                <tbody>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      Name
                    </th>
                    <td className="pl-2 py-4">{user?.name}</td>
                  </tr>
                  <tr className="border-b bg-gray-50">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      Email
                    </th>
                    <td className="pl-2 py-4">{user?.email}</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      gender
                    </th>
                    <td className="pl-2 py-4">{user?.gender}</td>
                  </tr>
                  <tr className="border-b bg-gray-50">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      phone Number
                    </th>
                    <td className="pl-2 py-4">{user?.phoneNumber}</td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      role
                    </th>
                    <td className="pl-2 py-4">{user?.role}</td>
                  </tr>
                  <tr className="border-b bg-gray-50">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      created on
                    </th>
                    <td className="pl-2 py-4">
                      {moment(user?.createAt).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                  <tr className="border-b bg-white">
                    <th
                      scope="row"
                      className="pl-2 py-4 font-medium whitespace-nowrap uppercase"
                    >
                      status
                    </th>
                    <td className="pl-2 py-4">
                      {user?.isActive ? (
                        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                          <span className="w-2 h-2 mr-1 bg-green-500 rounded-full"></span>
                          Active
                        </span>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="w-full mt-5 md:mt-0 rounded-xl bg-white col-span-2 md:col-span-1">
          <div className="text-xl w-full bg-gray-200 rounded-t-xl font-bold font-[Cambria] p-2 px-4.5">
            Update Profile Details
          </div>
          <div className="w-full bg-white p-2 flex flex-col items-center rounded-xl gap-6">
            <div className="relative z-0 w-full">
              <TextBox
                type="text"
                name="name"
                id="name"
                value={newUserData?.name}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                placeholder=" "
                label="Name"
                required
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="relative z-0 w-full">
              <TextBox
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={newUserData?.phoneNumber}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                placeholder=" "
                label="phone Number"
                required
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </div>
            <button
              type="submit"
              className="text-white bg-themeColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              onClick={() => {
                handleUpdate();
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="w-full mt-5 rounded-xl bg-white col-span-2 md:col-span-1">
          <div className="text-xl w-full bg-gray-200 rounded-t-xl font-bold font-[Cambria] p-2 px-4.5">
            Change Password
          </div>
          <div className="w-full bg-white p-2 flex flex-col items-center rounded-xl gap-6">
            <div className="relative z-0 w-full">
              <TextBox
                type="password"
                name="password"
                id="password"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                placeholder=" "
                label="New Password"
                required
                value={newUserData?.password}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="relative z-0 w-full">
              <TextBox
                type="password"
                name="cPass"
                id="cPass"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                placeholder=" "
                label="Confirm password"
                required
                value={newUserData?.cPass}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </div>
            <span className="text-red-500">{errorMessage}</span>
            <button
              type="submit"
              className="text-white bg-themeColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              onClick={() => {
                handleUpdatePassword();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
