export default function TextBox(props: any) {
  const {
    id,
    htmlFor,
    placeholder,
    tabIndex,
    type,
    name,
    value,
    className,
    labelClassName,
    label,
    key,
    onChange,
    defaultValue,
    disabled,
    maxValue,
    minValue,
    autoFocus,
    onKeyUp,
    ref,
    hasError,
    style,
    readOnly,
  } = props;
  return (
    <div>
      <div className="relative">
        <input
          key={key}
          id={id}
          name={name}
          type={type}
          readOnly={readOnly}
          tabIndex={tabIndex}
          disabled={disabled}
          value={value}
          style={style}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
          defaultValue={defaultValue}
          max={maxValue}
          min={minValue}
          autoFocus={autoFocus}
          autoComplete="off"
          ref={ref}
          className={`first-line:block px-2.5 h-10 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-1 appearance-none focus:outline-none focus:ring-0 peer ${
            type === "email" ? "lowercase" : "capitalize"
          } ${
            hasError
              ? " border-red-500 focus:border-red-500 "
              : " border-gray-500 focus:border-themeColor "
          } ${className}`}
        />
        <label
          htmlFor={htmlFor}
          className={`absolute w-max capitalize text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white  px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${
            hasError
              ? "peer-focus:text-red-500 text-red-500 "
              : "peer-focus:text-themeColor "
          } ${labelClassName}`}
        >
          {label}
        </label>
      </div>
    </div>
  );
}
