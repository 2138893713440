export default function Select(props: any) {
    const { id, htmlFor, label, name, className,LabelClassName, key, options, disabled, onChange, value } = props;
    return (
        <div>
            <div className="relative rounded-md">
                <select name={name} disabled={disabled} id={id} key={key} value={value} className={`block px-2.5 h-10 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-1  appearance-none focus:outline-none focus:ring-0 border-gray-500 peer capitalize ${className}`} onChange={onChange}>
                    {
                        options?.map((option: any, index: number) => {
                            return (
                                <option className="capitalize" value={option} key={index}>{option}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor={htmlFor} className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${LabelClassName}`}>{label}</label>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 mt-1">
                    <i className="text-gray-500 fas fa-angle-down sm:text-md"></i>
                </div>
            </div>
        </div>
    );
}
