import Needles from "../components/form/Needles";

const checkEmail = (email: string) => {
  if (email.trim() === "") {
    return {
      errorMessage: "Email must be filled out",
    };
  } else {
    const EmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailFormat = EmailRegex.test(email);
    if (!emailFormat) {
      return {
        errorMessage: "Enter valid email address",
      };
    }
  }
  return false;
};

function checkPassword(password: string) {
  if (!(password.trim() === "")) {
    // const uppercaseRegExp = /(?=.*?[A-Z])/;
    // const lowercaseRegExp = /(?=.*?[a-z])/;
    // const digitsRegExp = /(?=.*?[0-9])/;
    // const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{4,}/;
    // const uppercasePassword = uppercaseRegExp.test(password);
    // const lowercasePassword = lowercaseRegExp.test(password);
    // const digitsPassword = digitsRegExp.test(password);
    // const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword = minLengthRegExp.test(password);

    let errMsg = "";

    // if (!uppercasePassword) {
    //     errMsg = "At least one uppercase";
    // } else if (!lowercasePassword) {
    //     errMsg = "At least one lowercase";
    // } else if (!digitsPassword) {
    //     errMsg = "At least one digit";
    // } else if (!specialCharPassword) {
    //     errMsg = "At least one special characters";
    // } else
    if (!minLengthPassword) {
      errMsg = "At least 6 characters";
    } else {
      return (errMsg = "");
    }
    if (errMsg) {
      return {
        errorMessage: errMsg,
        isPasswordValid: false,
      };
    }
  } else {
    return {
      errorMessage: "Password must be filled out",
      isPasswordValid: false,
    };
  }
  return false;
}

export const loginValidation = (data: any) => {
  let result: any;

  result = checkEmail(data.email);
  if (result.errorMessage) {
    return {
      email: result.errorMessage,
    };
  }

  result = checkPassword(data.password);
  if (result.errorMessage) {
    return {
      password: result.errorMessage,
    };
  }
  return true;
};

export const UserValidation = (data: any) => {
  let result: any;

  result = checkEmail(data.email);
  if (result.errorMessage) {
    return {
      errorMessage: result.errorMessage,
    };
  }

  if (data.phoneNumber.trim() === "") {
    return {
      errorMessage: "PhoneNumber must be filled out",
    };
  } else {
    const PhoneRegex = /^([+]\d{2})?\d{10}$/;
    const phoneFormat = PhoneRegex.test(data.phoneNumber);
    if (!phoneFormat) {
      return {
        errorMessage: "Enter Valid Mobile Number",
      };
    }
  }

  if (data.name.trim() === "") {
    return {
      errorMessage: "Name must be filled out",
    };
  }
  if (data.gender.trim() === "") {
    return {
      errorMessage: "Gender must be filled out",
    };
  }
  if (data.isActive === "") {
    return {
      errorMessage: "isActive must be filled out",
    };
  }
  if (data.role.trim() === "") {
    return {
      errorMessage: "Role must be filled out",
    };
  }
  if (data.status === "") {
    return {
      errorMessage: "Status must be filled out",
    };
  }
  if (data.password === "") {
    return {
      errorMessage: "Password must be filled out",
    };
  }
  return true;
};
export const UserProfile = (data: any) => {
  if (data.name.trim() === "") {
    return {
      errorMessage: "Name must be filled out",
    };
  }
  if (data.phoneNumber.trim() === "") {
    return {
      errorMessage: "PhoneNumber must be filled out",
    };
  } else {
    const PhoneRegex = /^([+]\d{2})?\d{10}$/;
    const phoneFormat = PhoneRegex.test(data.phoneNumber);
    if (!phoneFormat) {
      return {
        errorMessage: "Enter Valid Mobile Number",
      };
    }
  }

 
  
  return true;
};

export const settingValidation = (data: any) => {
  if (data.metaKey.trim() === "") {
    return {
      errorMessage: "MetaKey must be filled out",
    };
  }
  if (data.metaValue === "") {
    return {
      errorMessage: "Metavalue must be filled out",
    };
  }
  if (data.metaValue.length === 0) {
    return {
      errorMessage: "Metavalue must be filled out",
    };
  }
  if (data.metaValue[0].length === 0) {
    return {
      errorMessage: "Metavalue must be filled out",
    };
  }
  return true;
};

export const itemValidation = (data: any) => {
  if (data.area.trim() === "") {
    return {
      errorMessage: "Area must be filled out",
    };
  }
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data.stitch === "") {
    return {
      errorMessage: "Stitch must be filled out",
    };
  }
  if (data.head === "") {
    return {
      errorMessage: "Head must be filled out",
    };
  }
  if (data.frame === "") {
    return {
      errorMessage: "Frame must be filled out",
    };
  }
  if (data.production === "") {
    return {
      errorMessage: "Production must be filled out",
    };
  }
  return true;
};

export const needleValidation = (data: any, head: any) => {
  if (data.fabricColorId === "") {
    return {
      errorMessage: "Fabric Color must be filled out",
    };
  }
  if (data?.needles?.length === 0) {
    return {
      errorMessage: "Needles must be filled out",
    };
  }
  for (let i = 0; i < data?.needles?.length; i++) {
    let item = data?.needles?.[i];
    let header = head?.[i];

    if (!header?.designPattern || header?.designPattern === "") {
      return {
        errorMessage: "Thread Color must be filled out",
      };
    }
    if (!header?.threadType || header?.threadType === "") {
      return {
        errorMessage: "Thread Type must be filled out",
      };
    }
    if (!item?.designCodeId1 || item?.designCodeId1 === "") {
      return {
        errorMessage: "Design Code1 must be filled out",
      };
    }
    if (
      (header?.totalUsed || header?.totalUsed !== "") &&
      header?.totalWeight < header?.totalUsed
    ) {
      return {
        errorMessage: "TotalWeight must be more than TotalUsed",
      };
    }
    if (
      (header?.totalUsed1 || header?.totalUsed1 !== "") &&
      header?.totalWeight1 < header?.totalUsed1
    ) {
      return {
        errorMessage: "TotalWeight1 must be more than TotalUsed1",
      };
    }
    if (
      (header?.threadType == "1+1" || header?.threadType == "2+2") &&
      (!item?.designCodeId2 || item?.designCodeId2 === "")
    ) {
      return {
        errorMessage: "Design Code2 must be filled out",
      };
    }
  }

  return true;
};

export const matchingNeedleValidation = (data: any, head: any, type: any) => {
  if (type === null) {
    return {
      errorMessage: "Please Select Type",
    };
  }
  if (data.fabricColorId === "") {
    return {
      errorMessage: "Fabric Color must be filled out",
    };
  }
  if (data.PM === "") {
    return {
      errorMessage: "Pcs / Mtr must be filled out",
    };
  }
  // if (type === "dress" || type === "lump") {
  //     if ((data.head === "")) {
  //         return {
  //             errorMessage: 'Head must be filled out'
  //         }
  //     }
  //     if ((data.frame === "")) {
  //         return {
  //             errorMessage: 'Frame must be filled out'
  //         }
  //     }
  // }

  if (data?.needles?.length === 0) {
    return {
      errorMessage: "Needles must be filled out",
    };
  }
  for (let i = 0; i < data?.needles?.length; i++) {
    let item = data?.needles?.[i];
    let header = head?.[i];

    if (!header?.designPattern || header?.designPattern === "") {
      return {
        errorMessage: "Thread Color must be filled out",
      };
    }
    if (!header?.threadType || header?.threadType === "") {
      return {
        errorMessage: "Thread Type must be filled out",
      };
    }
    if (!item?.designCodeId1 || item?.designCodeId1 === "") {
      return {
        errorMessage: "Design Code1 must be filled out",
      };
    }

    if (
      (header?.threadType == "1+1" || header?.threadType == "2+1") &&
      (!item?.designCodeId2 || item?.designCodeId2 === "")
    ) {
      return {
        errorMessage: "Design Code2 must be filled out",
      };
    }
  }

  return true;
};

export const jobWorkValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data?.stitch === "") {
    return {
      errorMessage: "Stitch must be filled out",
    };
  }
  if (data.area === "") {
    return {
      errorMessage: "Area must be filled out",
    };
  }
  if (data.Pcs === "") {
    return {
      errorMessage: "Pcs must be filled out",
    };
  }
  if (data.code === "") {
    return {
      errorMessage: "Code must be filled out",
    };
  }
  if (data.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  if (data.totalQty === "") {
    return {
      errorMessage: "Total Qty must be filled out",
    };
  }
  if (data.totalAmt === "") {
    return {
      errorMessage: "Total Amount must be filled out",
    };
  }

  return true;
};

export const fabricMeasureValidation = (data: any) => {
  if (data?.totalQty === "") {
    return {
      errorMessage: "Total Qty must be filled out",
    };
  }
  if (data.measurement === "") {
    return {
      errorMessage: "Measurement must be filled out",
    };
  }
  if (data?.clotheId === "") {
    return {
      errorMessage: "Fabric must be filled out",
    };
  }
  return true;
};

export const fabricCostValidation = (data: any) => {
  if (data?.PcM === "") {
    return {
      errorMessage: "Per Pcs must be filled out",
    };
  }
  if (data?.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  return true;
};

export const stitchingValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data?.amount === "") {
    return {
      errorMessage: "Amount must be filled out",
    };
  }
  if (data?.qty === "") {
    return {
      errorMessage: "Quantity must be filled out",
    };
  }
  if (data?.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  return true;
};

export const sareeJobWorkValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data?.stitch === "") {
    return {
      errorMessage: "Stitch must be filled out",
    };
  }
  if (data.area === "") {
    return {
      errorMessage: "Area must be filled out",
    };
  }
  if (data.meter === "") {
    return {
      errorMessage: "Meter must be filled out",
    };
  }
  if (data.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  if (data.code === "") {
    return {
      errorMessage: "Code must be filled out",
    };
  }

  return true;
};

export const sareeFoilValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data?.colorId === "") {
    return {
      errorMessage: "Foil Color must be filled out",
    };
  }
  if (data.remarksId === "") {
    return {
      errorMessage: "Remarks must be filled out",
    };
  }
  if (data.qty === "") {
    return {
      errorMessage: "Qty must be filled out",
    };
  }
  if (data.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  if (data.amount === "") {
    return {
      errorMessage: "Amount must be filled out",
    };
  }

  return true;
};

export const sareeStitchValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data.qty === "") {
    return {
      errorMessage: "Qty must be filled out",
    };
  }
  if (data.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  if (data.amount === "") {
    return {
      errorMessage: "Amount must be filled out",
    };
  }
  return true;
};

export const sareeHandValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data?.head === "") {
    return {
      errorMessage: "Head must be filled out",
    };
  }
  if (data?.diamond?.length === 0) {
    return {
      errorMessage: "Diamond must be filled out",
    };
  }
  return true;
};

export const sareeFabricValidation = (data: any) => {
  if (data?.itemId === "") {
    return {
      errorMessage: "Item must be filled out",
    };
  }
  if (data?.clotheId === "") {
    return {
      errorMessage: "Fabric must be filled out",
    };
  }
  if (data.clothePartyId === "") {
    return {
      errorMessage: "Fabric Party must be filled out",
    };
  }
  if (data.meter === "") {
    return {
      errorMessage: "Meter must be filled out",
    };
  }
  if (data.rate === "") {
    return {
      errorMessage: "Rate must be filled out",
    };
  }
  if (data.amount === "") {
    return {
      errorMessage: "Amount must be filled out",
    };
  }

  return true;
};

export const employeeValidation = (data: any) => {
  if (data?.name?.trim() === "") {
    return {
      errorMessage: "Name must be filled out",
    };
  }
  if (data?.type?.length == 0) {
    return {
      errorMessage: "Please Select at least 1 Type",
    };
  }

  return true;
};

export const jobWorkNameValidation = (data: any) => {
  if (data?.name?.trim() === "") {
    return {
      errorMessage: "Name must be filled out",
    };
  }
  if (data?.address?.trim() === "") {
    return {
      errorMessage: "Address must be filled out",
    };
  }

  return true;
};

export const designCodeValidation = (data: any) => {
  if (data?.designCode?.trim() === "") {
    return {
      errorMessage: "DesignCode must be filled out",
    };
  }
  return true;
};

export const reportItemValidation = (data: any) => {
  if (data.date === "" || data.date === undefined) {
    return {
      errorMessage: "Date be filled out",
    };
  }
  if (data.cardNo === "" || data.cardNo === undefined) {
    return {
      errorMessage: "Card No must be filled out",
    };
  }
  if (data.clothe === "" || data.clothe === undefined) {
    return {
      errorMessage: "Fabric must be filled out",
    };
  }
  if (data.designNo === "" || data.designNo === undefined) {
    return {
      errorMessage: "Design No must be filled out",
    };
  }
  if (data.pcs === "" || data.pcs === undefined) {
    return {
      errorMessage: "Pcs must be filled out",
    };
  }
  if (data.rate === "" || data.rate === undefined) {
    return {
      errorMessage: "Rate No must be filled out",
    };
  }
  return true;
};

export const challanJobWorkValidation = (data: any) => {
  if (data.descriptionId === "" || data.descriptionId === undefined) {
    return {
      errorMessage: "Description must be filled out",
    };
  }
  if (data.clotheId === "" || data.clotheId === undefined) {
    return {
      errorMessage: "Fabric must be filled out",
    };
  }
  if (data.fabricColorId === "" || data.fabricColorId === undefined) {
    return {
      errorMessage: "Fabric Color must be filled out",
    };
  }
  if (data.PM === "" || data.PM === undefined) {
    return {
      errorMessage: "Pcs/ Meter must be filled out",
    };
  }
  return true;
};

export const challanColorValidation = (data: any) => {
  if (data.fabricColorId === "" || data.fabricColorId === undefined) {
    return {
      errorMessage: "Fabric Color must be filled out",
    };
  }
  if (data.PM === "" || data.PM === undefined) {
    return {
      errorMessage: "Pcs/ Meter must be filled out",
    };
  }
  return true;
};
