import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rateCardData: []
}

export const rateCardSlice = createSlice({
    name: 'rateCardSlice',
    initialState,
    reducers: {
        setRateCard: (state, action) => {
            state.rateCardData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setRateCard
} = rateCardSlice.actions
export default rateCardSlice.reducer