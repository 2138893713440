import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const value: any = localStorage.getItem("user");
  var getadmin: any = JSON.parse(value)?.role;
  var type: any = JSON.parse(value)?.type;
  return (
    <div className="flex items-center">
      <div className="visible mr-6 lg:invisible">
        <nav
          className={`absolute ml-0 sm:ml-0 md:ml-0 lg:ml-0 sm:top-0 md:top-0 font-[Poppins] lg:top-0 left-6 right-0 z-990 flex flex-wrap items-center px-4 py-2 m-6 mb-0 shadow-sm rounded-t-xl ${
            isNavOpen ? "" : "rounded-b-xl"
          } bg-white lg:flex-nowrap lg:justify-start`}
        >
          <div className="flex justify-between items-center w-full">
            <NavLink to="/" onClick={() => localStorage.removeItem("filterData")}>
              <span className="text-2xl font-[Poppins]">
                <img
                  className="h-10 inline mx-2"
                  alt="SATV"
                  src="../../assets/images/logo.png"
                />
                Satv
              </span>
            </NavLink>
            <span className="mx-2 lg:hidden block">
              {/* <button onClick={() => setIsNavOpen(!isNavOpen)}>
                                <i className={`fas ${isNavOpen ? "fa-times" : "fa-bars"} text-black`}></i>
                            </button> */}
              <div>
                <button
                  className="relative group"
                  onClick={() => setIsNavOpen(!isNavOpen)}
                >
                  <div className="relative flex overflow-hidden items-center justify-center rounded-full w-[40px] h-[40px] transform transition-all bg-slate-700 ring-0 ring-gray-300 hover:ring-8 group-focus:ring-4 ring-opacity-30 duration-200 shadow-md">
                    <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
                      <div className="bg-white h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10"></div>
                      <div className="bg-white h-[2px] w-7 rounded transform transition-all duration-300 group-focus:translate-x-10 delay-75"></div>
                      <div className="bg-white h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10 delay-150"></div>

                      <div className="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10 group-focus:translate-x-0 flex w-0 group-focus:w-12">
                        <div className="absolute bg-white h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300 group-focus:rotate-45"></div>
                        <div className="absolute bg-white h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300 group-focus:-rotate-45"></div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </span>
          </div>

          <ul
            className={`${
              isNavOpen ? "nav-links show-nav" : "nav-links hidden"
            } bg-white rounded-b-xl shadow-lg py-2`}
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                    : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                }
                onClick={() => localStorage.removeItem("filterData")}
              >
                <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                  <i className="mr-2 fas fa-tv"></i>
                  Dashboard
                </div>
              </NavLink>
            </li>
            {getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "designer" ||
            getadmin === "programmer" ? (
              <>
                <li>
                  <NavLink
                    to="/samplecarddashboard"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <img
                        src="../../../assets/images/MatchingCard.svg"
                        className="inline transition-all duration-200 h-6 w-6 mr-2"
                        alt="Sample Cards"
                      />
                      Sample Card
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "designer" ||
            getadmin === "programmer" ? (
              <>
                <li>
                  <NavLink
                    to="/matchingcarddashboard"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <img
                        src="../../../assets/images/MatchingCard.svg"
                        className="inline transition-all duration-200 h-6 w-6 mr-2"
                        alt="Matching Cards"
                      />
                      Matching Card
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "programmer" ? (
              <>
                <li>
                  <NavLink
                    to="/jobworkdashboard"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <img
                        src="../../../assets/images/MatchingCard.svg"
                        className="inline transition-all duration-200 h-6 w-6 mr-2"
                        alt="Rate Cards"
                      />
                      Job Work
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "programmer" ? (
              <>
                <li>
                  <NavLink
                    to="/employeereportdashboard"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <i className="inline transition-all duration-200 h-6 w-6 mr-2 fas fa-file-alt"></i>
                      Employee Report
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "works" ||
            type === "all" ||
            getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "programmer" ? (
              <>
                <li>
                  <NavLink
                    to="/matchingcarddashboardstock"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <img
                        src="../../../assets/images/MatchingCard.svg"
                        className="inline transition-all duration-200 h-6 w-6 mr-2"
                        alt="Matching Cards Stock"
                      />
                      Stocks
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "superadmin" || getadmin === "admin" ? (
              <>
                <li>
                  <NavLink
                    to="/dressratecarddashboard"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <img
                        src="../../../assets/images/MatchingCard.svg"
                        className="inline transition-all duration-200 h-6 w-6 mr-2"
                        alt="Rate Cards"
                      />
                      Rate Cards
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "designer" ||
            getadmin === "programmer" ? (
              <>
                <li>
                  <NavLink
                    to="/partylist"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <i className="mr-2 fas fa-images"></i>
                      Party Images
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "admin" || getadmin === "superadmin" ? (
              <>
                <li>
                  <NavLink
                    to="/user"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <i className="mr-2 fas fa-users"></i>
                      User
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            {getadmin === "superadmin" ||
            getadmin === "admin" ||
            getadmin === "designer" ? (
              <>
                <li>
                  <NavLink
                    to="/setting"
                    className={({ isActive }) =>
                      isActive
                        ? "py-2.7 cursor-pointer bg-themeColor text-white text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col"
                        : "py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                    }
                    onClick={() => localStorage.removeItem("filterData")}
                  >
                    <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                      <i className="mr-2 fas fa-cog"></i>
                      Data Settings
                    </div>
                  </NavLink>
                </li>
              </>
            ) : null}
            <li>
              <div
                className="py-2.7 cursor-pointer border-b hover:bg-themeColor/40 text-sm ease-nav-brand my-0 mx-2 flex whitespace-nowrap rounded-lg px-4 transition-colors flex-col bg-transparent text-black hover:text-black"
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                <div className="block px-4 mr-2 font-normal duration-250 text-base lg:px-2">
                  <i className="mr-2 fas fa-lock"></i>
                  Sign Out
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
