import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyAllRateCardQuery, useLazyDressRateCardQuery, useLazyLumpRateCardQuery, useLazySareeRateCardQuery } from "../../servicesRtkQuery/publicApi";
import { useEffect, useRef, useState } from "react";
import { convertImageToBase64, getFormattedNumber, groupBy } from "../../utils/globalFunctions";
import Modal from "react-responsive-modal";
import * as htmlToImage from 'html-to-image';
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export default function SatvRateCardImages() {
    const navigate = useNavigate()
    const [dressTrigger, dressResult] = useLazyDressRateCardQuery()
    const { isSuccess: isDressSuccess, isFetching: isDressFetching } = dressResult

    const [sareeTrigger, sareeResult] = useLazySareeRateCardQuery()
    const { isSuccess: isSareeSuccess, isFetching: isSareeFetching } = sareeResult

    const [lumpTrigger, lumpResult] = useLazyLumpRateCardQuery()
    const { isSuccess: isLumpSuccess, isFetching: isLumpFetching } = lumpResult

    const [allTrigger, allResult] = useLazyAllRateCardQuery()
    const { isSuccess: isAllSuccess, isFetching: isAllFetching } = allResult

    const exportDressRef: any = useRef(null);
    const exportSareeRef: any = useRef(null);
    const exportLumpRef: any = useRef(null);
    const [dressRateCard, setDressRateCard]: any = useState([])
    const [sareeRateCard, setSareeRateCard]: any = useState([])
    const [lumpRateCard, setLumpRateCard]: any = useState([])

    const [singleRateCard, setsingleRateCard]: any = useState()

    const [logo, setLogo]: any = useState();
    const [image, setImage]: any = useState();

    const [originalImage, setOriginalImage]: any = useState(false)
    const [showDressRatePreview, setShowDressRatePreview] = useState(false);
    const [showSareeRatePreview, setShowSareeRatePreview] = useState(false);
    const [showLumpRatePreview, setShowLumpRatePreview] = useState(false);

    const [loadingImage, setLoadingImage]: any = useState(false)
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index: any) => {
        setToggleState(index);
    };

    const downloadImage = async (ref: any, fileName: any) => {
        const dataUrl = await htmlToImage.toPng(ref.current);
        // download image
        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataUrl;
        link.click();
    }

    const [total, setTotal]: any = useState()

    const [clothes, setClothes]: any = useState([])
    const [finalClotheData, setFinalClothData]: any = useState({})
    useEffect(() => {
        if (isAllSuccess && !isAllFetching) {
            dressTrigger({ limit: allResult?.data?.data?.dress })
            sareeTrigger({ limit: allResult?.data?.data?.saree })
            lumpTrigger({ limit: allResult?.data?.data?.lump })
        }
    }, [isAllSuccess, isAllFetching])

    useEffect(() => {
        allTrigger()
        convertImageToBase64("https://cnt-satv.s3.ap-south-1.amazonaws.com/logo.png", setLogo)
    }, [])

    useEffect(() => {
        setImage("")
        if (singleRateCard) {
            setLoadingImage(true)
            if (singleRateCard?.image?.startsWith("https:")) {
                convertImageToBase64(singleRateCard?.image, setImage)
            }
            else {
                Swal.fire({
                    toast: true,
                    icon: 'warning',
                    title: "You have not uploaded any image\nPlease upload a new image.",
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: ERROR_MESSAGE_TIMEOUT,
                    timerProgressBar: true
                })
                setLoadingImage(false)
            }
            var totalRate: any = 0
            singleRateCard?.works?.forEach((card: any) => {
                totalRate = totalRate + Math.ceil(card?.totalQty * (card?.rate + card?.valueAdd))
            });
            setTotal(totalRate)
            let groupData: any = []

            singleRateCard?.measurements?.forEach((item: any) => {
                let object = {
                    _id: item?._id,
                    clothe: item?.clothe?.name,
                    fabrics: item?.fabrics,
                    meter: item?.meter,
                }
                groupData.push(object)
            })
            var data = groupBy(groupData, 'clothe')
            setClothes(Object.keys(data))
            var finalData: any = {}

            singleRateCard?.measurements?.forEach((item: any) => {
                let object = {
                    _id: item?._id,
                    clothe: item?.clothe?.name,
                    fabrics: item?.fabrics,
                    meter: item?.meter,
                }
                groupData.push(object)
            })
            Object.keys(data).forEach((item: any) => {
                let total: any = 0
                data[item]?.forEach((data: any) => {
                    total = total + data?.meter
                });
                finalData[item] = ((total + ((total * Number(singleRateCard?.shortNet)) / 100)) + Number(singleRateCard?.plain)).toFixed(2)
            });
            setFinalClothData(finalData)
        }
    }, [singleRateCard])

    useEffect(() => {
        if (isDressSuccess && !isDressFetching) {
            setDressRateCard(dressResult?.data?.data?.dressCard || [])
        }
    }, [isDressSuccess, isDressFetching, dressResult?.data?.data?.dressCard])

    useEffect(() => {
        if (isDressSuccess) {
            if (isSareeSuccess && !isSareeFetching) {
                setSareeRateCard(sareeResult?.data?.data?.sareeCard || [])
            }
        }
    }, [isDressSuccess, isSareeSuccess, isSareeFetching, sareeResult?.data?.data?.sareeCard])

    useEffect(() => {
        if (isLumpSuccess) {
            if (isLumpSuccess && !isLumpFetching) {
                setLumpRateCard(lumpResult?.data?.data?.lumpCard || [])
            }
        }
    }, [isLumpSuccess, isLumpFetching, lumpResult?.data?.data?.lumpCard])

    useEffect(() => {
        if (image) {
            setLoadingImage(false)
        }
    }, [image])

    const handleClose = () => {
        setOriginalImage(false)
        setShowDressRatePreview(false)
    }

    const DressTab = () => {
        return (
            <>
                <div className="mb-6 py-4 break-words border-0 border-transparent border-solid bg-clip-border grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-center md:justify-start items-center">
                    <button className="w-50 max-50 px-3" onClick={() => navigate(`/partylist`)}>
                        <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs focus:bg-gray-100 focus:shadow-xs">
                            <div className="flex-auto p-4">
                                <div className="flex flex-row justify-center -mx-3">
                                    <i className="fas fa-level-up-alt text-4xl text-themeColor/70"></i>
                                </div>
                                <div className="text-center break-words overflow-clip">
                                    ...
                                </div>
                            </div>
                        </div>
                    </button>
                    {dressRateCard?.map((rate: any, i: any) => {
                        return (
                            <button key={i} className="w-40 max-50">
                                <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs" onClick={() => {
                                    setsingleRateCard(rate)
                                    setShowDressRatePreview(true)
                                }}>
                                    <div className="flex-auto py-4">
                                        <div className="">
                                            <i className="far fa-file-image text-9xl text-themeColor/70"></i>
                                        </div>
                                        <div className="text-center break-words overflow-clip">
                                            {rate?.designNo}
                                        </div>
                                    </div>
                                </div>
                            </button>
                        )
                    })
                    }
                </div>
                {
                    dressRateCard?.length == 0 ?
                        <div className="w-full h-full flex flex-col items-center justify-center text-gray-600 font-bold">
                            <div><i className="fas fa-image text-40 lg:text-90 text-gray-300"></i></div>
                            <div>No Dress Card Image Found</div>
                        </div>
                        : null
                }
            </>
        )
    }

    const SareeTab = () => {
        return (
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid bg-clip-border grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-center md:justify-start items-center">
                <button className="w-50 max-50 px-3" onClick={() => navigate(`/partylist`)}>
                    <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs focus:bg-gray-100 focus:shadow-xs">
                        <div className="flex-auto py-4">
                            <div className="flex flex-row justify-center -mx-3">
                                <i className="fas fa-level-up-alt text-4xl text-themeColor/70"></i>
                            </div>
                            <div className="text-center break-words overflow-clip">
                                ...
                            </div>
                        </div>
                    </div>
                </button>
                {sareeRateCard?.map((rate: any, i: any) => {
                    return (
                        <button key={i} className="w-40 max-50">
                            <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs" onClick={() => {
                                setsingleRateCard(rate)
                                setShowSareeRatePreview(true)
                            }}>
                                <div className="flex-auto p-4">
                                    <div className="flex flex-row justify-center -mx-3">
                                        <i className="far fa-file-image text-9xl text-themeColor/70"></i>
                                    </div>
                                    <div className="text-center break-words overflow-clip">
                                        {rate?.designNo}
                                    </div>
                                </div>
                            </div>
                        </button>
                    )
                })
                }
                {
                    sareeRateCard?.length == 0 ?
                        <div className="w-full h-full flex flex-col items-center justify-center text-gray-600 font-bold">
                            <div><i className="fas fa-image text-40 lg:text-90 text-gray-300"></i></div>
                            <div>No Saree Card Image Found</div>
                        </div>
                        : null
                }
            </div>
        )
    }

    const LumpTab = () => {
        return (
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid bg-clip-border grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-center md:justify-start items-center">
                <button className="w-50 max-50 px-3" onClick={() => navigate(`/partylist`)}>
                    <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs focus:bg-gray-100 focus:shadow-xs">
                        <div className="flex-auto p-4">
                            <div className="flex flex-row justify-center -mx-3">
                                <i className="fas fa-level-up-alt text-4xl text-themeColor/70"></i>
                            </div>
                            <div className="text-center break-words overflow-clip">
                                ...
                            </div>
                        </div>
                    </div>
                </button>
                {lumpRateCard?.map((rate: any, i: any) => {
                    return (
                        <button key={i} className="w-40 max-50">
                            <div className="flex flex-col min-w-0 break-words rounded-2xl bg-clip-border cursor-pointer hover:bg-gray-100 hover:shadow-xs" onClick={() => {
                                setsingleRateCard(rate)
                                setShowLumpRatePreview(true)
                            }}>
                                <div className="flex-auto py-4">
                                    <div className="flex flex-row justify-center -mx-3">
                                        <i className="far fa-file-image text-9xl text-themeColor/70"></i>
                                    </div>
                                    <div className="text-center break-words overflow-clip">
                                        {rate?.designNo}
                                    </div>
                                </div>
                            </div>
                        </button>
                    )
                })
                }
                {
                    lumpRateCard?.length == 0 ?
                        <div className="w-full h-full flex flex-col items-center justify-center text-gray-600 font-bold">
                            <div><i className="fas fa-image text-40 lg:text-90 text-gray-300"></i></div>
                            <div>No Lump Card Image Found</div>
                        </div>
                        : null
                }
            </div>
        )
    }

    const SareeCardPreview = () => {
        return (
            <Modal open={showSareeRatePreview} showCloseIcon={false}
                center
                closeOnEsc={false}
                onEscKeyDown={() => setShowSareeRatePreview(false)}
                onOverlayClick={() => setShowSareeRatePreview(false)}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl"
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    }
                }}
                aria-labelledby="dressrate-card-pdf"
                aria-describedby="dressrate-card-pdf-description"
                animationDuration={400}
                onClose={() => setShowSareeRatePreview(false)}>
                <div className="overflow-auto p-2">
                    <div ref={exportSareeRef} className={`overflow-hidden a5-landscape bg-white`}>
                        <div className="border border-gray-600">
                            <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
                                <div className="text-themeColor pl-25 capitalize">
                                    <img src={logo} alt="सत्व" />
                                </div>
                                <div className="text-themeColor text-7 text-left capitalize">
                                    design no : {singleRateCard?.designNo}
                                </div>
                            </div>
                            <div className="p-2 border-y border-black">
                                <div className={`text-sm grid grid-cols-6 gap-2`}>
                                    <div className="flex justify-center col-span-2 row-span-2 overflow-hidden">
                                        {
                                            image ?
                                                <img src={image} className="max-h-120 w-75" alt="" />
                                                : null
                                        }
                                    </div>
                                    <div className="col-span-4">
                                        {/* <table className="w-full border border-black border-collapse bg-white text-left text-5">
                                        <thead className="divide-black border-t border-x border-black bg-gray-100">
                                            <tr>
                                                <th scope="col" colSpan={singleRateCard?.fabrics?.length == 1 ? 2 : 3} className="py-1.5 border-r text-red-800 border-black capitalize text-center">Saree</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y text-center divide-black border-x border-t border-black text-5">
                                            <tr className="text-center">
                                                <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">fabric</td>
                                                {
                                                    singleRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">{fabric?.item?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">saree fabric party</td>
                                                {
                                                    singleRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black text-center capitalize">{fabric?.clothe?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">blouse fabric party</td>
                                                {
                                                    singleRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black text-center capitalize">{fabric?.clotheParty?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table> */}
                                        <table className="w-full border border-collapse bg-white text-left text-5">
                                            <thead className="divide-black border border-black">
                                                <tr>
                                                    <th scope="col" colSpan={4} className="py-1.5 bg-gray-100 border-r border-b text-red-800 border-black capitalize text-center text-2xl font-extrabold">Saree</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">item</th>
                                                    <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">Quality</th>
                                                    <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">Fabric Party</th>
                                                    <th scope="col" className="py-1.5 border-r text-red-800 border-black capitalize text-center">Cut / Mtr.</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-black border border-black">
                                                {
                                                    singleRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <tr>
                                                                <td className="gap-3 border-r border-black text-center py-2 capitalize">{fabric?.item?.name}</td>
                                                                <td className="gap-3 border-r border-black text-center py-2 capitalize">{fabric?.clothe?.name}</td>
                                                                <td className="gap-3 border-r border-black text-center py-2 capitalize">{fabric?.clotheParty?.name}</td>
                                                                <td className="gap-3 border-r border-black text-center py-2 capitalize">{(fabric?.meter)?.toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <table className="w-full border border-collapse bg-white text-5 text-center mt-4">
                                            <thead className="divide-black border-t border-x border-black">
                                                <tr>
                                                    <th scope="col" colSpan={2} className="py-1.5 border-r text-red-800 border-black capitalize bg-gray-100 text-2xl font-extrabold">rate</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-black border border-black">
                                                <tr>
                                                    <td width="50%" className="border-r py-1.5 border-black capitalize">job work</td>
                                                    <td width="50%" className="border-r py-1.5 border-black capitalize">{singleRateCard?.finalCosting?.work?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black py-1.5 capitalize">stitching</td>
                                                    <td className="border-r border-black py-1.5 capitalize">{singleRateCard?.finalCosting?.stitching?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black py-1.5 capitalize">foil</td>
                                                    <td className="border-r border-black py-1.5 text-gray-900r capitalize">{singleRateCard?.finalCosting?.foil?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black py-1.5 capitalize">hand work</td>
                                                    <td className="border-r border-black py-1.5 text-gray-900r capitalize">{singleRateCard?.finalCosting?.hand?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black py-1.5 capitalize">fabrics</td>
                                                    <td className="border-r border-black py-1.5 text-gray-900r capitalize">{singleRateCard?.finalCosting?.fabrics?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black py-1.5 capitalize">daying</td>
                                                    <td className="border-r border-black py-1.5 text-gray-900r capitalize">{singleRateCard?.finalCosting?.daying?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black py-1.5 capitalize">&nbsp;</td>
                                                    <td className="border-r border-black py-1.5 text-gray-900r capitalize">&nbsp;</td>
                                                </tr>
                                                <tr className="bg-gray-100">
                                                    <td className="border-r border-black py-1.5 capitalize text-red-800 font-extrabold text-2xl">total rate</td>
                                                    <td className="border-r border-black py-1.5 capitalize text-red-800 font-extrabold text-2xl">{
                                                        Number(singleRateCard?.finalCosting?.work?.amount) + Number(singleRateCard?.finalCosting?.hand?.amount) + Number(singleRateCard?.finalCosting?.stitching?.amount) + Number(singleRateCard?.finalCosting?.foil?.amount) + Number(singleRateCard?.finalCosting?.daying?.amount || 0) + Number(singleRateCard?.finalCosting?.fabrics?.amount)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-3 flex flex-col lg:flex-row justify-between gap-3">
                        <div>
                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => setShowSareeRatePreview(false)}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' disabled={loadingImage} onClick={() => {
                                downloadImage(exportSareeRef, singleRateCard?.designNo + " - Satv")
                            }}>
                                {loadingImage ?
                                    <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                    <><span>Download</span><i className='fas fa-file-image ml-2'></i></>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    const LumpCardPreview = () => {
        return (
            <Modal open={showLumpRatePreview} showCloseIcon={false}
                center
                closeOnEsc={false}
                onEscKeyDown={() => setShowLumpRatePreview(false)}
                onOverlayClick={() => setShowLumpRatePreview(false)}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl"
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    }
                }}
                aria-labelledby="dressrate-card-pdf"
                aria-describedby="dressrate-card-pdf-description"
                animationDuration={400}
                onClose={() => setShowLumpRatePreview(false)}>
                <div className="overflow-auto p-2">
                    <div ref={exportLumpRef} className={`overflow-hidden a5-landscape flex justify-center bg-white`}>
                        <div className="border border-gray-600">
                            <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
                                <div className="text-themeColor pl-25 capitalize">
                                    <img src={logo} alt="सत्व" />
                                </div>
                                <div className="text-themeColor text-7 text-left capitalize">
                                    design no : {singleRateCard?.designNo}
                                </div>
                            </div>
                            <div className="p-2 border-y border-black">
                                <div className={`text-sm grid grid-cols-6 gap-2`}>
                                    <div className="flex justify-center col-span-2 row-span-2 overflow-hidden">
                                        {
                                            image ?
                                                <img src={image} className="max-h-120 w-75" alt="" />
                                                : null
                                        }
                                    </div>
                                    <div className="col-span-4">
                                        <table className="w-full border border-black border-collapse bg-white text-left text-5">
                                            <thead className="divide-black border-t border-x border-black bg-gray-100">
                                                <tr>
                                                    <th scope="col" colSpan={singleRateCard?.fabrics?.length == 1 ? 2 : 3} className="py-1.5 border-r text-red-800 border-black capitalize text-center font-extrabold text-2xl">{singleRateCard?.mainTitle?.name ? singleRateCard?.mainTitle?.name : "Lump"}</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-black border-x border-t border-black text-5">
                                                <tr className="text-center">
                                                    <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r py-1.5 border-black capitalize">fabric</td>
                                                    {
                                                        singleRateCard?.fabrics?.map((fabric: any) => {
                                                            return (
                                                                <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r border-black capitalize">{fabric?.clothe?.name}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r py-1.5 border-black capitalize">Fabric party</td>
                                                    {
                                                        singleRateCard?.fabrics?.map((fabric: any) => {
                                                            return (
                                                                <td width={singleRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r border-black text-center capitalize">{fabric?.clotheParty?.name}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="w-full border border-collapse bg-white text-center text-5 mt-4">
                                            <thead className="bg-gray-100 divide-black border-t border-x border-black">
                                                <tr>
                                                    <th scope="col" colSpan={2} className="py-1.5 border-r text-red-800 border-black capitalize text-2xl font-extrabold">rate</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-black border-x border-t border-black">
                                                <tr>
                                                    <td width="50%" className="border-r border-black capitalize py-1.5">{singleRateCard?.jobTitle?.name ? singleRateCard?.jobTitle?.name : "job work"}</td>
                                                    <td width="50%" className="border-r border-black capitalize py-1.5">{singleRateCard?.finalCosting?.work?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black capitalize py-1.5">hand works</td>
                                                    <td className="border-r border-black capitalize py-1.5">{singleRateCard?.finalCosting?.hand?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black capitalize py-1.5">foil</td>
                                                    <td className="border-r border-black text-gray-900r capitalize py-1.5">{singleRateCard?.finalCosting?.foil?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black capitalize py-1.5">fabrics</td>
                                                    <td className="border-r border-black text-gray-900r capitalize py-1.5">{singleRateCard?.finalCosting?.fabrics?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black capitalize py-1.5">daying</td>
                                                    <td className="border-r border-black text-gray-900r capitalize py-1.5">{singleRateCard?.finalCosting?.daying?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-r border-black capitalize py-1.5">&nbsp;</td>
                                                    <td className="border-r border-black text-gray-900r capitalize py-1.5">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                            <tfoot className="text-red-800 bg-gray-100 border border-black">
                                                <tr>
                                                    <td className="border-r border-b border-black capitalize font-extrabold py-1.5 text-2xl">total rate</td>
                                                    <td className="border-r border-b border-black text-gray-900r capitalize font-extrabold py-1.5 text-2xl">{
                                                        Number(singleRateCard?.finalCosting?.work?.amount) + Number(singleRateCard?.finalCosting?.hand?.amount) + Number(singleRateCard?.finalCosting?.foil?.amount) +
                                                        Number(singleRateCard?.finalCosting?.daying?.amount || 0) + Number(singleRateCard?.finalCosting?.fabrics?.amount)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-3 flex flex-col lg:flex-row justify-between gap-3">
                        <div>
                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => setShowLumpRatePreview(false)}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' disabled={loadingImage} onClick={() => {
                                downloadImage(exportLumpRef, singleRateCard?.designNo + " - Satv")
                            }}>
                                {loadingImage ?
                                    <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                    <><span>Download</span><i className='fas fa-file-image ml-2'></i></>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
            <div className="mx-6 mt-20 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased overflow-auto transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-4 right-0 xl:translate-x-0">
                <div className="w-full rounded-xl bg-white">
                    <div className="sticky top-0">
                        <div className="w-full overflow-hidden">
                            <div className="border rounded-t-xl p-1.5 bg-gray-50 shadow-sm">
                                <ul className="flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row px-2" role="tablist">
                                    <li className={`${toggleState === 1 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-3`}>
                                        <button className={`w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate-transparent focus:isolate focus:border-transparent flex justify-center items-center`} onClick={() => toggleTab(1)}>
                                            <span className="mr-2">Dress</span>
                                            <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-themeColor border-2 border-white rounded-full ">{dressRateCard?.length}</span>
                                        </button>
                                    </li>
                                    <li className={`${toggleState === 2 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-3`}>
                                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(2)}>
                                            <span className="mr-2">Saree</span>
                                            <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-themeColor border-2 border-white rounded-full ">{sareeRateCard?.length}</span>
                                        </button>
                                    </li>
                                    <li className={`${toggleState === 3 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "hover:bg-themeColor/5"} flex-auto text-center rounded-xl mx-3`}>
                                        <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(3)}>
                                            <span className="mr-2">Lump</span>
                                            <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-themeColor border-2 border-white rounded-full ">{lumpRateCard?.length}</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <nav className="flex px-5 py-3 text-gray-700 border border-gray-200 bg-gray-50" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <Link to="/partylist" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-themeColor">
                                        <i className="fas fa-images mr-2"></i>
                                        Party Images
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <i className="fas fa-angle-right mr-2"></i>
                                        <label className="inline-flex items-center text-sm font-medium text-themeColor">
                                            Satv Images
                                        </label>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="w-full">
                        <div className="w-full h-full rounded-xl overflow-hidden bg-white p-4">
                            <div className="">
                                <div className={`transition-opacity duration-150 ease-linear ${toggleState === 1 ? "opacity-100" : "opacity-0 hidden"}`}>
                                    <DressTab />
                                </div>
                                <div className={`transition-opacity duration-150 ease-linear ${toggleState === 2 ? "opacity-100" : "opacity-0 hidden"}`}>
                                    <SareeTab />
                                </div>
                                <div className={`transition-opacity duration-150 ease-linear ${toggleState === 3 ? "opacity-100" : "opacity-0 hidden"}`}>
                                    <LumpTab />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDressRatePreview ?
                <Modal open={showDressRatePreview} showCloseIcon={false}
                    center
                    closeOnEsc={false}
                    onEscKeyDown={() => handleClose()}
                    onOverlayClick={() => handleClose()}
                    closeOnOverlayClick={false}
                    classNames={{
                        modal: "rounded-xl"
                    }}
                    styles={{
                        overlay: {
                            height: "auto",
                            width: "auto",
                        }
                    }}
                    aria-labelledby="dressrate-card-pdf"
                    aria-describedby="dressrate-card-pdf-description"
                    animationDuration={400}
                    onClose={() => handleClose()}>
                    <div className="overflow-auto p-2">
                        <div className="flex items-center px-7">
                            <label className="font-normal mr-2 cursor-pointer select-none text-sm text-slate-700 ">Fit Image</label>
                            <div className="flex flex-row pl-12 mb-0.5 text-left min-h-6">
                                <input id="originalImage" className="mt-0.5 rounded-10 duration-250 ease-in-out after:rounded-circle after:shadow-2xl after:duration-250 checked:after:translate-x-5.3 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-zinc-700/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-themeColor checked:bg-themeColor checked:bg-none checked:bg-right" type="checkbox" onChange={(e: any) => {
                                    setOriginalImage(e.target.checked)
                                }} />
                            </div>
                            <label className="ml-2 font-normal cursor-pointer select-none text-sm text-slate-700 ">Original Image</label>
                        </div>
                        <div ref={exportDressRef} className={`overflow-hidden a4-portrait bg-white`}>
                            <div className="border border-gray-600">
                                <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
                                    <div className="text-themeColor pl-25 capitalize">
                                        <img src={logo} alt="सत्व" />
                                    </div>
                                    <div className="text-themeColor text-7 text-left capitalize">
                                        design no : {singleRateCard?.designNo} {singleRateCard?.size?.value ? (singleRateCard?.size?.value) : null}
                                    </div>
                                </div>
                                <div className="p-2 border-y border-black">
                                    <div className={`text-sm grid grid-cols-6 gap-2`}>
                                        <div className="flex justify-center col-span-2 row-span-2 overflow-hidden max-h-120">
                                            {
                                                image ?
                                                    <img src={image} className={`max-h-120 h-full w-full ${originalImage ? "object-scale-down" : "object-fill"}`} alt="" />
                                                    : null
                                            }
                                        </div>
                                        <div className="col-span-4">
                                            <table className="w-full border border-collapse bg-white text-left">
                                                <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                                                    <tr>
                                                        <th scope="col" className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center">Item</th>
                                                        <th scope="col" className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center">Pcs/ Meater</th>
                                                        <th scope="col" className="py-1.5 w-16 border-r text-red-800 border-black capitalize text-center">Rate</th>
                                                        <th scope="col" className="py-1.5 w-20 border-r text-red-800 border-black capitalize text-center">total</th>
                                                        <th scope="col" className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center">remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                                                    {
                                                        singleRateCard?.works?.map((card: any) => {
                                                            return (
                                                                <tr>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">{card?.item?.name}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{getFormattedNumber(card?.totalQty)}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{card?.rate + card?.valueAdd}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{Math.ceil(card?.totalQty * (card?.rate + card?.valueAdd))}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{card?.remark}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                                <tfoot className=" bg-gray-100 text-center divide-black border border-black text-5">
                                                    <tr>
                                                        <td colSpan={6} className="gap-3 border-r pl-2 border-black font-bold capitalize text-red-900 py-1.5">
                                                            total rate =
                                                            <span className="ml-2 font-bold">
                                                                {Math.floor(total)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="col-span-4 mt-2">
                                            <table className="w-full border border-collapse bg-white text-left capitalize">
                                                <thead className="bg-gray-100 divide-black border-t border-x border-black text-4">
                                                    <tr>
                                                        <th colSpan={7} className="text-center border-b border-black text-themeColor py-2 font-bold text-5">fabric measurements</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col" className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center">item</th>
                                                        <th scope="col" className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center">Fabric</th>
                                                        <th scope="col" className="py-1.5 w-34 border-r text-red-800 border-black capitalize text-center">fabric color</th>
                                                        <th scope="col" className="py-1.5 w-24 border-r text-red-800 border-black capitalize text-center">pcs/ Meater</th>
                                                        <th scope="col" className="border-r w-16 py-1.5 text-red-800 border-black capitalize text-center">mej.</th>
                                                        <th scope="col" className="border-r w-16 py-1.5 text-red-800 border-black capitalize text-center">Meater</th>
                                                        <th scope="col" className="border-r w-34 py-1.5 text-red-800 border-black capitalize text-center">remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y text-center divide-black border-x border-t border-black text-4">
                                                    {
                                                        singleRateCard?.measurements?.map((card: any) => {
                                                            return (
                                                                <tr>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">{card?.item?.name}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">{card?.clothe?.name}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text capitalize">{card?.fabricColor?.colorName}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{card.totalQty}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{card.fabrics}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{card.meter}</td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">{card?.remark}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">Plain</td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">{singleRateCard?.plain}</td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">Short fabric</td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                        <td className="gap-3 border-r border-black font-bold text-gray-900 capitalize">{singleRateCard?.shortNet}</td>
                                                        <td className="gap-3 border-r border-black font-normal text-gray-900 capitalize"></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot className=" bg-gray-100 divide-black border border-black text-base text-center">
                                                    <tr>
                                                        <td colSpan={7} className="gap-3 border border-black font-bold capitalize text-red-900 py-1.5">
                                                            {
                                                                clothes?.length > 1 ?
                                                                    clothes?.map((item: any) => {
                                                                        return (
                                                                            <span className="mx-1">
                                                                                Total Meater =
                                                                                <span className="ml-1">
                                                                                    {finalClotheData[item] + " [" + item + "]"}
                                                                                </span>
                                                                            </span>
                                                                        )
                                                                    })
                                                                    :
                                                                    <>
                                                                        total Meater =
                                                                        <span className="ml-2">
                                                                            {singleRateCard?.finalCosting?.measurements?.totalMeter + " [" + clothes[0] + "]"}
                                                                        </span>
                                                                    </>
                                                            }

                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-3 flex flex-col lg:flex-row justify-between gap-3">
                            <div>
                                <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => handleClose()}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' disabled={loadingImage} onClick={() => {
                                    downloadImage(exportDressRef, singleRateCard?.designNo + " - Satv")
                                }}>
                                    {loadingImage ?
                                        <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                        <><span>Download</span><i className='fas fa-file-image ml-2'></i></>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                : null}

            {showSareeRatePreview ?
                <SareeCardPreview />
                : null}

            {showLumpRatePreview ?
                <LumpCardPreview />
                : null}
        </div>
    )
}