import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sampleCardData: []
}

export const sampleCardSlice = createSlice({
    name: 'sampleCardSlice',
    initialState,
    reducers: {
        setSampleCard: (state, action) => {
            state.sampleCardData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSampleCard
} = sampleCardSlice.actions
export default sampleCardSlice.reducer