import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  convertImageToBase64,
  getFormattedNumber,
} from "../../utils/globalFunctions";
import "./styles.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { useLazyGetSampleCardQuery } from "../../servicesRtkQuery/publicApi";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export const SampleCardPreview = (props: any) => {
  const exportRef: any = useRef(null);
  const exportBackRef = useRef(null);
  const [image, setImage]: any = useState();

  const [sampleCard, setSampleCard]: any = useState();
  const id = props.id;
  const [trigger, result] = useLazyGetSampleCardQuery();
  const { isSuccess, isFetching } = result;
  const [loadingImage, setLoadingImage]: any = useState(false);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setSampleCard(result?.data?.data?.sampleCard?.[0]);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    setLoadingImage(true);
    trigger(id);
    if (sampleCard) {
      if (sampleCard?.image?.URL?.startsWith("https:")) {
        convertImageToBase64(sampleCard?.image?.URL, setImage);
      } else {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "You have not uploaded any image\nPlease upload a new image.",
          position: "top-end",
          showConfirmButton: false,
          timer: ERROR_MESSAGE_TIMEOUT,
          timerProgressBar: true,
        });
        setLoadingImage(false);
      }
    }
  }, [sampleCard]);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index: any) => {
    setToggleState(index);
  };
  useEffect(() => {
    if (image) {
      setLoadingImage(false);
    }
  }, [image]);
  const [totalCutter, setTotalCutter]: any = useState(0);
  const [totalStiching, setTotalStiching]: any = useState(0);
  useEffect(() => {
    let TotalCutter: any = 0;
    sampleCard?.clothes?.map((item: any) => {
      TotalCutter += item?.CC;
    });
    setTotalCutter(TotalCutter);
  }, [sampleCard]);
  useEffect(() => {
    let TotalStiching: any = 0;
    sampleCard?.clothes?.map((item: any) => {
      TotalStiching += item?.stitch;
    });
    setTotalStiching(TotalStiching);
  }, [sampleCard]);
  function FrontPage() {
    return (
      <div
        ref={exportRef}
        className={`${
          sampleCard?.needlesHead?.length > 9 ? "a5-landscape" : "a5-portrait"
        } overflow-auto`}
      >
        <div className="text-lg text-center underline capitalize font-bold">
          Sample Card -&nbsp;&nbsp;{sampleCard?.type}
        </div>
        <div className="overflow-auto text-xs border border-black">
          {sampleCard?.needlesHead?.length > 9 ? (
            <>
              <table className="w-full border-collapse bg-white text-center">
                <thead className="capitalize border border-black">
                  <tr className="border border-black text-base">
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      party name
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      design no.
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      date
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      Challan No.
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      {sampleCard?.workType == "skechers" ? "Skecher" : "Image"}
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      designer
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      card no.
                    </th>
                  </tr>
                  <tr className="text-base leading-5">
                    <th className="text-center border-x border-black font-bold">
                      {sampleCard?.party?.name}
                    </th>
                    <th className="text-center border-r border-b border-black font-bold">
                      {sampleCard?.designNo}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {moment(sampleCard?.date).format("DD/MM/YYYY")}
                    </th>
                    <th className="text-cente border-r border-black font-bold">
                      {sampleCard?.challanNo}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {sampleCard?.puncher?.name}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {sampleCard?.designer?.name}
                    </th>
                    <th className="text-center border-b border-r border-black font-bold">
                      {sampleCard?.invoiceNo}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="w-full border-collapse bg-white text-center">
                <tbody className="border-b border-black">
                  <tr>
                    <td
                      rowSpan={2}
                      className="text-center border-y border-r font-bold border-black py-1 w-14"
                    >
                      Fabric Color
                    </td>
                    {/* <td className="text-center border-y font-bold border-black py-1 w-10">
                      Pcs
                    </td> */}
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N1
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N2
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N3
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N4
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N5
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N6
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N7
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N8
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N9
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N10
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N11
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N12
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N13
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N14
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N15
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N16
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N17
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N18
                    </td>
                  </tr>
                  <tr className="text-sm leading-5">
                    {/* <td className="text-center border-b border-black py-1">
                      {sampleCard?.TPPM}
                    </td> */}
                    {sampleCard?.needlesHead?.map((head: any, index: any) => {
                      return (
                        <td
                          key={index}
                          className="text-center border-b border-l border-black py-1"
                        >
                          <div
                            className="border-b text-base border-black py-1"
                            id="pattern"
                          >
                            {head?.designPattern}
                          </div>
                          <div>{head?.threadType}</div>
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(18 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-black font-bold py-1"
                              >
                                <div className="border-b border-black py-1">
                                  &nbsp;
                                </div>
                                <div>&nbsp;</div>
                              </td>
                            );
                          })
                      : null}
                  </tr>
                  {sampleCard?.fabricColors?.map((fabric: any, index: any) => {
                    return (
                      <tr>
                        <td
                          className={`text-right text-sm border-r border-b border-black font-bold capitalize`}
                          id="color"
                        >
                          {fabric?.fabricColorId?.colorName}
                        </td>
                        {/* <td
                          className={`text-center border-b border-black capitalize`}
                        >
                          {fabric?.PM}
                        </td> */}
                        {fabric?.niddles?.map((niddle: any, index: any) => {
                          return niddle?.designCodeId2?.designCode ? (
                            <td
                              key={index}
                              className="text-center uppercase border-b border-l border-black font-bold"
                            >
                              <tr className="border-b border-black">
                                {niddle?.designCodeId1?.designCode}
                              </tr>
                              <tr>{niddle?.designCodeId2?.designCode}</tr>
                            </td>
                          ) : (
                            <td
                              key={index}
                              className="text-center uppercase h-11 border-b border-l border-black font-bold"
                            >
                              <div>{niddle?.designCodeId1?.designCode}</div>
                            </td>
                          );
                        })}
                        {fabric?.niddles?.length > 0
                          ? Array(18 - Number(fabric?.niddles?.length))
                              ?.fill("-")
                              ?.map((row: any, index: number) => {
                                return (
                                  <td
                                    key={index}
                                    className="text-center h-11 border-b border-l border-black font-bold"
                                  >
                                    &nbsp;
                                  </td>
                                );
                              })
                          : null}
                      </tr>
                    );
                  })}
                  {sampleCard?.fabricColors?.length > 0 &&
                  Number(sampleCard?.fabricColors?.length) < 5
                    ? Array(5 - Number(sampleCard?.fabricColors?.length))
                        ?.fill("-")
                        ?.map((fabric: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="border-r border-black font-bold">
                                &nbsp;
                              </td>
                              <td className={`border-black capitalize`}>
                                &nbsp;
                              </td>
                              {Array(18)
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="border-l border-black"
                                    ></td>
                                  );
                                })}
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="w-full border-collapse bg-white text-center">
                <thead className="capitalize border border-black">
                  <tr className="border border-black text-base">
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      party name
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      design no.
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      date
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      Challan No.
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      {sampleCard?.workType == "skechers" ? "Skecher" : "Image"}
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      designer
                    </th>
                    <th
                      style={{ width: "14.28%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      card no.
                    </th>
                  </tr>
                  <tr className="text-base">
                    <th className="text-center border-x border-black font-bold">
                      {sampleCard?.party?.name}
                    </th>
                    <th className="text-center border-r border-b border-black font-bold">
                      {sampleCard?.designNo}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {moment(sampleCard?.date).format("DD/MM/YYYY")}
                    </th>
                    <th className="text-cente border-r border-black font-bold">
                      {sampleCard?.challanNo}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {sampleCard?.puncher?.name}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {sampleCard?.designer?.name}
                    </th>
                    <th className="text-center border-b border-r border-black font-bold">
                      {sampleCard?.invoiceNo}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="w-full border-collapse bg-white text-center">
                <tbody className="text-xs leading-5 border-b border-black">
                  <tr className="border-b border-black">
                    <td
                      rowSpan={2}
                      className="text-center border-y border-r font-bold border-black w-20"
                    >
                      Fabric Color
                    </td>
                    {/* <td className="text-center border-y font-bold border-black w-10">
                      Pcs
                    </td> */}
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N1
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N2
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N3
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N4
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N5
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N6
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N7
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N8
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N9
                    </td>
                  </tr>
                  <tr className="border-b border-black">
                    {/* <td className="text-center border-b border-black">
                      {sampleCard?.TPPM}
                    </td> */}
                    {sampleCard?.needlesHead?.map((head: any, index: any) => {
                      return (
                        <td
                          key={index}
                          className="text-center border-b border-l border-black"
                        >
                          <div
                            className="border-b text-base border-black"
                            id="pattern"
                          >
                            {head?.designPattern}
                          </div>
                          <div>{head?.threadType}</div>
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(9 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-black font-bold"
                              >
                                <div className="border-b border-black">
                                  &nbsp;
                                </div>
                                <div>&nbsp;</div>
                              </td>
                            );
                          })
                      : null}
                  </tr>
                  {sampleCard?.fabricColors?.map((fabric: any, index: any) => {
                    return (
                      <tr className="border-b border-black">
                        <td
                          className={`text-sm border-r border-b border-black font-bold capitalize text-center`}
                          id="color"
                        >
                          {fabric?.fabricColorId?.colorName}
                        </td>
                        {/* <td
                          className={`text-center border-b border-black capitalize`}
                        >
                          {fabric?.PM}
                        </td> */}
                        {fabric?.niddles?.map((niddle: any, index: any) => {
                          return niddle?.designCodeId2?.designCode ? (
                            <td
                              key={index}
                              className="text-center uppercase border-b border-l border-black font-bold"
                            >
                              <div className="border-b border-black">
                                {niddle?.designCodeId1?.designCode}
                              </div>
                              <div>{niddle?.designCodeId2?.designCode}</div>
                            </td>
                          ) : (
                            <td
                              key={index}
                              className="text-center uppercase h-11 border-b border-l border-black font-bold"
                            >
                              {niddle?.designCodeId1?.designCode}
                            </td>
                          );
                        })}
                        {fabric?.niddles?.length > 0
                          ? Array(9 - Number(fabric?.niddles?.length))
                              ?.fill("-")
                              ?.map((row: any, index: number) => {
                                return (
                                  <td
                                    key={index}
                                    className="text-center h-11 border-b border-l border-black font-bold"
                                  >
                                    &nbsp;
                                  </td>
                                );
                              })
                          : null}
                      </tr>
                    );
                  })}
                  {sampleCard?.fabricColors?.length > 0 &&
                  Number(sampleCard?.fabricColors?.length) < 3
                    ? Array(3 - Number(sampleCard?.fabricColors?.length))
                        ?.fill("-")
                        ?.map((fabric: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="border-r border-black font-bold">
                                &nbsp;
                              </td>
                              <td className={`border-black capitalize`}>
                                &nbsp;
                              </td>
                              {Array(9)
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  const isLastChild = index === 8; // Assuming 0-based index
                                  const borderClass = isLastChild
                                    ? ""
                                    : "border-l border-black";
                                  return (
                                    <td
                                      key={index}
                                      className={borderClass}
                                    ></td>
                                  );
                                })}
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </table>
            </>
          )}
          <div className={`text-xs p-1 grid grid-cols-8`}>
            <div className="col-span-6">
              <table className="w-full border border-collapse bg-white text-center">
                <thead className=" divide-black border-t border-x border-black text-sm">
                  <tr>
                    <th
                      scope="col"
                      className="border-r w-20 border-black capitalize py-1"
                    >
                      MC No.
                    </th>
                    <th
                      scope="col"
                      className="border-r border-black capitalize py-1"
                    >
                      Item
                    </th>
                    <th
                      scope="col"
                      className="border-r border-black capitalize py-1"
                    >
                      Area
                    </th>
                    <th
                      scope="col"
                      className="border-r w-24 border-black capitalize py-1"
                    >
                      Mc. Design
                    </th>
                    <th
                      scope="col"
                      className="border-r border-black capitalize py-1"
                    >
                      stitch
                    </th>
                    <th
                      scope="col"
                      className="border-r border-black capitalize py-1"
                    >
                      Head
                    </th>
                    {/* <th
                      scope="col"
                      className="border-r border-black capitalize py-1"
                    >
                      frame
                    </th> */}
                    <th
                      scope="col"
                      className="border-r w-20 border-black capitalize py-1"
                    >
                      Cutter
                    </th>
                    {/* <th
                      scope="col"
                      className="border-r border-black capitalize py-1"
                    >
                      prod.
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y text-center divide-black border border-black text-sm">
                  {sampleCard?.clothes?.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="text-center">
                        <td className="border-r border-black font-normal text-gray-900 text-left capitalize py-1"></td>

                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item?.item?.name}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item.area}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-left capitalize py-1">
                          #
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item.stitch}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item.head}
                        </td>
                        {/* <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item.frame}
                        </td> */}
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item.CC}
                        </td>
                        {/* <td className="border-r border-black font-normal text-gray-900 text-center capitalize py-1">
                          {item.production}
                        </td> */}
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      colSpan={4}
                      className=" border-black px-2 font-bold text-gray-900 border capitalize py-1"
                    ></td>
                    <td className="border-black font-bold text-gray-900 border capitalize py-1">
                      Total : {totalStiching ? totalStiching : 0}
                    </td>
                    <td></td>
                    <td className=" border-black px-2 font-bold text-gray-900 border capitalize py-1">
                      Total :{totalCutter ? totalCutter : 0}
                    </td>
                    {/* <td className=" border-black px-2 font-bold text-gray-900 border capitalize py-1">
                      Total : {sampleCard?.totalProduction}
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="grid grid-cols-7 col-span-2 row-span-2">
              <div className="col-span-7 text-center z-30 ">
                <span className="bg-white px-1">{sampleCard?.image?.top}</span>
              </div>
              <div className="flex items-center justify-center"></div>
              <div className="col-span-5">
                <div className="flex justify-center items-center">
                  {image ? (
                    <img
                      src={image}
                      className="object-contain max-h-48 min-h-48 min-w-44 max-w-44"
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-center ml-1 z-30">
                <span className="bg-white px-1">
                  {sampleCard?.image?.right}
                </span>
              </div>
              <div className="col-span-7 text-center z-30">
                <span className="bg-white px-1">
                  {sampleCard?.image?.bottom}
                </span>
              </div>
              <div className="col-span-7 text-center z-30">
                <span className="bg-white px-1 text-[14px]">
                  Note: {sampleCard?.notes}
                </span>
              </div>
            </div>
            <div className="col-span-6 grid grid-cols-6 gap-6 mt-2 border-2 border-black h-[62px]">
              <div className="col-span-5">
                <table className=" w-full border-collapse bg-white text-center">
                  <thead className="divide-black  text-sm border-r border-black">
                    <tr>
                      <th
                        style={{ width: "22%" }}
                        scope="col"
                        className="px-6 border-r border-black capitalize py-1"
                      >
                        Fabric
                      </th>
                      <th
                        style={{ width: "25%" }}
                        scope="col"
                        className="px-2 border-r border-black capitalize py-1"
                      >
                        Fabric Party
                      </th>
                      <th
                        style={{ width: "15%" }}
                        scope="col"
                        className="px-6 border-r border-black capitalize py-1"
                      >
                        Cut
                      </th>
                      <th
                        style={{ width: "15%" }}
                        scope="col"
                        className="px-2 border-r  border-black capitalize py-1"
                      >
                        Height
                      </th>
                      <th
                        scope="col"
                        className="px-2 border-r  border-black capitalize py-1"
                      >
                        Foil color
                      </th>
                    </tr>
                    <tr>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitaliz  border-t">
                        {sampleCard?.clothe?.name}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize  border-t">
                        {sampleCard?.clotheParty?.name}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize border-t">
                        {getFormattedNumber(sampleCard?.CM)}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize  border-t">
                        {sampleCard?.height}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize  border-t">
                        {sampleCard?.work?.foilColor?.name}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="col-span-1">
                <div className="grid grid-cols-2 w-30 m-auto">
                  <div
                    className={`text-center font-bold ${
                      sampleCard?.spacing?.top ? "" : "mt-[19px]"
                    }`}
                  >
                    {sampleCard?.spacing?.top}
                  </div>
                  <div></div>
                  <div className="p-2 border-2 border-black"></div>
                  <div className="ml-1 font-bold">
                    {sampleCard?.spacing?.right}
                  </div>
                  <div className="text-center font-bold">
                    {sampleCard?.spacing?.bottom}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const [colValue, setColValue]: any = useState();

  useEffect(() => {
    const colSpans: number[] = [];
    sampleCard?.fabricColors?.map((fabrics: any) => {
      fabrics?.niddles?.map((item: any, index: number) => {
        const colSpan = item?.designCodeId2 ? 2 : 1;
        colSpans.push(colSpan);
      });
    });
    setColValue(colSpans);
  }, [sampleCard]);
  function BackPage() {
    return (
      <div ref={exportBackRef} className="overflow-hidden p-7">
        <div className="overflow-auto text-xs border border-black">
          {sampleCard?.needlesHead?.length > 9 ? (
            <>
              <table className="w-full border-collapse bg-white text-center">
                <thead className="capitalize border border-black">
                  <tr>
                    <td
                      className="text-center border-l border-b font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[0]}
                    >
                      N1
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[1]}
                    >
                      N2
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[2]}
                    >
                      N3
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[3]}
                    >
                      N4
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[4]}
                    >
                      N5
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[5]}
                    >
                      N6
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[6]}
                    >
                      N7
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[7]}
                    >
                      N8
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[8]}
                    >
                      N9
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[9]}
                    >
                      N10
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[10]}
                    >
                      N11
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[11]}
                    >
                      N12
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[12]}
                    >
                      N13
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[13]}
                    >
                      N14
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[14]}
                    >
                      N15
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[15]}
                    >
                      N16
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[16]}
                    >
                      N17
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black py-1 w-20"
                      colSpan={colValue[17]}
                    >
                      N18
                    </td>
                  </tr>
                </thead>
                <tbody className="border-b border-black">
                  <tr>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      Thread color
                    </td>
                    {sampleCard?.fabricColors?.map((fabric: any) => {
                      return (
                        <>
                          {fabric?.niddles?.map((niddle: any, index: any) => {
                            return niddle?.designCodeId2?.designCode ? (
                              <>
                                <td
                                  key={index}
                                  className="text-center uppercase border-b border-l border-black font-bold w-20"
                                >
                                  <div className="">
                                    {niddle?.designCodeId1?.designCode}
                                  </div>
                                </td>
                                <td
                                  key={index}
                                  className="text-center uppercase border-b border-l border-black font-bold w-20"
                                >
                                  <div>{niddle?.designCodeId2?.designCode}</div>
                                </td>
                              </>
                            ) : (
                              <td
                                key={index}
                                className="text-center uppercase h-11 border-b border-r border-l border-black font-bold w-20"
                              >
                                {niddle?.designCodeId1?.designCode}
                              </td>
                            );
                          })}
                          {fabric?.niddles?.length > 0
                            ? Array(18 - Number(fabric?.niddles?.length))
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center border-b border-l border-t border-r border-black font-bold"
                                    ></td>
                                  );
                                })
                            : null}
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      TotalWeight Thread
                    </td>
                    {sampleCard?.needlesHead?.map((item: any, index: any) => {
                      return item?.threadType === "1+1" ||
                        item?.threadType === "2+2" ? (
                        <>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalWeight}
                          </td>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalWeight1 ? item?.totalWeight1 : "-"}
                          </td>
                        </>
                      ) : (
                        <td
                          key={index}
                          className="text-center uppercase border-b border-l border-black font-bold w-20"
                        >
                          {item?.totalWeight}
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(18 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-t border-r border-black font-bold"
                              >
                                &nbsp;
                              </td>
                            );
                          })
                      : null}
                  </tr>
                  <tr>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      Used Thread
                    </td>
                    {sampleCard?.needlesHead?.map((item: any, index: any) => {
                      return item?.threadType === "1+1" ||
                        item?.threadType === "2+2" ? (
                        <>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalUsed}
                          </td>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalUsed1 ? item?.totalUsed1 : "-"}
                          </td>
                        </>
                      ) : (
                        <td
                          key={index}
                          className="text-center uppercase border-b border-l border-black font-bold w-20"
                        >
                          {item?.totalUsed}
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(18 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-t border-r border-black font-bold"
                              >
                                &nbsp;
                              </td>
                            );
                          })
                      : null}
                  </tr>
                  <tr>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      TotalUsed Thread
                    </td>
                    {sampleCard?.needlesHead?.map((item: any, index: any) => {
                      return item?.threadType === "1+1" ||
                        item?.threadType === "2+2" ? (
                        <>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {Math.floor(item?.totalWeight - item?.totalUsed)}
                          </td>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {Math.floor(item?.totalWeight1 - item?.totalUsed1)}
                          </td>
                        </>
                      ) : (
                        <td
                          key={index}
                          className="text-center uppercase border-b border-l border-black font-bold w-20"
                        >
                          {Math.floor(item?.totalWeight - item?.totalUsed)}
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(18 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-t border-r border-black font-bold"
                              >
                                &nbsp;
                              </td>
                            );
                          })
                      : null}
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="w-full border-collapse bg-white text-center">
                <thead className="capitalize border border-black">
                  <tr className="border-y-2 border-black">
                    <td className="text-center border-l font-bold border-y border-black w-20"></td>
                    <td
                      className="text-center border-l border-b font-bold border-y border-black w-20"
                      colSpan={colValue[0]}
                    >
                      N1
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[1]}
                    >
                      N2
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[2]}
                    >
                      N3
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[3]}
                    >
                      N4
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[4]}
                    >
                      N5
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[5]}
                    >
                      N6
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[6]}
                    >
                      N7
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[7]}
                    >
                      N8
                    </td>
                    <td
                      className="text-center border-l font-bold border-y border-black w-20"
                      colSpan={colValue[8]}
                    >
                      N9
                    </td>
                  </tr>
                </thead>
                <tbody className="text-xs leading-5 border-b border-black">
                  <tr className="border-r border-black">
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      Thread color
                    </td>
                    {sampleCard?.fabricColors?.map((fabric: any) => {
                      return (
                        <>
                          {fabric?.niddles?.map((niddle: any, index: any) => {
                            return niddle?.designCodeId2?.designCode ? (
                              <>
                                <td
                                  key={index}
                                  className="text-center uppercase border-b border-l border-black font-bold w-20"
                                >
                                  <div className="">
                                    {niddle?.designCodeId1?.designCode}
                                  </div>
                                </td>
                                <td
                                  key={index}
                                  className="text-center uppercase border-b border-l border-black font-bold w-20"
                                >
                                  <div>{niddle?.designCodeId2?.designCode}</div>
                                </td>
                              </>
                            ) : (
                              <td
                                key={index}
                                className="text-center uppercase h-11 border-b border-r border-l border-black font-bold w-20"
                              >
                                {niddle?.designCodeId1?.designCode}
                              </td>
                            );
                          })}
                          {fabric?.niddles?.length > 0
                            ? Array(9 - Number(fabric?.niddles?.length))
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center border-b border-l border-t border-r border-black font-bold"
                                    ></td>
                                  );
                                })
                            : null}
                        </>
                      );
                    })}
                  </tr>
                  <tr className="border-r border-black">
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      TotalWeight Thread
                    </td>
                    {sampleCard?.needlesHead?.map((item: any, index: any) => {
                      return item?.threadType === "1+1" ||
                        item?.threadType === "2+2" ? (
                        <>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalWeight}
                          </td>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalWeight1 ? item?.totalWeight1 : "-"}
                          </td>
                        </>
                      ) : (
                        <td
                          key={index}
                          className="text-center uppercase border-b border-l border-black font-bold w-20"
                        >
                          {item?.totalWeight}
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(9 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-t border-r border-black font-bold"
                              >
                                &nbsp;
                              </td>
                            );
                          })
                      : null}
                  </tr>
                  <tr className="border-r border-black h-10">
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      Used Thread
                    </td>
                    {sampleCard?.needlesHead?.map((item: any, index: any) => {
                      return item?.threadType === "1+1" ||
                        item?.threadType === "2+2" ? (
                        <>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalUsed}
                          </td>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {item?.totalUsed1 ? item?.totalUsed1 : "-"}
                          </td>
                        </>
                      ) : (
                        <td
                          key={index}
                          className="text-center uppercase border-b border-l border-black font-bold w-20"
                        >
                          {item?.totalUsed}
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(9 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-t border-r border-black font-bold"
                              >
                                &nbsp;
                              </td>
                            );
                          })
                      : null}
                  </tr>
                  <tr className="border-r border-black">
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      TotalUsed Thread
                    </td>
                    {sampleCard?.needlesHead?.map((item: any, index: any) => {
                      return item?.threadType === "1+1" ||
                        item?.threadType === "2+2" ? (
                        <>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {Math.floor(item?.totalWeight - item?.totalUsed)}
                          </td>
                          <td
                            key={index}
                            className="text-center uppercase border-b border-l border-black font-bold w-20"
                          >
                            {Math.floor(item?.totalWeight1 - item?.totalUsed1)}
                          </td>
                        </>
                      ) : (
                        <td
                          key={index}
                          className="text-center uppercase border-b border-l border-black font-bold w-20"
                        >
                          {Math.floor(item?.totalWeight - item?.totalUsed)}
                        </td>
                      );
                    })}
                    {sampleCard?.needlesHead?.length > 0
                      ? Array(9 - Number(sampleCard?.needlesHead?.length))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-t border-r border-black font-bold"
                              >
                                &nbsp;
                              </td>
                            );
                          })
                      : null}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      <Modal
        open={props.showSamplePreview}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        onEscKeyDown={() => props.setShowSamplePreview(false)}
        onOverlayClick={() => props.setShowSamplePreview(false)}
        aria-labelledby="sample-card-pdf"
        aria-describedby="sample-card-pdf-description"
        animationDuration={400}
        onClose={() => props.setShowSamplePreview(false)}
      >
        <div className="overflow-auto">
          <div className="text-center">
            <div className="bg-white border rounded-lg text-left shadow-xl">
              {/* <div className="w-full rounded-xl overflow-hidden p-1">
                <div className="border rounded-xl md:rounded-full p-1.5 bg-gray-50 shadow-sm">
                  <ul
                    className="flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row px-2"
                    role="tablist"
                  >
                    <li
                      className={`${
                        toggleState === 1
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(1)}
                      >
                        front page
                      </button>
                    </li>
                    <li
                      className={`${
                        toggleState === 2
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(2)}
                      >
                        back page
                      </button>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div
                className={`transition-opacity duration-150 ease-linear ${
                  toggleState === 1 ? "opacity-100" : "opacity-0 hidden"
                }`}
              >
                <FrontPage />
              </div>
              <div
                className={`transition-opacity duration-150 ease-linear ${
                  toggleState === 2 ? "opacity-100" : "opacity-0 hidden"
                }`}
              >
                <BackPage />
              </div>

              <div className="m-3 flex flex-wrap">
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                        disabled={loadingImage}
                      >
                        {loadingImage ? (
                          <>
                            <span>Loading</span>
                            <i className="fas fa-spinner animate-spin ml-2"></i>
                          </>
                        ) : (
                          <>
                            <span>Print</span>
                            <i className="fas fa-file-download ml-2"></i>
                          </>
                        )}
                      </button>
                    )}
                    content={() => exportRef.current}
                    documentTitle={props.pageName}
                  />
                </div>
                {/* <div>
                  <ReactToPrint
                    trigger={() => (
                      <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                        Back Print <i className="fas fa-file-download"></i>
                      </button>
                    )}
                    content={() => exportBackRef.current}
                    documentTitle={props.pageName + "-Back"}
                  />
                </div> */}
                <div>
                  <button
                    className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                    onClick={() => props.setShowSamplePreview(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
