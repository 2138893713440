import Modal from "react-responsive-modal";
import { Link, useNavigate } from "react-router-dom";
import { useLazyMatchingCardQuery } from "../../servicesRtkQuery/publicApi";
import { useEffect } from "react";

export const TableAction = (props: any) => {
  const {
    header,
    isModalOpen,
    setIsModalOpen,
    selectedRow,
    isCopy,
    isPreview,
    openInvoicePreview,
    handleDelete,
    route,
  } = props;
  const Close = (
    <i className="fas fa-times bg-black text-white rounded-full px-2 py-1.5"></i>
  );
  const navigate = useNavigate();
  
  return (
    <div>
      <Modal
        open={isModalOpen}
        closeIcon={Close}
        onClose={() => {
          setIsModalOpen(false);
        }}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onOverlayClick={() => {
          setIsModalOpen(false);
        }}
        onEscKeyDown={() => {
          setIsModalOpen(false);
        }}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          modal: { background: "#e9ecef" },
          overlay: {
            height: "auto",
          },
        }}
      >
        <div className="p-2 overflow-hidden mx-6">
          <div className="font-bold text-center">
            Selected Record : {header}
          </div>
          <div className="flex justify-center">
            <ul className="float-left min-w-max list-none rounded-lg border-none bg-clip-padding text-left text-base p-1 flex gap-2">
              {isCopy ? (
                <li title="Copy">
                  <button
                    type="button"
                    className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none hover:text-yellow-500"
                    onClick={() => {
                      localStorage.setItem("copy", selectedRow?._id);
                      // copyTrigger(selectedRow?._id)
                      navigate({
                        pathname: `/${route}/copy`,
                      });
                    }}
                  >
                    <i className="fas fa-copy text-lg -ml-px" title="Copy"></i>
                  </button>
                </li>
              ) : null}
              <li title="Edit">
                <button
                  type="button"
                  className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none hover:text-lime-500"
                  onClick={() => {
                    navigate({
                      pathname: `/${route}/${selectedRow._id}`,
                    });
                  }}
                >
                  <i className="fas fa-edit text-lg -ml-px" title="Edit"></i>
                </button>
              </li>
              {route !== "matchingcarddashboardstock/update" &&
                (isPreview ? (
                  <li title="Download">
                    <button
                      type="button"
                      className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none hover:text-cyan-500"
                      onClick={() => {
                        openInvoicePreview(selectedRow);
                      }}
                    >
                      <i
                        className="fas fa-file-download text-lg -ml-px"
                        title="Download"
                      ></i>
                    </button>
                  </li>
                ) : null)}
              <li title="Delete">
                <button
                  type="button"
                  className="flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none hover:text-red-500"
                  onClick={() => {
                    handleDelete(selectedRow);
                    setIsModalOpen(false);
                  }}
                >
                  <i
                    className="fas fa-trash-alt text-lg -ml-px"
                    title="Delete"
                  ></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};
