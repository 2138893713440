import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useAddDescMutation, useLazyDescQuery, useUpdateDescMutation } from '../../servicesRtkQuery/publicApi'
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Swal from 'sweetalert2';
import { employeeValidation } from '../../utils/validation';
import TextBox from '../../components/form/TextBox';
import customStyles, { capitalize } from '../../utils/globalFunctions';
import { ERROR_MESSAGE_TIMEOUT } from '../../utils/constants';
import moment from 'moment';
import { SettingTableAction } from '../../components/modal/SettingTableAction';

const Description = () => {
    const [trigger, result] = useLazyDescQuery()
    const { isSuccess, isFetching, isError, error } = result
    const [tableData, setTableData]: any = useState<any>([])

    const [totalRows, setTotalRows]: any = useState(0);
    const [perPage, setPerPage]: any = useState(25);
    const [page, setPage]: any = useState(1);

    const [newData, setNewData]: any = useState({
        name: ""
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState("");
    const Close = <i className='fas fa-times bg-black text-white rounded-full px-2 py-1.5'></i>
    useEffect(() => {
        if (!filterText) {
            trigger({ page: page, limit: perPage });
        }
    }, [page, perPage]);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setTotalRows(result?.data?.data?.count)
            setTableData(result?.data?.data?.description)
        }
    }, [isSuccess, isFetching])

    function editFunction(row: any) {
        setIsModalOpen(true)
        setUpdateRows(row)
        setIsAdd(false)
        let updatedData = {
            name: row.name,
        }
        setNewData(updatedData)
    }
    const TableAction = (props: any) => {
        const row = props.row
        return (
            <>
                <div className="relative" data-te-dropdown-ref data-te-dropdown-position="dropend" >
                    <button className="flex items-center whitespace-nowrap rounded-full bg-themeColor px-3 py-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-themeColor/60 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none" type="button" data-te-dropdown-toggle-ref aria-expanded="false" title="Action">
                        <i className='fa fa-ellipsis-v'></i>
                    </button>
                    <ul className="absolute z-[1000] float-left hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-clip-padding text-left text-base p-1 [&[data-te-dropdown-show]]:block md:[&[data-te-dropdown-show]]:flex gap-2"
                        aria-labelledby="action"
                        data-te-dropdown-menu-ref>
                        <li>
                            <button className='m-2 flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none' type='button' onClick={() => {
                                setIsModalOpen(true)
                                setUpdateRows(row)
                                setIsAdd(false)
                                let updatedData = {
                                    name: row.name,
                                }
                                setNewData(updatedData)
                            }}>
                                <i className='fas fa-pen text-base hover:text-green-600'></i>
                            </button>
                        </li>
                        <li>
                            <button className='m-2 flex justify-center items-center w-[52px] h-[52px] bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none' onClick={() => {
                                handleDelete(row)
                            }}>
                                <i className='fas fa-trash text-base hover:text-red-600'></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const columns: any = [
        {
            name: "Description Name",
            selector: (row: { [x: string]: any; }) => row?.name,
            sortable: true
        },
        {
            name: "Created At",
            selector: (row: { [x: string]: any; }) => moment(row?.createAt).format("DD-MM-YYYY"),
            sortable: true
        }
    ];
    const [addTrigger, addResult] = useAddDescMutation()
    const [updateTrigger, updateResult] = useUpdateDescMutation()
    const [updateRow, setUpdateRows] = useState<any>();

    const handleUpdate = () => {
        let updatedData = {
            name: capitalize(newData.name.trim()),
        }
        const result: any = employeeValidation(updatedData)
        if (result === true) {
            let id = updateRow._id
            updateTrigger({
                payload: updatedData,
                query: {
                    dId: id
                }
            }).then((data: any) => {
                if (data.error) {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: data.error.data.message,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: ERROR_MESSAGE_TIMEOUT,
                        timerProgressBar: true
                    })
                }
                else {
                    setIsModalOpen(false)
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Description has been Successfully Updated!",
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    })
                    if (!filterText) {
                        trigger({ page: page, limit: perPage });
                    }
                }
            })

        }
        else {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.errorMessage,
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }

    }

    const handleDelete = (row: any) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let id = row._id
                let updatedData = {
                    name: row.name,
                    status: false
                }
                updateTrigger({
                    payload: updatedData,
                    query: {
                        dId: id
                    }
                }).then((data: any) => {
                    if (data.error) {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: data.error.data.message,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: ERROR_MESSAGE_TIMEOUT,
                            timerProgressBar: true
                        })
                    }
                    else {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: "Description has been deleted!",
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        })
                        if (!filterText) {
                            trigger({ page: page, limit: perPage });
                        }
                    }
                })
            }
        });

    }

    const onHandleChange = (e: any) => {
        setNewData({
            ...newData,
            [e.target.name]: e.target.value,
        });

    };

    const handleSubmit = (e: any) => {
        let updatedData = {
            name: capitalize(newData.name.trim()),
        }
        const result: any = employeeValidation(updatedData)
        if (result === true) {
            addTrigger(updatedData).then((data: any) => {
                if (data.error) {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: data.error.data.message,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: ERROR_MESSAGE_TIMEOUT,
                        timerProgressBar: true
                    })
                }
                else {
                    setIsModalOpen(false)
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Description has been Successfully Added!",
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    })
                    if (!filterText) {
                        trigger({ page: page, limit: perPage });
                    }
                }
            })
        }
        else {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: result.errorMessage,
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }

    const modalClose = () => {
        setIsModalOpen(false)
    }
    const [isAdd, setIsAdd] = useState<any>(false)
    // filter search
    const [filterText, setFilterText] = useState('');
    useEffect(() => {
        if (filterText) {
            let payload = {
                name: filterText,
                page: page,
                limit: perPage
            }
            trigger(payload)
        }
        if (!filterText) {
            trigger({ page: page, limit: perPage })
        }
    }, [filterText, page, perPage])
    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            trigger({ page: page, limit: perPage })
        }
    };

    // pagination 
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const handlePerRowsChange = async (newPerPage: any) => {
        setPerPage(newPerPage);
    }
    return (
        <div className="mb-6 break-words border-0 border-transparent border-solid bg-clip-border">
            <div className="px-3 py-4 flex justify-between items-center">
                <button className="px-4 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                    onClick={() => {
                        setIsModalOpen(true);
                        setIsAdd(true);
                        let updatedData = {
                            name: ""
                        }
                        setNewData(updatedData)
                    }}>
                    <i className='fas fa-hat-cowboy'></i><span className='ml-2'>Add Description</span>
                </button>
                <div className='relative w-[90px] md:w-max'>
                    <TextBox
                        id="search"
                        type="text"
                        placeholder=" "
                        label="Search"
                        aria-label="Search Input"
                        value={filterText}
                        onChange={(e: any) => {
                            setFilterText(e?.target?.value)
                        }}
                    />
                    {filterText ?
                        <i className='fas fa-times-circle text-gray-600 cursor-pointer right-3 top-3 absolute' onClick={handleClear}></i>
                        : null
                    }
                </div>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
                <div className="px-5 overflow-x-auto">
                    <DataTable
                        title="Description"
                        columns={columns}
                        data={tableData}
                        fixedHeader={true}
                        defaultSortFieldId="id"
                        defaultSortAsc={false}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                        paginationDefaultPage={page}
                        paginationPerPage={perPage}
                        striped={true}
                        responsive={true}
                        highlightOnHover
                        persistTableHead
                        customStyles={customStyles}
                        onRowClicked={(e: any) => {
                            setSelectedRow(e);
                            setIsActionModalOpen(true)
                        }}
                    />
                </div>
            </div>

            <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto rounded">
                    {isModalOpen ?
                        <Modal
                            open={isModalOpen}
                            closeIcon={Close}
                            onClose={modalClose}
                            center
                            onEscKeyDown={() => setIsModalOpen(false)}
                            onOverlayClick={() => setIsModalOpen(false)}
                            classNames={{
                                modal: "rounded-xl"
                            }}
                            styles={{
                                overlay: {
                                    height: "auto",
                                },
                            }}
                        >
                            <form className="w-100 p-2">
                                <div className="flex flex-wrap mb-6">
                                    <div className="w-full md:w-4/5 px-3">
                                        <TextBox name="name" htmlFor="name" id="name" type="text" placeholder=" " className="text-lg" label="Name" value={newData?.name} onChange={(e: any) => onHandleChange(e)} />
                                    </div>
                                </div>
                                {
                                    isAdd ?
                                        <button className="ml-4 px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                                            onClick={handleSubmit}>
                                            Add
                                        </button>
                                        :
                                        <button className="ml-4 px-3 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
                                            onClick={handleUpdate}>
                                            Update
                                        </button>
                                }
                            </form>

                        </Modal>
                        : null}
                </div>
            </div>

            {isActionModalOpen ?
                <SettingTableAction
                    header={selectedRow?.name}
                    isActionModalOpen={isActionModalOpen}
                    setIsActionModalOpen={setIsActionModalOpen}
                    editFunction={editFunction}
                    selectedRow={selectedRow}
                    handleDelete={handleDelete}
                />
                : null}
        </div>
    )
}

export default Description
