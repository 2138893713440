import { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  useLazyGetMatchingCardQuery,
  useLazyJobWorkQuery,
  useUpdateJobWorkMutation,
  useLazyChallanCountQuery,
  useLazyGetJobWorkByKeywordQuery,
} from "../../servicesRtkQuery/publicApi";
import Select from "react-select";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { setJobWork } from "../../redux/jobWorkSlice";
import Swal from "sweetalert2";
import TextBox from "../../components/form/TextBox";
import CountUp from "react-countup";
import DataTableLoader from "../../components/page/DataTableLoader";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import customStyles from "../../utils/globalFunctions";
import { JobWorkPreview } from "../../components/pdf/challan/jobWorkPreview";
import { TableAction } from "../../components/modal/TableAction";
import { JobworkDataPdf } from "../../components/pdf/dashboard/jobworkDataPdf";

export default function JobworkDashboard() {
  const dispatch = useDispatch();
  const [trigger, result] = useLazyJobWorkQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [matchingTrigger, matchingResult] = useLazyGetMatchingCardQuery();
  const {
    isSuccess: isMatchingSuccess,
    isFetching: isMatchingFetching,
    isError: isMatchingError,
    error: matchingError,
  } = matchingResult;

  const [cardTrigger, cardResult] = useLazyGetJobWorkByKeywordQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;

  const [countTrigger, countResult] = useLazyChallanCountQuery();
  const {
    isSuccess: isCountSuccess,
    isFetching: isCountFetching,
    isError: isCountError,
    error: countError,
  } = countResult;

  const [cardData, setCardData]: any = useState<any>([]);
  const navigate = useNavigate();
  const [updateCard] = useUpdateJobWorkMutation();
  const [showPreview, setShowPreview] = useState(false);
  const [singleCard, setSingleCard]: any = useState<any>();

  const jobWorkData = useSelector(
    (state: any) => state.jobWorkSlice.jobWorkData
  );

  // Counters
  const [allCount, setAllCount]: any = useState(0);
  const [dressCount, setDressCount]: any = useState(0);
  const [sareeCount, setSareeCount]: any = useState(0);
  const [lumpCount, setLumpCount]: any = useState(0);

  // Filtering states
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isExportData, setIsExportData] = useState(false);

  //Pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const urlData: any = useLocation().search;
  const dashboardType = new URLSearchParams(urlData).get("type");

  const [type, setType]: any = useState(dashboardType ? dashboardType : "all");
  const [isSearching, setIsSearching]: any = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");

  //#region API triggers
  useEffect(() => {
    countTrigger();
    if (jobWorkData?.jobWorks) {
      setCardData(jobWorkData?.jobWorks);
    } else {
      trigger({
        page: page,
        limit: perPage,
      });
    }
  }, []);

  const globalTriggers = (page: Number, perPage: Number) => {
    if (isSearching) {
      let searchData: any = {
        page: page,
        limit: perPage,
      };
      Object.keys(search).map((key: any) => {
        if (search[key] && search[key].text !== "") {
          searchData[key] = search[key]?.text;
        }
      });
      if (Object.keys(searchData).length > 2) {
        cardTrigger(searchData);
      }
    } else {
      trigger({
        page: page,
        limit: perPage,
      });
    }
  };

  useEffect(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    globalTriggers(page, perPage);
  }, [type]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      if (!isSearching) {
        dispatch(setJobWork(result?.data?.data));
      }
      setCardData(result?.data?.data?.jobWorks);
      setTotalRows(result?.data?.data?.count);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCardData(cardResult?.data?.data?.jobWorks);
      // if (!isSearching) {
      //   dispatch(setJobWork(cardResult?.data?.data));
      // }
      setTotalRows(cardResult?.data?.data?.count);
    }
  }, [isCardSuccess, isCardFetching]);

  useEffect(() => {
    if (isMatchingSuccess && !isMatchingFetching) {
      setSingleCard(matchingResult?.data?.data?.jobWorks?.[0]);
    }
  }, [isMatchingSuccess, isMatchingFetching]);
  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setSingleCard(cardResult?.data?.data?.jobWorks?.[0]);
    }
  }, [isCardSuccess, isCardFetching]);

  useEffect(() => {
    if (isCountSuccess && !isCountFetching) {
      setAllCount(countResult?.data?.data?.job);
      setDressCount(countResult?.data?.data?.dress);
      setSareeCount(countResult?.data?.data?.saree);
      setLumpCount(countResult?.data?.data?.lump);
    }
  }, [isCountSuccess, isCountFetching]);
  //#endregion

  //#region Field Search section
  const [search, setSearch]: any = useState({
    cardNo: {
      text: "",
      status: false,
    },
    partyName: {
      text: "",
      status: false,
    },
    designNo: {
      text: "",
      status: false,
    },
    jobNo: {
      text: "",
      status: false,
    },
    name: {
      text: "",
      status: false,
    },
    address: {
      text: "",
      status: false,
    },
    PCNo: {
      text: "",
      status: false,
    },
    itemName: {
      text: "",
      status: false,
    },
    date: {
      text: "",
      status: false,
    },
    fromDate: {
      text: "",
      status: false,
    },
    fromreceiveDate: {
      text: "",
      status: false,
    },
    toreceiveDate: {
      text: "",
      status: false,
    },
    toDate: {
      text: "",
      status: false,
    },
    plain: {
      text: "",
      status: false,
    },
    clothe: {
      text: "",
      status: false,
    },
    day: {
      text: "",
      status: false,
    },
    receiveDate: {
      text: "",
      status: false,
    },
    short: {
      text: "",
      status: false,
    },
    // PTPM: {
    //   text: "",
    //   status: false,
    // },
    // RTPM: {
    //   text: "",
    //   status: false,
    // },
    // TPM: {
    //   text: "",
    //   status: false,
    // },
    // TRP: {
    //   text: "",
    //   status: false,
    // },
    rate: {
      text: "",
      status: false,
    },
    totalAmount: {
      text: "",
      status: false,
    },
  });

  const onSearch = (key: string) => {
    let searchData = search;
    setIsSearching(true);
    Object.keys(searchData).forEach((searchkey: any, value: any) => {
      let item = searchData[searchkey];
      item["status"] = false;
      return item;
    });
    setSearch({
      ...searchData,
      [key]: {
        ...search[key],
        status: !search[key]["status"],
      },
    });
  };
  useEffect(() => {
    let searchData: any = {};
    Object.keys(search).map((key: any) => {
      if (key === "receiveDate" && search[key].text === "") {
        cardTrigger(searchData);
      } else if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
      setTotalRows(result?.data?.data?.count);
    });
    if (Object.keys(searchData).length !== 0) {
      searchData = {
        ...searchData,
        type: searchData?.type,
      };

      setIsSearching(true);
      cardTrigger(searchData);
    } else {
      setIsSearching(false);

      globalTriggers(page, perPage);
    }
  }, [search]);

  const searchComponent = (key: string, label: string) => {
    return key === "receiveDate" ? (
      <div className={`text-left`}>
        {search[key]?.status ? (
          <div>
            <input
              type="checkbox"
              name="receiveDate"
              onChange={(e) => {
                e.target.checked
                  ? setSearch({
                      ...search,
                      receiveDate: {
                        text: null,
                        status: true,
                      },
                    })
                  : setSearch({
                      ...search,
                      receiveDate: {
                        text: "",
                        status: false,
                      },
                    });
              }}
              id=""
            />
            <label>Show Empty</label>
          </div>
        ) : (
          <div className="mb-2">
            <div
              className="mb-1 cursor-pointer"
              onClick={() => {
                onSearch(key);
              }}
            >
              {label}
              {/* <i className='fas fa-search ml-2 text-gray-600'></i> */}
            </div>
            {search[key]?.text !== "" && (
              <div className="flex justify-center items-center m-1 font-medium py-1 px-3 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                <div className="text-xs font-normal leading-none max-w-full flex-initial px-4">
                  {search[key]?.text}
                </div>
                <div className="flex flex-auto flex-row-reverse">
                  <i
                    className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                    onClick={() => {
                      setSearch({
                        ...search,
                        [key]: {
                          text: "",
                          status: false,
                        },
                      });
                      setCardData(result?.data?.data?.jobWorks);
                    }}
                  ></i>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    ) : (
      <div className={`text-left`}>
        {search[key]?.status ? (
          <TextBox
            type={"text"}
            placeholder={label}
            autoFocus
            className="font-light"
            value={search[key].text}
            onChange={(e: any) => {
              if (e.target.value.length === 0) {
                setSearch({
                  ...search,
                  [key]: {
                    text: "",
                    status: false,
                  },
                });
                setCardData(result?.data?.data?.jobWorks);
              } else {
                setSearch({
                  ...search,
                  [key]: {
                    text: e.target.value,
                    status: true,
                  },
                });
              }
            }}
          />
        ) : (
          <div className="mb-2">
            <div
              className="mb-1 cursor-pointer"
              onClick={() => {
                onSearch(key);
              }}
            >
              {label}
              {/* <i className='fas fa-search ml-2 text-gray-600'></i> */}
            </div>
            {search[key]?.text !== "" && (
              <div className="flex justify-center items-center m-1 font-medium py-1 px-3 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                <div className="text-xs font-normal leading-none max-w-full flex-initial px-4">
                  {search[key]?.text}
                </div>
                <div className="flex flex-auto flex-row-reverse">
                  <i
                    className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                    onClick={() => {
                      setSearch({
                        ...search,
                        [key]: {
                          text: "",
                          status: false,
                        },
                      });
                      setCardData(result?.data?.data?.jobWorks);
                    }}
                  ></i>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  //#endregion

  const columns: any = [
    {
      name: "Date",
      selector: (row: any) => moment(row.date).format("DD-MM-yyyy"),
      sortable: true,
    },
    {
      name: searchComponent("receiveDate", "Receive Date"),
      selector: (row: any) =>
        row.receiveDate ? moment(row.receiveDate).format("DD-MM-yyyy") : "-",
      width: "150px",
    },
    {
      name: searchComponent("day", "day"),
      selector: (row: any) => row.day,
    },
    {
      name: searchComponent("jobNo", "Job No"),
      selector: (row: { [x: string]: any }) => row?.jobNo,
    },
    {
      name: searchComponent("designNo", "Design No"),
      selector: (row: { [x: string]: any }) => row?.designNo,
    },
    {
      name: searchComponent("name", "Name"),
      selector: (row: { [x: string]: any }) => row?.cName?.name,
    },
    {
      name: searchComponent("clothe", "cloth"),
      selector: (row: any) => row?.work[0]?.clothe?.name,
    },
    {
      // name: searchComponent("TPM", "Pcs"),
      name:  "Pcs",
      selector: (row: any) => row?.TPM,
    },
    {
      // name: searchComponent("RTPM", "Receive Pcs"),
      name:  "Receive Pcs",
      selector: (row: any) => (row?.RTPM ? row?.RTPM : "-"),
    },
    {
      // name: searchComponent("PTPM", "Pending Pcs"),
      name: "Pending Pcs",
      selector: (row: any) => Number(row?.TPM) - Number(row?.RTPM),
    },
    {
      name: searchComponent("plain", "plain"),
      selector: (row: any) => (row?.plain ? row?.plain : "-"),
    },
    {
      // name: searchComponent("short", "short"),
      name: "short",
      selector: (row: any) => (row?.short ? row?.short : "-"),
    },
    {
      name: "Total Receive Pcs ",
      // name: searchComponent("TRP", "Total Receive Pcs "),
      selector: (row: any) =>
        Number(Number(row?.RTPM) + Number(row?.plain) + Number(row?.short)),
    },
    {
      // name: searchComponent("rate", "Rate"),
      name: "Rate",
      selector: (row: any) => row?.rate,
    },
    {
      name: "Total Amount",
      // name: searchComponent("totalAmount", "Total Amount"),
      selector: (row: { [x: string]: any }) =>
        row?.totalAmount?.toString ? row?.totalAmount?.toString : "-",
    },
    {
      name: searchComponent("address", "Address"),
      selector: (row: { [x: string]: any }) => row?.cName?.address,
    },
    {
      name: searchComponent("itemName", "Item"),
      selector: (row: { [x: string]: any }) => row?.item?.name,
    },
    {
      name: searchComponent("cardNo", "Card No"),
      selector: (row: { [x: string]: any }) => row?.card?.number.split("-")[0],
    },
    {
      selector: (row: { [x: string]: any }) => row?.party?.name,
      name: searchComponent("partyName", "Party Name"),
    },
    
    {
      name: searchComponent("PCNo", "P Challan No"),
      selector: (row: { [x: string]: any }) => row?.PCNo,
    },
  ];
  const [selectedType, setSelectedType] = useState({
    type: [
      {
        label: "All Date",
        value: "allDate",
      },
      {
        label: "Receive Date",
        value: "receiveDate",
      },
      {
        label: "Pending Date",
        value: "pendingDate",
      },
    ],
    selectedDateType: { label: "", value: "" },
  });
  //#region Global searving section
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="flex flex-wrap gap-2">
        <div className="w-full md:w-44">
          <Select
            name="fromreceiveDate"
            classNamePrefix="customSelect"
            className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
            onChange={(e: any) => {
              if (e) {
                setSelectedType({
                  ...selectedType,
                  selectedDateType: { label: e.label, value: e.value },
                });
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: "",
                      status: false,
                    },
                    fromreceiveDate: {
                      text: "",
                      status: false,
                    },
                    fromDateall: {
                      text: "",
                      status: false,
                    },
                    toDate: {
                      text: "",
                      status: false,
                    },
                    toreceiveDate: {
                      text: "",
                      status: false,
                    },
                    toDateall: {
                      text: "",
                      status: false,
                    },
                  };
                });
              }
            }}
            options={selectedType?.type}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="fromDate"
            type="date"
            placeholder=" "
            label="From"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: "",
                      status: false,
                    },
                    fromreceiveDate: {
                      text: "",
                      status: false,
                    },
                    fromDateall: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else {
                setIsSearching(true);
                if (selectedType?.selectedDateType?.value === "allDate") {
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      fromDateall: {
                        text: e.target.value,
                        status: false,
                      },
                      fromreceiveDate: {
                        text: "",
                        status: false,
                      },
                      fromDate: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                } else if (
                  selectedType?.selectedDateType?.value === "pendingDate"
                ) {
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      fromDate: {
                        text: e.target.value,
                        status: false,
                      },
                      fromreceiveDate: {
                        text: "",
                        status: false,
                      },
                      fromDateall: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                } else {
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      fromreceiveDate: {
                        text: e.target.value,
                        status: false,
                      },
                      fromDate: {
                        text: "",
                        status: false,
                      },
                      fromDateall: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                }
              }
            }}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="toDate"
            type="date"
            placeholder=" "
            label="To"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: "",
                      status: false,
                    },
                    toreceiveDate: {
                      text: "",
                      status: false,
                    },
                    toDateall: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else {
                setIsSearching(true);
                if (selectedType?.selectedDateType?.value === "allDate") {
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      toDateall: {
                        text: e.target.value,
                        status: false,
                      },
                      toreceiveDate: {
                        text: "",
                        status: false,
                      },
                      toDate: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                } else if (
                  selectedType?.selectedDateType?.value === "pendingDate"
                ) {
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      toDate: {
                        text: e.target.value,
                        status: false,
                      },
                      toreceiveDate: {
                        text: "",
                        status: false,
                      },
                      toDateall: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                } else {
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      toreceiveDate: {
                        text: e.target.value,
                        status: false,
                      },
                      toDate: {
                        text: "",
                        status: false,
                      },
                      toDateall: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                }
              }
            }}
          />
        </div>
        <div className="md:block w-full md:w-max">
          <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
            <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
              <i className="fas fa-search w-4 fill-current"></i>
            </span>
            <input
              type="search"
              name="leadingIcon"
              id="leadingIcon"
              placeholder="Search here"
              value={filterText}
              onChange={(e: any) => {
                setFilterText(e.target.value);
              }}
              className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition"
            />
          </div>
        </div>
        <div>
          <button
            className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            type="button"
            onClick={() => {
              setIsExportData(true);
            }}
          >
            <i className="fas fa-file"></i>
            <span className="ml-2">Export Pdf</span>
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, selectedType]);

  useEffect(() => {
    if (filterText) {
      cardTrigger({
        search: filterText,
      });
    } else {
      globalTriggers(page, perPage);
    }
  }, [filterText]);
  //#endregion

  const openInvoicePreview = async (row: any) => {
    setShowPreview(true);
    if (isSearching) {
      let payload = {
        mcId: row._id,
        query: {
          isSingle: true,
        },
      };
      matchingTrigger(payload);
    } else {
      setSingleCard(row);
    }
  };

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let jwId = row._id;
        let updatedData = {
          status: false,
        };
        updateCard({
          payload: updatedData,
          query: {
            jwId: jwId,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Job Work has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            globalTriggers(page, perPage);
          }
        });
      }
    });
  };

  //#region Pagination section
  const handlePageChange = (page: any) => {
    setFilterText("");
    setPage(page);
    globalTriggers(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setFilterText("");
    setPage(page);
    setPerPage(newPerPage);
    globalTriggers(page, newPerPage);
  };
  //#endregion

  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("all");
                navigate(`/jobworkdashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "all"
                    ? "bg-gradient-to-r from-blue-100 to-violet-100"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={allCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                        <i className="leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("dress");
                navigate(`/dresschallandashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "dress"
                    ? "bg-gradient-to-r from-yellow-100 to-red-100"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Dress Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={dressCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("saree");
                navigate(`/sareechallandashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "saree"
                    ? "bg-gradient-to-r from-blue-100 to-teal-200"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Saree Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={sareeCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("lump");
                navigate(`/lumpchallandashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "lump"
                    ? "bg-gradient-to-r from-yellow-100 to-orange-200"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Lump Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={lumpCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-6 pt-6 mx-auto">
          <div className="rounded-xl bg-white">
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
              <div className="px-3">
                {type === "all" ? (
                  <button
                    className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    type="button"
                    onClick={() => {
                      navigate(`/jobwork/challan`);
                    }}
                  >
                    <i className="fas fa-folder-plus"></i>
                    <span className="ml-2">Add Challan</span>
                  </button>
                ) : (
                  <button
                    className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    type="button"
                    onClick={() => {
                      navigate(`/jobwork/${type}challan`);
                    }}
                  >
                    <i className="fas fa-folder-plus"></i>
                    <span className="ml-2">Add {type} Challan</span>
                  </button>
                )}
              </div>
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={type + " Challan"}
                    className="touch-auto"
                    columns={columns}
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortFieldId="invoiceNo"
                    defaultSortAsc={false}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    striped={true}
                    responsive={true}
                    highlightOnHover
                    persistTableHead
                    subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      setSelectedRow(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <TableAction
          header={selectedRow?.designNo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedRow={selectedRow}
          isCopy={false}
          isPreview={true}
          openInvoicePreview={openInvoicePreview}
          handleDelete={handleDelete}
          route={"jobwork/challan"}
        />
      ) : null}
      {/* Pdf Preview */}
      {showPreview && (
        <JobWorkPreview
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          id={singleCard?._id}
          pageName="Challan"
        />
      )}
      {isExportData && (
        <JobworkDataPdf
          isExportData={isExportData}
          setIsExportData={setIsExportData}
          sampleCard={cardData}
          allCount={allCount}
          isSearching={isSearching}
          search={search}
          type={type}
          pageName="JobWork Report"
        />
      )}
    </>
  );
}
