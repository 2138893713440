import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    lumpChallanData: []
}

export const lumpChallanSlice = createSlice({
    name: 'lumpChallanSlice',
    initialState,
    reducers: {
        setLumpChallan: (state, action) => {
            state.lumpChallanData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setLumpChallan
} = lumpChallanSlice.actions
export default lumpChallanSlice.reducer