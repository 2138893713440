import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { convertImageToBase64 } from "../../../utils/globalFunctions";
import ReactToPrint from "react-to-print";
import * as htmlToImage from "html-to-image";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";

export const SareeRateCardPreview = (props: any) => {
  const exportRef: any = useRef(null);
  const exportPartyRef: any = useRef(null);
  const exportTotalRatePdf: any = useRef(null);
  const sareeRateCard = props.singleCard;
  const [image, setImage]: any = useState();
  const [logo, setLogo]: any = useState();
  const [toggleState, setToggleState] = useState(1);
  const [loadingImage, setLoadingImage]: any = useState(false);

  const downloadImage = async (ref: any, fileName: any) => {
    const dataUrl = await htmlToImage.toPng(ref.current, { quality: 0.01 });
    // download image
    const link = document.createElement("a");
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  };
  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  useEffect(() => {
    setLoadingImage(true);
    if (sareeRateCard) {
      if (sareeRateCard?.image?.startsWith("https:")) {
        convertImageToBase64(sareeRateCard.image, setImage);
      } else {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "You have not uploaded any image\nPlease upload a new image.",
          position: "top-end",
          showConfirmButton: false,
          timer: ERROR_MESSAGE_TIMEOUT,
          timerProgressBar: true,
        });
        setLoadingImage(false);
      }
    }
    convertImageToBase64(
      "https://cnt-satv.s3.ap-south-1.amazonaws.com/logo.png",
      setLogo
    );
  }, []);

  useEffect(() => {
    if (image) {
      setLoadingImage(false);
    }
  }, [image]);

  let total: any = 0;

  sareeRateCard?.works?.forEach((card: any) => {
    total = total + card?.totalQty * (card?.rate + card?.valueAdd);
  });

  const SareeRatePdf = () => {
    return (
      <div className="overflow-auto p-2">
        <div
          ref={exportRef}
          className={`overflow-hidden a5-landscape bg-white`}
        >
          <div className="border border-gray-600">
            <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
              <div className="text-themeColor pl-25 capitalize">
                <img src={logo} alt="सत्व" />
              </div>
              <div className="text-themeColor text-7 text-left capitalize">
                design no : {sareeRateCard?.designNo}
              </div>
            </div>
            <div className="p-2 border-y border-black">
              <div className={`text-sm grid grid-cols-6 gap-2`}>
                <div className="flex justify-center col-span-2 row-span-2 overflow-hidden">
                  {image ? (
                    <img src={image} className="max-h-120 w-75" alt="" />
                  ) : null}
                </div>
                <div className="col-span-4">
                  {/* <table className="w-full border border-black border-collapse bg-white text-left text-5">
                                        <thead className="divide-black border-t border-x border-black bg-gray-100">
                                            <tr>
                                                <th scope="col" colSpan={sareeRateCard?.fabrics?.length == 1 ? 2 : 3} className="py-1.5 border-r text-red-800 border-black capitalize text-center">Saree</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y text-center divide-black border-x border-t border-black text-5">
                                            <tr className="text-center">
                                                <td width={sareeRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">fabric</td>
                                                {
                                                    sareeRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={sareeRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">{fabric?.item?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td width={sareeRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">saree fabric party</td>
                                                {
                                                    sareeRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={sareeRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black text-center capitalize">{fabric?.clothe?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td width={sareeRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black capitalize">blouse fabric party</td>
                                                {
                                                    sareeRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={sareeRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 py-1.5 border-r border-black text-center capitalize">{fabric?.clotheParty?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table> */}
                  <table className="w-full border border-collapse bg-white text-left text-5">
                    <thead className="divide-black border border-black">
                      <tr>
                        <th
                          scope="col"
                          colSpan={4}
                          className="py-1.5 bg-gray-100 border-r border-b text-red-800 border-black capitalize text-center text-2xl font-extrabold"
                        >
                          Saree
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                        >
                          item
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                        >
                          Quality
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                        >
                          Fabric Party
                        </th>
                        <th
                          scope="col"
                          className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                        >
                          Cut / Mtr.
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border border-black">
                      {sareeRateCard?.fabrics?.map((fabric: any) => {
                        return (
                          <tr>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.item?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.clothe?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.clotheParty?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.meter?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="w-full border border-collapse bg-white text-5 text-center mt-4">
                    <thead className="divide-black border-t border-x border-black">
                      <tr>
                        <th
                          scope="col"
                          colSpan={2}
                          className="py-1.5 border-r text-red-800 border-black capitalize bg-gray-100 text-2xl font-extrabold"
                        >
                          rate
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-black border border-black">
                      <tr>
                        <td
                          width="50%"
                          className="border-r py-1.5 border-black capitalize"
                        >
                          job work
                        </td>
                        <td
                          width="50%"
                          className="border-r py-1.5 border-black capitalize"
                        >
                          {sareeRateCard?.finalCosting?.work?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-black py-1.5 capitalize">
                          stitching
                        </td>
                        <td className="border-r border-black py-1.5 capitalize">
                          {sareeRateCard?.finalCosting?.stitching?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-black py-1.5 capitalize">
                          foil
                        </td>
                        <td className="border-r border-black py-1.5 text-gray-900r capitalize">
                          {sareeRateCard?.finalCosting?.foil?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-black py-1.5 capitalize">
                          hand work
                        </td>
                        <td className="border-r border-black py-1.5 text-gray-900r capitalize">
                          {sareeRateCard?.finalCosting?.hand?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-black py-1.5 capitalize">
                          fabrics
                        </td>
                        <td className="border-r border-black py-1.5 text-gray-900r capitalize">
                          {sareeRateCard?.finalCosting?.fabrics?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-black py-1.5 capitalize">
                          daying
                        </td>
                        <td className="border-r border-black py-1.5 text-gray-900r capitalize">
                          {sareeRateCard?.finalCosting?.daying?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-black py-1.5 capitalize">
                          &nbsp;
                        </td>
                        <td className="border-r border-black py-1.5 text-gray-900r capitalize">
                          &nbsp;
                        </td>
                      </tr>
                      <tr className="bg-gray-100">
                        <td className="border-r border-black py-1.5 capitalize text-red-800 font-extrabold text-2xl">
                          total rate
                        </td>
                        <td className="border-r border-black py-1.5 capitalize text-red-800 font-extrabold text-2xl">
                          {Number(sareeRateCard?.finalCosting?.work?.amount) +
                            Number(sareeRateCard?.finalCosting?.hand?.amount) +
                            Number(
                              sareeRateCard?.finalCosting?.stitching?.amount
                            ) +
                            Number(sareeRateCard?.finalCosting?.foil?.amount) +
                            Number(
                              sareeRateCard?.finalCosting?.daying?.amount || 0
                            ) +
                            Number(
                              sareeRateCard?.finalCosting?.fabrics?.amount
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PartyPdf = () => {
    return (
      <div className="overflow-auto p-2">
        <div
          ref={exportPartyRef}
          className={`overflow-hidden a5-landscape bg-white`}
        >
          <div className="border-t border-x border-black">
            <div className="grid grid-cols-4 font-bold items-center p-2 bg-gray-100">
              <div className="text-themeColor pl-25 capitalize">
                <img src={logo} alt="सत्व" />
              </div>
              <div className="text-cyan-900 font-extrabold col-span-2 text-5xl text-center capitalize">
                design no : {sareeRateCard?.designNo}
              </div>
            </div>
            <div className="p-2 border-y border-black">
              <div className={`text-sm grid grid-cols-6 gap-2`}>
                <div className="flex justify-center col-span-6 row-span-2 overflow-hidden bg-black">
                  {image ? (
                    <img
                      src={image}
                      className="max-h-[1200px] w-[800px]"
                      alt=""
                    />
                  ) : null}
                </div>
                <div className="col-span-6">
                  <table className="w-full border border-collapse bg-white text-left text-3xl">
                    <thead className="divide-black border border-black">
                      <tr>
                        <th
                          scope="col"
                          colSpan={4}
                          className="py-3 bg-gray-100 border-r border-b text-red-800 border-black capitalize text-center"
                        >
                          Saree
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          item
                        </th>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          Quality
                        </th>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          Fabric Party
                        </th>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          Cut / Mtr.
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border border-black">
                      {sareeRateCard?.fabrics?.map((fabric: any) => {
                        return (
                          <tr>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.item?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.clothe?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.clotheParty?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.meter?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className="divide-y text-center divide-black border border-black text-4xl">
                      <tr>
                        {sareeRateCard?.finalCosting?.work?.isSelected &&
                        (sareeRateCard?.finalCosting?.hand?.isSelected ||
                          sareeRateCard?.finalCosting?.fabric?.isSelected) ? (
                          <td
                            colSpan={
                              sareeRateCard?.finalCosting?.hand?.isSelected
                                ? 0
                                : 2
                            }
                            className="border-r text-center border-black capitalize bg-gray-100 py-3 text-red-900 font-bold"
                          >
                            total Job =&nbsp;
                            {(sareeRateCard?.finalCosting?.work?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.work?.amount
                                )
                              : 0) +
                              (sareeRateCard?.finalCosting?.stitching
                                ?.isSelected
                                ? Number(
                                    sareeRateCard?.finalCosting?.stitching
                                      ?.amount
                                  )
                                : 0) +
                              (sareeRateCard?.finalCosting?.foil?.isSelected
                                ? Number(
                                    sareeRateCard?.finalCosting?.foil?.amount
                                  )
                                : 0) +
                              (sareeRateCard?.finalCosting?.daying?.isSelected
                                ? Number(
                                    sareeRateCard?.finalCosting?.daying?.amount
                                  )
                                : 0)}
                          </td>
                        ) : null}
                        {sareeRateCard?.finalCosting?.hand?.isSelected ? (
                          <td
                            colSpan={
                              sareeRateCard?.finalCosting?.work?.isSelected
                                ? 0
                                : 2
                            }
                            className="border-r text-center border-black capitalize bg-gray-100 py-3 text-red-900 font-bold"
                          >
                            total Hand =&nbsp;
                            {sareeRateCard?.finalCosting?.hand?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.hand?.amount
                                )
                              : 0}
                          </td>
                        ) : null}
                        {sareeRateCard?.finalCosting?.fabrics?.isSelected ? (
                          <td
                            colSpan={
                              sareeRateCard?.finalCosting?.work?.isSelected ||
                              sareeRateCard?.finalCosting?.hand?.isSelected
                                ? 0
                                : 2
                            }
                            className="border-r text-center border-black capitalize bg-gray-100 py-3 text-red-900 font-bold"
                          >
                            total fabrics =&nbsp;
                            {sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.fabrics?.amount
                                )
                              : 0}
                          </td>
                        ) : null}
                        <td
                          colSpan={
                            sareeRateCard?.finalCosting?.hand?.isSelected ||
                            sareeRateCard?.finalCosting?.fabric?.isSelected
                              ? 2
                              : sareeRateCard?.finalCosting?.work?.isSelected &&
                                sareeRateCard?.finalCosting?.hand?.isSelected
                              ? 2
                              : 4
                          }
                          className="border-r text-center border-black capitalize bg-gray-100 py-3 text-red-900 font-bold"
                        >
                          total rate =&nbsp;
                          {(sareeRateCard?.finalCosting?.work?.isSelected
                            ? Number(sareeRateCard?.finalCosting?.work?.amount)
                            : 0) +
                            (sareeRateCard?.finalCosting?.hand?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.hand?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.stitching?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.stitching?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.foil?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.foil?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.daying?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.daying?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.fabrics?.amount
                                )
                              : 0)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          className="border-r text-center border-black capitalize bg-gray-100 py-3 font-bold"
                        >
                          Note : With&nbsp;
                          {"Job Work" +
                            (sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? " & "
                              : "") +
                            (sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? sareeRateCard?.finalCosting?.fabrics?.itemName
                              : "") +
                            (sareeRateCard?.finalCosting?.hand?.isSelected
                              ? " & "
                              : "") +
                            (sareeRateCard?.finalCosting?.hand?.isSelected
                              ? sareeRateCard?.finalCosting?.hand?.itemName
                              : "") +
                            (sareeRateCard?.finalCosting?.stitching?.isSelected
                              ? " & "
                              : "") +
                            (sareeRateCard?.finalCosting?.stitching?.isSelected
                              ? sareeRateCard?.finalCosting?.stitching?.itemName
                              : "")}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TotalRatePdf = () => {
    return (
      <div className="overflow-auto p-2">
        <div
          ref={exportTotalRatePdf}
          className={`overflow-hidden a5-landscape bg-white`}
        >
          <div className="border-t border-x border-black">
            <div className="grid grid-cols-4 font-bold items-center p-2 bg-gray-100">
              <div className="text-themeColor pl-25 capitalize">
                <img src={logo} alt="सत्व" />
              </div>
              <div className="text-cyan-900 font-extrabold col-span-2 text-5xl text-center capitalize">
                design no : {sareeRateCard?.designNo}
              </div>
            </div>
            <div className="p-2 border-y border-black">
              <div className={`text-sm grid grid-cols-6 gap-2`}>
                <div className="flex justify-center col-span-6 row-span-2 overflow-hidden bg-black">
                  {image ? (
                    <img
                      src={image}
                      className="max-h-[1200px] w-[800px]"
                      alt=""
                    />
                  ) : null}
                </div>
                <div className="col-span-6">
                  <table className="w-full border border-collapse bg-white text-left text-3xl">
                    <thead className="divide-black border border-black">
                      <tr>
                        <th
                          scope="col"
                          colSpan={4}
                          className="py-3 bg-gray-100 border-r border-b text-red-800 border-black capitalize text-center"
                        >
                          Saree
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          item
                        </th>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          Quality
                        </th>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          Fabric Party
                        </th>
                        <th
                          scope="col"
                          className="py-3 border-r text-red-800 border-black capitalize text-center"
                        >
                          Cut / Mtr.
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y text-center divide-black border border-black">
                      {sareeRateCard?.fabrics?.map((fabric: any) => {
                        return (
                          <tr>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.item?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.clothe?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.clotheParty?.name}
                            </td>
                            <td className="gap-3 border-r border-black text-center py-2 capitalize">
                              {fabric?.meter?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className="divide-y text-center divide-black border border-black text-4xl">
                      <tr>
                        <td
                          colSpan={4}
                          className="border-r text-center border-black capitalize bg-gray-100 py-3 text-red-900 font-bold"
                        >
                          total rate =&nbsp;
                          {(sareeRateCard?.finalCosting?.work?.isSelected
                            ? Number(sareeRateCard?.finalCosting?.work?.amount)
                            : 0) +
                            (sareeRateCard?.finalCosting?.hand?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.hand?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.stitching?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.stitching?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.foil?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.foil?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.daying?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.daying?.amount
                                )
                              : 0) +
                            (sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? Number(
                                  sareeRateCard?.finalCosting?.fabrics?.amount
                                )
                              : 0)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          className="border-r text-center border-black capitalize bg-gray-100 py-3 font-bold"
                        >
                          Note : With&nbsp;
                          {"Job Work" +
                            (sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? " & "
                              : "") +
                            (sareeRateCard?.finalCosting?.fabrics?.isSelected
                              ? sareeRateCard?.finalCosting?.fabrics?.itemName
                              : "") +
                            (sareeRateCard?.finalCosting?.hand?.isSelected
                              ? " & "
                              : "") +
                            (sareeRateCard?.finalCosting?.hand?.isSelected
                              ? "HandWork"
                              : "") +
                            (sareeRateCard?.finalCosting?.stitching?.isSelected
                              ? " & "
                              : "") +
                            (sareeRateCard?.finalCosting?.stitching?.isSelected
                              ? sareeRateCard?.finalCosting?.stitching?.itemName
                              : "")}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={props.showSareeRatePreview}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        onOverlayClick={() => props.setShowSareeRatePreview(false)}
        onEscKeyDown={() => props.setShowSareeRatePreview(false)}
        aria-labelledby="sample-card-pdf"
        aria-describedby="sample-card-pdf-description"
        animationDuration={400}
        onClose={() => props.setShowSareeRatePreview(false)}
      >
        <div className="overflow-auto">
          <div className="text-center">
            <div className="bg-white border rounded-lg text-left shadow-xl">
              <div className="w-full rounded-xl overflow-hidden p-1">
                <div className="border rounded-xl md:rounded-full p-1.5 bg-gray-50 shadow-sm">
                  <ul
                    className="flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row px-2"
                    role="tablist"
                  >
                    <li
                      className={`${
                        toggleState === 1
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(1)}
                      >
                        saree rate card
                      </button>
                    </li>
                    <li
                      className={`${
                        toggleState === 2
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(2)}
                      >
                        party pdf
                      </button>
                    </li>
                    <li
                      className={`${
                        toggleState === 3
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                      } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(3)}
                      >
                        Total Rate PDF
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full h-full rounded-xl overflow-hidden bg-white">
                <div
                  className={`transition-opacity duration-150 ease-linear ${
                    toggleState === 1 ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <SareeRatePdf />
                  <div className="m-3 flex flex-wrap gap-3">
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                        onClick={() => props.setShowSareeRatePreview(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                            disabled={loadingImage}
                          >
                            {loadingImage ? (
                              <>
                                <span>Loading</span>
                                <i className="fas fa-spinner animate-spin ml-2"></i>
                              </>
                            ) : (
                              <>
                                <span>Saree Rate Print</span>
                                <i className="fas fa-file-download ml-2"></i>
                              </>
                            )}
                          </button>
                        )}
                        content={() => exportRef.current}
                        copyStyles={true}
                        documentTitle={props.pageName + "-Satv"}
                      />
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                        onClick={() =>
                          downloadImage(
                            exportRef,
                            sareeRateCard?.designNo + "-Satv"
                          )
                        }
                        disabled={loadingImage}
                      >
                        {loadingImage ? (
                          <>
                            <span>Loading</span>
                            <i className="fas fa-spinner animate-spin ml-2"></i>
                          </>
                        ) : (
                          <>
                            <span>Saree Rate Image</span>
                            <i className="fas fa-file-image ml-2"></i>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`transition-opacity duration-150 ease-linear ${
                    toggleState === 2 ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <PartyPdf />
                  <div className="m-3 flex flex-col lg:flex-row gap-2">
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                            disabled={loadingImage}
                          >
                            {loadingImage ? (
                              <>
                                <span>Loading</span>
                                <i className="fas fa-spinner animate-spin ml-2"></i>
                              </>
                            ) : (
                              <>
                                <span>Saree Party Print</span>
                                <i className="fas fa-file-download ml-2"></i>
                              </>
                            )}
                          </button>
                        )}
                        content={() => exportPartyRef.current}
                        copyStyles={true}
                        documentTitle={props.pageName + "- Party"}
                      />
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                        onClick={() =>
                          downloadImage(
                            exportPartyRef,
                            sareeRateCard?.designNo + "-Party"
                          )
                        }
                        disabled={loadingImage}
                      >
                        {loadingImage ? (
                          <>
                            <span>Loading</span>
                            <i className="fas fa-spinner animate-spin ml-2"></i>
                          </>
                        ) : (
                          <>
                            <span>Saree Party Image</span>
                            <i className="fas fa-file-image ml-2"></i>
                          </>
                        )}
                      </button>
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                        onClick={() => props.setShowSareeRatePreview(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`transition-opacity duration-150 ease-linear ${
                    toggleState === 3 ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <TotalRatePdf />
                  <div className="m-3 flex flex-col lg:flex-row gap-2">
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                            disabled={loadingImage}
                          >
                            {loadingImage ? (
                              <>
                                <span>Loading</span>
                                <i className="fas fa-spinner animate-spin ml-2"></i>
                              </>
                            ) : (
                              <>
                                <span>Total Rate PDF</span>
                                <i className="fas fa-file-download ml-2"></i>
                              </>
                            )}
                          </button>
                        )}
                        content={() => exportTotalRatePdf.current}
                        copyStyles={true}
                        documentTitle={props.pageName + "- Party"}
                      />
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                        onClick={() =>
                          downloadImage(
                            exportTotalRatePdf,
                            sareeRateCard?.designNo + "-Party"
                          )
                        }
                        disabled={loadingImage}
                      >
                        {loadingImage ? (
                          <>
                            <span>Loading</span>
                            <i className="fas fa-spinner animate-spin ml-2"></i>
                          </>
                        ) : (
                          <>
                            <span>Total Rate Image</span>
                            <i className="fas fa-file-image ml-2"></i>
                          </>
                        )}
                      </button>
                    </div>
                    <div>
                      <button
                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                        onClick={() => props.setShowSareeRatePreview(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
