import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dressChallanData: []
}

export const dressChallanSlice = createSlice({
    name: 'dressChallanSlice',
    initialState,
    reducers: {
        setDressChallan: (state, action) => {
            state.dressChallanData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setDressChallan
} = dressChallanSlice.actions
export default dressChallanSlice.reducer